import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid, { Column } from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { getUsuarios, deleteUsuario } from "../../actions/administracion";
import EditarUsuario from "./EditarUsuario";
import "../../config";

//"0","","","ASC",""
const GestionUsuario = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px', height: '100%' }
    const { listaUser, loadingUser } = useSelector((state) => state.administracion);
    let [editUsuarioOpen, setEditUsuarioOpen] = useState("")
    let [usuarioSeleccionado, setUsuarioSeleccionado] = useState("null")
    let [mostrarUsuarios, setMostrarUsuarios] = useState(false)

    //console.log("aqui", listaUser, "fin")
    if (listaUser === null && mostrarUsuarios === false) {
        setMostrarUsuarios(true)
        dispatch(getUsuarios("0", "", "", "ASC", ""))
            .then(() => {
            })
            .catch(() => {
            });

    }

    let [plantillaFilter, setPlantillaFilter] = useState("");
    let [gridInfor, setGridInfor] = useState([listaUser !== null ? listaUser : []]);

    if (listaUser !== null && listaUser != gridInfor && plantillaFilter === "")
        setGridInfor(listaUser)

    function filterPlantilla(nego, plantillaFilter) {
        //value =>   getRol(data["Rol"])
        setGridInfor(nego.filter(function (item) {
            if (item["Nombre"].toLowerCase().includes(plantillaFilter.toLocaleLowerCase()) ||
                item["Nick"].toLowerCase().includes(plantillaFilter.toLocaleLowerCase()) ||
                item["Email"].toLowerCase().includes(plantillaFilter.toLocaleLowerCase()) ||
                getRol(item["Rol"]).toLowerCase().includes(plantillaFilter.toLocaleLowerCase())
            )
                return true;
            return false;
        }))
    }
    function openEditUser() {
        setEditUsuarioOpen("EDIT")
    }
    function openNewUser() {
        setUsuarioSeleccionado(null)
        setEditUsuarioOpen("EDIT")
    }
    function closeUser() {
        setEditUsuarioOpen("")
    }
    function closeWindowUsuario() {
        props.closeUsuarioClick()
    }
    const renderRowContextMenu = useCallback((menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
            {
                label: 'Editar',
                onClick: () => {
                    setUsuarioSeleccionado(rowProps.data)
                    setEditUsuarioOpen("EDIT")
                    console.log(rowProps.data)
                }
            },
            {
                label: 'Eliminar',
                onClick: () => {
                    confirmAlert({
                        title: 'Eliminar usuario ' + rowProps.data["Nombre"],
                        message: '¿Seguro desea eliminar?.',
                        buttons: [
                            {
                                label: 'Si',
                                onClick: () => {
                                    console.log("si eliminar")
                                    dispatch(deleteUsuario(rowProps.data["UsuarioId"]))
                                        .then(() => {

                                        })
                                        .catch(() => { });
                                }
                            },
                            {
                                label: 'No',
                                onClick: () => {

                                }
                            }
                        ]
                    });
                }
            }
        ]
    }, [])
    function getRol(str) {
        switch (str) {
            case "ROLE_ADMIN":
                return "ADMINISTRADOR"
            case "ROLE_RUEDA":
                return "DIRECTOR DE RUEDA"
            case "ROLE_CAJA":
                return "USUARIO CAJA"
            case "ROLE_VISITANTE":
                return "VISITANTE"
            case "ROLE_OPERADOR":
                return "USUARIO OPERADOR"
            case "ROLE_LIQUIDACION":
                return "USUARIO LIQUIDACIÓN"
            case "ROLE_REGISTRADOR":
                return "USUARIO REGISTRADOR"

        }

        return ""
    }
    let columns = [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Nombre', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE</div>), defaultFlex: 1, xdefaultLocked: 'start', defaultWidth: 400, headerAlign: "center" },
        { name: 'Nick', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>APODO</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center" },
        { name: 'Email', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>EMAIL</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center" },
        { name: 'Rol', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ROL</div>), defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => getRol(data["Rol"]), headerAlign: "center" },
    ]
    return (
        <div className={["overlay3", "fade-in"].join(" ")}>
            <div className={['loading_cont', loadingUser
                !== true ? "displaynone" : ""].join(' ')}><div className='single6'></div></div>

            {editUsuarioOpen === "EDIT" ? <EditarUsuario closeUser={closeUser} usuarioSeleccionado={usuarioSeleccionado} /> : ""}
            <div className="borderContent ventanaPortafolio">
                <div className="innerShadow-height-100">
                    <h2>
                        Usuarios
                        <div className="subItemCatalogo">
                            <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                                <MenuItem onClick={openNewUser}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem>
                            </Menu>
                        </div>
                    </h2>
                    <div className="titleTable-search">BUSCAR<div className='floatright'>
                        <div className="filterNeg">
                            <input type="text" placeholder="Filtrar" value={plantillaFilter} onChange={(e) => {
                                setPlantillaFilter(e.target.value);
                                filterPlantilla(listaUser !== null ? listaUser : [], e.target.value)
                            }} /><i className="fas fa-search"></i></div></div></div>
                    <DataGrid
                        idProperty="UsuarioId"
                        style={gridStyle}
                        //theme="default-light"                    
                        columns={columns}
                        enableSelection={true}
                        selection={{ mode: 'single' }}
                        dataSource={gridInfor}
                        renderRowContextMenu={renderRowContextMenu}
                        theme="amber-dark"
                    />
                    <div className="dividerLine"></div>
                    <div className="textCenter">
                        <div className="btnCancelar" onClick={closeWindowUsuario}>
                            SALIR
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionUsuario);
