import {
  GET_GESTION_CATALOGOS,
  GET_GESTION_CATALOGOS_FAIL,
  UPDATE_GESTION_CATALOGOS,
  UPDATE_GESTION_CATALOGOS_FAIL,
  DELETE_GESTION_CATALOGOS,
  DELETE_GESTION_CATALOGOS_FAIL,
  GET_CATALOGOS_PADRE,
  GET_CATALOGOS_PADRE_FAIL,
  DELETE_ITEM_CATALOGO,
  DELETE_ITEM_CATALOGO_FAIL,
  UPDATE_ITEM_CATALOGO,
  UPDATE_ITEM_CATALOGO_FAIL,
  GET_ITEM_CATALOGOS,
  GET_ITEM_CATALOGOS_FAIL,
  UPDATE_STATUS_BY_KEY_ADM,
  CATALOGOS_INI,
  ITEMS_INI,
  GET_EMPRESA,
  GET_EMPRESA_FAIL,
  EMPRESA_INI,
  DELETE_EMPRESA,
  DELETE_EMPRESA_FAIL,
  UPDATE_EMPRESA,
  UPDATE_EMPRESA_FAIL,
  GET_INVERSIONISTA,
  GET_INVERSIONISTA_FAIL,
  INVERSIONISTA_INI,
  DELETE_INVERSIONISTA,
  DELETE_INVERSIONISTA_FAIL,
  UPDATE_INVERSIONISTA,
  UPDATE_INVERSIONISTA_FAIL,
  GET_EMPRESA_INST,
  GET_EMPRESA_INST_FAIL,
  GET_USUARIOS_INV,
  GET_USUARIOS_INV_FAIL,
  GET_ORDEN,
  GET_ORDEN_FAIL,
  GET_ORDEN_USER_ID,
  GET_ORDEN_USER_ID_FAIL,
  UPDATE_ORDEN,
  UPDATE_ORDEN_FAIL,
  DELETE_ORDEN,
  DELETE_ORDEN_FAIL,
  ORDEN_INI,
  GET_EMPRESA_SELECT,
  GET_EMPRESA_SELECT_FAIL,
  GET_INVERSIONISTA_SELECT,
  GET_INVERSIONISTA_SELECT_FAIL,
  INVERSIONISTA_SELECT_INI,
  GET_EVENTO,
  GET_EVENTO_FAIL,
  UPDATE_EVENTO,
  UPDATE_EVENTO_FAIL,
  DELETE_EVENTO,
  DELETE_EVENTO_FAIL,
  EVENTO_INI,
  GET_USUARIO_EMPRESA,
  GET_USUARIO_EMPRESA_FAIL,
  GET_USUARIO_EMPRESA_SIN_ASIG,
  GET_USUARIO_EMPRESA_SIN_ASIG_FAIL,
  SELECT_USUARIO_EMPRESA,
  SELECT_USUARIO_EMPRESA_FAIL,
  DELETE_USUARIO_EMPRESA,
  DELETE_USUARIO_EMPRESA_FAIL,
  USUARIO_EMPRESA_INI,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  GET_USERS,
  GET_USERS_FAIL,
  DELETE_USER,
  DELETE_USER_FAIL,
  USER_INI,
  SELECT_EVENTO,
  SELECT_EVENTO_FAIL,
  GET_PARAMETRO,
  GET_PARAMETRO_FAIL,
  DELETE_PARAMETRO,
  DELETE_PARAMETRO_FAIL,
  UPDATE_PARAMETRO,
  UPDATE_PARAMETRO_FAIL,
  PARAMETRO_INI,
  GET_EMPRESA_CON_EVENTO,
  GET_EMPRESA_CON_EVENTO_FAIL,
  GET_TODO_EVENTO,
  GET_TODO_EVENTO_FAIL,
  EMPRESA_CON_EVENTO_INI,
  EVENTO_EMPRESA_INI,
  GET_EVENTO_EMPRESA,
  GET_EVENTO_EMPRESA_FAIL,
  GET_EVENTO_EMPRESA_SIN_ASIG,
  GET_EVENTO_EMPRESA_SIN_ASIG_FAIL,
  SELECT_EVENTO_EMPRESA,
  SELECT_EVENTO_EMPRESA_FAIL,
  DELETE_EVENTO_EMPRESA,
  DELETE_EVENTO_EMPRESA_FAIL,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAIL,
  ONLY_EVENT_DATA,
  ONLY_EVENT_DATA_FAIL,
  ONLY_EVENT_DATA_INI,
  DATA_EVENT_ID,
  GET_ADJUDICACION,
  GET_ADJUDICACION_FAIL,
  ADJUDICACION_INI,
  SALA_INI,
  ORDEN_USER_ID_INI,
  GET_DATA_EMPRESA,
  GET_DATA_EMPRESA_FAIL,
  DATA_EPMRESA_SINI,

  // AGG JP
  GET_EMPRESA_SELECT_INVERSIONISTA,
  GET_EMPRESA_SELECT_INVERSIONISTA_FAIL,
  GET_DATOS_ADJUDICACIONES,
  GET_DATOS_ADJUDICACIONES_FAIL,
  UPDATE_IMPRESION_LIQUIDACIONES,
  UPDATE_IMPRESION_LIQUIDACIONES_FAIL,
  UPDATE_IMPRESION_TITULOS,
  UPDATE_IMPRESION_TITULOS_FAIL,
  UPDATE_IMPRESION_REPORT_RUEDA,
  UPDATE_IMPRESION_REPORT_RUEDA_FAIL,
  UPDATE_INVITACION_EMAIL,
  UPDATE_INVITACION_EMAIL_FAIL,
  UPDATE_LIQUIDACION_EMAIL,
  UPDATE_LIQUIDACION_EMAIL_FAIL,
  UPDATE_LIQUIDACION,
  UPDATE_LIQUIDACION_FAIL,
  GET_CATALOGOS_MODALIDAD,
  GET_CATALOGOS_MODALIDAD_FAIL,
  UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL,
  UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL_FAIL,
  //control
  SEND_VALUE



} from "../actions/types";

const initialState = {
  listBanner: [],
  loadingBanner: false,
  listaCatalogosPag: null,
  listaCatalogosCount: 0,
  listaCatalogosPadre: null,
  listaItemsPag: null,
  listaItemsCount: 0,
  loadingCatalogos: false,
  loadingItems: false,
  listaEmpresa: null,
  listaEmpresaCount: 0,
  loadingEmpresa: false,
  listaInversionista: null,
  listaInversionistaCount: 0,
  loadingInversionista: false,
  listaInstitucionEmpresa: null,
  listaUsuarios: null,
  listaOrdenes: null,
  listaOrdenesCount: 0,
  listaOrdenesPorUsuario: null,
  listaOrdenesPorUsuarioCount: 0,
  loadingOrden: false,
  listaEmpresaSelect: null,
  listaInversionistaSelect: null,
  listaEventos: null,
  listaEventosCount: 0,
  loadingEventos: false,
  listaUsuarioEmpresa: null,
  listaUsuarioEmpresaSinAsig: null,
  loadingUsuarioEmpresa: false,
  listaUser: null,
  listaUserCount: 0,
  loadingUser: false,
  listaEventoSelect: null,
  listaParametro: null,
  listaParametroCount: 0,
  loadingParametro: false,
  listaEmpresaConEvento: null,
  listaTodosLosEventos: null,
  loadingEmpresaConEvento: false,
  listaEventoEmpresa: null,
  listaEventoEmpresaSinAsig: null,
  loadingEventoEmpresa: false,
  listaDatosEvento: null,
  loadingDatosEvento: false,
  datoEventoId: null,
  listaAdjudicacion: null,
  listaAdjudicacionCount: 0,
  loadingAdjudicacion: false,
  loadingSala: false,
  loadingOrdenUserId: false,
  loadingEmpresaConEvento: false,
  loadingInversionistaSelect: false,
  datosEmpresa: null,
  laodingDatosEmpresa: false,

  // AGG JP
  listaEmpresasSelecInversionista: null,
  listaDatosAdjudicaciones: null,
  listaAdjudicacionesCount: 0,
  loadingAdjudicacionesD: false,

  listaCatalogosModalidad: null,
  //
  loadingControl: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_STATUS_BY_KEY_ADM:
      switch (payload.key) {
        case "listaItemsPag":
          return {
            ...state,
            listaItemsPag: payload.value,
          };
        case "listaEmpresa":
          return {
            ...state,
            listaEmpresa: payload.value,
          };
        case "listaInversionista":
          return {
            ...state,
            listaInversionista: payload.value,
          };
        case "listaInstitucionEmpresa":
          return {
            ...state,
            listaInstitucionEmpresa: payload.value,
          };
        case "listaUsuarios":
          return {
            ...state,
            listaUsuarios: payload.value,
          };
        case "listaOrdenesPorUsuario":
          return {
            ...state,
            listaOrdenesPorUsuario: payload.value,
          };
        case "listaOrdenes":
          return {
            ...state,
            listaOrdenes: payload.value,
          };
        case "listaEmpresaSelect":
          return {
            ...state,
            listaEmpresaSelect: payload.value,
          };
        case "listaInversionistaSelect":
          return {
            ...state,
            listaInversionistaSelect: payload.value,
          };
        case "listaEventos":
          return {
            ...state,
            listaEventos: payload.value,
          };
        case "listaUsuarioEmpresa":
          return {
            ...state,
            listaUsuarioEmpresa: payload.value,
          };
        case "listaUsuarioEmpresaSinAsig":
          return {
            ...state,
            listaUsuarioEmpresaSinAsig: payload.value,
          };
        case "listaUser":
          return {
            ...state,
            listaUser: payload.value,
          };
        case "listaEventoSelect":
          return {
            ...state,
            listaEventoSelect: payload.value,
          };
        case "listaParametro":
          return {
            ...state,
            listaParametro: payload.value,
          };
        case "listaEventoEmpresa":
          return {
            ...state,
            listaEventoEmpresa: payload.value,
          };
        case "listaEventoEmpresaSinAsig":
          return {
            ...state,
            listaEventoEmpresaSinAsig: payload.value,
          };
        case "listaDatosEvento":
          return {
            ...state,
            listaDatosEvento: payload.data,
          };
        case "datoEventoId":
          return {
            ...state,
            datoEventoId: payload.data,
          };
        case "listaAdjudicacion":
          return {
            ...state,
            listaAdjudicacion: payload.data,
          };
        case "datosEmpresa":
          return {
            ...state,
            datosEmpresa: payload.data,
          };

        // agg JP
        case "listaEmpresaSelectInvesionista":
          return {
            ...state,
            listaEmpresasSelecInversionista: payload.value,
          };
        case "listaDatosAdjudicaciones":
          return {
            ...state,
            listaDatosAdjudicaciones: payload.value,
          };

        //control
        case "loadingControl":
          return {
            ...state,
            loadingControl: payload.value,
          };

        case "listaCatalogosModalidad":
          return {
            ...state,
            listaCatalogosModalidad: payload.value,
          };

      }
      break;
    case GET_GESTION_CATALOGOS:
      return {
        ...state,
        listaCatalogosPag: payload.data[0],
        listaCatalogosCount: payload.data[1],
        loadingCatalogos: false,
      };
    case GET_GESTION_CATALOGOS_FAIL:
      return {
        ...state,
        loadingCatalogos: false,
      };
    case UPDATE_GESTION_CATALOGOS:
      return {
        ...state,
        loadingCatalogos: false,
      };
    case UPDATE_GESTION_CATALOGOS_FAIL:
      return {
        ...state,
        loadingCatalogos: false,
      };
    case DELETE_GESTION_CATALOGOS:
      return {
        ...state,
        loadingCatalogos: false,
      };
    case DELETE_GESTION_CATALOGOS_FAIL:
      return {
        ...state,
        loadingCatalogos: false,
      };
    case GET_CATALOGOS_PADRE:
      return {
        ...state,
        listaCatalogosPadre: payload.data,
      };
    case GET_CATALOGOS_PADRE_FAIL:
      return {
        ...state,
      };
    case GET_ITEM_CATALOGOS:
      return {
        ...state,
        listaItemsPag: payload.data[0],
        listaItemsCount: payload.data[1],
        loadingItems: false,
      };
    case GET_ITEM_CATALOGOS_FAIL:
      return {
        ...state,
        loadingItems: false,
      };
    case UPDATE_ITEM_CATALOGO:
      return {
        ...state,
        loadingItems: false,
      };
    case UPDATE_ITEM_CATALOGO_FAIL:
      return {
        ...state,
        loadingItems: false,
      };
    case DELETE_ITEM_CATALOGO:
      return {
        ...state,
        loadingItems: false,
      };
    case DELETE_ITEM_CATALOGO_FAIL:
      return {
        ...state,
        loadingItems: false,
      };
    case CATALOGOS_INI:
      return {
        ...state,
        loadingCatalogos: true,
      };
    case ITEMS_INI:
      return {
        ...state,
        loadingItems: true,
      };
    case EMPRESA_INI:
      return {
        ...state,
        loadingEmpresa: true,
      };
    case GET_EMPRESA:
      return {
        ...state,
        listaEmpresa: payload.data[0],
        listaEmpresaCount: payload.data[1],
        loadingEmpresa: false,
      };
    case GET_EMPRESA_FAIL:
      return {
        ...state,
        loadingEmpresa: false,
      };
    case DELETE_EMPRESA:
      return {
        ...state,
        loadingEmpresa: false,
      };
    case DELETE_EMPRESA_FAIL:
      return {
        ...state,
        loadingEmpresa: false,
      };
    case UPDATE_EMPRESA:
      return {
        ...state,
        loadingEmpresa: false,
      };
    case UPDATE_EMPRESA_FAIL:
      return {
        ...state,
        loadingEmpresa: false,
      };
    case INVERSIONISTA_INI:
      return {
        ...state,
        loadingInversionista: true,
      };
    case GET_INVERSIONISTA:
      return {
        ...state,
        listaInversionista: payload.data[0],
        listaInversionistaCount: payload.data[1],
        loadingInversionista: false,
      };
    case GET_INVERSIONISTA_FAIL:
      return {
        ...state,
        loadingInversionista: false,
      };
    case DELETE_INVERSIONISTA:
      return {
        ...state,
        loadingInversionista: false,
      };
    case DELETE_INVERSIONISTA_FAIL:
      return {
        ...state,
        loadingInversionista: false,
      };
    case UPDATE_INVERSIONISTA:
      return {
        ...state,
        loadingInversionista: false,
      };
    case UPDATE_INVERSIONISTA_FAIL:
      return {
        ...state,
        loadingInversionista: false,
      };
    case GET_EMPRESA_INST:
      return {
        ...state,
        listaInstitucionEmpresa: payload.data,
        loadingEmpresa: false,
      };
    case GET_EMPRESA_INST_FAIL:
      return {
        ...state,
        loadingEmpresa: false,
      };
    case GET_USUARIOS_INV:
      return {
        ...state,
        listaUsuarios: payload.data,
        loadingInversionista: false,
      };
    case GET_USUARIOS_INV_FAIL:
      return {
        ...state,

        loadingInversionista: false,
      };
    case GET_ORDEN:
      return {
        ...state,
        listaOrdenes: payload.data[0],
        listaOrdenesCount: payload.data[1],
        loadingOrden: false,
      };
    case GET_ORDEN_FAIL:
      return {
        ...state,
        loadingOrden: false,
      };
    case GET_ORDEN_USER_ID:
      return {
        ...state,
        listaOrdenesPorUsuario: payload.data[0],
        listaOrdenesPorUsuarioCount: payload.data[1],
        loadingOrdenUserId: false,
      };
    case GET_ORDEN_USER_ID_FAIL:
      return {
        ...state,
        loadingOrdenUserId: false,
      };
    case ORDEN_USER_ID_INI:
      return {
        ...state,
        loadingOrdenUserId: true,
      };
    case UPDATE_ORDEN:
      return {
        ...state,
        loadingSala: false,
      };
    case UPDATE_ORDEN_FAIL:
      return {
        ...state,
        loadingSala: false,
      };
    case DELETE_ORDEN:
      return {
        ...state,
        loadingOrden: false,
      };
    case DELETE_ORDEN_FAIL:
      return {
        ...state,
        loadingOrden: false,
      };
    case ORDEN_INI:
      return {
        ...state,
        loadingOrden: true,
      };
    case GET_EMPRESA_SELECT:
      return {
        ...state,
        listaEmpresaSelect: payload.data,
        loadingSala: false,
      };
    case GET_EMPRESA_SELECT_FAIL:
      return {
        ...state,
      };
    case GET_INVERSIONISTA_SELECT:
      return {
        ...state,
        listaInversionistaSelect: payload.data,
        loadingInversionistaSelect: false,
      };
    case GET_INVERSIONISTA_SELECT_FAIL:
      return {
        ...state,
        loadingInversionista: false,
      };
    case INVERSIONISTA_SELECT_INI:
      return {
        ...state,
        loadingInversionistaSelect: true,
      };
    case GET_EVENTO:
      return {
        ...state,
        listaEventos: payload.data[0],
        listaEventosCount: payload.data[1],
        loadingEventos: false,
      };
    case GET_EVENTO_FAIL:
      return {
        ...state,
        loadingEventos: false,
      };
    case UPDATE_EVENTO:
      return {
        ...state,
        loadingEventos: false,
      };
    case UPDATE_EVENTO_FAIL:
      return {
        ...state,
        loadingEventos: false,
      };
    case DELETE_EVENTO:
      return {
        ...state,
        loadingEventos: false,
      };
    case DELETE_EVENTO_FAIL:
      return {
        ...state,
        loadingEventos: false,
      };
    case EVENTO_INI:
      return {
        ...state,
        loadingEventos: true,
      };
    case GET_USUARIO_EMPRESA:
      return {
        ...state,
        listaUsuarioEmpresa: payload.data,
        loadingUsuarioEmpresa: false,
      };
    case GET_USUARIO_EMPRESA_FAIL:
      return {
        ...state,
        loadingUsuarioEmpresa: false,
      };
    case GET_USUARIO_EMPRESA_SIN_ASIG:
      return {
        ...state,
        listaUsuarioEmpresaSinAsig: payload.data,
        loadingUsuarioEmpresa: false,
      };
    case GET_USUARIO_EMPRESA_SIN_ASIG_FAIL:
      return {
        ...state,
        loadingUsuarioEmpresa: false,
      };
    case SELECT_USUARIO_EMPRESA:
      return {
        ...state,
        loadingUsuarioEmpresa: false,
      };
    case SELECT_USUARIO_EMPRESA_FAIL:
      return {
        ...state,
        loadingUsuarioEmpresa: false,
      };

    case DELETE_USUARIO_EMPRESA:
      return {
        ...state,
        loadingUsuarioEmpresa: false,
      };
    case DELETE_USUARIO_EMPRESA_FAIL:
      return {
        ...state,
        loadingUsuarioEmpresa: false,
      };
    case USUARIO_EMPRESA_INI:
      return {
        ...state,
        loadingUsuarioEmpresa: true,
      };
    case GET_USERS:
      return {
        ...state,
        listaUser: payload.data[0],
        listaUserCount: payload.data[1],
        loadingUser: false,
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        usuariosLoading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        usuariosLoading: false,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,

        usuariosLoading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        usuariosLoading: false,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        usuariosLoading: false,
      };
    case USER_INI:
      return {
        ...state,
        usuariosLoading: true,
      };
    case SELECT_EVENTO:
      return {
        ...state,
        listaEventoSelect: payload.data,
      };
    case SELECT_EVENTO_FAIL:
      return {
        ...state,
      };
    case DELETE_PARAMETRO:
      return {
        ...state,
        loadingParametro: false,
      };
    case DELETE_PARAMETRO_FAIL:
      return {
        ...state,
        loadingParametro: false,
      };
    case GET_PARAMETRO:
      return {
        ...state,
        listaParametro: payload.data[0],
        listaParametroCount: payload.data[1],
        loadingParametro: false,
      };
    case GET_PARAMETRO_FAIL:
      return {
        ...state,
        loadingEmpresaConEvento: false,
      };
    case UPDATE_PARAMETRO:
      return {
        ...state,
        loadingParametro: false,
      };
    case UPDATE_PARAMETRO_FAIL:
      return {
        ...state,
        loadingParametro: false,
      };
    case PARAMETRO_INI:
      return {
        ...state,
        loadingParametro: true,
      };
    case GET_EMPRESA_CON_EVENTO:
      return {
        ...state,
        listaEmpresaConEvento: payload.data,
        loadingEmpresaConEvento: false,
      };

    case GET_EMPRESA_CON_EVENTO_FAIL:
      return {
        ...state,
        loadingEmpresaConEvento: false,
      };
    case GET_TODO_EVENTO:
      return {
        ...state,
        listaTodosLosEventos: payload.data,
        loadingEmpresaConEvento: false,
      };

    case GET_TODO_EVENTO_FAIL:
      return {
        ...state,
        loadingEmpresaConEvento: false,
      };

    case EMPRESA_CON_EVENTO_INI:
      return {
        ...state,
        loadingEmpresaConEvento: true,
      };
    case GET_EVENTO_EMPRESA:
      return {
        ...state,
        listaEventoEmpresa: payload.data,
        loadingEventoEmpresa: false,
      };
    case GET_EVENTO_EMPRESA_FAIL:
      return {
        ...state,
        loadingEventoEmpresa: false,
      };

    case GET_EVENTO_EMPRESA_SIN_ASIG:
      return {
        ...state,
        listaEventoEmpresaSinAsig: payload.data,
        loadingEventoEmpresa: false,
      };
    case GET_EVENTO_EMPRESA_SIN_ASIG_FAIL:
      return {
        ...state,
        loadingEventoEmpresa: false,
      };
    case SELECT_EVENTO_EMPRESA:
      return {
        ...state,
        loadingEventoEmpresa: false,
      };
    case SELECT_EVENTO_EMPRESA_FAIL:
      return {
        ...state,
        loadingEventoEmpresa: false,
      };

    case DELETE_EVENTO_EMPRESA:
      return {
        ...state,
        loadingEventoEmpresa: false,
      };
    case DELETE_EVENTO_EMPRESA_FAIL:
      return {
        ...state,
        loadingEventoEmpresa: false,
      };

    case EVENTO_EMPRESA_INI:
      return {
        ...state,
        loadingEventoEmpresa: true,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
      };
    case UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
      };
    case ONLY_EVENT_DATA:
      return {
        ...state,
        listaDatosEvento: payload.data[0],
        loadingDatosEvento: false,
      };
    case ONLY_EVENT_DATA_FAIL:
      return {
        ...state,
        loadingDatosEvento: false,
      };
    case ONLY_EVENT_DATA_INI:
      return {
        ...state,
        loadingDatosEvento: true,
      };
    case DATA_EVENT_ID:
      return {
        ...state,
        datoEventoId: payload.data,
      };
    case GET_ADJUDICACION:
      return {
        ...state,
        listaAdjudicacion: payload.data[0],
        listaAdjudicacionCount: payload.data[1],
        loadingAdjudicacion: false,
      };
    case GET_ADJUDICACION_FAIL:
      return {
        ...state,
        loadingAdjudicacion: false,
      };
    case ADJUDICACION_INI:
      return {
        ...state,
        loadingAdjudicacion: true,
      };
    case GET_EVENTO_EMPRESA_FAIL:
      return {
        ...state,
      };
    case SALA_INI:
      return {
        ...state,
        loadingSala: true,
      };
    case GET_DATA_EMPRESA:
      return {
        ...state,
        datosEmpresa: payload.data,
        laodingDatosEmpresa: false


      };
    case GET_DATA_EMPRESA_FAIL:
      return {
        ...state,
        laodingDatosEmpresa: false

      };




    // AGG JP
    case GET_EMPRESA_SELECT_INVERSIONISTA:
      return {
        ...state,
        listaEmpresasSelecInversionista: payload.data,

      };
    case GET_EMPRESA_SELECT_INVERSIONISTA_FAIL:
      return {
        ...state

      };

    // obtener adjudicaciones para menu liquidaciones
    case GET_DATOS_ADJUDICACIONES:
      return {
        ...state,
        listaDatosAdjudicaciones: payload.data[0],
        listaAdjudicacionesCount: payload.data[1],
        loadingAdjudicacionesD: false,
      };
    case GET_DATOS_ADJUDICACIONES_FAIL:
      return {
        ...state,
        loadingAdjudicacionesD: false,
      };

    // impresion certificados
    case UPDATE_IMPRESION_LIQUIDACIONES:
      return {
        ...state,
        liquidacionesLoading: false,
      };
    case UPDATE_IMPRESION_LIQUIDACIONES_FAIL:
      return {
        ...state,
        liquidacionesLoading: false,
      };

    // impresion titulos
    case UPDATE_IMPRESION_TITULOS:
      return {
        ...state,
        liquidacionesLoading: false,
      };
    case UPDATE_IMPRESION_TITULOS_FAIL:
      return {
        ...state,
        liquidacionesLoading: false,
      };

    // impresion reporte user rueda
    case UPDATE_IMPRESION_REPORT_RUEDA:
      return {
        ...state,
        loadingEventoEmpresa: false,
      };
    case UPDATE_IMPRESION_REPORT_RUEDA_FAIL:
      return {
        ...state,
        loadingEventoEmpresa: false,
      };

    case UPDATE_INVITACION_EMAIL:
      return {
        ...state,
        liquidacionesLoading: false,
      };
    case UPDATE_INVITACION_EMAIL_FAIL:
      return {
        ...state,
        liquidacionesLoading: false,
      };

    case UPDATE_LIQUIDACION_EMAIL:
      return {
        ...state,
        liquidacionesLoading: false,
      };
    case UPDATE_LIQUIDACION_EMAIL_FAIL:
      return {
        ...state,
        liquidacionesLoading: false,
      };
    case UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL:
      return {
        ...state,
        liquidacionesLoading: false,
      };
    case UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL_FAIL:
      return {
        ...state,
        liquidacionesLoading: false,
      };

    // guardar liquidaciones
    case UPDATE_LIQUIDACION:
      return {
        ...state,
        liquidacionesLoading: false,
      };
    case UPDATE_LIQUIDACION_FAIL:
      return {
        ...state,
        liquidacionesLoading: false,
      };
    // control juego
    case SEND_VALUE:
      return {
        ...state,
        loadingControl: false,
      };
    default:
      return state;

    // Catalogos modalidad
    case GET_CATALOGOS_MODALIDAD:
      return {
        ...state,
        listaCatalogosModalidad: payload.data,

      };
    case GET_CATALOGOS_MODALIDAD_FAIL:
      return {
        ...state

      };


  }
}
