import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { register } from "../actions/auth";

import logojunior from '../image/investTraderLogo.png';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const Register = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(register(username, email, password))
        .then(() => {
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  return (
      
      <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <div className="cryptorio-forms cryptorio-forms-dark text-center pt-5 pb-5">
                     <div className="logo">
                        <img src={logojunior} alt="Logo" />
                    </div>
                    <h3 className="p-4">Registro</h3>
                    <div className="cryptorio-main-form">
                        <form action="" className="text-left">
                            <label className="label-credenciales" for="email">Correo electrónico</label>
                            <input className="input-credenciales" type="text" id="email" name="email" placeholder="Tu correo electrónico"/>
                            <label className="label-credenciales" for="password">Contraseña</label>
                            <input className="input-credenciales" type="password" id="password" name="password" placeholder="6-20 letras y números"/>
                            <label className="label-credenciales" for="confirm-password">Repetir Contraseña</label>
                            <input className="input-credenciales" type="password" id="confirm-password" name="password" placeholder="6-20 letras y números"/>
                            <div className="my-1">
                              <div className="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" className="custom-control-input" id="terms-agree"/>
                                <label className="custom-control-label" for="terms-agree">Acepto los términos y condiciones</label>
                              </div>
                            </div>
                            <input type="submit" value="Registrarse" className="crypt-button-red-full"/>
                        </form>
                        <p className="float-left"><a href="login.html">Iniciar Sesión</a></p>
                        <p className="float-right"><a href="forgot.html">Recuperar Contraseña</a></p>
                    </div>
                </div>
            </div>
            <div className="col-md-3"></div>
        </div>
      
      

/*
        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block">Sign Up</button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
 */
  );
};

export default Register;
