const initialState = {
  valueApertura: false,
  valueSubasta: false,
  valueAdjudicacion: false,

  valueBotonApertura: true,
  valueBotonSubasta: true,
  valueBotonAdjudicacion: true,

  valueVisibleSelectEditarOrden: false,
  datosjuego:{}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEND_VALUE_APERTURA':
      return {
        ...state,
        valueApertura: !state.value
      };
    case 'SEND_VALUE_SUBASTA':
      return {
        ...state,
        valueSubasta: !state.valueSubasta
      };
    case 'SEND_VALUE_ADJUDICACION':
      return {
        ...state,
        valueAdjudicacion: !state.valueAdjudicacion
      };

    // botones
    case 'SEND_VALUE_BOTON_APERTURA':
      return {
        ...state,
        valueBotonApertura: false
      };
    case 'SEND_VALUE_BOTON_SUBASTA':
      return {
        ...state,
        valueBotonSubasta: false
      };
    case 'SEND_VALUE_BOTON_ADJUDICACION':
      return {
        ...state,
        valueBotonAdjudicacion: false
      };
    case 'SEND_VALUE_BOTON_EDITAR_ORDEN':
      return {
        ...state,
        valueVisibleSelectEditarOrden: true
      };
    
      case 'UPDATE_EVENTO_OBJECT':      
      return {
        ...state,
        datosjuego: action.payload.value
      };



    default:
      return state;
  }
};

export default reducer;
