import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {formatDateTime} from "./Util";
import {getEmpresaAct,deleteEmpresaAct} from "../../actions/administracion";
import EditarEmpresa from "./EditarEmpresa";
import UsuarioEmpresa from "./UsuarioEmpresa";
import "../../config";
import {NotificationContainer} from "react-notifications";

const Empresa = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'100%' }
    const {listaEmpresa,loadingEmpresa} = useSelector((state) => state.administracion);
    console.log("loadingEmpresa", loadingEmpresa)
    let [eventoSeleccionadoId] = useState(props.eventoSeleccionado);
    let [empresaSeleccionada,setEmpresaSeleccionada] = useState(null);
    let [usuarioEmpresaOpen,setUsuarioEmpresaOpen] = useState("");
    let [editEmpresaOpen, setEditEmpresaOpen] = useState("")
    let [loadEmpresa, setLoadEmpresa] = useState(false) 

    if(loadEmpresa===false){
        setLoadEmpresa(true)
        dispatch(getEmpresaAct("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }

    let [empresaFilter, setEmpresaFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaEmpresa!==null?listaEmpresa:[]]);     

    if(listaEmpresa!==null && listaEmpresa!=gridInfor && empresaFilter==="")
        setGridInfor(listaEmpresa)

    function filterCatalogo(nego,empresaFilter){
        //value => 
        setGridInfor(nego.filter(function(item) {
            if (item["Nombre"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()) || 
                item["Institucion"].toLowerCase().includes(empresaFilter.toLocaleLowerCase())||
                item["Email"].toLowerCase().includes(empresaFilter.toLocaleLowerCase())||
                item["Estado"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()))
                return true;
            return false;
        }))
    }
    function closeEmpresa(){
        props.closeEmpresaClick()
    }
    function openNewEmpresa(){
        setEmpresaSeleccionada(null)
        setEditEmpresaOpen("EDIT")
    }

    function closeEditEmpresa(){
        setEditEmpresaOpen("")
    }
    function closeUsuarioEmpresa(){
        setUsuarioEmpresaOpen("")
    }
    function formatEstado(estado){
		switch(estado){
			case "A":
				return "ACTIVO"
		}
		return "INACTIVO"
	}
    let columns= [
      
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Nombre',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center"},
        { name: 'Institucion',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>INSTITUCIÓN</div>), defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, headerAlign: "center" },        
        { name: 'Estado',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ESTADO</div>), defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => formatEstado(data["Estado"]), headerAlign: "center" },           
        { name: 'Email',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>EMAIL</div>), defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, headerAlign: "center"},           
    ]
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Usuarios',
            onClick:()=>{   
                setEmpresaSeleccionada(rowProps.data)
                setUsuarioEmpresaOpen("OPEN")
                console.log(rowProps.data)
            }
        },      
        {
            label: 'Editar',
            onClick:()=>{   
                setEmpresaSeleccionada(rowProps.data)
                setEditEmpresaOpen("EDIT")
                console.log(rowProps.data)
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar empresa',
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                           dispatch(deleteEmpresaAct(rowProps.data["EmpresaId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])  
  return (
    <div
      className={[
        "overlay3",
        "fade-in"
      ].join(" ")}
    > 
     <div className={['loading_cont',loadingEmpresa
     !==true?"displaynone":""].join(' ')}><div className='single6'></div></div>
      {editEmpresaOpen==="EDIT" ? <EditarEmpresa 
      sendMessage={props.sendMessage}
      closeEditEmpresa={closeEditEmpresa} 
      empresaSeleccionada={empresaSeleccionada}/>  :  ""}
      {usuarioEmpresaOpen==="OPEN" ? <UsuarioEmpresa closeUsuarioEmpresa={closeUsuarioEmpresa} eventoSeleccionado={eventoSeleccionadoId}  empresaSeleccionada={empresaSeleccionada}/>  :  ""}
        <div className="borderContent ventanaPortafolio">
            <div className="innerShadow-height-100">
                <h2>
                    Empresa
                    <div className="subItemCatalogo">
                        <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                            <MenuItem onClick={openNewEmpresa}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                        </Menu>
                    </div>
                </h2>  
                <div className="titleTable-search">EMPRESA<div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
                  setEmpresaFilter(e.target.value);
                  filterCatalogo(listaEmpresa!==null?listaEmpresa:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div> 
                <DataGrid
                    idProperty="EmpresaId"
                    style={gridStyle}                   
                    columns={columns}
                    enableSelection={true}
                    selection={{ mode: 'single' }}                    
                    dataSource={gridInfor}
                    renderRowContextMenu={renderRowContextMenu}
                    theme="amber-dark"
                />
                <div className="dividerLine"></div>
                <div className="textCenter">
                    <div className="btnCancelar"  onClick={closeEmpresa}>
                        SALIR
                    </div>
                </div> 
            </div>
        </div>  
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Empresa);
