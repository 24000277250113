import {NotificationManager} from "react-notifications";
import {
   
    SEND_EMAIL_PASSWORD,
    SEND_EMAIL_PASSWORD_FAIL
} from "./types";
  
import UserService from "../services/user.service";

export const sendEmailPassword = (email) => (dispatch) => {
   
    return UserService.getPassword(email).then(
      (response) => {
        if(response===0){
          
          dispatch({
            type: SEND_EMAIL_PASSWORD,
          });        
          NotificationManager.success(
            "Se ha enviado una notificación a su correo!",
            "Sistema",
            3000,
            () => {},
            4
          );    
        }else if(response ===-1){        
          dispatch({
            type: SEND_EMAIL_PASSWORD_FAIL,
          });
          NotificationManager.error(
            "Error, no se encontró el correo",
            "Sistema",
            3000,
            () => {},
            4
          );
        }            
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: SEND_EMAIL_PASSWORD_FAIL,
        });
       
        return Promise.reject();
      }
    );
  };
  
  
