import { connect, useDispatch, useSelector } from "react-redux";
import React, { useCallback, useState } from 'react'
import DataGrid, { Column } from '@inovua/reactdatagrid-community';
import { getEventoEmpresaAct, updateImpresionReportAdjudicacion, updateImpresionReportLiquidacion, updateImpresionReportRueda } from "../../actions/administracion";
import fileDownload from 'js-file-download'
import axios from "axios";
import { formatNumber } from "./Util";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";


const ReporteUserRueda = (props) => {
    const dispatch = useDispatch();
    const { listaEventoEmpresa, loadingEventoEmpresa } = useSelector((state) => state.administracion);
    const gridStyle = { minHeight: '300px', height: '100%' }
    let [eventoSeleccionadoId] = useState(props.eventoSeleccionado);
    //console.log(eventoSeleccionadoId);
    let [plantillaFilter, setPlantillaFilter] = useState("");
    let [gridInfor, setGridInfor] = useState([listaEventoEmpresa !== null ? listaEventoEmpresa : []]);
    let [loadEventoEmpresa, setloadEventoEmpresa] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    if (loadEventoEmpresa === false) {
        setloadEventoEmpresa(true)
        dispatch(getEventoEmpresaAct(eventoSeleccionadoId))
            .then(() => {
            })
            .catch(() => {
            });

    }

    if (listaEventoEmpresa !== null && listaEventoEmpresa != gridInfor && plantillaFilter === "")
        setGridInfor(listaEventoEmpresa)

    function closeReporte() {
        props.closeReporteClick()
    }

    const renderButtonI = (value, rowData) => {
        return (
            <>
                <button id={value.data !== null ?
                    value.data["EventoId"] : "0"}
                    className="fas fa-print"
                    onClick={(e) => {
                        handleClickReporEvento(e)
                        //setIsLoading(true) 
                    }
                    }></button>
            </>
        )
    }


    function downloadExcel(file) {
        window.open(file);
    }
    const handleClickReporEvento = (rowData) => {
        let eventoId = parseInt(rowData.target.id)
        let data = listaEventoEmpresa.filter(x => x.EventoId === eventoId)[0]//rowData.cellProps["data"]
        if (data !== null) {
            dispatch(updateImpresionReportRueda(eventoSeleccionadoId))
                .then((url) => {
                    downloadExcel(url)
                    setIsLoading(false);
                })
                .catch(() => {
                });
        }

    }

    const handleClickReporLiquidacion = (rowData) => {
        let eventoId = parseInt(rowData.target.id)
        let data = listaEventoEmpresa.filter(x => x.EventoId === eventoId)[0]//rowData.cellProps["data"]
        if (data !== null) {
            dispatch(updateImpresionReportLiquidacion(eventoSeleccionadoId))
                .then((url) => {
                    downloadExcel(url)
                    setIsLoading(false);
                })
                .catch(() => {
                });
        }

    }
    const handleClickReporAdjudicacion = (rowData) => {
        let eventoId = parseInt(rowData.target.id)
        let data = listaEventoEmpresa.filter(x => x.EventoId === eventoId)[0]//rowData.cellProps["data"]
        if (data !== null) {
            dispatch(updateImpresionReportAdjudicacion(eventoSeleccionadoId))
                .then((url) => {
                    downloadExcel(url)
                    setIsLoading(false);
                })
                .catch(() => {
                });
        }

    }



    function ReporEventos(eventoIds) {
        let eventoId = parseInt(eventoIds)
        let data = listaEventoEmpresa.filter(x => x.EventoId === eventoId)[0]//rowData.cellProps["data"]
        if (data !== null) {
            dispatch(updateImpresionReportRueda(eventoSeleccionadoId))
                .then((url) => {
                    downloadExcel(url)
                    setIsLoading(false);
                })
                .catch(() => {
                });
        }
    }
    function ReporLiquidacion(eventoIds) {
        let eventoId = parseInt(eventoIds)
        let data = listaEventoEmpresa.filter(x => x.EventoId === eventoId)[0]//rowData.cellProps["data"]
        if (data !== null) {
            dispatch(updateImpresionReportLiquidacion(eventoSeleccionadoId))
                .then((url) => {
                    downloadExcel(url)
                    setIsLoading(false);
                })
                .catch(() => {
                });
        }
    }
    function ReporAdjudicacion(eventoIds) {
        let eventoId = parseInt(eventoIds)
        let data = listaEventoEmpresa.filter(x => x.EventoId === eventoId)[0]//rowData.cellProps["data"]
        if (data !== null) {
            dispatch(updateImpresionReportAdjudicacion(eventoSeleccionadoId))
                .then((url) => {
                    downloadExcel(url)
                    setIsLoading(false);
                })
                .catch(() => {
                });
        }
    }


    let columns = [

        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Institucion', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>COLEGIO</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center", minWidth: 150 },
        { name: 'Nombre', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE COMPAÑÍA</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center", minWidth: 200 },
        { name: 'ValorUnitarioAccion', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>VALOR ACCIÓN</div>), defaultFlex: 1, xdefaultLocked: 'end', className: "numDataGrid", render: ({ value }) => formatNumber(value), headerAlign: "center", minWidth: 145 },
        { name: 'NumAccionesOferta', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}># ACCIONES</div>), defaultFlex: 1, xdefaultLocked: 'end', className: "numDataGrid", headerAlign: "center", minWidth: 130 },
        { name: 'Receptor', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>RECEPTOR</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center" },
        { name: 'Gerente', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>GERENTE</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center" },
        { name: 'NombreExponente', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>EXPONENTE</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center", minWidth: 130 },
        /*{ name: "ImprimirReporte", header: "Exportar Reporte", defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonI(rowData)}*/]

    return (
        <div className={["overlay3", "fade-in"].join(" ")}>

            <div className={['loading_cont', (isLoading
                !== true) ? "displaynone" : ""].join(' ')}><div className='single6'></div></div>

            <div className="borderContent ventanaPortafolio">
                <div className="innerShadow-height-100">
                    <h2>
                        Reportes
                        <div className="subItemCatalogo">
                            {/*<Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                                <MenuItem onClick={(e) => {
                                    ReporEventos(eventoSeleccionadoId)
                                    setIsLoading(true)
                                }}>Evento</MenuItem>
                                <MenuItem onClick={(e) => {
                                    ReporLiquidacion(eventoSeleccionadoId)
                                    setIsLoading(true)
                                }}>Liquidación</MenuItem>
                                <MenuItem onClick={(e) => {
                                    ReporAdjudicacion(eventoSeleccionadoId)
                                    setIsLoading(true)
                                }}>Adjudicación</MenuItem>
                            </Menu>*/}
                        </div>
                    </h2>
                    <div className="titleTable-search">REPORTE DEL EVENTO
                        <div className='floatright'>
                            <div className="filterNeg">

                            </div>
   
                            <div className="fas fa-file-excel btnReportes" onClick={(e) => {
                                    handleClickReporEvento(e)
                                    setIsLoading(true)
                                }}> Evento
                            </div>
                            <div className="fas fa-file-excel btnReportes" onClick={(e) => {
                                    handleClickReporLiquidacion(e)
                                    setIsLoading(true)
                                }}> Liquidación
                            </div>
                            <div className="fas fa-file-excel btnReportes" onClick={(e) => {
                                    handleClickReporAdjudicacion(e)
                                    setIsLoading(true)
                                }}> Adjudicación
                            </div>
                        </div>
                    </div>
                    <DataGrid
                        idProperty="AdjudicacionId"
                        style={gridStyle}
                        //theme="default-light"
                        columns={columns}
                        selection={{ mode: "single" }}
                        dataSource={gridInfor}
                        theme="amber-dark"
                    />

                    <div className="dividerLine">

                    </div>

                    <div className="textCenter">
                        <div className="btnCancelar" onClick={closeReporte}>
                            SALIR
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(ReporteUserRueda);
