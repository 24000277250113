import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import UploadFile from "./UploadFile";

import { updatePerfilUsuario } from "../../actions/administracion";
import "../../config";

const Sala = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  let history = useHistory();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);

  let [errors] = useState({ emailErr: "" }, { nombreErr: "" }, { nickErr: "" });

  let [nombre, setNombre] = useState(
    currentUser !== null ? currentUser["Nombre"] : ""
  );

  let [nick, setNick] = useState(
    currentUser !== null ? currentUser["displayName"] : ""
  );

  let [email, setEmail] = useState(
    currentUser !== null ? currentUser["email"] : ""
  );

  let [clave, setClave] = useState("");

  let [uploadId, setUploadId] = useState(
    currentUser !== null ? currentUser["UploadId"] : "-1"
  );

  let [fileNombre, setFileNombre] = useState(
    currentUser !== null ? currentUser["fileNombre"] : ""
  );

  let [fileNombreUrl, setFileNombreUrl] = useState(
    currentUser !== null ? currentUser["fileNombreUrl"] : ""
  );

  let [imagenUrl, setImagenUrl] =  useState(
    currentUser !== null ? currentUser["ImagenUrl"] : ""
  );


  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  const nombreRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;
  const textoRegex = /^[a-zA-Z0-9{Ñ}{ñ}\s]*$/;

  function actualizarPerfil() {
    let valid = true;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      errors["emailErr"] = "Ingrese email (example@example.com)"
      valid = false;
    }
    if (nombre == "") {
      errors["nombreErr"] = "Ingrese nombre";
      valid = false;
    }
    if (!nombreRegex.test(nombre)) {
      errors["nombreErr"] = "Ingrese solo letras"
      valid = false
    }


    if (nick == "") {
      errors["nickErr"] = "Ingrese apodo";
      valid = false;
    }
    if (!textoRegex.test(nick)) {
      errors["nickErr"] = "Ingrese solo letras y números"
      valid = false
    }
    if (!valid) {
      return;
    }
    dispatch(
      updatePerfilUsuario(
        currentUser["userId"],
        nombre,
        nick,
        email,
        clave,
        imagenUrl,
        uploadId
      )
    )
      .then(() => {
        window.location.reload();
      })
      .catch(() => { });

    NotificationManager.success(
      "Información actualizada",
      "Sistema",
      3000,
      () => { },
      4
    );
    closeWindow();
  }
  function closeWindow() {
    props.closeProfileClick();
  }

  function closeEditEmpresa() {
    props.closeEditEmpresa();
  }
  function reloadDocuments(file) {
    const result = JSON.parse(file);
    setUploadId(result["UploadId"]);
    setFileNombre(result["fileNombre"]);
    setFileNombreUrl(result["fileNombreUrl"]);
  }

  const convertirBase64 = (files) => {
    Array.from(files).forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let arrayImage = []
        let base = reader.result;
        arrayImage = base.split(',');
        setImagenUrl(arrayImage[1])


      }
    })
  }

  return (
    <div className="overlay3 fade-in">
      <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
          <div id="formulario-estilo">
            <h2>Configuración</h2>
            <div className="vertical-scroll customScroll">
              <div className="columna-1-formulario-ventana-perfil-edit-evento-user">
                <div className="row-form">
                  <div className="col-30">
                    <label>
                      <i className="fas fa-edit"></i> <span>Nombre:</span>
                    </label>
                  </div>
                  <div className="col-70">
                    <input
                    autocomplete="off"
                      type="text"
                      value={nombre}
                      maxLength={100}
                      placeholder="Ingresar nombre"
                      className="input-form"
                      onChange={(e) => {
                        setNombre(e.target.value);
                        errors["nombreErr"] = "";
                      }}
                    ></input>
                    {errors["nombreErr"] !== "" ? (
                      <div className="camporequerido">{errors["nombreErr"]}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row-form">
                  <div className="col-30">
                    <label>
                      <i className="fas fa-edit"></i> <span>Nick:</span>
                    </label>
                  </div>
                  <div className="col-70">
                    <input
                    autocomplete="off"
                      type="text"
                      value={nick}
                      maxLength={100}
                      placeholder="Ingresar nick"
                      className="input-form"
                      onChange={(e) => {
                        setNick(e.target.value);
                        errors["nickErr"] = "";
                      }}
                    ></input>
                    {errors["nickErr"] !== "" ? (
                      <div className="camporequerido">{errors["nickErr"]}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row-form">
                  <div className="col-30">
                    <label>
                      <i className="fas fa-edit"></i> <span>Email:</span>
                    </label>
                  </div>
                  <div className="col-70">
                    <input
                    autocomplete="off"
                      type="email"
                      value={email}
                      maxLength={100}
                      placeholder="Ingresar email"
                      className="input-form"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        errors["emailErr"] = "";
                      }}
                    ></input>
                    {errors["emailErr"] !== "" ? (
                      <div className="camporequerido">{errors["emailErr"]}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row-form">
                  <div className="col-30">
                    <label>
                      <i className="fas fa-edit"></i> <span>Contraseña:</span>
                    </label>
                  </div>
                  <div className="col-70">
                    <input
                    autocomplete="new-password"
                      type={passwordVisible ? "text" : "password"}
                      value={clave}
                      maxLength={500}
                      placeholder="Ingresar contraseña"
                      className="input-form"
                      onChange={(e) => {
                        setClave(e.target.value);

                      }}
                    ></input>
                    <button className={passwordVisible ? "fas fa-eye fa-1x" : "fas fa-eye-slash fa-1x"} onClick={() => setPasswordVisible(!passwordVisible)}></button>
                  </div>
                </div>
                <div className="row-form">
                  <div className="col-30">
                    <label>
                      <i className="fas fa-edit"></i> <span>Imagen:</span>
                    </label>
                  </div>
                  <div className="col-70">

                    <input type="file" className="boxStyleSelect" multiple onChange={(e) => convertirBase64(e.target.files)}></input>
                    <div className="boxStyleSelect">Selecciona el archivo para subirlo</div>

                  </div>
                </div>
              </div>
            </div>

            <div className="dividerLine"></div>
            <div className="textCenter">
              <div className="btnGuardar" onClick={actualizarPerfil}>
                GUARDAR
              </div>{" "}
              <div className="btnCancelar" onClick={closeWindow}>
                CANCELAR
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Sala);
