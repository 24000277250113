import React, { useState, useEffect,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import {updateParametroAct} from "../../actions/administracion";
import "../../config";
import NumberFormat from "react-number-format";
const EditarGestionItems = (props) => {
    const dispatch = useDispatch();
    let [errors,setErrors] = useState({"codigoErr":""},{"nombreErr":""},{"estadoErr":""},{"valorErr":""},{"descripcionErr":""})
    let [codigo,setCodigo] = useState(props.parametroSeleccionado!==null?props.parametroSeleccionado["Codigo"]:"")
    let [nombre, setNombre] = useState(props.parametroSeleccionado!==null?props.parametroSeleccionado["Nombre"]:"")
    let [descripcion,setDescripcion] = useState(props.parametroSeleccionado!==null?props.parametroSeleccionado["Descripcion"]:"")
    let [valor,setValor] = useState(props.parametroSeleccionado!==null?props.parametroSeleccionado['Valor']:"")
    let [isError,setIsError] = useState(false)
    const { user: currentUser } = useSelector((state) => state.auth);
    const closeEditParametro = () => props.closeEditParametro();
    let cambioEstado=useState(null)
    
    if(props.parametroSeleccionado!==null && props.parametroSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.parametroSeleccionado!==null && props.parametroSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.parametroSeleccionado===null){
        cambioEstado ={value:'A', label:"Activo"}
    }
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'A', label: 'Activo' },
        { value: 'I', label: 'Inactivo' },
        
    ] 
    const nombreRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;
    const textoRegex = /^[a-zA-Z0-9À-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;

    function actualizar(){
      
       let valid=true
       
        if(codigo == ""){
            errors["codigoErr"] = "Ingrese código"
            valid = false
        }
        if(nombre == ""){
            errors["nombreErr"] = "Ingrese nombre"
            valid = false
        }
     
        if(estadoSelect == null){
            errors["estadoErr"] = "Ingrese estado"
            valid = false
        }

        if(valor == ""){
            errors["valorErr"] = "Ingrese valor"
            valid = false
        }
        if(descripcion == ""){
            errors["descripcionErr"] = "Ingrese descripción"
            valid = false
        }
        if(!textoRegex.test(nombre)){
            errors["nombreErr"] = "Ingrese solo letras y números"
            valid = false
        }
        if(!textoRegex.test(codigo)){
            errors["codigoErr"] = "Ingrese solo letras y números"
            valid = false
        }
        /*if(!textoRegex.test(descripcion)){
            errors["descripcionErr"] = "Ingrese solo letras y números"
            valid = false
        }*/

        
        setErrors(errors)
        setIsError(true)
        if(!valid){
            return
        }
        dispatch(updateParametroAct(
            props.parametroSeleccionado!==null?props.parametroSeleccionado["ParametroId"]:-1,
            codigo,
            nombre,
            descripcion,
            valor,
            currentUser["userId"],
            estadoSelect["value"]      
            )).then(() => {     
    
             }).catch(() => {}
        );      
       closeEditParametro()
    }

    return (
<div className= "overlay3 fade-in">
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow-height-100">
            <div id="formulario-estilo">
                <h2>
                    Parámetro            
                </h2>  
                <div className='vertical-scroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil-edit-evento-user"> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Código:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                autocomplete="off"
                                    type="text"
                                    value={codigo}
                                    maxLength={50}
                                    placeholder="Ingresar código"
                                    className="input-form"
                                    onChange={(e) => {
                                        setCodigo(e.target.value.toUpperCase());
                                        errors["codigoErr"] = ""
                                    }}
                                ></input>
                                {errors["codigoErr"]!==""?<div className='camporequerido'>{errors["codigoErr"]}</div>:""}
                            </div> 
                        </div>  
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Nombre:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                autocomplete="off"
                                    type="text"
                                    value={nombre}
                                    maxLength={200}
                                    placeholder="Ingresar nombre"
                                    className="input-form"
                                    onChange={(e) => {
                                        setNombre(e.target.value);
                                        errors["nombreErr"] = ""
                                    }}
                                ></input>
                                {errors["nombreErr"]!==""?<div className='camporequerido'>{errors["nombreErr"]}</div>:""}
                            </div> 
                        </div> 
                       
                        
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Valor:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <NumberFormat
                                    autocomplete="off"
                                    value={valor}
                                    maxLength={50}
                                    decimalScale={2}
                            fixedDecimalScale={true} 
                            decimalSeparator={'.'} 
                                    placeholder="Ingresar valor"
                                    className="input-form"
                                    onChange={(e) => {
                                        setValor(e.target.value);
                                        errors["valorErr"] = ""
                                    }}
                                ></NumberFormat>
                                {errors["valorErr"]!==""?<div className='camporequerido'>{errors["valorErr"]}</div>:""}
                            </div> 
                        </div>  
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select  
                                autocomplete="off"
                                className="input-form" id="estadoSelectCombo"  value={estadoSelect} onChange={(e) => {
                                    setEstadoSelect(e)
                                    errors["estadoErr"] = ""
                                }} placeholder="Seleccione" options={optionsEstado}/>
                                {errors["estadoErr"]!==""?<div className='camporequerido'>{errors["estadoErr"]}</div>:""}
                            </div>
                        </div>           
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Descripción:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <textarea
                                autocomplete="off"
                                type="text"
                                value={descripcion}
                                maxLength={1000}
                                
                                placeholder="Descripción"
                                className="text-area-form"
                                onChange={(e) => {
                                    setDescripcion(e.target.value);   
                                    errors["descripcionErr"] = ""                            
                                }}
                                ></textarea>   
                                {errors["descripcionErr"]!==""?<div className='camporequerido'>{errors["descripcionErr"]}</div>:""}                              
                            </div>                       
                        </div>                 
                    </div> 
                    
                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizar} >
                        GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeEditParametro}>
                            SALIR
                        </div>
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</div>
);
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarGestionItems);
