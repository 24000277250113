import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logojunior from "../../image/investTraderLogo.png";
import { useState, useEffect } from "react";
import { logout } from "../../actions/auth";
import userImage from "../../image/profileImg.png";
import ConfigurarUsuario from "./ConfigurarUsuario";
import { enableFuncionalityForRole, formatTime, secondFormatComplete, secondFormatHour, time } from "../general/Util";
import { getDataEventAct } from "../../actions/administracion";
import { connect } from 'react-redux';
import { estadoBoton } from '././ControlJuego';
import { toggleBotonApertura, toggleBotonSubasta, toggleBotonAdjudicacion } from '../../actions/controlJuego';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { useContext } from "react";


const Header = (props) => {

  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  //console.log("currentUser",currentUser)
  //console.log("logo",currentUser["LogoEmpresa"])

  // Timer
  let [secondsApertura, setSecondsApertura] = useState(0);
  let [secondsSubasta, setSecondsSubasta] = useState(0);
  let [secondsAdj, setSecondsAdj] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const { datosjuego } = useSelector(state => state.controlJuego);
  const [intervalReloj,setIntervalReloj] = useState(0);
  //console.log(datosjuego)

  const [dateInit,setDateInit] = useState(new Date())


  // termina
  const { eventoSeleccionado: eventoSeleccionado, pInscripcion: pInscripcion, pColocacion: pColocacion, pSubasta: pSubasta, pAdjudicacion: pAdjudicacion } = useSelector((state) => state.juego);
  let [insc, setInsc] = useState(null);
  let [colo, setColo] = useState(null);
  let [sub, setSub] = useState(null);
  let [adj, setAdj] = useState(null);
  const { listaDatosEvento, loadingDatosEvento } = useSelector((state) => state.administracion);
  let [openProfile, setOpenProfile] = useState("");
  let [admFuncionality] = useState(currentUser !== null ? enableFuncionalityForRole(currentUser["role"], "FUNC_ADM") : false);
  let [userRuedaFuncionality] = useState(currentUser !== null ? enableFuncionalityForRole(currentUser["role"], "FUNC_RUED") : false);
  let [userCajaFuncionality] = useState(currentUser !== null ? enableFuncionalityForRole(currentUser["role"], "FUNC_CAJA") : false);
  let [userLiquidacionFuncionality] = useState(currentUser !== null ? enableFuncionalityForRole(currentUser["role"], "FUNC_LIQUI") : false);
  let [userReceptorFuncionality] = useState(currentUser !== null ? enableFuncionalityForRole(currentUser["role"], "FUNC_RECEP") : false);
  let [userOperadorFuncionality] = useState(currentUser !== null ? enableFuncionalityForRole(currentUser["role"], "FUNC_OPER") : false);
  let [userRegistradorFuncionality] = useState(currentUser !== null ? enableFuncionalityForRole(currentUser["role"], "FUNC_REGIS") : false
  );

  let [userFuntion] = useState(currentUser !== null ? enableFuncionalityForRole(currentUser["role"], "USER_ORDEN") : false);
  let [loadDatosEvento, setLoadDatosEvento] = useState(false)
  let [mostrarHora, setMostrarHora] = useState(0);
  let [loadCargarHora, setLoadCargarHora] = useState(false)
  let [loadEstado, setloadEstado] = useState(false)


  const [dat, setDat] = useState(
    window.localStorage.getItem("value")
  )
  const setLocalStorage = value => {
    try {
      setDat(value)
      window.localStorage.setItem("value", value)
    } catch (error) {
      console.error(error)
    }
  }
  if (loadDatosEvento === false && eventoSeleccionado !== null) {
    setLoadDatosEvento(true)
    dispatch(getDataEventAct(eventoSeleccionado))
      .then(() => {
      })
      .catch(() => {
      });
    
  }



  if (listaDatosEvento !== null && eventoSeleccionado !== null && eventoSeleccionado !== undefined && loadCargarHora === false) {

    console.log(listaDatosEvento);
    setInsc(new Date(listaDatosEvento !== null && listaDatosEvento !== undefined && listaDatosEvento !== "NaN" ? listaDatosEvento["PeriodoInscripcion"] : ""));
    setColo(new Date(listaDatosEvento !== null && listaDatosEvento !== undefined && listaDatosEvento !== "NaN" ? listaDatosEvento["PeriodoColocacion"] : ""));
    setSub(new Date(listaDatosEvento !== null && listaDatosEvento !== undefined && listaDatosEvento !== "NaN" ? listaDatosEvento["PeriodoSubasta"] : ""));
    setAdj(new Date(listaDatosEvento !== null && listaDatosEvento !== undefined && listaDatosEvento !== "NaN" ? listaDatosEvento["PeriodoAdjudicacion"] : ""));
    setLoadCargarHora(true)
  }

  useEffect(() => {

    const timer = setInterval(() => {

      //let fechaActual = new Date();
      let fechaActual = new Date();
      if (fechaActual >= insc && fechaActual < colo) { setMostrarHora(mostrarHora => 1); }
      else if (fechaActual >= colo && fechaActual < sub) { setMostrarHora(mostrarHora => 2); }
      else if (fechaActual >= sub && fechaActual <= adj) { setMostrarHora(mostrarHora => 3); }
      else { setMostrarHora(mostrarHora => 0); }

    }, 1000);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    clearInterval(intervalReloj)
    console.log("llegue interval>",intervalReloj)
    let intertmp = setInterval(muestraReloj, 1000);
    setIntervalReloj(intertmp)
  },[dateInit])
  


  function muestraReloj() {
    let fechaHora = dateInit//new Date(datosjuego["FechaActual"])
    //console.log(fechaHora)
    let horas = fechaHora.getHours();
    let minutos = fechaHora.getMinutes();
    let segundos = fechaHora.getSeconds();
    //console.log(horas)
    fechaHora.setSeconds(fechaHora.getSeconds() + 1);


    if (horas < 10) { horas = '0' + horas; }
    if (minutos < 10) { minutos = '0' + minutos; }
    if (segundos < 10) { segundos = '0' + segundos; }
    if (document.getElementById("reloj") != undefined && document.getElementById("reloj") != null ){
      document.getElementById("reloj").innerHTML = new Date().toLocaleDateString() + ' ' + horas + ':' + minutos + ':' + segundos
    }

  }



  window.onload = function () {    
    //   setInterval(pintarHora, 1000,insc,colo,sub,adj);
  }
  const logOut = (e) => {
    // e.preventDefault();
    dispatch(logout());
  };
  const openProfileClick = () => {
    setOpenProfile("OPEN")
  };
  const closeProfileClick = () => {
    setOpenProfile("")
  };
  
  useEffect(() => {
    let interval = null;
    console.log("setDateInit--->")
    //console.log(dateInit)
    //if(dateInit!==null){
      //console.log("aqui")
      //setInterval(muestraReloj, 1000);
   // }
    if (datosjuego) {
      console.log("aqui--->")
      //console.log(dateInit)
      switch (datosjuego["EstadoEjecucion"]) {
        case "I":
          setDateInit(new Date(datosjuego["FechaActual"]))
          secondsApertura = datosjuego["TiempoInicioRes"] >= 0 ? datosjuego["TiempoInicioRes"] : 0
          setSecondsApertura(secondsApertura)
          interval = setInterval(() => {
            if (secondsApertura > 0)
              setSecondsApertura((secondsApertura) => secondsApertura + -1);
            else
              clearInterval(interval);
          }, 1000);
          break
        case "S":
          setDateInit(new Date(datosjuego["FechaActual"]))
          secondsSubasta = datosjuego["PeriodoSubastaRes"] >= 0 ? datosjuego["PeriodoSubastaRes"] : 0
          setSecondsSubasta(secondsSubasta)
          interval = setInterval(() => {
            if (secondsSubasta > 0)
              setSecondsSubasta((secondsSubasta) => secondsSubasta + -1);
            else
              clearInterval(interval);
          }, 1000);
          break
        case "J":
          setDateInit(new Date(datosjuego["FechaActual"]))
          secondsAdj = datosjuego["PeriodoAdjudicacionRes"] >= 0 ? datosjuego["PeriodoAdjudicacionRes"] : 0
          setSecondsAdj(secondsAdj)
          interval = setInterval(() => {
            if (secondsAdj > 0)
              setSecondsAdj((secondsAdj) => secondsAdj + -1);
            else
              clearInterval(interval);
          }, 1000);
          break
        default:
          break
      }
      return () => {
        
        clearInterval(interval);
        
      };
      
    }
  }, [datosjuego])

  return (
    <div>

      {openProfile === "OPEN" ? <ConfigurarUsuario closeProfileClick={closeProfileClick} openProfile={openProfile} /> : ""}
      {currentUser ? (
        <div className="crypt-header">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
              <div className="row">
                <div className="col-xs-2">
                  <div className="crypt-logo">
                    <img src={logojunior} alt="Logo Marca 1" />

                    {currentUser["LogoEmpresa"] !== undefined && currentUser["LogoEmpresa"] !== "" && currentUser["LogoEmpresa"] !== 1 && currentUser["LogoEmpresa"] !== null ?
                      (<img className="logoempresa" src={`data:image/png;base64, ${currentUser["LogoEmpresa"]}`} alt="LogoEmpresa" />)
                      : ""}

                  </div>
                </div>
              </div>
            </div>



            <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block d-lg-block">

              <ul className="crypt-heading-menu fright">
                <li className="crypt-box-menu">
                  <div className="profileContainer">
                    <div className="userPicture">

                      <Menu menuButton={
                        <MenuButton><i className="far fa-user fa-2x" ></i></MenuButton>}>
                        <MenuItem onClick={openProfileClick} >
                          <i className="fas fa-address-card"></i>Perfil
                        </MenuItem>
                        <MenuItem onClick={logOut}>
                          <i className="fas fa-sign-out-alt"></i>Salir
                        </MenuItem>

                      </Menu>
                    </div>

                    <div className="userInfo">


                      {userOperadorFuncionality === true || userRegistradorFuncionality === true ?
                        <div>
                          <span>Empresa: </span>{currentUser["NombreEmpresa"].toUpperCase()}
                        </div>
                        :
                        <span></span>}

                      {userOperadorFuncionality === true || userRegistradorFuncionality === true ?
                        <div>
                          <span>Colegio: </span>
                          {currentUser["NombreInstitucion"].toUpperCase()}
                        </div>
                        : <span></span>}


                      <div>
                        <span>Usuario: </span>
                        {currentUser !== null ? currentUser["Nombre"].toUpperCase() : ""}
                      </div>
                    </div>
                  </div>
                </li>

                {listaDatosEvento !== null ?
                  <li className="crypt-box-menu">
                    <div className="blockContainer">
                      <div>
                        <span>Evento: </span>
                        <span>{listaDatosEvento !== null && listaDatosEvento !== undefined ? listaDatosEvento["NombreEvento"] : ""}</span>
                      </div>
                      <div className={[datosjuego && datosjuego["EstadoEjecucion"] === "I" ? "color-header" : ""].join(' ')}>
                        <span>Apertura:</span>
                        {listaDatosEvento !== null && listaDatosEvento !== undefined ? formatTime(listaDatosEvento["PeriodoInscripcion"]) : ""}
                      </div>
                      <div className={[datosjuego && datosjuego["EstadoEjecucion"] === "S" ? "color-header" : ""].join(' ')}>
                        <span>Subasta:</span>
                        {listaDatosEvento !== null && listaDatosEvento !== undefined ? formatTime(listaDatosEvento["PeriodoSubasta"]) : ""}
                      </div>
                      <div className={[datosjuego && datosjuego["EstadoEjecucion"] === "J" ? "color-header" : ""].join(' ')}>
                        <span>Adjudicación:</span>
                        {listaDatosEvento !== null && listaDatosEvento !== undefined ? formatTime(listaDatosEvento["PeriodoAdjudicacion"]) : ""}
                      </div>
                    </div>

                  </li>
                  :
                  <li className="crypt-box-menu">
                    <div className="blockContainer">
                      <div>

                        <span>Evento:</span>
                      </div>
                      <div>
                        <span>Apertura:</span>
                      </div>
                      <div>
                        <span>Subasta:</span>
                      </div>
                      <div>
                        <span>Adjudicación:</span>

                      </div>
                    </div>
                  </li>
                }
                <li className="crypt-box-menu">
                  <div className="timeContainer">
                    <div className="timeTxt">
                      <div>Fecha Hora:</div>
                      <div>Tiempo restante:</div>
                    </div>
                    <div className="continertime">

                      <div id="reloj" className={["time"].join(' ')}></div>
                      <div className={["time", datosjuego && datosjuego["EstadoEjecucion"] === "F" ? "red-color" : ""].join(' ')}>
                        {datosjuego && datosjuego["EstadoEjecucion"] === "I" ? secondFormatComplete(secondsApertura) : ""}
                        {datosjuego && datosjuego["EstadoEjecucion"] === "S" ? secondFormatComplete(secondsSubasta) : ""}
                        {datosjuego && datosjuego["EstadoEjecucion"] === "J" ? secondFormatComplete(secondsAdj) : ""}
                        {datosjuego && datosjuego["EstadoEjecucion"] === "F" ? "FINALIZADO" : ""}
                        {datosjuego && datosjuego["EstadoEjecucion"] === "" || datosjuego["EstadoEjecucion"] === null ? "NO INICIADO" : ""}
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <i className="menu-toggle pe-7s-menu d-xs-block d-sm-block d-md-none d-sm-none"></i>
          </div>
        </div>
      ) : (
        <div></div>
      )}

    </div>
  );
};


const mapStateToProps = state => ({

  segundosApertura: state.juego.segundosApertura,
  segundosSubasta: state.juego.segundosSubasta,
  segundosAdjudicacion: state.juego.segundosAdjudicacion,
});
export default connect(mapStateToProps, {
})(Header);


