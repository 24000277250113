import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import {
    updateEventoAct,
    getEmpresaSelectAct,
    getCatalogosModalidad
} from "../../actions/administracion";
import "../../config";
import UploadFile from "./UploadFile";
import { NotificationContainer, NotificationManager } from "react-notifications";
const EditarEvento = (props) => {
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    let [errors, setErrors] = useState({ "modalidadIdErr": "" }, { "empresaIdErr": "" }, { "nombreEventoErr": "" }, { "nombreEmpresaCortoErr": "" }, { "nombreExponenteErr": "" }, { "fechaInscripcionErr": "" }, { "fechaSubastaErr": "" }, { "fechaAdjudicacionErr": "" }, { "fechaFinalizacionErr": "" });
    let [eventoId, setEventoId] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["EventoId"] : "-1");
    let [nombreEvento, setNombreEvento] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["NombreEvento"] : "");
    let [logo, setLogo] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["Logo"] : "");
    let [logoUrl, setLogoUrl] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["LogoUrl"] : "");

    let [inscripcion, setInscripcion] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["PeriodoInscripcion"] : new Date());
    let [colocacion, setColocacion] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["PeriodoColocacion"] : new Date());
    let [subasta, setSubasta] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["PeriodoSubasta"] : new Date());
    let [adjudicacion, setAdjudicacion] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["PeriodoAdjudicacion"] : new Date());
    let [fechaApertura, setFechaApertura] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["FechaApertura"] : new Date());
    let [fechaSubasta, setFechaSubasta] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["FechaSubasta"] : new Date());
    let [fechaAdjudicacion, setFechaAdjudicacion] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["FechaAdjudicacion"] : new Date());
    let [fechaFinalizacion, setFechaFinalizacion] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["FechaFinalizacion"] : new Date());
    let [nombreEmpresaCorto, setNombreEmpresaCorto] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["NombreEmpresaCorto"] : "");
    let [nombreExponente, setNombreExponente] = useState(props.eventoSeleccionado !== null ? props.eventoSeleccionado["NombreExponente"] : "");
    let [isError, setIsError] = useState(false)
    let cambioModalidad = useState(null);
    const { listaCatalogosModalidad } = useSelector((state) => state.administracion);
    let [loadModalidades, setLoadModalidades] = useState(false)
    //console.log("listaCatalogosModalidad", listaCatalogosModalidad)
    if (loadModalidades === false && listaCatalogosModalidad === null) {
        setLoadModalidades(true)
        dispatch(getCatalogosModalidad())
            .then(() => {
            })
            .catch(() => {
            });

    }


    if (props.eventoSeleccionado !== null && props.eventoSeleccionado["ModalidadId"] == "1") {
        cambioModalidad = { value: "1", label: "Virtual" };
    }
    if (props.eventoSeleccionado !== null && props.eventoSeleccionado["ModalidadId"] == "2") {
        cambioModalidad = { value: "2", label: "Semipresencial" };
    }
    if (props.eventoSeleccionado !== null && props.eventoSeleccionado["ModalidadId"] == "3") {
        cambioModalidad = { value: "3", label: "Manual" };
    }
    if (props.eventoSeleccionado === null) {
        cambioModalidad = { value: "1", label: "Virtual" };
    }
    let [modalidad, setModalidad] = useState(cambioModalidad);

    let cambioEstado = useState(null);
    if (props.eventoSeleccionado !== null && props.eventoSeleccionado["Estado"] == "I") {
        cambioEstado = { value: "I", label: "Inactivo" };
    }
    if (props.eventoSeleccionado !== null && props.eventoSeleccionado["Estado"] == "A") {
        cambioEstado = { value: "A", label: "Activo" };
    }
    if (props.eventoSeleccionado === null) {
        cambioEstado = { value: "A", label: "Activo" };
    }
    let [estadoSelect, setEstadoSelect] = useState(cambioEstado);
    const optionsEstado = [
        { value: "I", label: "Inactivo" },
        { value: "A", label: "Activo" },
    ];
    function closeEditEvento() {
        props.closeEditEvento();
    }

    const nombreRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;

    function actualizar() {
        let valid = true;

        //let err = errors;
        if (nombreEvento == "") {
            errors["nombreEventoErr"] = "Ingrese nombre";
            valid = false;
        }
        //if (!textoRegex.test(nombreEvento)) {
        //  errors["nombreEventoErr"] = "Ingrese solo letras y números"
        // valid = false
        //}

        if (nombreEmpresaCorto == "") {
            errors["nombreEmpresaCortoErr"] = "Ingrese nombre corto";
            valid = false;
        }
        if (nombreExponente == "") {
            errors["nombreExponenteErr"] = "Ingrese nombre";
            valid = false;
        }

        if (!nombreRegex.test(nombreExponente)) {
            errors["nombreExponenteErr"] = "Ingrese solo letras"
            valid = false
        }

        if (Date.parse(subasta) <= Date.parse(inscripcion)) {
           // errors["fechaSubastaErr"] = "Fecha inválida"
           valid = false
            NotificationManager.error(
                "Error. Fecha subasta inválida",
                "Sistema",
                5000,
                () => { },
                4
              );
              return
              
        }

        if (Date.parse(adjudicacion) <= Date.parse(subasta)) {
            //errors["fechaAdjudicacionErr"] = "Fecha inválida"
            valid = false
             NotificationManager.error(
                "Error. Fecha adjudicación inválida",
                "Sistema",
                5000,
                () => { },
                4
              );
              return
            
        }
       // console.log("-----------------------")
       // console.log(colocacion,adjudicacion)
        if (Date.parse(colocacion) < Date.parse(adjudicacion)) {
            //errors["fechaFinalizacionErr"] = "Fecha inválida"
            valid = false
            NotificationManager.error(
                "Error. Fecha finalización inválida",
                "Sistema",
                5000,
                () => { },
                4
              );
              return
            
        }

        setErrors(errors)
        setIsError(true)
        if (!valid) {
            return;
        }
        dispatch(
            updateEventoAct(
                eventoId,
                logoUrl,
                modalidad["value"],
                nombreEvento,
                logo,
                estadoSelect["value"],
                currentUser["userId"],
                fechaSubasta,
                inscripcion,
                subasta,
                colocacion,
                adjudicacion,
                nombreEmpresaCorto,
                nombreExponente,
                "0",
                "",
                "",
                "ASC",
                ""
            )
        )
            .then(() => {
                //window.location.reload();
            })
            .catch(() => { });

        closeEditEvento();
        console.log(eventoId)
        console.log(modalidad["value"])
        console.log(nombreEvento)
        console.log(logo)
        console.log(estadoSelect["value"])
        console.log(currentUser["userId"])
        console.log(fechaSubasta)
        console.log(inscripcion)
        console.log(colocacion)
        console.log(subasta)
        console.log(adjudicacion)
        console.log(nombreEmpresaCorto)
        console.log(nombreExponente)

    }
    {/*Para agregar logo*/ }
    let [uploadId, setUploadId] = useState(
        currentUser !== null ? currentUser["UploadId"] : "-1"
    );

    let [fileNombre, setFileNombre] = useState(
        currentUser !== null ? currentUser["fileNombre"] : ""
    );

    let [fileNombreUrl, setFileNombreUrl] = useState(
        currentUser !== null ? currentUser["fileNombreUrl"] : ""
    );

    function reloadDocuments(file) {
        const result = JSON.parse(file);
        setUploadId(result["UploadId"]);
        setFileNombre(result["fileNombre"]);
        setFileNombreUrl(result["fileNombreUrl"]);
    }

    const convertirBase64 = (files) => {
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let arrayImage = []
                let base = reader.result;
                arrayImage = base.split(',');
                setLogoUrl(arrayImage[1])


            }
        })
    }

    return (
        <div className="overlay3 fade-in">
            <div className="borderContent ventanaPortafolioEvento">
                <div className="innerShadow3">
                    <div id="formulario-estilo">
                        <div className="editEmpresa">
                            <h2>
                                Evento
                            </h2>
                            <div className='vertical-scroll customScroll'>
                                <div id="columna-1-formulario">
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Fecha Subasta:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <DatePicker
                                                selected={Date.parse(fechaSubasta)}
                                                onChange={date => setFechaSubasta(date)}
                                                showMonthDropdown
                                                showYearDropdown
                                                dateFormat="dd/MM/yyyy"
                                                className="input-form"
                                            />
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Nombre evento:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                value={nombreEvento}
                                                maxLength={1000}
                                                placeholder="Ingresar nombre evento"
                                                className="input-form"
                                                onChange={(e) => {
                                                    setNombreEvento(e.target.value);
                                                    errors["nombreEventoErr"] = ""
                                                }}
                                            ></input>
                                            {errors["nombreEventoErr"] !== "" ? <div className='camporequerido'>{errors["nombreEventoErr"]}</div> : ""}
                                        </div>
                                    </div>

                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Nombre empresa corto:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                value={nombreEmpresaCorto}
                                                maxLength={1000}
                                                placeholder="Ingresar empresa corto"
                                                className="input-form"
                                                onChange={(e) => {
                                                    setNombreEmpresaCorto(e.target.value);
                                                    errors["nombreEmpresaCortoErr"] = ""
                                                }}
                                            ></input>
                                            {errors["nombreEmpresaCortoErr"] !== "" ? <div className='camporequerido'>{errors["nombreEmpresaCortoErr"]}</div> : ""}
                                        </div>
                                    </div>

                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Nombre exponente:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <input
                                             autocomplete="off"
                                                type="text"
                                                value={nombreExponente}
                                                maxLength={1000}
                                                placeholder="Ingresar nombre exponente"
                                                className="input-form"
                                                onChange={(e) => {
                                                    setNombreExponente(e.target.value);
                                                    errors["nombreExponenteErr"] = ""
                                                }}
                                            ></input>
                                            {errors["nombreExponenteErr"] !== "" ? <div className='camporequerido'>{errors["nombreExponenteErr"]}</div> : ""}
                                        </div>
                                    </div>



                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Modalidad:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <Select
                                                autocomplete="off"
                                                className="input-form" id="estadoSelectCombo"
                                                value={modalidad}
                                                onChange={(e) => {
                                                    setModalidad(e)
                                                }} placeholder="Seleccione"
                                                options={listaCatalogosModalidad} />
                                        </div>
                                    </div>

                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Logo:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">

                                            <input type="file" className="boxStyleSelect" multiple onChange={(e) => convertirBase64(e.target.files)}></input>
                                            <div className="boxStyleSelect">Selecciona el archivo para subirlo</div>
                                        </div>
                                    </div>
                                </div>
                                <div id="columna-2-formulario">
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Estado:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <Select 
                                            autocomplete="off"
                                            className="input-form" 
                                            id="estadoSelectCombo" 
                                            value={estadoSelect} 
                                            onChange={(e) => {
                                                setEstadoSelect(e)
                                            }} placeholder="Seleccione" options={optionsEstado} />
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Periodo Apertura:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <DatePicker
                                                selected={Date.parse(inscripcion)}
                                                onChange={(date) => {
                                                    setInscripcion(date)

                                                }}
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                //showTimeSelectOnly

                                                timeIntervals={5}
                                                timeCaption="Time"
                                                dateFormat="HH:mm"
                                                className="input-form"
                                                timeFormat="HH:mm"
                                            />
                                        </div>
                                    </div>


                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Periodo Subasta:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <DatePicker
                                                selected={Date.parse(subasta)}

                                                onChange={(date) => {
                                                    setSubasta(date)
                                                    errors["fechaSubastaErr"] = ""
                                                }}
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                //showTimeSelectOnly

                                                timeIntervals={5}
                                                timeCaption="Time"
                                                dateFormat="HH:mm"
                                                className="input-form"
                                                timeFormat="HH:mm"
                                            />
                                            {errors["fechaSubastaErr"] !== "" ? <div className='camporequerido'>{errors["fechaSubastaErr"]}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Periodo Adjudicación:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <DatePicker
                                                selected={Date.parse(adjudicacion)}
                                                onChange={(date) => {
                                                    setAdjudicacion(date)
                                                    errors["fechaAdjudicacionErr"] = ""
                                                }}
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                //showTimeSelectOnly


                                                timeIntervals={5}
                                                timeCaption="Time"
                                                dateFormat="HH:mm"
                                                className="input-form"
                                                timeFormat="HH:mm"
                                            />
                                            {errors["fechaAdjudicacionErr"] !== "" ? <div className='camporequerido'>{errors["fechaAdjudicacionErr"]}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Periodo Finalización:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <DatePicker
                                                selected={Date.parse(colocacion)}
                                                onChange={(date) => {
                                                    setColocacion(date)
                                                    errors["fechaFinalizacionErr"] = ""
                                                }}
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                //showTimeSelectOnly

                                                timeIntervals={5}
                                                timeCaption="Time"
                                                dateFormat="HH:mm"
                                                className="input-form"
                                                timeFormat="HH:mm"
                                            />
                                            {errors["fechaFinalizacionErr"] !== "" ? <div className='camporequerido'>{errors["fechaFinalizacionErr"]}</div> : ""}
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="textCenter clear-both">
                                <div className="btnGuardar" onClick={actualizar} >
                                    GUARDAR
                                </div>{" "}
                                <div className="btnCancelar" onClick={closeEditEvento}>
                                    SALIR
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarEvento);
