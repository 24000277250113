import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid, { Column } from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import { getItemCatalogos, deleteItemCatalogo, setStatusByKey } from "../../actions/administracion";
import EditarItemCatalogo from "./EditarItemCatalogo";
import '@szhsin/react-menu/dist/index.css';
const GestionItems = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px', height: '100%'}
    const { listaItemsPag, loadingItems } = useSelector((state) => state.administracion)
    const [editarItemOpen, setEditarItemOpen] = useState("")
    const [itemSeleccionado, setItemSeleccionado] = useState(null)
    const [catalogoId, setCatalogoId] = useState(null)
    let [loadItems, setLoadItems] = useState(false)
    let itemId = useState(null);
    if (listaItemsPag === null && loadItems === false) {
        setLoadItems(true)
        dispatch(getItemCatalogos(props.catalogoSeleccionado["CatalogoId"], "0", "", "", "ASC", ""))
            .then(() => {
            })
            .catch(() => {
            });

    }
    if (catalogoId === null) {
        setCatalogoId(props.catalogoSeleccionado["CatalogoId"])
        console.log("catalogo id=" + props.catalogoSeleccionado["CatalogoId"])
    }
    let [itemFilter, setItemFilter] = useState("");
    let [gridInfor, setGridInfor] = useState([listaItemsPag !== null ? listaItemsPag : []]);

    if (listaItemsPag !== null && listaItemsPag != gridInfor && itemFilter === "")
        setGridInfor(listaItemsPag)

    function filterItem(nego, itemFilter) {
        //value => 
        setGridInfor(nego.filter(function (item) {
            if (item["Codigo"].toLowerCase().includes(itemFilter.toLocaleLowerCase()) ||
                item["Nombre"].toLowerCase().includes(itemFilter.toLocaleLowerCase()) ||
                item["NombreCorto"].toLowerCase().includes(itemFilter.toLocaleLowerCase()))
                return true;
            return false;
        }))
    }
    function closeWindowItem() {
        dispatch(setStatusByKey("listaItemsPag", null))
            .then(() => {
            })
            .catch(() => {
            });
        props.closeItemCatalogo()
    }
    function closeEditItem() {
        setEditarItemOpen("")
    }
    function newEditItem() {
        setItemSeleccionado(null)
        setEditarItemOpen("EDIT_ITEM")
    }
    const renderRowContextMenu = useCallback((menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
            {
                label: 'Editar',
                onClick: () => {
                    setItemSeleccionado(rowProps.data)
                    itemId = rowProps.data["ItemId"]
                    console.log(rowProps.data)
                    setEditarItemOpen("EDIT_ITEM")


                }
            },
            {
                label: 'Eliminar',
                onClick: () => {
                    confirmAlert({
                        title: 'Eliminar catálogo',
                        message: '¿Seguro desea eliminar?.',
                        buttons: [
                            {
                                label: 'Si',
                                onClick: () => {
                                    dispatch(deleteItemCatalogo(rowProps.data["ItemId"], rowProps.data["CatalogoId"], "0", "", "", "ASC", ""))
                                        .then(() => {

                                        })
                                        .catch(() => { });

                                }

                            },
                            {
                                label: 'No',
                                onClick: () => {

                                }
                            }
                        ]
                    });
                }
            }
        ]
    }, [])
    let columns = [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Codigo',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>CÓDIGO</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center" },
        { name: 'Nombre', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE</div>), defaultFlex: 1, xdefaultLocked: 'start', defaultWidth: 400,headerAlign: "center" },
        { name: 'NombreCorto', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE CORTO</div>), defaultFlex: 1, xdefaultLocked: 'start', defaultWidth: 400,headerAlign: "center" },

    ]

    
    return (
        <div className={[
            "overlay",
            "fade-in"
        ].join(" ")}>
            <div className={['loading_cont', loadingItems !== true ? "displaynone" : ""].join(' ')}><div className='single6'></div></div>
            {editarItemOpen == "EDIT_ITEM" ? <EditarItemCatalogo closeEditItem={closeEditItem} itemSeleccionado={itemSeleccionado} catalogoId={catalogoId} /> : ""}
            <div className="borderContent ventanaPortafolio">
                <div className="innerShadow-height-100">
                    <h2>
                        Item catálogo
                        <div className="subItemCatalogo">
                            <Menu menuButton={<MenuButton>
                                <i className="fas fa-cog fa-1x"></i></MenuButton>}>
                                <MenuItem onClick={newEditItem}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem>
                            </Menu>
                        </div>
                    </h2>
                    <div className="titleTable-search">ITEM CATALOGOS
                        <div className='floatright'><div className="filterNeg">
                            <input type="text" placeholder="Filtrar" value={itemFilter} onChange={(e) => {
                                setItemFilter(e.target.value);
                                filterItem(listaItemsPag !== null ? listaItemsPag : [], e.target.value)
                            }} />
                            <i className="fas fa-search"></i>
                        </div>
                        </div>
                    </div>
                    <DataGrid
                        idProperty="ItemId"
                        style={gridStyle}
                        
                        columns={columns}
                        enableSelection={true}
                        selection={{ mode: 'single' }}
                        dataSource={gridInfor}
                        renderRowContextMenu={renderRowContextMenu}
                        theme="amber-dark"
                    />

                    <div className="dividerLine"></div>
                    <div className="textCenter">
                        <div className="btnCancelar" onClick={closeWindowItem}>
                            SALIR
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionItems);
