import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { updateUsuario } from "../../actions/administracion";
import UploadFile from "./UploadFile";
import UploadFileNew from "./UploadFileNew";
import "../../config";
import { enableFuncionalityForRole } from "./Util";
const EditarGestionUsuario = (props) => {
    const dispatch = useDispatch();
    let [userId, setUserId] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["UsuarioId"] : "-1")
    let [errors, setErrors] = useState({ "nombreErr": "" }, { "nickErr": "" }, { "emailErr": "" }, { "rolErr": "" });
    let [nombre, setNombre] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["Nombre"].toString() : "")
    let [nick, setNick] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["Nick"].toString() : "")
    let [email, setEmail] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["Email"].toString() : "")
    let [rol, setRol] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["Rol"] : "")
    let [uploadId, setUploadId] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["UploadId"] : "-1")
    let [fileNombre, setFileNombre] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["fileNombre"] : "")
    let [fileNombreUrl, setFileNombreUrl] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["fileNombreUrl"] : "")


    let [imagenUrl, setImagenUrl] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["ImagenUrl"] : "")
    //console.log("imagen", imagenUrl)
    //let [logo, setLogo] = useState(props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["ImagenUrl"] : "");
    //console.log("logo", logo)

    let [clave, setClave] = useState(null)
    let cambioEstado = useState(null)
    let [isError, setIsError] = useState(false)

    const { user: currentUser } = useSelector((state) => state.auth);

    let [admFuncionality] = useState(
        currentUser !== null
            ? enableFuncionalityForRole(currentUser["role"], "FUNC_ADM")
            : false
    );
    let [userRuedaFuncionality] = useState(
        currentUser !== null
            ? enableFuncionalityForRole(currentUser["role"], "FUNC_RUED")
            : false
    );



    if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Estado"] == "I") {
        cambioEstado = { value: 'I', label: "Inactivo" }
    }
    if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Estado"] == "A") {
        cambioEstado = { value: 'A', label: "Activo" }
    }
    if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Estado"] == "D") {
        cambioEstado = { value: 'D', label: "Desactivado" }
    }
    if (props.usuarioSeleccionado === null) {
        cambioEstado = { value: 'A', label: "Activo" }
    }
    let [estadoSelect, setEstadoSelect] = useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ]
    let cambioRol = useState(null)

    if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Rol"] == "ROLE_ADMIN") {
        cambioRol = { value: 'ROLE_ADMIN', label: "Admin" }
    }
    // if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Rol"] == "ROLE_USER") {
    //   cambioRol = { value: 'ROLE_USER', label: "Jugador" }
    //}
    if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Rol"] == "ROLE_RUEDA") {
        cambioRol = { value: 'ROLE_RUEDA', label: "Director de rueda" }
    }
    if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Rol"] == "ROLE_CAJA") {
        cambioRol = { value: 'ROLE_CAJA', label: "Usuario Caja" }
    }

    if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Rol"] == "ROLE_LIQUIDACION") {
        cambioRol = { value: 'ROLE_LIQUIDACION', label: "Usuario Liquidacion" }
    }
    /*if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Rol"] == "ROLE_CAJA") {
        cambioRol = { value: 'ROLE_VISITANTE', label: "Visitante" }
    }*/


    if (props.usuarioSeleccionado === null) {
        cambioRol = admFuncionality === true ? { value: 'ROLE_OPERADOR', label: "Usuario Operador" } : { value: 'ROLE_OPERADOR', label: "Usuario Operador" };
    }

    if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Rol"] == "ROLE_OPERADOR") {
        cambioRol = { value: 'ROLE_OPERADOR', label: "Usuario Operador" }
    }

    if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Rol"] == "ROLE_REGISTRADOR") {
        cambioRol = { value: 'ROLE_REGISTRADOR', label: "Usuario Registrador" }
    }
    // if (props.usuarioSeleccionado !== null && props.usuarioSeleccionado["Rol"] == "ROLE_RECEPTOR") {
    //    cambioRol = { value: 'ROLE_RECEPTOR', label: "Usuario Receptor" }
    //}


    let [rolSelect, setRolSelect] = useState(cambioRol);



    const optionsRol = admFuncionality === true ? [
        { value: 'ROLE_ADMIN', label: 'Administrador' },
        //  { value: 'ROLE_USER', label: 'Jugador' },
        { value: 'ROLE_RUEDA', label: 'Director de rueda' },
        { value: 'ROLE_CAJA', label: 'Usuario Caja' },
        { value: 'ROLE_LIQUIDACION', label: 'Usuario Liquidacion' },
        { value: 'ROLE_OPERADOR', label: 'Usuario Operador' },
        { value: 'ROLE_REGISTRADOR', label: 'Usuario Registrador' }
        //{ value: 'ROLE_RECEPTOR', label: 'Usuario Receptor' }
    ] : [
        { value: 'ROLE_OPERADOR', label: 'Usuario Operador' },
        //{ value: 'ROLE_RECEPTOR', label: 'Usuario Receptor' }
    ]

    function crearClave() {
        let valid = true
        if (email == "") {
            errors["emailErr"] = "Ingrese email"
            valid = false
        } else {
            var valor = email
            var division = valor.split("@", 1)
            var result = division.toString().replace(/[^a-zA-Z ]/g, "");
            var numeros = aleatorio(1000, 9999)
            result = result + numeros
            setClave(result)
            errors["claveErr"] = ""
        }
        return valid
    }
    function aleatorio(inferior, superior) {
        var numPosibilidades = superior - inferior;
        var aleatorio = Math.random() * (numPosibilidades + 1);
        aleatorio = Math.floor(aleatorio);
        return inferior + aleatorio;
    }
    function closeEditU() {
        props.closeUser()
    }

    const textoRegex = /^[a-zA-Z0-9Ññ_\s-]*$/;
    const nombreRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;

    function actualizarU() {

        let valid = true

        if (userId <= 0 && clave === null) {
            errors["claveErr"] = "Ingrese una contraseña"
            valid = false
        }
        if (nombre == "") {
            errors["nombreErr"] = "Ingrese nombre"
            valid = false
        }
        if (!nombreRegex.test(nombre)) {
            errors["nombreErr"] = "Ingrese solo letras"
            valid = false
        }

        if (nick == "") {
            errors["nickErr"] = "Ingrese nick"
            valid = false
        }
        if (!textoRegex.test(nick)) {
            errors["nickErr"] = "Ingrese solo letras y números"
            valid = false
        }
        if (email == "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            errors["emailErr"] = "Ingrese email (example@example.com)"
            valid = false
        }
        setErrors(errors)
        setIsError(true)
        if (!valid) {
            return
        }

        dispatch(updateUsuario(
            props.usuarioSeleccionado !== null ? props.usuarioSeleccionado["UsuarioId"] : "-1",
            nombre,
            nick,
            email,
            rolSelect["value"],
            clave !== null ? clave : "",
            estadoSelect["value"],
            uploadId,
            imagenUrl
        )).then(() => {

        }).catch(() => { }
        );
        closeEditU()
    }
    function reloadDocuments(file) {
        const result = JSON.parse(file);
        setUploadId(result["UploadId"])
        setFileNombre(result["fileNombre"])
        setFileNombreUrl(result["fileNombreUrl"])
    }

    const convertirBase64 = (files) => {
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let arrayImage = []
                let base = reader.result;
                arrayImage = base.split(',');
                setImagenUrl(arrayImage[1])


            }
        })
    }

    const [passwordVisible, setPasswordVisible] = useState(false);
    return (
        <div className='overlay3 fade-in'>
            <div className="borderContent ventanaPerfil">
                <div className="innerShadow3">
                    <div id="formulario-estilo">
                        <h2>
                            Usuarios
                        </h2>
                        <div className='vertical-scroll customScroll'>
                            <div className="columna-1-formulario-ventana-perfil">
                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-edit"></i> <span>Nombre:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <input
                                            autocomplete="off"
                                            type="text"
                                            value={nombre}
                                            maxLength={100}
                                            placeholder="Ingresar nombre"
                                            className="input-form"
                                            onChange={(e) => {
                                                setNombre(e.target.value);
                                                errors["nombreErr"] = ""
                                            }}
                                        ></input>
                                        {errors["nombreErr"] !== "" ? <div className='camporequerido'> {errors["nombreErr"]}</div> : ""}
                                    </div>
                                </div>
                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-edit"></i> <span>Nick:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <input
                                            autocomplete="off"
                                            type="text"
                                            value={nick}
                                            maxLength={50}
                                            placeholder="Ingresar nick"
                                            className="input-form"
                                            onChange={(e) => {
                                                setNick(e.target.value);
                                                errors["nickErr"] = ""
                                            }}
                                        ></input>
                                        {errors["nickErr"] !== "" ? <div className='camporequerido'> {errors["nickErr"]}</div> : ""}
                                    </div>
                                </div>
                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-at"></i> <span>Email:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <input
                                            autocomplete="off"
                                            type="email"
                                            value={email}
                                            maxLength={50}
                                            placeholder="Ingresar email"
                                            className="input-form"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                errors["emailErr"] = ""
                                            }}
                                        ></input>
                                        {errors["emailErr"] !== "" ? <div className='camporequerido'> {errors["emailErr"]}</div> : ""}
                                    </div>
                                </div>

                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-wrench"></i> <span>Rol:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <Select
                                            autocomplete="off"
                                            
                                            className="input-form"
                                            id="InstitucionCombo"
                                            value={rolSelect}
                                            onChange={(e) => {
                                                setRolSelect(e)
                                            }} placeholder="Seleccione" options={optionsRol !== null ? optionsRol : []}
                                        />
                                    </div>
                                </div>


                                <div className="row-form">
                                    <a className="col-30">
                                        <label>
                                            <i className="fas fa-edit"></i> <span>Contraseña:</span>
                                        </label>
                                    </a>
                                    <div className="col-70">
                                        <div className="cursor-pointer" onClick={crearClave}>
                                            <i className="fas fa-key"></i> Generar
                                        </div>
                                        <input
                                            autocomplete="new-password"
                                            type={passwordVisible ? "text" : "password"}
                                            value={clave}
                                            maxLength={50}
                                            placeholder="Ingresar contraseña"
                                            className="input-form"
                                            onChange={(e) => {
                                                setClave(e.target.value);
                                                errors["claveErr"] = ""
                                            }}
                                            
                                        ></input>
                                        <button className={passwordVisible ? "fas fa-eye fa-1x" : "fas fa-eye-slash fa-1x"} onClick={() => setPasswordVisible(!passwordVisible)}></button>
                                        {errors["claveErr"] !== "" ? <div className='camporequerido'> {errors["claveErr"]}</div> : ""}
                                    </div>
                                </div>
                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-file-image"></i> <span>Imagen:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <input type="file" className="boxStyleSelect" multiple onChange={(e) => convertirBase64(e.target.files)}></input>
                                        <div className="boxStyleSelect">Selecciona el archivo para subirlo</div>
                                    </div>
                                </div>
                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-wrench"></i> <span>Estado:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <Select
                                            autocomplete="off"
                                            className="input-form"
                                            id="InstitucionCombo"
                                            value={estadoSelect}
                                            onChange={(e) => {
                                                setEstadoSelect(e)
                                            }} placeholder="Seleccione" options={optionsEstado !== null ? optionsEstado : []}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="textCenter clear-both">
                                <div className="btnGuardar" onClick={actualizarU} >
                                    GUARDAR
                                </div>{" "}
                                <div className="btnCancelar" onClick={closeEditU}>
                                    SALIR
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarGestionUsuario);
