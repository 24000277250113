import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {formatDateTime} from "./Util";
import {getEventoAct,deleteEventoAct} from "../../actions/administracion";
import EditarEvento from "./EditarEvento";
import EventoEmpresa from "./EventoEmpresa";
import "../../config";
import {NotificationContainer} from "react-notifications";

const Evento = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'100%'}
    const {listaEventos,loadingEventos} = useSelector((state) => state.administracion);
    let [eventoSeleccionado,setEventoSeleccionado] = useState(null);
    let [eventoEmpresaOpen,setEventoEmpresaOpen] = useState("");
    let [editEventoOpen, setEditEventoOpen] = useState("")
    let [loadEvento, setLoadEvento] = useState(false) 

    if(loadEvento===false){
        setLoadEvento(true)
        dispatch(getEventoAct("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }

    let [empresaFilter, setEmpresaFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaEventos!==null?listaEventos:[]]);     

    if(listaEventos!==null && listaEventos!=gridInfor && empresaFilter==="")
        setGridInfor(listaEventos)

    function filterCatalogo(nego,empresaFilter){
        //value => 
        setGridInfor(nego.filter(function(item) {
            if (item["NombreEvento"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()) ||                 
                item["Estado"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()))
                return true;
            return false;
        }))
    }
   
    function closeEvento(){
        props.closeEventoClick()
    }
    function openNewEvento(){
        setEventoSeleccionado(null)
        setEditEventoOpen("EDIT")
    }

    function closeEditEvento(){
        setEditEventoOpen("")
    }
    function closeEventoEmpresaClick(){
        setEventoEmpresaOpen("")
    }
    function formatEstado(estado){
		switch(estado){
			case "A":
				return "ACTIVO"
		}
		return "INACTIVO"
	}
    let columns= [
      
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'NombreEvento',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE</div>), defaultFlex: 1, xdefaultLocked: 'end', minWidth: 500, headerAlign: "center" },
        { name: 'Estado',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ESTADO</div>), defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => formatEstado(data["Estado"]), headerAlign: "center" },           
        { name: 'FechaSubasta',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>FECHA SUBASTA</div>), defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => new Date(data["FechaSubasta"]).toLocaleString().replace(',', '') , headerAlign: "center"},      
            
       
    ]
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Empresa',
            onClick:()=>{   
                setEventoSeleccionado(rowProps.data)
                setEventoEmpresaOpen("OPEN")
                console.log(rowProps.data)
            }
        },      
        {
            label: 'Editar',
            onClick:()=>{   
                setEventoSeleccionado(rowProps.data)
                setEditEventoOpen("EDIT")
                console.log(rowProps.data)
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar evento',
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                           dispatch(deleteEventoAct(rowProps.data["EventoId"]))
                            .then(() => {     
                                props.history.push("/seleccionarEvento");
                                window.location.reload();
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])  
    console.log("lista de eventos")
    console.log(listaEventos)
  return (
    <div
      className={[
        "overlay3",
        "fade-in"
      ].join(" ")}
    > 
     <div className={['loading_cont',loadingEventos
     !==true?"displaynone":""].join(' ')}><div className='single6'></div></div>
      {editEventoOpen==="EDIT" ? <EditarEvento closeEditEvento={closeEditEvento} eventoSeleccionado={eventoSeleccionado}/>  :  ""}
      {eventoEmpresaOpen==="OPEN" ? <EventoEmpresa closeEventoEmpresaClick={closeEventoEmpresaClick} eventoSeleccionado={eventoSeleccionado}/>  :  ""}
        <div className="borderContent ventanaPortafolio">
            <div className="innerShadow-height-100">
                <h2>
                    Eventos
                    <div className="subItemCatalogo">
                        <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                            <MenuItem onClick={openNewEvento}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                        </Menu>
                    </div>
                </h2>  
                <div className="titleTable-search">BUSCAR<div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
                  setEmpresaFilter(e.target.value);
                  filterCatalogo(listaEventos!==null?listaEventos:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div> 
                <DataGrid
                    idProperty="EventoId"
                    style={gridStyle}                  
                    columns={columns}
                    enableSelection={true}
                    selection={{ mode: 'single' }}                    
                    dataSource={gridInfor}
                    renderRowContextMenu={renderRowContextMenu}
                    theme="amber-dark"
                />
                <div className="dividerLine"></div>
                <div className="textCenter">
                    <div className="btnCancelar"  onClick={closeEvento}>
                        SALIR
                    </div>
                </div> 
            </div>
        </div>  
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Evento);
