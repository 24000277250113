import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {formatDateTime} from "./Util";
import {deleteEventoAct, deleteEventoEmpresaAct, getEventoEmpresaAct} from "../../actions/administracion";
import SeleccionarEventoEmpresa from "./SeleccionarEventoEmpresa";
//import ItemEmpresa from "./ItemEmpresa";
import "../../config";
import {NotificationContainer} from "react-notifications";

const EventoEmpresa = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'100%' }
    const {listaEventoEmpresa,loadingEventoEmpresa} = useSelector((state) => state.administracion);
    let [eventoSeleccionadoId] = useState(props.eventoSeleccionado !== null? props.eventoSeleccionado["EventoId"]: "-1");
    let [selectEventoEmpresaOpen, setSelectEventoEmpresaOpen] = useState("")
    let [loadEventoEmpresa, setloadEventoEmpresa] = useState(false) 
    
    if(loadEventoEmpresa===false){
        setloadEventoEmpresa(true)
        dispatch(getEventoEmpresaAct(eventoSeleccionadoId))
        .then(() => {
        })
        .catch(() => {
        });
        
    }
    let [empresaFilter, setEmpresaFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaEventoEmpresa!==null?listaEventoEmpresa:[]]);     

    if(listaEventoEmpresa!==null && listaEventoEmpresa!=gridInfor && empresaFilter==="")
        setGridInfor(listaEventoEmpresa)

    function filterCatalogo(nego,empresaFilter){
        //value => 
        setGridInfor(nego.filter(function(item) {
            if (item["Nombre"].toLowerCase().includes(empresaFilter.toLocaleLowerCase())
              )
                return true;
            return false;
        }))
    }
    function closeEventoEmpresa(){
        props.closeEventoEmpresaClick()
    }
    function openSelectEventoEmpresa(){
        setSelectEventoEmpresaOpen("OPEN")
    }
    function closeSelectEventoEmpresa(){
        setSelectEventoEmpresaOpen("")
    }
    function formatEstado(estado){
		switch(estado){
			case "A":
				return "ACTIVO"
		}
		return "INACTIVO"
	}
    let columns= [
      
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Nombre',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE</div>), defaultFlex: 1, xdefaultLocked: 'end' , headerAlign: "center"},
        { name: 'Estado',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ESTADO</div>), defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => formatEstado(data["Estado"]), headerAlign: "center" },   
        { name: 'FechaRegistro',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>FECHA REGISTRO</div>), defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => formatDateTime(data["FechaRegistro"]), headerAlign: "center"  },  
        
    ]
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar empresa',
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                            dispatch(deleteEventoEmpresaAct(rowProps.data["EventoEmpresaId"],eventoSeleccionadoId))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])  
    return (
        <div
          className={[
            "overlay3",
            "fade-in"
          ].join(" ")}
        > 
         <div className={['loading_cont',loadingEventoEmpresa!==true?"displaynone":""].join(' ')}><div className='single6'></div></div>
         {selectEventoEmpresaOpen==="OPEN" ? <SeleccionarEventoEmpresa closeSelectEventoEmpresa={closeSelectEventoEmpresa} eventoSeleccionadoId={eventoSeleccionadoId}/>  :  ""}
            <div className="borderContent ventanaPortafolio">
                <div className="innerShadow-height-100">
                    <h2>
                        Empresa
                        <div className="subItemCatalogo">
                            <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                                <MenuItem onClick={openSelectEventoEmpresa}><i className="fas fa-sign-out-alt"></i>Seleccionar</MenuItem> 
                            </Menu>
                        </div>
                    </h2>  
                    <div className="titleTable-search">EMPRESA<div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
                      setEmpresaFilter(e.target.value);
                      filterCatalogo(listaEventoEmpresa!==null?listaEventoEmpresa:[],e.target.value)
                    }}/><i className="fas fa-search"></i></div></div></div> 
                    <DataGrid
                        idProperty="EventoEmpresaId"
                        style={gridStyle}                  
                        columns={columns}
                        enableSelection={true}
                        selection={{ mode: 'single' }}                    
                        dataSource={gridInfor}
                        renderRowContextMenu={renderRowContextMenu}
                        theme="amber-dark"
                    />
                    <div className="dividerLine"></div>
                    <div className="textCenter">
                        <div className="btnCancelar"  onClick={props.closeEventoEmpresaClick}>
                            SALIR
                        </div>
                    </div> 
                </div>
            </div>  
        </div>
      );
    };
    const mapStateToProps = (state) => ({});
    export default connect(mapStateToProps, {})(EventoEmpresa);
    