import { NotificationManager } from "react-notifications";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,

} from "./types";

import AuthService from "../services/auth.service";



export const register = (username, email, password) => (dispatch) => {
  return AuthService.register(username, email, password).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password

    ).then(
    (data) => {
      if(data!=null){
        
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });  
        return Promise.resolve(data);

      }
      else{
        console.log("datos no recuperados")
        dispatch({
          type: LOGIN_FAIL,
          payload: "Error al validar usuario",
        })
        NotificationManager.error(
          "Error al validar usuario",
          "Sistema",
          3000,
          () => {},
          4
        ); 
        
  
        dispatch({
          type: SET_MESSAGE,
        });
        return Promise.reject();
      }          
    },
    (error) => {
      console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
        payload: "Error al validar usuario",
      });
      NotificationManager.error(
        "Error al validar usuario",
        "Sistema",
        3000,
        () => {},
        4
      );

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
