import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';

import { getEmpresaSelectAct, getUsuariosAct, getDataEmpresaAct, updateInversionistaAct, getEmpresaAct, getEmpresaSelectInversionista } from "../../actions/administracion";

import NumberFormat from 'react-number-format';
import "../../config";
const EditarInversionista = (props) => {
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { listaEmpresasSelecInversionista, loadingEmpresa } = useSelector((state) => state.administracion);
    let [loadEmpresa, setLoadEmpresa] = useState(false)


    let [errors, setErrors] = useState({ "empresaErr": "" }, { "usuarioIdErr": "" }, { "nombreErr": "" }, { "apellidoErr": "" }, { "cedulaErr": "" }, { "telefonoErr": "" }, { "direccionErr": "" }, { "emailErr": "" });
    let [nombre, setNombre] = useState(props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["Nombre"] : "")
    //console.log("estoy aqui en nombre", nombre)
    let [apellido, setApellido] = useState(props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["Apellido"] : "")
    let [cedula, setCedula] = useState(props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["Cedula"] : "")
    let [telefono, setTelefono] = useState(props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["Telefono"] : "")
    let [direccion, setDireccion] = useState(props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["Direccion"] : "")
    let [usuarioRegistroId, setUsuarioRegistroId] = useState(props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["UsuarioRegistroId"] : "")
    console.log(props.empresaSeleccionada)
    let [empresa, setEmpresa] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["EmpresaId"] : "")
    let [email, setEmail] = useState(props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["Email"] : "");
    let [empresaSeleccionada, setEmpresaSeleccionada] = useState(listaEmpresasSelecInversionista != null ? listaEmpresasSelecInversionista.filter(x => x.value === empresa)[0] : {})

    console.log(props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["EmpresaId"] : "")




    let [isError, setIsError] = useState(false)

    let cambioEstado = useState(null)
    let cambioEmpresa = useState(null)
    if (props.inversionistaSeleccionado !== null && props.inversionistaSeleccionado["Estado"] == "I") {
        cambioEstado = { value: 'I', label: "Inactivo" }
    }
    if (props.inversionistaSeleccionado !== null && props.inversionistaSeleccionado["Estado"] == "A") {
        cambioEstado = { value: 'A', label: "Activo" }
    }
    if (props.inversionistaSeleccionado === null) {
        cambioEstado = { value: 'A', label: "Activo" }
        cambioEmpresa = { value: '0', label: "Ninguno" }
    }
    let [estadoSelect, setEstadoSelect] = useState(cambioEstado);
    let [estadoSelectEmpresa, setEstadoSelectEmpresa] = useState(cambioEmpresa);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ]
    function closeEditInversionista() {
        props.closeEditInversionista()
    }



    //let [empresa, setEmpresa] = useState(null)

    if (empresa === null) {
        //console.log("lista de empres",loadingEmpresa)
        setEmpresa({ value: '0', label: "Ninguno" })
    } else {
        //console.log("empresa: ", empresa,"fin")
    }




    if (loadEmpresa === false && listaEmpresasSelecInversionista === null) {
        setLoadEmpresa(true)
        dispatch(getEmpresaSelectInversionista())
            .then(() => {
                console.log("listas", listaEmpresasSelecInversionista)
            })
            .catch(() => {
                console.log("error")
            });

    }


    // Validacion campos

    const nombreRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;
    //const cedulaRegex = /^[0-9]+$/;
    function actualizar() {

        let valid = true

        if (nombre == "") {
            errors["nombreErr"] = "Ingrese nombre"
            valid = false
        }
        if (!nombreRegex.test(nombre)) {
            errors["nombreErr"] = "Ingrese solo letras"
            valid = false
        }
        if (apellido == "") {
            errors["apellidoErr"] = "Ingrese apellido"
            valid = false
        }
        if (!nombreRegex.test(apellido)) {
            errors["apellidoErr"] = "Ingrese solo letras"
            valid = false
        }
        if (cedula == "") {
            errors["cedulaErr"] = "Ingrese cédula"
            valid = false
        }
        //if (telefono == "") {
        //  errors["telefonoErr"] = "Ingrese teléfono"
        //valid = false
        //}
        // if (direccion == "") {
        //   errors["direccionErr"] = "Ingrese dirección"
        // valid = false
        //}
        if (email == "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            errors["emailErr"] = "Ingrese mail (example@example.com)";
            valid = false;
        }

        setErrors(errors)
        setIsError(true)
        if (!valid) {
            return
        }



        dispatch(updateInversionistaAct(
            props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["InversionistaId"] : "-1",
            nombre,
            apellido,
            cedula,
            telefono,
            direccion,
            estadoSelect["value"],
            currentUser["userId"],
            props.empresaSeleccionada,
            email,
            "0",
            "",
            "",
            "ASC",
            ""
        )).then(() => {
        }).catch(() => {
            //console.log(props.empresaSeleccionada);
        }
        );

        closeEditInversionista()
        console.log(props.inversionistaSeleccionado !== null ? props.inversionistaSeleccionado["InversionistaId"] : "-1")
        console.log("nombre:" + nombre)
        console.log("apellido:" + apellido)
        console.log("cedula:" + cedula)
        console.log("telefono:" + telefono)
        console.log("direccion:" + direccion)
        console.log("estado:" + estadoSelect["value"])
        console.log("usuarioRegistroId:" + estadoSelect["value"])
        console.log("empresaSeleccionada:" + props.empresaSeleccionada)
    }
    return (
        <div className='overlay3 fade-in inversionista'>

            <div className="borderContent ventanaPerfil">
                <div className="innerShadow3">
                    <div id="formulario-estilo">
                        <h2>
                            Inversionista
                        </h2>

                        <div className='vertical-scroll customScroll'>
                            <div className="columna-1-formulario-inversionista">
                                <div className="col-30">
                                    <label>
                                        <i className="fas fa-edit"></i> <span>Nombre:</span>
                                    </label>
                                </div>
                                <div className="col-70">
                                    <input
                                        autocomplete="off"
                                        type="text"
                                        value={nombre}
                                        maxLength={1000}
                                        placeholder="Ingresar Nombre"
                                        className="input-form"
                                        onChange={(e) => {
                                            const inputText = e.target.value;
                                            const capitalizedText = inputText.toUpperCase();
                                            setNombre(capitalizedText);
                                            errors["NombreErr"] = ""
                                        }}
                                    ></input>
                                    {errors["nombreErr"] !== "" ? <div className='camporequerido'>{errors["nombreErr"]}</div> : ""}
                                </div>


                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-edit"></i> <span>Apellido:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <input
                                            autocomplete="off"
                                            type="text"
                                            value={apellido}
                                            maxLength={1000}
                                            placeholder="Ingresar Apellido"
                                            className="input-form"
                                            onChange={(e) => {
                                                const inputText = e.target.value;
                                                const capitalizedText = inputText.toUpperCase();
                                                setApellido(capitalizedText);
                                                //setApellido(e.target.value);
                                                errors["apellidoErr"] = ""
                                            }}
                                        ></input>
                                        {errors["apellidoErr"] !== "" ? <div className='camporequerido'>{errors["apellidoErr"]}</div> : ""}
                                    </div>

                                </div>


                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-edit"></i> <span>Email:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <input
                                            autocomplete="off"
                                            type="email"
                                            value={email}
                                            maxLength={1000}
                                            placeholder="Ingresar Email"
                                            className="input-form"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                errors["emailErr"] = ""
                                            }}
                                        ></input>
                                        {errors["emailErr"] !== "" ? <div className='camporequerido'>{errors["emailErr"]}</div> : ""}
                                    </div>
                                </div>





                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-edit"></i> <span>Cédula:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <NumberFormat
                                            autocomplete="off"
                                            value={cedula}
                                            maxLength={10}
                                            decimalScale={0}
                                            placeholder="Ingresar Cédula"
                                            className="input-form"
                                            allowLeadingZeros={true}
                                            onChange={(e) => {
                                                setCedula(e.target.value);
                                                errors["cedulaErr"] = ""
                                            }}
                                        ></NumberFormat>
                                        {errors["cedulaErr"] !== "" ? <div className='camporequerido'>{errors["cedulaErr"]}</div> : ""}
                                    </div>

                                </div>
                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-edit"></i> <span>Teléfono:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <NumberFormat
                                            autocomplete="off"
                                            value={telefono}
                                            maxLength={10}
                                            decimalScale={0}
                                            placeholder="Ingresar Teléfono"
                                            allowLeadingZeros={true}
                                            className="input-form"
                                            onChange={(e) => {
                                                setTelefono(e.target.value);
                                                errors["telefonoErr"] = ""
                                            }}
                                        ></NumberFormat>
                                        {errors["telefonoErr"] !== "" ? <div className='camporequerido'>{errors["telefonoErr"]}</div> : ""}
                                    </div>
                                </div>
                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-wrench"></i> <span>Estado:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <Select
                                            autocomplete="off"
                                            className="input-form"
                                            id="estadoSelectCombo"
                                            value={estadoSelect}
                                            onChange={(e) => {
                                                setEstadoSelect(e)
                                            }} placeholder="Seleccione" options={optionsEstado} />
                                    </div>
                                </div>
                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-edit"></i> <span>Dirección:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <textarea
                                            autocomplete="off"
                                            type="text"
                                            value={direccion}
                                            maxLength={5000}
                                            placeholder="Dirección"
                                            className="text-area-form"
                                            onChange={(e) => {
                                                setDireccion(e.target.value);
                                                errors["direccionErr"] = ""
                                            }}
                                        ></textarea>
                                        {errors["direccionErr"] !== "" ? <div className='camporequerido'>{errors["direccionErr"]}</div> : ""}
                                    </div>
                                </div>


                            </div>

                            <div className="textCenter clear-both">
                                <div className="btnGuardar" onClick={actualizar} >
                                    GUARDAR
                                </div>{" "}
                                <div className="btnCancelar" onClick={closeEditInversionista}>
                                    SALIR
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarInversionista);
