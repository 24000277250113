import {
    SHOW_BANNER,
    SHOW_BANNER_INIT,
    SHOW_BANNER_FAIL,
    SEND_EMAIL_PASSWORD,
    SEND_EMAIL_PASSWORD_FAIL,
} from "../actions/types";

const initialState = {
  listBanner : [],
  loadingBanner : false
}



export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SHOW_BANNER:
            return {
                ...state,
                loadingBanner: false,
                listBanner: payload.data,
              };
        case SHOW_BANNER_INIT:
          return {
            ...state,
            loadingBanner: true
          };
        case SHOW_BANNER_FAIL:
          return {
            ...state,
            loadingBanner: false,
            listBanner: [],
          };
        case SEND_EMAIL_PASSWORD:
            return {
                ...state,            
                recuperarLoading:false,
              };
        case SEND_EMAIL_PASSWORD_FAIL: 
        return {
            ...state,
            recuperarLoading: false,
          };   
        default:
            return state;
    }
  }
