import { NotificationManager } from "react-notifications";
import {
  SET_MESSAGE,
  GET_GESTION_CATALOGOS,
  GET_GESTION_CATALOGOS_FAIL,
  UPDATE_GESTION_CATALOGOS,
  UPDATE_GESTION_CATALOGOS_FAIL,
  DELETE_GESTION_CATALOGOS,
  DELETE_GESTION_CATALOGOS_FAIL,
  GET_CATALOGOS_PADRE,
  GET_CATALOGOS_PADRE_FAIL,
  DELETE_ITEM_CATALOGO,
  DELETE_ITEM_CATALOGO_FAIL,
  UPDATE_ITEM_CATALOGO,
  UPDATE_ITEM_CATALOGO_FAIL,
  GET_ITEM_CATALOGOS,
  GET_ITEM_CATALOGOS_FAIL,
  UPDATE_STATUS_BY_KEY_ADM,
  CATALOGOS_INI,
  ITEMS_INI,
  GET_EMPRESA,
  GET_EMPRESA_FAIL,
  EMPRESA_INI,
  DELETE_EMPRESA,
  DELETE_EMPRESA_FAIL,
  UPDATE_EMPRESA,
  UPDATE_EMPRESA_FAIL,
  GET_INVERSIONISTA,
  GET_INVERSIONISTA_FAIL,
  INVERSIONISTA_INI,
  DELETE_INVERSIONISTA,
  DELETE_INVERSIONISTA_FAIL,
  UPDATE_INVERSIONISTA,
  UPDATE_INVERSIONISTA_FAIL,
  GET_EMPRESA_INST,
  GET_EMPRESA_INST_FAIL,
  GET_USUARIOS_INV,
  GET_USUARIOS_INV_FAIL,
  GET_ORDEN,
  GET_ORDEN_FAIL,
  GET_ORDEN_USER_ID,
  GET_ORDEN_USER_ID_FAIL,
  ORDEN_USER_ID_INI,
  UPDATE_ORDEN,
  UPDATE_ORDEN_FAIL,
  DELETE_ORDEN,
  DELETE_ORDEN_FAIL,
  ORDEN_INI,
  GET_EMPRESA_SELECT,
  GET_EMPRESA_SELECT_FAIL,
  EMPRESA_SELECT_INI,
  GET_INVERSIONISTA_SELECT,
  GET_INVERSIONISTA_SELECT_FAIL,
  GET_EVENTO,
  GET_EVENTO_FAIL,
  UPDATE_EVENTO,
  UPDATE_EVENTO_FAIL,
  DELETE_EVENTO,
  DELETE_EVENTO_FAIL,
  EVENTO_INI,
  GET_USUARIO_EMPRESA,
  GET_USUARIO_EMPRESA_FAIL,
  GET_USUARIO_EMPRESA_SIN_ASIG,
  GET_USUARIO_EMPRESA_SIN_ASIG_FAIL,
  SELECT_USUARIO_EMPRESA,
  SELECT_USUARIO_EMPRESA_FAIL,
  DELETE_USUARIO_EMPRESA,
  DELETE_USUARIO_EMPRESA_FAIL,
  USUARIO_EMPRESA_INI,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  GET_USERS,
  GET_USERS_FAIL,
  DELETE_USER,
  DELETE_USER_FAIL,
  USER_INI,
  SELECT_EVENTO,
  SELECT_EVENTO_FAIL,
  GET_PARAMETRO,
  GET_PARAMETRO_FAIL,
  DELETE_PARAMETRO,
  DELETE_PARAMETRO_FAIL,
  UPDATE_PARAMETRO,
  UPDATE_PARAMETRO_FAIL,
  PARAMETRO_INI,
  GET_EMPRESA_CON_EVENTO,
  GET_EMPRESA_CON_EVENTO_FAIL,
  EMPRESA_CON_EVENTO_INI,
  GET_TODO_EVENTO,
  GET_TODO_EVENTO_FAIL,

  DELETE_EVENTO_EMPRESA,
  DELETE_EVENTO_EMPRESA_FAIL,
  SELECT_EVENTO_EMPRESA,
  SELECT_EVENTO_EMPRESA_FAIL,
  GET_EVENTO_EMPRESA_SIN_ASIG,
  GET_EVENTO_EMPRESA_SIN_ASIG_FAIL,
  GET_EVENTO_EMPRESA,
  GET_EVENTO_EMPRESA_FAIL,
  EVENTO_EMPRESA_INI,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAIL,
  ONLY_EVENT_DATA,
  ONLY_EVENT_DATA_FAIL,
  ONLY_EVENT_DATA_INI,
  GET_ADJUDICACION,
  GET_ADJUDICACION_FAIL,
  ADJUDICACION_INI,
  SALA_INI,
  INVERSIONISTA_SELECT_INI,
  GET_DATA_EMPRESA,
  GET_DATA_EMPRESA_FAIL,
  GET_DATA_EMPRESA_INI,

  // agg JP
  GET_EMPRESA_SELECT_INVERSIONISTA,
  GET_EMPRESA_SELECT_INVERSIONISTA_FAIL,

  GET_DATOS_ADJUDICACIONES,
  GET_DATOS_ADJUDICACIONES_FAIL,

  UPDATE_IMPRESION_LIQUIDACIONES,
  UPDATE_IMPRESION_LIQUIDACIONES_FAIL,

  UPDATE_IMPRESION_TITULOS,
  UPDATE_IMPRESION_TITULOS_FAIL,

  UPDATE_IMPRESION_REPORT_RUEDA,
  UPDATE_IMPRESION_REPORT_RUEDA_FAIL,

  UPDATE_INVITACION_EMAIL,
  UPDATE_INVITACION_EMAIL_FAIL,

  UPDATE_LIQUIDACION_EMAIL,
  UPDATE_LIQUIDACION_EMAIL_FAIL,

  UPDATE_LIQUIDACION,
  UPDATE_LIQUIDACION_FAIL,

  GET_CATALOGOS_MODALIDAD,
  GET_CATALOGOS_MODALIDAD_FAIL,

  UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL,
  UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL_FAIL,

  UPDATE_COBRAR_LIQUIDACION,
  UPDATE_COBRAR_LIQUIDACION_FAIL,

  UPDATE_COMENTARIO_LIQUIDACION,
  UPDATE_COMENTARIO_LIQUIDACION_FAIL,

  UPDATE_ESTADO_IMPRIMIR_CERTIFICADO,
  UPDATE_ESTADO_IMPRIMIR_CERTIFICADO_FAIL,


  UPDATE_ESTADO_IMPRIMIR_LIQUIDACION,
  UPDATE_ESTADO_IMPRIMIR_LIQUIDACION_FAIL,

  UPDATE_ESTADO_EMAIL_LIQUIDACION_EMPRESA,
  UPDATE_ESTADO_EMAIL_LIQUIDACION_EMPRESA_FAIL,

  UPDATE_ESTADO_EMAIL_LIQUIDACION_INVERSIONISTA,
  UPDATE_ESTADO_EMAIL_LIQUIDACION_INVERSIONISTA_FAIL,

  UPDATE_IMPRESION_REPORT_LIQUIDACION,
  UPDATE_IMPRESION_REPORT_LIQUIDACION_FAIL,

  UPDATE_IMPRESION_REPORT_ADJUDICACION,
  UPDATE_IMPRESION_REPORT_ADJUDICACION_FAIL,


  // control jueg
  SEND_VALUE



} from "./types";
import AdmService from "../services/administracion.service";

export const setStatusByKey = (key, value) => (dispatch) => {
  dispatch({
    type: UPDATE_STATUS_BY_KEY_ADM,
    payload: { key: key, value: value },
  });
  return Promise.resolve();
};
//OBTENER DATA EMPRESA
export const getDataEmpresaAct = (empresaId) => (dispatch) => {
  dispatch({
    type: GET_DATA_EMPRESA_INI,
  });
  return AdmService.getDataEmpresaSer(empresaId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_DATA_EMPRESA,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_DATA_EMPRESA_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_DATA_EMPRESA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


// FIN OBTENER DATA EMPRESA 
//obtener adjudicacion getAdjudicacionSer
export const getAdjudicacionAct = (eventoId, empresaId, usuarioId, page, filterField, filer, typeOrd, sort) => (
  dispatch
) => {
  dispatch({
    type: ADJUDICACION_INI,
  });
  return AdmService.getAdjudicacionSer(eventoId, empresaId, usuarioId, page, filterField, filer, typeOrd, sort).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_ADJUDICACION,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_ADJUDICACION_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ADJUDICACION_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
// fin de obtener adjudicacion

//obtener datos evento  getDataEventSer
export const getDataEventAct = (eventoId) => (dispatch) => {
  dispatch({
    type: ONLY_EVENT_DATA_INI,
  });
  return AdmService.getDataEventSer(eventoId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: ONLY_EVENT_DATA,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: ONLY_EVENT_DATA_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: ONLY_EVENT_DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

//fin de obtener datos evento

//perfil usuario 
export const updatePerfilUsuario = (usuarioId, nombre, nick, email, clave, image, uploadId) => (dispatch) => {
  return AdmService.updatePerfilUsuario(usuarioId, nombre, nick, email, clave, image, uploadId).then(
    (response) => {
      dispatch({
        type: UPDATE_USER_PROFILE,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_USER_PROFILE_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
//fin perfil usuario
//EVENTO EMPRESA
export const deleteEventoEmpresaAct = (eventoEmpresaId, eventoId) => (
  dispatch
) => {
  dispatch({
    type: EVENTO_EMPRESA_INI,
  });
  return AdmService.deleteEventoEmpresaSer(eventoEmpresaId).then(
    (response) => {
      if (response === 0) {
        dispatch(getEventoEmpresaAct(eventoId));

        dispatch({
          type: DELETE_EVENTO_EMPRESA,
        });
        NotificationManager.success(
          "Empresa Eliminada",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_EVENTO_EMPRESA_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_EVENTO_EMPRESA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const selectEventoEmpresaAct = (eventoId, empresaId, usuarioIdRegistro) => (dispatch) => {
  dispatch({
    type: EVENTO_EMPRESA_INI,
  });
  return AdmService.selectEventoEmpresaSer(eventoId, empresaId, usuarioIdRegistro).then(
    (response) => {
      if (response === 0) {
        dispatch(getEventoEmpresaAct(eventoId));
        dispatch(getEventoEmpresaSinAsigAct(eventoId));
        dispatch({
          type: SELECT_EVENTO_EMPRESA,
        });
        NotificationManager.success(
          "Empresa guardada",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: SELECT_EVENTO_EMPRESA_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SELECT_EVENTO_EMPRESA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getEventoEmpresaSinAsigAct = (eventoId) => (dispatch) => {
  dispatch({
    type: EVENTO_EMPRESA_INI,
  });
  return AdmService.getEventoEmpresaSinAsigSer(eventoId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_EVENTO_EMPRESA_SIN_ASIG,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_EVENTO_EMPRESA_SIN_ASIG_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EVENTO_EMPRESA_SIN_ASIG_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getEventoEmpresaAct = (eventoId) => (dispatch) => {
  dispatch({
    type: EVENTO_EMPRESA_INI,
  });
  return AdmService.getEventoEmpresaSer(eventoId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_EVENTO_EMPRESA,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_EVENTO_EMPRESA_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EVENTO_EMPRESA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

//FIN EVENTO EMPRESA


// obtener eventos y empresas de eventos selccionado
export const getTodoEventoAct = (usuarioId) => (dispatch) => {
  dispatch({
    type: EMPRESA_CON_EVENTO_INI,
  });
  return AdmService.getTodoEventoSer(usuarioId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_TODO_EVENTO,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_TODO_EVENTO_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_TODO_EVENTO_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const getEmpresaConEventoAct = (eventoId, usuarioId, empresaId) => (dispatch) => {
  dispatch({
    type: EMPRESA_CON_EVENTO_INI,
  });
  return AdmService.getEmpresaConEventoSer(eventoId, usuarioId, empresaId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_EMPRESA_CON_EVENTO,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_EMPRESA_CON_EVENTO_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EMPRESA_CON_EVENTO_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
// fin btener evento y empresas de ventos seleccionados


//Parametros 
export const deleteParametroAct = (parametroId) => (dispatch) => {

  dispatch({
    type: PARAMETRO_INI,
  });
  return AdmService.deleteParametroSer(parametroId).then(
    (response) => {
      if (response === 0) {
        dispatch(getParametroAct("0", "", "", "ASC", ""));
        dispatch({
          type: DELETE_PARAMETRO,
        });
        NotificationManager.success(
          "Parámetro Eliminado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_PARAMETRO_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar parámetro",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_PARAMETRO_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getParametroAct = (page, filterField, filer, typeOrd, sort) => (
  dispatch
) => {
  dispatch({
    type: PARAMETRO_INI,
  });
  return AdmService.getParametroSer(page, filterField, filer, typeOrd, sort).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_PARAMETRO,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_PARAMETRO_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_PARAMETRO_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const updateParametroAct = (
  parametroId,
  codigo,
  nombre,
  descripcion,
  valor,
  usaurioIdRegistro,
  estado
) => (dispatch) => {
  dispatch({
    type: USER_INI,
  });
  return AdmService.updateParametroSer(
    parametroId,
    codigo,
    nombre,
    descripcion,
    valor,
    usaurioIdRegistro,
    estado
  ).then(
    (response) => {
      if (response === 0) {
        dispatch(getParametroAct("0", "", "", "ASC", ""));
        dispatch({
          type: UPDATE_PARAMETRO,
        });
        NotificationManager.success(
          "Parámetro guardado",
          "Sistema",
          3000,
          () => { },
          4
        );
      }

      if (response === -1) {
        dispatch({
          type: UPDATE_PARAMETRO_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_PARAMETRO_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
//fin parametros

//usuarios
export const deleteUsuario = (usuarioId) => (dispatch) => {

  dispatch({
    type: USER_INI,
  });
  return AdmService.deleteUsuario(usuarioId).then(
    (response) => {

      if (response === 0) {
        dispatch(getUsuarios("0", "", "", "ASC", ""));
        dispatch({
          type: DELETE_USER,
        });
        NotificationManager.success(
          "Usuario Eliminado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_USER_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar usuario",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DELETE_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getUsuarios = (page, filterField, filer, typeOrd, sort) => (
  dispatch
) => {
  dispatch({
    type: USER_INI,
  });
  return AdmService.getUsuarios(page, filterField, filer, typeOrd, sort).then(
    (data) => {
      console.log(data);
      if (data != null) {
        dispatch({
          type: GET_USERS,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_USERS_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_USERS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const updateUsuario = (
  usuarioId,
  nombre,
  nick,
  email,
  rol,
  clave,
  estado,
  uploadId,
  imagenUrl
) => (dispatch) => {
  dispatch({
    type: USER_INI,
  });
  return AdmService.updateUsuario(
    usuarioId,
    nombre,
    nick,
    email,
    rol,
    clave,
    estado,
    uploadId,
    imagenUrl
  ).then(
    (response) => {
      if (response === 0) {
        dispatch(getUsuarios("0", "", "", "ASC", ""));
        dispatch({
          type: UPDATE_USER,
        });
        NotificationManager.success(
          "Usuario guardado",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      if (response === -2) {
        dispatch({
          type: UPDATE_USER_FAIL,
        });
        NotificationManager.error(
          "Error, correo ya existe!",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      if (response === -1) {
        dispatch({
          type: UPDATE_USER_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
//fin usuarios

//USUARIOS EMPRESA
export const deleteUsuarioEmpresaAct = (usuarioEmpresaId, empresaId) => (
  dispatch
) => {
  dispatch({
    type: USUARIO_EMPRESA_INI,
  });
  return AdmService.deleteUsuarioEmpresaSer(usuarioEmpresaId).then(
    (response) => {
      if (response === 0) {
        dispatch(getUsuarioEmpresaAct(empresaId));

        dispatch({
          type: DELETE_USUARIO_EMPRESA,
        });
        NotificationManager.success(
          "Usuario Eliminado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_USUARIO_EMPRESA_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_USUARIO_EMPRESA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const selectUsuarioEmpresaAct = (empresaId, usuarioId) => (dispatch) => {
  dispatch({
    type: USUARIO_EMPRESA_INI,
  });
  return AdmService.selectUsuarioEmpresaSer(empresaId, usuarioId).then(
    (response) => {
      if (response === 0) {
        dispatch(getUsuarioEmpresaAct(empresaId));
        dispatch(getUsuarioEmpresaSinAsigAct(empresaId));
        dispatch({
          type: SELECT_USUARIO_EMPRESA,
        });
        NotificationManager.success(
          "Usuario guardado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: SELECT_USUARIO_EMPRESA_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: SELECT_USUARIO_EMPRESA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getUsuarioEmpresaSinAsigAct = (empresaId) => (dispatch) => {
  dispatch({
    type: USUARIO_EMPRESA_INI,
  });
  return AdmService.getUsuarioEmpresaSinAsigSer(empresaId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_USUARIO_EMPRESA_SIN_ASIG,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_USUARIO_EMPRESA_SIN_ASIG_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_USUARIO_EMPRESA_SIN_ASIG_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getUsuarioEmpresaAct = (empresaId) => (dispatch) => {
  dispatch({
    type: USUARIO_EMPRESA_INI,
  });
  return AdmService.getUsuarioEmpresaSer(empresaId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_USUARIO_EMPRESA,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_USUARIO_EMPRESA_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_USUARIO_EMPRESA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

//FIN USUARIOS EMPRESA

//evento
export const deleteEventoAct = (
  eventoId,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: EVENTO_INI,
  });
  return AdmService.deleteEventoSer(eventoId).then(
    (response) => {
      if (response === 0) {
        dispatch(getEventoAct(page, filterField, filer, typeOrd, sort));

        dispatch({
          type: DELETE_EVENTO,
        });
        NotificationManager.success(
          "Evento Eliminado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_EVENTO_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_EVENTO_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const updateEventoAct = (
  eventoId,
  logoUrl,
  modalidadId,
  nombreEvento,
  logo,
  estado,
  usuarioRegistroId,
  fechaSubasta,
  inscripcion,
  subasta,
  colocacion,
  adjudicacion,
  nombreEmpresaCorto,
  nombreExponente,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: EVENTO_INI,
  });
  return AdmService.updateEventoSer(
    eventoId,
    logoUrl,
    modalidadId,
    nombreEvento,
    logo,
    estado,
    usuarioRegistroId,
    fechaSubasta,
    inscripcion,
    subasta,
    colocacion,
    adjudicacion,
    nombreEmpresaCorto,
    nombreExponente
  ).then(
    (response) => {
      if (response === 0) {
        dispatch(getEventoAct(page, filterField, filer, typeOrd, sort));

        dispatch({
          type: UPDATE_EVENTO,
        });
        NotificationManager.success(
          "Evento guardado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: UPDATE_EVENTO_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_EVENTO_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getEventoAct = (page, filterField, filer, typeOrd, sort) => (
  dispatch
) => {
  dispatch({
    type: EVENTO_INI,
  });
  return AdmService.getEventoSer(page, filterField, filer, typeOrd, sort).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_EVENTO,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_EVENTO_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EVENTO_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
//fin evento

//  ordenes
export const getEventoSelectAct = () => (dispatch) => {
  return AdmService.getEventoSelectSer().then(
    (data) => {
      if (data != null) {
        dispatch({
          type: SELECT_EVENTO,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: SELECT_EVENTO_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SELECT_EVENTO_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const getEmpresaSelectAct = (eventoId) => (dispatch) => {
  dispatch({
    type: SALA_INI,
  });
  return AdmService.getEmpresaSelectSer(eventoId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_EMPRESA_SELECT,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_EMPRESA_SELECT_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EMPRESA_SELECT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getInversionistaSelectAct = (empresaId) => (dispatch) => {
  dispatch({
    type: INVERSIONISTA_SELECT_INI,
  });
  return AdmService.getInversionistaSelectSer(empresaId).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_INVERSIONISTA_SELECT,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_INVERSIONISTA_SELECT_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_INVERSIONISTA_SELECT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteOrdenAct = (
  ordenId,
  empresaId,
  eventoId,
  userId
) => (dispatch) => {
  dispatch({
    type: ORDEN_INI,
  });
  return AdmService.deleteOrdenSer(ordenId).then(
    (response) => {
      if (response === 0) {

        dispatch(getOrdenPorUsuarioAct(empresaId, eventoId, userId, "0", "", "", "ASC", ""));
        dispatch(getAdjudicacionAct(eventoId, empresaId, userId, "0", "", "", "ASC", ""));
        dispatch({
          type: DELETE_ORDEN,
        });
        NotificationManager.success(
          "Orden Eliminado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_ORDEN_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_INVERSIONISTA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getOrdenAct = (page, filterField, filer, typeOrd, sort) => (
  dispatch
) => {
  dispatch({
    type: SALA_INI,
  });
  return AdmService.getOrdenSer(page, filterField, filer, typeOrd, sort).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_ORDEN,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_ORDEN_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ORDEN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getOrdenPorUsuarioAct = (
  empresaId,
  eventoId,
  usuarioId,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: ORDEN_USER_ID_INI,
  });
  return AdmService.getOrdenPorUsuarioSer(
    empresaId,
    eventoId,
    usuarioId,
    page,
    filterField,
    filer,
    typeOrd,
    sort
  ).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_ORDEN_USER_ID,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_ORDEN_USER_ID_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ORDEN_USER_ID_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateOrdenAct = (
  ordenId, empresaId, inversionistaId, accionesComprar, precioPagar, estado, usuarioRegistroId, eventoId,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: SALA_INI,
  });
  return AdmService.updateOrdenSer(
    ordenId,
    empresaId,
    inversionistaId,
    accionesComprar,
    precioPagar,
    estado,
    usuarioRegistroId,
    eventoId
  ).then(
    (response) => {
      if (response === 0) {
        dispatch({
          type: UPDATE_ORDEN,
        });
        dispatch(getOrdenPorUsuarioAct(empresaId, eventoId, usuarioRegistroId, page, filterField, filer, typeOrd, sort));
        NotificationManager.success(
          "Orden guardada",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      if (response === -1) {
        dispatch({
          type: UPDATE_ORDEN_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      /* if(response ===-2){        
         dispatch({
           type: UPDATE_ORDEN_FAIL,
         });
         NotificationManager.error(
           "Error. Acciones sobrepasan las acciones de la empresa",
           "Sistema",
           4000,
           () => {},
           4
         );
       }*/
      if (response === -3) {
        dispatch({
          type: UPDATE_ORDEN_FAIL,
        });
        NotificationManager.error(
          "Error. Precio debe ser mayor al precio de oferta",
          "Sistema",
          4000,
          () => { },
          4
        );
      }
      if (response === -2) {
        dispatch({
          type: UPDATE_ORDEN_FAIL,
        });
        NotificationManager.error(
          "Error, precio debe ser menor que el techo máximo",
          "Sistema",
          4000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_ORDEN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
//  fin ordenes
//obtener usuarios
export const getUsuariosAct = () => (dispatch) => {
  dispatch({
    type: INVERSIONISTA_INI,
  });
  return AdmService.getUsuariosSer().then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_USUARIOS_INV,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_USUARIOS_INV_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_USUARIOS_INV_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
// fin obt usuarios

//obtener instituciones getInstitucionesSer
export const getInstitucionesEmpresaAct = () => (dispatch) => {
  dispatch({
    type: EMPRESA_INI,
  });
  return AdmService.getInstitucionesEmpresaSer().then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_EMPRESA_INST,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_EMPRESA_INST_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EMPRESA_INST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
// fin obtener instituciones

//inversionista
export const deleteInversionistaAct = (
  inversionistaId,
  usuarioId,
  empresaId,
  page,
  filterField,
  filer,
  typeOrd,
  sort,

) => (dispatch) => {
  dispatch({
    type: INVERSIONISTA_INI,
  });
  return AdmService.deleteInversionistaSer(inversionistaId).then(
    (response) => {
      if (response === 0) {
        dispatch(getInversionistaAct(usuarioId, empresaId, page, filterField, filer, typeOrd, sort));

        dispatch({
          type: DELETE_INVERSIONISTA,
        });
        NotificationManager.success(
          "Inversionista Eliminado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_INVERSIONISTA_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_INVERSIONISTA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const updateInversionistaAct = (
  inversionistaId,
  nombre,
  apellido,
  cedula,
  telefono,
  direccion,
  estado,
  usuarioRegistroId,
  empresaId,
  email,
  page,
  filterField,
  filer,
  typeOrd,
  sort,
  empresaUsuarioId
) => (dispatch) => {
  dispatch({
    type: INVERSIONISTA_INI,
  });
  return AdmService.updateInversionistaSer(
    inversionistaId,
    nombre,
    apellido,
    cedula,
    telefono,
    direccion,
    estado,
    usuarioRegistroId,
    empresaId,
    email
  ).then(
    (response) => {
      // Aqui se cambio 0
      console.log("respuesta de servicio ", response);
      //const idEmpres = response;
      if (response !== "") {
        dispatch(getInversionistaAct(usuarioRegistroId, empresaUsuarioId, page, filterField, filer, typeOrd, sort));
        dispatch(getInversionistaSelectAct(empresaId,));


        dispatch({
          type: UPDATE_INVERSIONISTA,
        });
        NotificationManager.success(" guardado", "Sistema", 3000, () => { }, 4);
      } else {
        dispatch({
          type: UPDATE_INVERSIONISTA_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_INVERSIONISTA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getInversionistaAct = (
  usuarioId,
  empresaId,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: INVERSIONISTA_INI,
  });
  return AdmService.getInversionistaSer(
    usuarioId,
    empresaId,
    page,
    filterField,
    filer,
    typeOrd,
    sort
  ).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_INVERSIONISTA,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_INVERSIONISTA_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_INVERSIONISTA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
//fin inversionista

// empresa
export const deleteEmpresaAct = (
  empresaId,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: EMPRESA_INI,
  });
  return AdmService.deleteEmpresaSer(empresaId).then(
    (response) => {
      if (response === 0) {
        dispatch(getEmpresaAct(page, filterField, filer, typeOrd, sort));

        dispatch({
          type: DELETE_EMPRESA,
        });
        NotificationManager.success(
          "Empresa Eliminada",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_EMPRESA_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_EMPRESA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const updateEmpresaAct = (
  empresaId,
  nombre,
  logo,
  direccion,
  estado,
  usuarioRegistroId,
  institucion,
  email,
  gerente,
  operador,
  receptor,
  numAccionesOferta,
  precioInicialPorAccion,
  valorUnitarioAccion,
  montoMinMejoraPrecio,
  comisionOperador,
  comisionBVQ,
  porcentajePrecioTechoMax,
  fechaConstitucion,
  fechaInscripcion,
  capitalPagado,
  divididoEn,
  valor,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: EMPRESA_INI,
  });
  return AdmService.updateEmpresaSer(
    empresaId,
    nombre,
    logo,
    direccion,
    estado,
    usuarioRegistroId,
    institucion,
    email,
    gerente,
    operador,
    receptor,
    numAccionesOferta,
    precioInicialPorAccion,
    valorUnitarioAccion,
    montoMinMejoraPrecio,
    comisionOperador,
    comisionBVQ,
    porcentajePrecioTechoMax,
    fechaConstitucion,
    fechaInscripcion,
    capitalPagado,
    divididoEn,
    valor,
  ).then(
    (response) => {
      if (response === 0) {
        dispatch(getEmpresaAct(page, filterField, filer, typeOrd, sort));
        dispatch(getEmpresaSelectAct());
        dispatch({
          type: UPDATE_EMPRESA,
        });
        NotificationManager.success(
          "Empresa guardada",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: UPDATE_EMPRESA_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_EMPRESA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getEmpresaAct = (page, filterField, filer, typeOrd, sort) => (
  dispatch
) => {
  dispatch({
    type: EMPRESA_INI,
  });
  return AdmService.getEmpresaSer(page, filterField, filer, typeOrd, sort).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_EMPRESA,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_EMPRESA_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EMPRESA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

// fin empresa

//item catalogo

export const deleteItemCatalogo = (
  itemId,
  catalogoId,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: ITEMS_INI,
  });
  return AdmService.deleteItemCatalogo(itemId).then(
    (response) => {
      if (response === 0) {
        dispatch(
          getItemCatalogos(catalogoId, page, filterField, filer, typeOrd, sort)
        );

        dispatch({
          type: DELETE_ITEM_CATALOGO,
        });
        NotificationManager.success(
          "Item catálogo Eliminado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_ITEM_CATALOGO_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_ITEM_CATALOGO_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const updateItemCatalogo = (
  itemId,
  codigo,
  nombre,
  activo,
  editar,
  itemPadreId,
  catalogoId,
  nombreCorto,
  estado,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: ITEMS_INI,
  });
  return AdmService.updateItemCatalogo(
    itemId,
    codigo,
    nombre,
    activo,
    editar,
    itemPadreId,
    catalogoId,
    nombreCorto,
    estado
  ).then(
    (response) => {
      if (response === 0) {
        dispatch(
          getItemCatalogos(catalogoId, page, filterField, filer, typeOrd, sort)
        );
        dispatch({
          type: UPDATE_ITEM_CATALOGO,
        });
        NotificationManager.success(
          "Item catálogo guardado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: UPDATE_ITEM_CATALOGO_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_ITEM_CATALOGO_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getItemCatalogos = (
  catalogoId,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: ITEMS_INI,
  });
  return AdmService.getItemsCatalogosPag(
    catalogoId,
    page,
    filterField,
    filer,
    typeOrd,
    sort
  ).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_ITEM_CATALOGOS,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_ITEM_CATALOGOS_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_ITEM_CATALOGOS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
// fin item catalogos
//catalogos

export const getCatalogosPadre = () => (dispatch) => {
  return AdmService.getCatalogosPadre().then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_CATALOGOS_PADRE,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_CATALOGOS_PADRE_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_CATALOGOS_PADRE_FAIL,
      });
      return Promise.reject();
    }
  );
};

export const deleteCatalogo = (
  catalogoId,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: CATALOGOS_INI,
  });
  return AdmService.deleteGestionCatalogo(catalogoId).then(
    (response) => {
      if (response === 0) {
        dispatch(getCatalogos(page, filterField, filer, typeOrd, sort));

        dispatch({
          type: DELETE_GESTION_CATALOGOS,
        });
        NotificationManager.success(
          "Catálogo eliminado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: DELETE_GESTION_CATALOGOS_FAIL,
        });
        NotificationManager.error(
          "Error al eliminar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_GESTION_CATALOGOS_FAIL,
      });
      return Promise.reject();
    }
  );
};

export const updateGestionCatalogo = (
  catalogoId,
  codigo,
  nombre,
  activo,
  editar,
  catalogoPadreId,
  estado,
  page,
  filterField,
  filer,
  typeOrd,
  sort
) => (dispatch) => {
  dispatch({
    type: CATALOGOS_INI,
  });
  return AdmService.updateGestionCatalogo(
    catalogoId,
    codigo,
    nombre,
    activo,
    editar,
    catalogoPadreId,
    estado
  ).then(
    (response) => {
      if (response === 0) {
        dispatch(getCatalogos(page, filterField, filer, typeOrd, sort));
        dispatch(getCatalogosPadre());
        dispatch({
          type: UPDATE_GESTION_CATALOGOS,
        });
        NotificationManager.success(
          "Catálogo guardado",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: UPDATE_GESTION_CATALOGOS_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_GESTION_CATALOGOS_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const getCatalogos = (page, filterField, filer, typeOrd, sort) => (
  dispatch
) => {
  dispatch({
    type: CATALOGOS_INI,
  });
  return AdmService.getGestionCatalogosPag(
    page,
    filterField,
    filer,
    typeOrd,
    sort
  ).then(
    (data) => {
      if (data != null) {
        dispatch({
          type: GET_GESTION_CATALOGOS,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_GESTION_CATALOGOS_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_GESTION_CATALOGOS_FAIL,
      });

      return Promise.reject();
    }
  );
};

// AGG JP
export const getEmpresaSelectInversionista = (usuarioId, empresaId) => (dispatch) => {
  return AdmService.getEmpresaSelectInversionista(
    usuarioId, empresaId
  ).then(
    (data) => {
      //console.log("action");
      //console.log(data);
      if (data != null) {

        dispatch({
          type: GET_EMPRESA_SELECT_INVERSIONISTA,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_EMPRESA_SELECT_INVERSIONISTA_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_EMPRESA_SELECT_INVERSIONISTA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const getDatosAdjudicaciones = (eventoId, usuarioId, empresaId, page, filterField, filer, typeOrd, sort) => (dispatch) => {

  return AdmService.getDatosAdjudicaciones(eventoId, usuarioId, empresaId, page, filterField, filer, typeOrd, sort).then(
    (data) => {
      //console.log(data);
      if (data != null) {
        dispatch({
          type: GET_DATOS_ADJUDICACIONES,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_DATOS_ADJUDICACIONES_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_DATOS_ADJUDICACIONES_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


// Imprimir certificado de liquidaciones
export const updateImpresionLiquidaciones = (
  liquidacionId

) => (dispatch) => {
  return AdmService.updateImpresionLiquidaciones(
    liquidacionId

  ).then(
    (response) => {
      if (response !== "") {
        dispatch({
          type: UPDATE_IMPRESION_LIQUIDACIONES
        });
        NotificationManager.success(
          "Certificado Impreso",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      if (response === -2) {
        dispatch({
          type: UPDATE_IMPRESION_LIQUIDACIONES_FAIL,
        });
        NotificationManager.error(
          "Error al imprimir",
          "Sistema",
          3000,
          () => { },
          4
        );
      }

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_IMPRESION_LIQUIDACIONES_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );



};

// Imprimir titulos de liquidaciones
export const updateImpresionTitulos = (
  liquidacionId

) => (dispatch) => {
  return AdmService.updateImpresionTitulos(
    liquidacionId

  ).then(
    (response) => {
      if (response !== "") {
        //dispatch(getUsuarios("0", "", "", "ASC", ""));
        dispatch({
          type: UPDATE_IMPRESION_TITULOS
        });
        NotificationManager.success(
          "Titulo Impreso",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      if (response === -2) {
        dispatch({
          type: UPDATE_IMPRESION_TITULOS_FAIL,
        });
        NotificationManager.error(
          "Error al imprimir",
          "Sistema",
          3000,
          () => { },
          4
        );
      }

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_IMPRESION_TITULOS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }

  )
};

// reporte user rueda
export const updateImpresionReportRueda = (
  adjudicacionId

) => (dispatch) => {
  return AdmService.updateImpresionReportRueda(
    adjudicacionId

  ).then(
    (response) => {
      if (response !== "") {
        //dispatch(getUsuarios("0", "", "", "ASC", ""));
        dispatch({
          type: UPDATE_IMPRESION_REPORT_RUEDA
        });
        NotificationManager.success(
          "Reporte Impreso",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      if (response === -2) {
        dispatch({
          type: UPDATE_IMPRESION_REPORT_RUEDA_FAIL,
        });
        NotificationManager.error(
          "Error al imprimir",
          "Sistema",
          3000,
          () => { },
          4
        );
      }

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_IMPRESION_REPORT_RUEDA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }

  )
};

// enviar email invitacion
export const sendEmailInvitacionUser = (
  eventoId, usuarioId, empresaId

) => (dispatch) => {
  return AdmService.sendEmailInvitacionUser(
    eventoId, usuarioId, empresaId).then(
      (response) => {
        if (response === 0) {
          dispatch({
            type: UPDATE_INVITACION_EMAIL
          });
          NotificationManager.success(
            "Invitación individual enviada",
            "Sistema",
            3000,
            () => { },
            4
          );
        }
        if (response === 1) {
          dispatch({
            type: UPDATE_INVITACION_EMAIL
          });
          NotificationManager.success(
            "Invitación grupal enviada",
            "Sistema",
            3000,
            () => { },
            4
          );
        }
        if (response === -1) {
          dispatch({
            type: UPDATE_INVITACION_EMAIL_FAIL,
          });
          NotificationManager.error(
            "Error al enviar invitación",
            "Sistema",
            3000,
            () => { },
            4
          );
        }

        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_INVITACION_EMAIL_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }

    )
};

// enviar email liquidacion
export const sendLiquidacionUser = (
  eventoId, usuarioId

) => (dispatch) => {
  return AdmService.sendLiquidacionUser(
    eventoId, usuarioId

  ).then(
    (response) => {
      if (response !== -1) {
        //dispatch(getUsuarios("0", "", "", "ASC", ""));
        dispatch({
          type: UPDATE_LIQUIDACION_EMAIL
        });
        NotificationManager.success(
          "Email enviado correctamente",
          "Sistema",
          3000,
          () => { },
          4
        );
      }

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_LIQUIDACION_EMAIL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      NotificationManager.error(
        "Error al enviar email",
        "Sistema",
        3000,
        () => { },
        4
      );

      return Promise.reject();
    }

  )
};

// liquidaciones crud
export const updateLiquidacion = (
  eventoId
) => (dispatch) => {
  return AdmService.updateLiquidacion(
    eventoId
  ).then(
    (response) => {
      if (response === 0) {
        dispatch({
          type: UPDATE_LIQUIDACION,
        });
        NotificationManager.success(
          "Liquidación guardada",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      if (response === -1) {
        dispatch({
          type: UPDATE_LIQUIDACION_FAIL,
        });
        NotificationManager.error(
          "Error al guardar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_LIQUIDACION_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

// control juego
export const sendValue = value => {
  return {
    type: 'SEND_VALUE',
    //payload: value
  };
};

export const getCatalogosModalidad = () => (dispatch) => {
  return AdmService.getCatalogosModalidad().then(
    (data) => {
      console.log("modalidades", data)
      if (data != null) {

        dispatch({
          type: GET_CATALOGOS_MODALIDAD,
          payload: { data: data },
        });
        return Promise.resolve();
      } else {
        dispatch({
          type: GET_CATALOGOS_MODALIDAD_FAIL,
        });
      }

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_CATALOGOS_MODALIDAD_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


// enviar email liquidacion inversionista
export const sendLiquidacionInversionista = (
  eventoId, usuarioId

) => (dispatch) => {
  return AdmService.sendLiquidacionInversionista(
    eventoId, usuarioId

  ).then(
    (response) => {
      if (response !== -1) {
        //dispatch(getUsuarios("0", "", "", "ASC", ""));
        dispatch({
          type: UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL
        });
        NotificationManager.success(
          "Email enviado correctamente",
          "Sistema",
          3000,
          () => { },
          4
        );
      }

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      NotificationManager.error(
        "Error al enviar email",
        "Sistema",
        3000,
        () => { },
        4
      );

      return Promise.reject();
    }

  )
};


export const updateCobrarLiquidacion = (

  liquidacionId, eventoId, usuarioId, empresaId
) => (dispatch) => {

  return AdmService.updateCobrarLiquidacion(liquidacionId).then(
    (response) => {
      if (response === 0) {
        dispatch(getDatosAdjudicaciones(eventoId, usuarioId, empresaId, "0", "", "", "ASC", ""))
        //dispatch(getOrdenPorUsuarioAct(empresaId,eventoId,userId,"0", "", "", "ASC",""));
        //dispatch(getAdjudicacionAct(eventoId, empresaId, userId, "0", "", "", "ASC", ""));
        dispatch({
          type: UPDATE_COBRAR_LIQUIDACION,
        });
        NotificationManager.success(
          "Liquidación cobrada",
          "Sistema",
          3000,
          () => { },
          4
        );
      } else {
        dispatch({
          type: UPDATE_COBRAR_LIQUIDACION_FAIL,
        });
        NotificationManager.error(
          "Error al cobrar",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_COBRAR_LIQUIDACION_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const updateComentarioLiquidacion = (

  liquidacionId, comentario, eventoId, usuarioId, empresaId
) => (dispatch) => {
  return AdmService.updateComentarioLiquidacion(liquidacionId, comentario, eventoId, usuarioId, empresaId).then(
    (response) => {
      if (response === 0) {


        dispatch(getDatosAdjudicaciones(eventoId, usuarioId, empresaId, "0", "", "", "ASC", ""))

        dispatch({
          type: UPDATE_COMENTARIO_LIQUIDACION,
        });
        // NotificationManager.success(
        // "Comentario guardado",
        //"Sistema",
        // 3000,
        // () => {},
        // 4
        // );
      } else {
        dispatch({
          type: UPDATE_COMENTARIO_LIQUIDACION_FAIL,
        });
        //  NotificationManager.error(
        //  "Error al guardar",
        // "Sistema",
        // 3000,
        // () => {},
        // 4
        // );
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_COMENTARIO_LIQUIDACION_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

// Estado iconos liquidacion
export const updateImprimirCertificado = (
  liquidacionId, eventoId, usuarioId, empresaId
) => (dispatch) => {

  return AdmService.updateImprimirCertificado(liquidacionId).then(
    (response) => {
      if (response === 0) {
        dispatch(getDatosAdjudicaciones(eventoId, usuarioId, empresaId, "0", "", "", "ASC", ""))

        dispatch({
          type: UPDATE_ESTADO_IMPRIMIR_CERTIFICADO,
        });
        /*NotificationManager.success(
          "Liquidación cobrada",
          "Sistema",
          3000,
          () => {},
          4
        );*/
      } else {
        dispatch({
          type: UPDATE_ESTADO_IMPRIMIR_CERTIFICADO_FAIL,
        });
        /*NotificationManager.error(
          "Error al cobrar",
          "Sistema",
          3000,
          () => {},
          4
        );*/
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_ESTADO_IMPRIMIR_CERTIFICADO_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};



export const updateImprimirLiquidacion = (
  liquidacionId, eventoId, usuarioId, empresaId
) => (dispatch) => {

  return AdmService.updateImprimirLiquidacion(liquidacionId).then(
    (response) => {
      if (response === 0) {
        dispatch(getDatosAdjudicaciones(eventoId, usuarioId, empresaId, "0", "", "", "ASC", ""))

        dispatch({
          type: UPDATE_ESTADO_IMPRIMIR_LIQUIDACION,
        });
        /*NotificationManager.success(
          "Liquidación cobrada",
          "Sistema",
          3000,
          () => {},
          4
        );*/
      } else {
        dispatch({
          type: UPDATE_ESTADO_IMPRIMIR_LIQUIDACION_FAIL,
        });
        /*NotificationManager.error(
          "Error al cobrar",
          "Sistema",
          3000,
          () => {},
          4
        );*/
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_ESTADO_IMPRIMIR_LIQUIDACION_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};


export const updateEstadoEmailEmpresa = (
  liquidacionId, eventoId, usuarioId, empresaId
) => (dispatch) => {

  return AdmService.updateEstadoEmailEmpresa(liquidacionId).then(
    (response) => {
      if (response === 0) {
        dispatch(getDatosAdjudicaciones(eventoId, usuarioId, empresaId, "0", "", "", "ASC", ""))

        dispatch({
          type: UPDATE_ESTADO_EMAIL_LIQUIDACION_EMPRESA,
        });
        /*NotificationManager.success(
          "Liquidación cobrada",
          "Sistema",
          3000,
          () => {},
          4
        );*/
      } else {
        dispatch({
          type: UPDATE_ESTADO_EMAIL_LIQUIDACION_EMPRESA_FAIL,
        });
        /*NotificationManager.error(
          "Error al cobrar",
          "Sistema",
          3000,
          () => {},
          4
        );*/
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_ESTADO_EMAIL_LIQUIDACION_EMPRESA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const updateEstadoEmailInversionista = (
  liquidacionId, eventoId, usuarioId, empresaId
) => (dispatch) => {

  return AdmService.updateEstadoEmailInversionista(liquidacionId).then(
    (response) => {
      if (response === 0) {
        dispatch(getDatosAdjudicaciones(eventoId, usuarioId, empresaId, "0", "", "", "ASC", ""))

        dispatch({
          type: UPDATE_ESTADO_EMAIL_LIQUIDACION_INVERSIONISTA,
        });
        /*NotificationManager.success(
          "Liquidación cobrada",
          "Sistema",
          3000,
          () => {},
          4
        );*/
      } else {
        dispatch({
          type: UPDATE_ESTADO_EMAIL_LIQUIDACION_INVERSIONISTA_FAIL,
        });
        /*NotificationManager.error(
          "Error al cobrar",
          "Sistema",
          3000,
          () => {},
          4
        );*/
      }
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: UPDATE_ESTADO_EMAIL_LIQUIDACION_INVERSIONISTA_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};


// reporte user rueda
export const updateImpresionReportLiquidacion = (
  eventoId

) => (dispatch) => {
  return AdmService.updateImpresionReportLiquidacion(
    eventoId

  ).then(
    (response) => {
      if (response !== "") {
        //dispatch(getUsuarios("0", "", "", "ASC", ""));
        dispatch({
          type: UPDATE_IMPRESION_REPORT_LIQUIDACION
        });
        NotificationManager.success(
          "Reporte Impreso",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      if (response === -2) {
        dispatch({
          type: UPDATE_IMPRESION_REPORT_LIQUIDACION_FAIL,
        });
        NotificationManager.error(
          "Error al imprimir",
          "Sistema",
          3000,
          () => { },
          4
        );
      }

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_IMPRESION_REPORT_LIQUIDACION_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }

  )
};


// reporte user rueda
export const updateImpresionReportAdjudicacion = (
  eventoId

) => (dispatch) => {
  return AdmService.updateImpresionReportAdjudicacion(
    eventoId

  ).then(
    (response) => {
      if (response !== "") {
        //dispatch(getUsuarios("0", "", "", "ASC", ""));
        dispatch({
          type: UPDATE_IMPRESION_REPORT_ADJUDICACION
        });
        NotificationManager.success(
          "Reporte Impreso",
          "Sistema",
          3000,
          () => { },
          4
        );
      }
      if (response === -2) {
        dispatch({
          type: UPDATE_IMPRESION_REPORT_ADJUDICACION_FAIL,
        });
        NotificationManager.error(
          "Error al imprimir",
          "Sistema",
          3000,
          () => { },
          4
        );
      }

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: UPDATE_IMPRESION_REPORT_ADJUDICACION_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }

  )
};