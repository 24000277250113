import {
    SHOW_BANNER,
    SHOW_BANNER_FAIL,
    SHOW_BANNER_INIT,
    SET_MESSAGE,
} from "./types";

import BannerService from "../services/banner.service";

export const showBanner = () => (dispatch) => {
    dispatch({
        type: SHOW_BANNER_INIT,
      });
    return BannerService.getBannerByGame().then(
      (data) => {
        if(data!=null){
          dispatch({
            type: SHOW_BANNER,
            payload: { data: data },
          });  
          return Promise.resolve();
        }else{
          dispatch({
            type: SHOW_BANNER_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: "Error al obtener banners",
          });
          return Promise.reject();
        }          
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: SHOW_BANNER_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };
  