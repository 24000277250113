export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//Sala de espera
export const SHOW_BANNER = "SHOW_BANNER";
export const SHOW_BANNER_INIT = "SHOW_BANNER_INIT";
export const SHOW_BANNER_FAIL = "SHOW_BANNER_FAIL";

//Eventos
export const GET_EVENT_BY_USER = "GET_EVENT_BY_USER";
export const GET_EVENT_BY_USER_FAIL = "GET_EVENT_BY_USER_FAIL";
export const GET_EVENT_BY_USER_INIT = "GET_EVENT_BY_USER_INIT";

//Titulos
export const GET_EVENT_TITLE_BY_USER = "GET_EVENT_TITLE_BY_USER";
export const GET_EVENT_TITLE_BY_USER_FAIL = "GET_EVENT_TITLE_BY_USER_FAIL";
export const GET_EVENT_TITLE_BY_USER_INIT = "GET_EVENT_TITLE_BY_USER_INIT";

//recuperar clave
//recuperar contraseña 

export const SEND_EMAIL_PASSWORD = "SEND_EMAIL_PASSWORD";
export const SEND_EMAIL_PASSWORD_FAIL = "SEND_EMAIL_PASSWORD_FAIL";

//catalogos
export const GET_GESTION_CATALOGOS = "GET_GESTION_CATALOGOS";
export const GET_GESTION_CATALOGOS_FAIL = "GET_GESTION_CATALOGOS_FAIL";

export const UPDATE_GESTION_CATALOGOS = "UPDATE_GESTION_CATALOGOS";
export const UPDATE_GESTION_CATALOGOS_FAIL = "UPDATE_GESTION_CATALOGOS_FAIL";

export const DELETE_GESTION_CATALOGOS = "DELETE_GESTION_CATALOGOS";
export const DELETE_GESTION_CATALOGOS_FAIL = "DELETE_GESTION_CATALOGOS_FAIL";

export const GET_CATALOGOS_PADRE = "GET__CATALOGOS_PADRE";
export const GET_CATALOGOS_PADRE_FAIL = "GET__CATALOGOS_PADRE_FAIL";

//item catalogo

export const DELETE_ITEM_CATALOGO = "DELETE_ITEM_CATALOGO";
export const DELETE_ITEM_CATALOGO_FAIL = "DELETE_ITEM_CATALOGO_FAIL";

export const UPDATE_ITEM_CATALOGO = "UPDATE_ITEM_CATALOGO";
export const UPDATE_ITEM_CATALOGO_FAIL = "UPDATE_ITEM_CATALOGO_FAIL";

export const GET_ITEM_CATALOGOS = "GET_ITEM_CATALOGOS";
export const GET_ITEM_CATALOGOS_FAIL = "GET_ITEM_CATALOGOS_FAIL";


export const UPDATE_STATUS_BY_KEY_ADM = "UPDATE_STATUS_BY_KEY_ADM";

export const CATALOGOS_INI = "CATALOGOS_INI";

export const ITEMS_INI = "ITEMS_INI";

//empresa


export const GET_EMPRESA = "GET_EMPRESA";
export const GET_EMPRESA_FAIL = "GET_EMPRESA_FAIL";

export const EMPRESA_INI = "EMPRESA_INI";

export const DELETE_EMPRESA = "DELETE_EMPRESA";
export const DELETE_EMPRESA_FAIL = "DELETE_EMPRESA_FAIL";


export const UPDATE_EMPRESA = "UPDATE_EMPRESA";
export const UPDATE_EMPRESA_FAIL = "UPDATE_EMPRESA_FAIL";

export const GET_INVERSIONISTA = "GET_INVERSIONISTA";
export const GET_INVERSIONISTA_FAIL = "GET_INVERSIONISTA_FAIL";
export const INVERSIONISTA_INI = "INVERSIONISTA_INI";
export const DELETE_INVERSIONISTA = "DELETE_INVERSIONISTA";
export const DELETE_INVERSIONISTA_FAIL = "DELETE_INVERSIONISTA_FAIL";
export const UPDATE_INVERSIONISTA = "UPDATE_INVERSIONISTA";
export const UPDATE_INVERSIONISTA_FAIL = "UPDATE_INVERSIONISTA_FAIL";

export const GET_USUARIOS_INV = "GET_USUARIOS_INV";
export const GET_USUARIOS_INV_FAIL = "GET_USUARIOS_INV_FAIL";

export const GET_EMPRESA_INST_FAIL = "GET_EMPRESA_INST_FAIL";
export const GET_EMPRESA_INST = "GET_EMPRESA_INST";

//ORDENES

export const SELECT_EVENTO = "SELECT_EVENTO";
export const SELECT_EVENTO_FAIL = "SELECT_EVENTO_FAIL";

export const GET_ORDEN = "GET_ORDEN";
export const GET_ORDEN_FAIL = "GET_ORDEN_FAIL";
export const GET_ORDEN_USER_ID = "GET_ORDEN_USER_ID";
export const GET_ORDEN_USER_ID_FAIL = "GET_ORDEN_USER_ID_FAIL";
export const ORDEN_USER_ID_INI = "ORDEN_USER_ID_INI";

export const UPDATE_ORDEN = "UPDATE_ORDEN";
export const UPDATE_ORDEN_FAIL = "UPDATE_ORDEN_FAIL";
export const DELETE_ORDEN = "DELETE_ORDEN";
export const DELETE_ORDEN_FAIL = "DELETE_ORDEN_FAIL";
export const ORDEN_INI = "ORDEN_INI";
export const GET_EMPRESA_SELECT = "GET_EMPRESA_SELECT";
export const GET_EMPRESA_SELECT_FAIL = "GET_EMPRESA_SELECT_FAIL";

export const GET_INVERSIONISTA_SELECT = "GET_INVERSIONISTA_SELECT";
export const GET_INVERSIONISTA_SELECT_FAIL = "GET_INVERSIONISTA_SELECT_FAIL";
export const INVERSIONISTA_SELECT_INI = "GET_INVERSIONISTA_SELECT_INI";
//  EVENTO

export const GET_EVENTO = "GET_EVENTO";
export const GET_EVENTO_FAIL = "GET_EVENTO_FAIL";
export const UPDATE_EVENTO = "UPDATE_EVENTO";
export const UPDATE_EVENTO_FAIL = "UPDATE_EVENTO_FAIL";
export const DELETE_EVENTO = "DELETE_EVENTO";
export const DELETE_EVENTO_FAIL = "DELETE_EVENTO_FAIL";
export const EVENTO_INI = "EVENTO_INI";

//USUARIO EMPRESA

export const GET_USUARIO_EMPRESA = "GET_USUARIO_EMPRESA";
export const GET_USUARIO_EMPRESA_FAIL = "GET_USUARIO_EMPRESA_FAIL";
export const GET_USUARIO_EMPRESA_SIN_ASIG = "GET_USUARIO_EMPRESA_SIN_ASIG";
export const GET_USUARIO_EMPRESA_SIN_ASIG_FAIL = "GET_USUARIO_EMPRESA_SIN_ASIG_FAIL";
export const SELECT_USUARIO_EMPRESA = "SELECT_USUARIO_EMPRESA";
export const SELECT_USUARIO_EMPRESA_FAIL = "SELECT_USUARIO_EMPRESA_FAIL";
export const DELETE_USUARIO_EMPRESA = "DELETE_USUARIO_EMPRESA";
export const DELETE_USUARIO_EMPRESA_FAIL = "DELETE_USUARIO_EMPRESA_FAIL";
export const USUARIO_EMPRESA_INI = "USUARIO_EMPRESA_INI";

//USERS

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const GET_USERS = "GET_USERS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const USER_INI = "USER_INI";

//parametro
export const GET_PARAMETRO = "GET_PARAMETRO";
export const GET_PARAMETRO_FAIL = "GET_PARAMETRO_FAIL";
export const DELETE_PARAMETRO = "DELETE_PARAMETRO";
export const DELETE_PARAMETRO_FAIL = "DELETE_PARAMETRO_FAIL";
export const UPDATE_PARAMETRO = "UPDATE_PARAMETRO";
export const UPDATE_PARAMETRO_FAIL = "UPDATE_PARAMETRO_FAIL";
export const PARAMETRO_INI = "PARAMETRO_INI";

//eventos y empresas 

export const GET_EMPRESA_CON_EVENTO = "GET_EMPRESA_CON_EVENTO";
export const GET_EMPRESA_CON_EVENTO_FAIL = "GET_EMPRESA_CON_EVENTO_FAIL";
export const GET_TODO_EVENTO = "GET_TODO_EVENTO";
export const GET_TODO_EVENTO_FAIL = "GET_TODO_EVENTO_FAIL";
export const EMPRESA_CON_EVENTO_INI = "EMPRESA_CON_EVENTO_INI";


export const UPDATE_STATUS_BY_KEY = "UPDATE_STATUS_BY_KEY";
export const UPDATE_EVENTO_OBJECT = "UPDATE_EVENTO_OBJECT";

//EVENTO EMPRESA

export const EVENTO_EMPRESA_INI = "EVENTO_EMPRESA_INI";
export const GET_EVENTO_EMPRESA_FAIL = "GET_EVENTO_EMPRESA_FAIL";
export const GET_EVENTO_EMPRESA = "GET_EVENTO_EMPRESA";
export const GET_EVENTO_EMPRESA_SIN_ASIG_FAIL = "GET_EVENTO_EMPRESA_SIN_ASIG_FAIL";
export const GET_EVENTO_EMPRESA_SIN_ASIG = "GET_EVENTO_EMPRESA_SIN_ASIG";
export const SELECT_EVENTO_EMPRESA_FAIL = "SELECT_EVENTO_EMPRESA_FAIL";
export const SELECT_EVENTO_EMPRESA = "SELECT_EVENTO_EMPRESA";
export const DELETE_EVENTO_EMPRESA_FAIL = "DELETE_EVENTO_EMPRESA_FAIL";
export const DELETE_EVENTO_EMPRESA = "DELETE_EVENTO_EMPRESA";

//perfil usuario 


export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

//obtener datos del evento

export const ONLY_EVENT_DATA = "ONLY_EVENT_DATA";
export const ONLY_EVENT_DATA_FAIL = "ONLY_EVENT_DATA_FAIL";
export const ONLY_EVENT_DATA_INI = "ONLY_EVENT_DATA_INI";

export const DATA_EVENT_ID = "DATA_EVENT_ID";
export const EVENT_SELECT = "EVENT_SELECT";

//ADJUDICACION  

export const GET_ADJUDICACION = "GET_ADJUDICACION";
export const GET_ADJUDICACION_FAIL = "GET_ADJUDICACION_FAIL";
export const ADJUDICACION_INI = "ADJUDICACION_INI";
//select empresa 

export const EMPRESA_SELECT = "EMPRESA_SELECT";
export const EMPRESA_SELEC_INI = ""

//LOADING SALA INI SALA_INI
export const SALA_INI = "EMPRESA_SELEC_INI SALA_INI";

//obtener datos oslo de una empresa 

export const GET_DATA_EMPRESA = "GET_DATA_EMPRESA";
export const GET_DATA_EMPRESA_FAIL = "GET_DATA_EMPRESA_FAIL";
export const GET_DATA_EMPRESA_INI = "GET_DATA_EMPRESA_INI";

//datos header tiempo 

export const INSCRIPCION = "INSCRIPCION";
export const COLOCACION = "COLOCACION";
export const SUBASTA = "SUBASTA";
export const ADJUDICACION = "ADJUDICACION";


// agg JP
//inversionista

export const GET_EMPRESA_SELECT_INVERSIONISTA = "GET_EMPRESA_SELECT_INVERSIONISTA";
export const GET_EMPRESA_SELECT_INVERSIONISTA_FAIL = "GET_EMPRESA_SELECT_INVERSIONISTA_FAIL";

// Datos de adjudicaciones

export const GET_DATOS_ADJUDICACIONES = "GET_DATOS_ADJUDICACIONES";
export const GET_DATOS_ADJUDICACIONES_FAIL = "GET_DATOS_ADJUDICACIONES_FAIL";

// Impresion de certificados
export const UPDATE_IMPRESION_LIQUIDACIONES = "UPDATE_IMPRESION_LIQUIDACIONES";
export const UPDATE_IMPRESION_LIQUIDACIONES_FAIL = "UPDATE_IMPRESION_LIQUIDACIONES_FAIL";

// Impresion de titulos
export const UPDATE_IMPRESION_TITULOS = "UPDATE_IMPRESION_TITULOS";
export const UPDATE_IMPRESION_TITULOS_FAIL = "UPDATE_IMPRESION_TITULOS_FAIL";

// Impresion de reporte user rueda
export const UPDATE_IMPRESION_REPORT_RUEDA = "UPDATE_IMPRESION_REPORT_RUEDA";
export const UPDATE_IMPRESION_REPORT_RUEDA_FAIL = "UPDATE_IMPRESION_REPORT_RUEDA_FAIL";

// Invitacion email usuario
export const UPDATE_INVITACION_EMAIL = "UPDATE_INVITACION_EMAIL";
export const UPDATE_INVITACION_EMAIL_FAIL = "UPDATE_INVITACION_EMAIL_FAIL";

// Liquidacion email 
export const UPDATE_LIQUIDACION_EMAIL = "UPDATE_LIQUIDACION_EMAIL";
export const UPDATE_LIQUIDACION_EMAIL_FAIL = "UPDATE_LIQUIDACION_EMAIL_FAIL";

// Guardar Liquidacion 
export const UPDATE_LIQUIDACION = "UPDATE_LIQUIDACION";
export const UPDATE_LIQUIDACION_FAIL = "UPDATE_LIQUIDACION_FAIL";

export const SEND_VALUE = "SEND_VALUE";

// catalogos modalidad
export const GET_CATALOGOS_MODALIDAD = "GET_CATALOGOS_MODALIDAD";
export const GET_CATALOGOS_MODALIDAD_FAIL = "GET_CATALOGOS_MODALIDAD_FAIL";

// email para inversionista
export const UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL = "UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL";
export const UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL_FAIL = "UPDATE_LIQUIDACION_INVERSIONISTA_EMAIL_FAIL";

// cobrar liquidacion 
export const UPDATE_COBRAR_LIQUIDACION = "UPDATE_COBRAR_LIQUIDACION";
export const UPDATE_COBRAR_LIQUIDACION_FAIL = "UPDATE_COBRAR_LIQUIDACION_FAIL";

// comentario liquidacion 
export const UPDATE_COMENTARIO_LIQUIDACION = "UPDATE_COMENTARIO_LIQUIDACION";
export const UPDATE_COMENTARIO_LIQUIDACION_FAIL = "UPDATE_COMENTARIO_LIQUIDACION_FAIL";

// estado iconos liquidacion 
export const UPDATE_ESTADO_IMPRIMIR_CERTIFICADO = "UPDATE_ESTADO_IMPRIMIR_CERTIFICADO";
export const UPDATE_ESTADO_IMPRIMIR_CERTIFICADO_FAIL = "UPDATE_ESTADO_IMPRIMIR_CERTIFICADO_FAIL";

export const UPDATE_ESTADO_IMPRIMIR_LIQUIDACION = "UPDATE_ESTADO_IMPRIMIR_LIQUIDACION";
export const UPDATE_ESTADO_IMPRIMIR_LIQUIDACION_FAIL = "UPDATE_ESTADO_IMPRIMIR_LIQUIDACION_FAIL";

export const UPDATE_ESTADO_EMAIL_LIQUIDACION_EMPRESA = "UPDATE_ESTADO_EMAIL_LIQUIDACION_EMPRESA";
export const UPDATE_ESTADO_EMAIL_LIQUIDACION_EMPRESA_FAIL = "UPDATE_ESTADO_EMAIL_LIQUIDACION_EMPRESA_FAIL";

export const UPDATE_ESTADO_EMAIL_LIQUIDACION_INVERSIONISTA = "UPDATE_ESTADO_EMAIL_LIQUIDACION_INVERSIONISTA";
export const UPDATE_ESTADO_EMAIL_LIQUIDACION_INVERSIONISTA_FAIL = "UPDATE_ESTADO_EMAIL_LIQUIDACION_INVERSIONISTA_FAIL";

// Impresion de reportes excel
export const UPDATE_IMPRESION_REPORT_LIQUIDACION = "UPDATE_IMPRESION_REPORT_LIQUIDACION";
export const UPDATE_IMPRESION_REPORT_LIQUIDACION_FAIL = "UPDATE_IMPRESION_REPORT_LIQUIDACION_FAIL";

export const UPDATE_IMPRESION_REPORT_ADJUDICACION = "UPDATE_IMPRESION_REPORT_ADJUDICACION";
export const UPDATE_IMPRESION_REPORT_ADJUDICACION_FAIL = "UPDATE_IMPRESION_REPORT_ADJUDICACION_FAIL";





