import React, { useState, useEffect,useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {formatDateTime} from "./Util";
import {getEmpresaAct,deleteEmpresaAct} from "../../actions/administracion";

import "../../config";


const VerEmpresas = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'100%' }
    const {listaEmpresa,loadingEmpresa} = useSelector((state) => state.administracion);
    let [loadEmpresa, setLoadEmpresa] = useState(false) 

    if(loadEmpresa===false){
        setLoadEmpresa(true)
        dispatch(getEmpresaAct("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }


    let [empresaFilter, setEmpresaFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaEmpresa!==null?listaEmpresa:[]]);     

    if(listaEmpresa!==null && listaEmpresa!=gridInfor && empresaFilter==="")
        setGridInfor(listaEmpresa)

    function filterCatalogo(nego,empresaFilter){
        //value => 
        setGridInfor(nego.filter(function(item) {
            if (item["Nombre"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()) || 
                item["Institucion"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()) )
                return true;
            return false;
        }))
    }
    function closeEvento(){
        props.closeEventoClick()
    }
  
    function formatEstado(estado){
		switch(estado){
			case "A":
				return "ACTIVO"
		}
		return "INACTIVO"
	}
    let columns= [
      
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Nombre',header: 'Empresa', defaultFlex: 1, xdefaultLocked: 'end' },
        { name: 'Institucion',header: 'Institucion', defaultFlex: 1, xdefaultLocked: 'start',defaultWidth: 400, },        
        { name: 'NumAccionesOferta',header: 'Acciones', defaultFlex: 1, xdefaultLocked: 'end'},   
        { name: 'Precio',header: 'Fecha Apertura', defaultFlex: 1, xdefaultLocked: 'end' },  
        { name: 'ValorUnitarioAccion',header: 'Valor por acción', defaultFlex: 1, xdefaultLocked: 'end' },        
       
    ]
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar Empresa',
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                           dispatch(deleteEmpresaAct(rowProps.data["EmpresaId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])  
  return (
    <div>
    <h6 className="crypt-bg-head">EMPRESAS<div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
          setEmpresaFilter(e.target.value);
          filterCatalogo(listaEmpresa!==null?listaEmpresa:[],e.target.value)
      }}/> <i className="fas fa-search"></i></div></div></h6>
    <div className="tab-content">
      <DataGrid
        idProperty="EmpresaId"
        style={gridStyle}
        theme="default-light"
        columns={columns}
        enableSelection={true}
        selection={{ mode: "single" }}
        dataSource={gridInfor}
        renderRowContextMenu={renderRowContextMenu}
        theme="amber-dark"
      />
      <div className="no-orders text-center"></div>
    </div>
  </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(VerEmpresas);
