import React, { useState,useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid,{ Column }  from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import {Menu,MenuItem,MenuButton,SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {getParametroAct,deleteParametroAct} from "../../actions/administracion";
import EditarParametro from "./EditarParametro";
import "../../config";

const Parametro = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px',height:'100%'}
    let [parametroSeleccionado,setParametroSeleccionado] = useState(null);
    let [editParametroOpen, setEditParametroOpen] = useState("")
    let [loadParametro, setLoadParametro] = useState(false) 
    const {listaParametro,loadingParametro} = useSelector((state) => state.administracion);
    if(loadParametro===false){
        setLoadParametro(true)
        dispatch(getParametroAct("0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
    }
    let [parametroFilter, setparametroFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaParametro!==null?listaParametro:[]]);     
    if(listaParametro!==null && listaParametro!=gridInfor && parametroFilter==="")
        setGridInfor(listaParametro)

    function filterParametro(nego,parametroFilter){
        setGridInfor(nego.filter(function(item) {
            if (item["Nombre"].toLowerCase().includes(parametroFilter.toLocaleLowerCase()) ||   
                item["Codigo"].toLowerCase().includes(parametroFilter.toLocaleLowerCase()))
                return true;
            return false;
        }))
    }
    const formatEstado = estado =>{
		switch(estado){
			case "A":
				return "ACTIVO"
		}
		return "INACTIVO"
	}
    let columns= [
        { name: 'Nombre',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE</div>), defaultFlex: 1, xdefaultLocked: 'end' , headerAlign: "center"},
        { name: 'Codigo',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>CÓDIGO</div>), defaultFlex: 1, xdefaultLocked: 'end' , headerAlign: "center"},
        { name: 'Valor',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>VALOR</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center" },
        { name: 'Estado',header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ESTADO</div>), defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => formatEstado(data["Estado"]) , headerAlign: "center"},            
    ]
    const closeParametro =()=> props.closeParametroClick()
    const openNewParametro = () => {setParametroSeleccionado(null); setEditParametroOpen("EDIT");}
    const closeEditParametro =()=> setEditParametroOpen("")
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
        menuProps.autoDismiss = true
        menuProps.items = [
        {
            label: 'Editar',
            onClick:()=>{   
                setParametroSeleccionado(rowProps.data)
                setEditParametroOpen("EDIT")
                console.log(rowProps.data)
            }
        },          
        {
        label: 'Eliminar',
            onClick:()=>{
                confirmAlert({
                    title: 'Eliminar parámetro',
                    message: '¿Seguro desea eliminar?.',
                    buttons: [
                      {
                        label: 'Si',
                        onClick: () =>{                    
                            dispatch(deleteParametroAct(rowProps.data["ParametroId"]))
                            .then(() => {     
                        
                            })
                            .catch(() => {});
                        }
                      },
                      {
                        label: 'No',
                        onClick: () => {
                            
                        }
                      }
                    ]
                });               
            }
        }
        ]
    }, [])  
    return (
    <div className={["overlay3","fade-in"].join(" ")}> 
     <div className={['loading_cont',loadingParametro
     !==true?"displaynone":""].join(' ')}><div className='single6'></div></div>
      {editParametroOpen==="EDIT" ? <EditarParametro closeEditParametro={closeEditParametro} parametroSeleccionado={parametroSeleccionado}/>  :  ""}
        <div className="borderContent ventanaPortafolio">
            <div className="innerShadow-height-100">
                <h2>
                    Parámetros
                    <div className="subItemCatalogo">
                        <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                            <MenuItem onClick={openNewParametro}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem> 
                        </Menu>
                    </div>
                </h2>  
                <div className="titleTable-search">BUSCAR<div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={parametroFilter} onChange={(e) => {
                  setparametroFilter(e.target.value);
                  filterParametro(listaParametro!==null?listaParametro:[],e.target.value)
                }}/><i className="fas fa-search"></i></div></div></div> 
                <DataGrid
                    idProperty="ParametroId"
                    style={gridStyle}                   
                    columns={columns}
                    enableSelection={true}
                    selection={{ mode: 'single' }}                    
                    dataSource={gridInfor}
                    renderRowContextMenu={renderRowContextMenu}
                    theme="amber-dark"
                />
                <div className="dividerLine"></div>
                <div className="textCenter">
                    <div className="btnCancelar"  onClick={closeParametro}>
                        SALIR
                    </div>
                </div> 
            </div>
        </div>  
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Parametro);
