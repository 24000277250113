import React, { Component} from "react";
import Loading from "./ProgressBar";

class LoadingContainer extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            completed:0
        }
        if(this.props.autoplay){
            this.restartLoading()
        }
    }
    restartLoading(){
        this.setState({completed:0})
        setInterval(() => {
            if(this.state.completed<100){
                this.setState({completed:this.state.completed + 1})
            }      
          }, 1000);
    }

    render() {         
        return (
            <div className='salaloading'> 
                <Loading bgcolor={"#28ba00"} completed={this.state.completed} autoplay={this.props.autoplay}/>
            </div>
        )
    }
}

export default LoadingContainer;