import React, { Component } from "react";
import NumberFormat from 'react-number-format'
import '../../config'

class ItemRentaVariable extends Component {    
    constructor(props) {
        super(props);
    }
    getStyleByIndex(index){
        console.log("------------" - index)
        switch(index){
            case 0:
                return "one"
            case 1:
                return "two"
            case 2:
                return "three"
            case 3:
                return "four"
            case 4:
                return "five"
            case 5:
                return "six"
            case 6:
                return "seven"
            case 7:
                return "eight"
        }
    }
    render() {
        return (
            <div title={this.props.item["Emisor"] + " - $ " + this.props.item["Precio"]}>    
                <div className={["bar",this.getStyleByIndex(this.props.index)].join(' ')}><div className="percent">{this.props.item["Precio"]}</div><div className={"logo" + (this.props.index + 1)}><img src={global.config.URL_IMAGE_EMISOR + this.props.item["EmisorCodigo"]  + ".png"} alt="Logo Marca 1" /></div><div className={"bottomPercent" + (this.props.index + 1)}>{this.props.item["ValorNominal"]}</div></div>
            </div>
        )
    }
}
export default ItemRentaVariable;