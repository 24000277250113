import React, {useState} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import {updateGestionCatalogo,getCatalogosPadre} from "../../actions/administracion";
import "../../config";
const EditarCatalogo = (props) => {
    const dispatch = useDispatch();
    //let [errors,] = useState({"usuarioIdErr":""},{"nombreErr":""},{"apellidoErr":""},{"cedulaErr":""},{"telefonoErr":""});
    let [errors,setErrors] = useState({"codigoErr":""},{"nombreErr":""},{"estadoErr":""});
    let [codigo,setCodigo] = useState(props.catalogoSeleccionado!==null?props.catalogoSeleccionado["Codigo"]:"")
    let [nombre,setNombre] = useState(props.catalogoSeleccionado!==null?props.catalogoSeleccionado["Nombre"]:"")
    //checkbox activo
    let [isError,setIsError] = useState(false)
    let [activo, setActivo] = useState(null)
    let [editar, setEditar] = useState(null)
    if(props.catalogoSeleccionado!==null && activo==null){
        if(props.catalogoSeleccionado["Activo"]===true){
            setActivo(1)
        }else{
            setActivo(0)
        }
    }
    if(props.catalogoSeleccionado!==null && editar==null){
        if(props.catalogoSeleccionado["Editar"]===true){
            setEditar(1)
        }else{
            setEditar(0)
        }
    }
    //checkbox 
    const handleCheckedActivo = ({ target }) => {
        target.checked===true?setActivo(1):setActivo(0)
    }
    const handleCheckedEditar = ({ target }) => {
        target.checked===true?setEditar(1):setEditar(0)
    }
    function closeEditCat (){
        props.closeCatalogo();
    }
    let cambioEstado=useState(null)
    if(props.catalogoSeleccionado!==null && props.catalogoSeleccionado["Estado"] == "I"){
        cambioEstado ={value:'I', label:"Inactivo"}
    }
    if(props.catalogoSeleccionado!==null && props.catalogoSeleccionado["Estado"] == "A"){
        cambioEstado ={value:'A', label:"Activo"}
    }
    if(props.catalogoSeleccionado===null){
        cambioEstado ={value:'A', label:"Activo"}
    }
    let [estadoSelect, setEstadoSelect] =  useState(cambioEstado);
    const optionsEstado = [
        { value: 'I', label: 'Inactivo' },
        { value: 'A', label: 'Activo' },
    ] 

    //
    const [isValid, setIsValid] = useState(true);
    const textoRegex = /^[a-zA-Z0-9À-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;
    const nombreRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;

   // function validateNombre(texto) {
     //   setIsValid(textoRegex.test(texto));
    //}
    //function validateCodigo(texto) {
      //  setIsValid(textoRegex.test(texto));
    //}
    

    function actualizar(){

        let valid=true
       
        if(codigo == ""){
            errors["codigoErr"] = "Ingrese código"
            valid = false
        }
        if(nombre == ""){
            errors["nombreErr"] = "Ingrese nombre"
            valid = false
        }
        if(!textoRegex.test(nombre)){
            errors["nombreErr"] = "Ingrese solo letras y números"
            valid = false
        }
        if(!textoRegex.test(codigo)){
            errors["codigoErr"] = "Ingrese solo letras  y números"
            valid = false
        }

        setErrors(errors)
        setIsError(true)
        if(!valid){
            return
        }
        
        dispatch(updateGestionCatalogo(
            props.catalogoSeleccionado!==null?props.catalogoSeleccionado["CatalogoId"]:"-1",
            codigo,
            nombre,
            activo,
            editar,
            0,
            estadoSelect["value"],
            "0","","","ASC",""            
            )).then(() => {     
    
             }).catch(() => {}
        );
      
        closeEditCat()
    }

    

    return (
<div className='overlay3 fade-in'>
    <div className="borderContent ventanaPerfil">
        <div className="innerShadow3">
            <div id="formulario-estilo">
                <h2>
                    Catálogos               
                </h2>  
                <div className='vertical-scroll customScroll'>               
                    <div className="columna-1-formulario-ventana-perfil-edit-evento-user"> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Código:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                    type="text"
                                    value={codigo}
                                    autocomplete="off"
                                    maxLength={1000}
                                    placeholder="Ingresar código"
                                    className="input-form"
                                    onChange={(e) => {
                                        setCodigo(e.target.value.toUpperCase());
                                        errors["codigoErr"] = "";
                                    }}
                                ></input>
                                {errors["codigoErr"]!==""?<div className='camporequerido'>{errors["codigoErr"]}</div>:""}
                                {/*{!isValid && <div className='camporequerido'>Ingrese solo texto</div>}*/}
                            </div> 
                        </div> 
                        <div className="row-form"> 
                            <div className="col-30">             
                                <label>
                                    <i className="fas fa-edit"></i> <span>Nombre:</span>
                                </label>
                            </div>  
                            <div className="col-70">
                                <input
                                    autocomplete="off"
                                    type="text"
                                    value={nombre}
                                    maxLength={1000}
                                    placeholder="Ingresar nombre"
                                    className="input-form"
                                    onChange={(e) => {
                                        setNombre(e.target.value);
                                        errors["nombreErr"] = "";
                                        
                                    }}
                                    onBlur = {(e) => {
                                        
                                    }}
                                ></input>
                                {errors["nombreErr"]!==""?<div className='camporequerido'>{errors["nombreErr"]}</div>:""}
                                

                            </div> 
                        </div> 
                        <div className="row-form">
                            <div className="col-30">
                                <label>
                                    <i className="fas fa-wrench"></i> <span>Estado:</span>                
                                </label>
                            </div>
                            <div className="col-70">
                                <Select  
                                autocomplete="off"
                                className="input-form" 
                                id="estadoSelectCombo"  
                                value={estadoSelect} 
                                onChange={(e) => {
                                    setEstadoSelect(e)
                                }} placeholder="Seleccione" options={optionsEstado}/>
                            </div>
                        </div>       
                        <div className="row-form">  
                            <div className="col-30">
                            <label>
                                <i className="fas fa-check-square"></i> <span>Activo:</span>
                            </label>   
                            </div>                         
                            <div className="col-70">
                            <Checkbox 
                                right="true" 
                                defaultChecked={activo} 
                                text="Juega renta fija"
                                onChange={handleCheckedActivo}
                            />    
                            </div>                    
                        </div>
                        <div className="row-form">  
                            <div className="col-30">
                            <label>
                                <i className="fas fa-check-square"></i> <span>Editable:</span>
                            </label>   
                            </div>                         
                            <div className="col-70">
                            <Checkbox 
                                right="true" 
                                defaultChecked={editar} 
                                text="editar"
                                onChange={handleCheckedEditar}
                            />    
                            </div>                    
                        </div>                    
                    </div> 
                    
                    <div className="textCenter clear-both">
                        <div className="btnGuardar" onClick={actualizar} >
                        GUARDAR
                        </div>{" "}
                        <div className="btnCancelar" onClick={closeEditCat}>
                            SALIR
                        </div>
                    </div>
                </div> 
            </div>      
        </div>
    </div>
</div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarCatalogo);
