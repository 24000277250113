import axios from "axios";
import '../config'

const getEventosPorUsuario = (userId) => {
    const params = {"strUserId":userId}
    return axios
      .post(global.config.URL_SERVICE + "ObtenerEventosPorUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        if(result.length>0){
          return result;  
        }else{
          return null
        }
      });
  };

const getTitulosEventoById = (eventId) => {
  const params = {"strEventId":eventId}
  return axios
      .post(global.config.URL_SERVICE + "ObtenerTitulosPorEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        if(result.length>0){
            localStorage.setItem("eventos", JSON.stringify(result));
            localStorage.setItem("titulosRVCount", result.filter(value => value["TipoRentaCod"] === 'RV').length);
            
            return result;  
        }else{
          return null
        }
      });
}

export default {
    getEventosPorUsuario,
    getTitulosEventoById
};
  