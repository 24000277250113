import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { getUsuarioEmpresaSinAsigAct, selectUsuarioEmpresaAct } from "../../actions/administracion";
import DataGrid, { Column, Selection } from '@inovua/reactdatagrid-community';

import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import '@szhsin/react-menu/dist/index.css';
import "../../config";
//updateGestionEventoUsuario = (eventoUsuarioId,usuarioId,eventoId,usuarioIdRegistro,estado,monto,saldo)
const SeleccionarUsuarioEmpresa = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const { listaUsuarioEmpresaSinAsig } = useSelector((state) => state.administracion);
    let [loadListaUsuariosEmpresaSinAsig, setLoadListaUsuariosEmpresaSinAsig] = useState(true);
    let [empresaFilter, setEmpresaFilter] = useState("");
    let [gridInfor, setGridInfor] = useState([listaUsuarioEmpresaSinAsig !== null ? listaUsuarioEmpresaSinAsig : []]);

    if (listaUsuarioEmpresaSinAsig !== null && listaUsuarioEmpresaSinAsig != gridInfor && empresaFilter === "")
        setGridInfor(listaUsuarioEmpresaSinAsig)


    const closeSeleccionarUsuarioEmpresa = () => {
        props.closeSelectUsuarioEmpresa()
    }
    const checkBoxesMode = useState("onClick");
    const allMode = useState("allPages");
    const dispatch = useDispatch();
    if (loadListaUsuariosEmpresaSinAsig === true) {
        setLoadListaUsuariosEmpresaSinAsig(false)
        dispatch(getUsuarioEmpresaSinAsigAct(props.empresaSeleccionadaId))
            .then(() => {
            })
            .catch(() => {
            });
    }
    console.log("empresaId =" + props.empresaSeleccionadaId)
    console.log("usuarios sin asignar =")
    console.log(listaUsuarioEmpresaSinAsig)

    const gridStyle = { minHeight: '300px', height: '100%', margin: 20 }
    const toArray = selected => Object.keys(selected).map(id => id * 1);
    let columns = [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'value', header: 'Id', defaultVisible: false, defaultWidth: 60, type: 'number' },
        { name: 'label', header: 'Usuarios', defaultFlex: 1, xdefaultLocked: 'start', defaultWidth: 400, },



    ]
    function filterCatalogo(nego, empresaFilter) {
        //value => 
        setGridInfor(nego.filter(function (item) {
            if (
                item["label"].toLowerCase().includes(empresaFilter.toLocaleLowerCase())
            )
                return true;
            return false;
        }))
    }



    const [selected, setSelected] = useState({ 0: true });

    const onSelectionChange = useCallback(({ selected }) => {
        setSelected(selected)
    }, [])

    function actualizar() {

        dispatch(selectUsuarioEmpresaAct(
            props.empresaSeleccionadaId,
            toArray(selected).toString(),
        )).then(() => {

        }).catch(() => { }
        )
        console.log("datos insertados:")
        console.log(props.eventoSeleccionadoId)
        console.log(toArray(selected).toString())

        closeSeleccionarUsuarioEmpresa()
    }
    function onCheckBoxesModeChanged({ value }) {
        this.setState({ checkBoxesMode: value });
    }

    function onAllModeChanged({ value }) {
        this.setState({ allMode: value });
    }



    return (
        <div className="overlay3 fade-in">
            <div className="borderContent ventanaPerfil">
                <div className="innerShadow-height-100">
                    <div id="formulario-estilo">
                        <h2>
                            Seleccionar usuarios
                        </h2>
                        <div className='vertical-noscroll customScroll'>
                            <div className="columna-1-formulario-ventana-perfil-edit-evento-user">
                                <div className="titleTable-search-user">
                                    <div className='floatright'>
                                        <div className="filterNeg">
                                            <input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
                                                setEmpresaFilter(e.target.value);
                                                filterCatalogo(listaUsuarioEmpresaSinAsig !== null ? listaUsuarioEmpresaSinAsig : [], e.target.value)
                                            }} />
                                            <i className="fas fa-search"></i>
                                        </div>
                                    </div>
                                </div>
                                <DataGrid
                                    idProperty="value"
                                    selected={selected}
                                    checkboxColumn
                                    onSelectionChange={onSelectionChange}
                                    style={gridStyle}
                                    columns={columns}
                                    dataSource={gridInfor}//dataSource={listaUsuarioEmpresaSinAsig !== null ? listaUsuarioEmpresaSinAsig : []}
                                    theme="amber-dark"
                                />

                            </div>
                            <div className="textCenter clear-both">

                                <div className="btnGuardar" onClick={actualizar} >
                                    GUARDAR
                                </div>{" "}
                                <div className="btnCancelar" onClick={closeSeleccionarUsuarioEmpresa}>
                                    SALIR
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(SeleccionarUsuarioEmpresa);
