import React, { Component } from "react";
import NumberFormat from 'react-number-format'
import '../../config'

class ItemRentaFija extends Component {    
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="valorRentaFija">        
                <div className="imgLogo gris"><img src={global.config.URL_IMAGE_EMISOR + this.props.item["EmisorCodigo"]  + ".png"} alt="Logo Renta Fija 1" /></div>
                <div className="bold">{this.props.item["Emisor"]}</div>
                <div className="bold">{this.props.item["TipoValorNombre"]}</div>
                <div className="blueStyle">
                    <NumberFormat value={this.props.item["ValorNominal"]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} thousandSeparator={','} decimalSeparator={'.'} prefix={"$ "}/>
                </div>
                <div className="blueStyle">
                    <NumberFormat value={this.props.item["Rendimiento"]} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} thousandSeparator={','} decimalSeparator={'.'} suffix={" %"}/>
                </div>
                <div className="blueStyle">
                    <NumberFormat value={this.props.item["PlazoDias"]} displayType={'text'} thousandSeparator={true} decimalScale={0} fixedDecimalScale={false} thousandSeparator={','} decimalSeparator={'.'} suffix={" días"}/>
                </div>
            </div>
        )
    }
}
export default ItemRentaFija;