import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { NotificationManager, NotificationContainer } from "react-notifications";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid, { Column } from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { formatDateTime } from "./Util";
import { deleteUsuarioEmpresaAct, getUsuarioEmpresaAct, sendEmailInvitacionUser } from "../../actions/administracion";
import SeleccionarUsuarioEmpresa from "./SeleccionarUsuarioEmpresa";
//import ItemEmpresa from "./ItemEmpresa";
import "../../config";



const UsuarioEmpresa = (props) => {
    const dispatch = useDispatch();
    let [eventoSeleccionadoId] = useState(props.eventoSeleccionado);

    //console.log("empres", props.empresaSeleccionada)
    const gridStyle = { minHeight: '300px', height: '100%' }
    const { listaUsuarioEmpresa, loadingUsuarioEmpresa } = useSelector((state) => state.administracion);
    let [empresaSeleccionadaId] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["EmpresaId"] : "-1");
    let [nombreEmpresa] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Nombre"] : "");
    // console.log(nombreEmpresa)
    let [selectUsuarioEmpresaOpen, setSelectUsuarioEmpresaOpen] = useState("")
    let [loadUsuarioEmpresa, setloadUsuarioEmpresa] = useState(false)

    if (loadUsuarioEmpresa === false) {
        setloadUsuarioEmpresa(true)
        dispatch(getUsuarioEmpresaAct(empresaSeleccionadaId))
            .then(() => {
            })
            .catch(() => {
            });

    }
    // console.log("empresaid =" + empresaSeleccionadaId)
    //console.log(listaUsuarioEmpresa)
    let [empresaFilter, setEmpresaFilter] = useState("");
    let [gridInfor, setGridInfor] = useState([listaUsuarioEmpresa !== null ? listaUsuarioEmpresa : []]);

    if (listaUsuarioEmpresa !== null && listaUsuarioEmpresa != gridInfor && empresaFilter === "")
        setGridInfor(listaUsuarioEmpresa)

    function filterCatalogo(nego, empresaFilter) {
        //value => 
        setGridInfor(nego.filter(function (item) {
            if (item["Nombre"].toLowerCase().includes(empresaFilter.toLocaleLowerCase())
            )
                return true;
            return false;
        }))
    }
    function closeUsuarioEmpresa() {
        props.closeUsuarioEmpresaClick()
    }
    function openSelectUsuarioEmpresa() {
        setSelectUsuarioEmpresaOpen("OPEN")
    }
    function closeSelectUsuarioEmpresa() {
        setSelectUsuarioEmpresaOpen("")
    }
    function formatEstado(estado) {
        switch (estado) {
            case "A":
                return "ACTIVO"
        }
        return "INACTIVO"
    }


    const renderButtonInvitacion = (value, rowData) => {
        return (
            <>
                <button id={value.data !== null ?
                    value.data["UsuarioId"] : "0"}
                    className="fas fa-print"
                    onClick={(e) => {
                        handleClickInvitacionIndividual(e)
                        //setIsLoading(true)
                    }}></button>
            </>
        )
    }


    const handleClickInvitacionIndividual = (rowData) => {
        let usuarioId = parseInt(rowData.target.id)
        let data = listaUsuarioEmpresa.filter(x => x.UsuarioId === usuarioId)[0]//rowData.cellProps["data"]
        if (data !== null) {
            console.log(usuarioId)
            dispatch(sendEmailInvitacionUser(eventoSeleccionadoId, usuarioId))
                .then(() => {
                    NotificationManager.success(
                        "Invitación individual enviada",
                        "Sistema",
                        3000,
                        () => { },
                        4
                    );

                }
                )
                .catch(() => {
                    NotificationManager.error(
                        "Error al enviar invitación",
                        "Sistema",
                        3000,
                        () => { },
                        4
                    );
                });
        }

    }
    const handleClickInvitacionGrupal = (rowData) => {

        dispatch(sendEmailInvitacionUser(eventoSeleccionadoId, -1, empresaSeleccionadaId))
            .then(() => {
                NotificationManager.success(
                    "Invitación grupal enviada",
                    "Sistema",
                    3000,
                    () => { },
                    4
                );
            })
            .catch(() => {
                NotificationManager.error(
                    "Error al enviar invitación",
                    "Sistema",
                    3000,
                    () => { },
                    4
                );
            });
    }

    function getRol(str) {
        switch (str) {
            case "ROLE_ADMIN":
                return "ADMINISTRADOR"
            case "ROLE_RUEDA":
                return "DIRECTOR DE RUEDA"
            case "ROLE_CAJA":
                return "USUARIO CAJA"
            case "ROLE_VISITANTE":
                return "VISITANTE"
            case "ROLE_OPERADOR":
                return "USUARIO OPERADOR"
            case "ROLE_LIQUIDACION":
                return "USUARIO LIQUIDACIÓN"
            case "ROLE_REGISTRADOR":
                return "USUARIO REGISTRADOR"

        }

        return ""
    }

    let columns = [

        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Nombre', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center" },
        { name: 'Rol', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ROL</div>), defaultFlex: 1, xdefaultLocked: 'end',render: ({ data }) => getRol(data["Rol"]), headerAlign: "center" },
        { name: 'Estado', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ESTADO</div>), defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => formatEstado(data["Estado"]), headerAlign: "center" },
        { name: 'FechaRegistro', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>FECHA REGISTRO</div>), defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => formatDateTime(data["FechaRegistro"]), headerAlign: "center" },
        /*{ name: "EnviarInvitación", header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ENVIAR INVITACIÓN</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonInvitacion(rowData), headerAlign: "center" },*/

    ]
    const renderRowContextMenu = useCallback((menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [

            {
                label: 'Eliminar',
                onClick: () => {
                    confirmAlert({
                        title: 'Eliminar usuario',
                        message: '¿Seguro desea eliminar?.',
                        buttons: [
                            {
                                label: 'Si',
                                onClick: () => {
                                    dispatch(deleteUsuarioEmpresaAct(rowProps.data["UsuarioEmpresaId"], empresaSeleccionadaId))
                                        .then(() => {

                                        })
                                        .catch(() => { });
                                }
                            },
                            {
                                label: 'No',
                                onClick: () => {

                                }
                            }
                        ]
                    });
                }
            }
        ]
    }, [])
    return (
        <div className={["overlay3", "fade-in"].join(" ")}>
            <div className={['loading_cont', loadingUsuarioEmpresa !== true ? "displaynone" : ""].join(' ')}><div className='single6'></div></div>
            {selectUsuarioEmpresaOpen === "OPEN" ? <SeleccionarUsuarioEmpresa closeSelectUsuarioEmpresa={closeSelectUsuarioEmpresa} empresaSeleccionadaId={empresaSeleccionadaId} /> : ""}
            <div className="borderContent ventanaPortafolio">
                <div className="innerShadow-height-100">
                    <h2>
                        Usuario
                        <div className="subItemCatalogo">
                            <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                                <MenuItem onClick={openSelectUsuarioEmpresa}><i className="fas fa-sign-out-alt"></i>Seleccionar</MenuItem>
                                {/*<MenuItem onClick={handleClickInvitacionGrupal}><i className="fas fa-sign-out-alt"></i>Invitar</MenuItem>*/}
                            </Menu>
                        </div>
                    </h2>
                    <div className="titleTable-search">EMPRESA {nombreEmpresa.toUpperCase()}
                        <div className='floatright'>
                            <div className="filterNeg">

                                <input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
                                    setEmpresaFilter(e.target.value);
                                    filterCatalogo(listaUsuarioEmpresa !== null ? listaUsuarioEmpresa : [], e.target.value)
                                }} /><i className="fas fa-search"></i>
                            </div>
                        </div>
                    </div>
                    <DataGrid
                        idProperty="EmpresaId"
                        style={gridStyle}

                        columns={columns}
                        enableSelection={true}
                        selection={{ mode: 'single' }}
                        dataSource={gridInfor}
                        renderRowContextMenu={renderRowContextMenu}
                        theme="amber-dark"
                    />
                    <div className="dividerLine"></div>
                    <div className="textCenter">
                        <div className="btnCancelar" onClick={props.closeUsuarioEmpresa}>
                            SALIR
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(UsuarioEmpresa);
