import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import NumberFormat from 'react-number-format';
import DatePicker from "react-datepicker";
import {
    updateEmpresaAct,
    getInstitucionesEmpresaAct,
    getUsuariosAct,
} from "../../actions/administracion";
import "../../config";
import UploadFile from "./UploadFile";
import UploadFileNew from "./UploadFileNew";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { generateMessageAdm } from "./Util";
const EditarEmpresa = (props) => {
    console.log(props.empresaSeleccionada)
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    const [image, setImage] = useState("");
    let [errors, setErrors] = useState(
        { "nombreErr": "" },
        { "institucionErr": "" },
        { usuarioErr: "" },
        { emailErr: "" },
        { gerenteErr: "" },
        { logoErr: "" },
        { operadorErr: "" },
        { direccionErr: "" },
        { receptorErr: "" },
        { numAccionesOferta: "" },
        { comisionOperadorErr: "" },
        { comisionBvqErr: "" },
        { porcentajeTechoErr: "" },
        { precioInicialPorAccionErr: "" },
        { valorUnitarioAccionErr: "" },
        { valorUnitarioAccionErr: "" },
        { montoMinMejoraPrecioErr: "" },
        { capitalPagadoErr: "" },
        { valorErr: "" },
        { divididoErr: "" }
        );

    let [empresaId, setEmpresaId] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["EmpresaId"] : "-1");
    let [nombre, setNombre] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Nombre"] : "");
    let [logo, setLogo] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Logo"] : "");

    // console.log("logo traido y nuevo", logo)
    let [direccion, setDireccion] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Direccion"] : "");
    let [institucion, setInstitucion] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Institucion"] : "");
    let [email, setEmail] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Email"] : "");
    let [gerente, setGerente] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Gerente"] : "");
    let [operador, setOperador] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Operador"] : "");
    let [receptor, setReceptor] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Receptor"] : "");
    let [numAccionesOferta, setNumAccionesOferta] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["NumAccionesOferta"] : "");
    let [precioInicialPorAccion, setPrecioInicialPorAccion] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["PrecioInicialPorAccion"] : "");
    let [valorUnitarioAccion, setValorUnitarioAccion] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["ValorUnitarioAccion"] : "");
    let [montoMinMejoraPrecio, setMontoMinMejoraPrecio] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["MontoMinMejoraPrecio"] : "");
    let [comisionOperador, setComisionOperador] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["ComisionOperador"] : "");
    let [comisionBVQ, setComisionBVQ] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["ComisionBVQ"] : "");
    let [porcentajePrecioTechoMax, setPorcentajePrecioTechoMax] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["PorcentajePrecioTechoMax"] : "");
    let [fechaConstitucion, setFechaConstitucion] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["FechaConstitucion"] : new Date());
    let [fechaInscripcion, setFechaInscripcion] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["FechaInscripcion"] : new Date());
    let [isError, setIsError] = useState(false)

    let [capitalPagado, setCapitalPagado] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["CapitalPagado"] : "");
    let [divididoEn, setDivididoEn] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["DivididoEn"] : "");
    let [valor, setValor] = useState(props.empresaSeleccionada !== null ? props.empresaSeleccionada["Valor"] : "");

    const { listaInstitucionEmpresa } = useSelector(
        (state) => state.administracion
    );
    let [loadInstitucion, setLoadInstitucion] = useState(false);
    let [loadUsuarios, setLoadUsuarios] = useState(false);
    if (listaInstitucionEmpresa === null && loadInstitucion === false) {
        setLoadInstitucion(true);
        dispatch(getInstitucionesEmpresaAct())
            .then(() => { })
            .catch(() => { });
    }

    let cambioEstado = useState(null);
    if (
        props.empresaSeleccionada !== null &&
        props.empresaSeleccionada["Estado"] == "I"
    ) {
        cambioEstado = { value: "I", label: "Inactivo" };
    }
    if (
        props.empresaSeleccionada !== null &&
        props.empresaSeleccionada["Estado"] == "A"
    ) {
        cambioEstado = { value: "A", label: "Activo" };
    }
    if (props.empresaSeleccionada === null) {
        cambioEstado = { value: "A", label: "Activo" };
    }
    let [estadoSelect, setEstadoSelect] = useState(cambioEstado);
    const optionsEstado = [
        { value: "I", label: "Inactivo" },
        { value: "A", label: "Activo" },
    ];
    function closeEditEmpresa() {
        props.closeEditEmpresa();
    }

    // Agg
    const textoRegex = /^[a-zA-Z0-9À-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;
    const nombreRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;

    function actualizar() {
        let valid = true;

        //let err = errors;
        if (nombre == "") {
            errors["nombreErr"] = "Ingrese nombre";
            valid = false;
        }
        if (!textoRegex.test(nombre)) {
            errors["nombreErr"] = "Ingrese solo letras"
            valid = false
        }

        if (institucion == "") {
            errors["institucionErr"] = "Ingrese institución";
            valid = false;
        }
        if (!nombreRegex.test(institucion)) {
            errors["institucionErr"] = "Ingrese solo letras y números"
            valid = false
        }
        //if (logo == "") {
        //  errors["logoErr"] = "Ingrese logo";
        //valid = false;
        //}
        //if (direccion == "") {
          //  errors["direccionErr"] = "Ingrese dirección";
            //valid = false;
       // }

        if (email == "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            errors["emailErr"] = "Ingrese mail (example@example.com)";
            valid = false;
        }
        if (gerente == "") {
            errors["gerenteErr"] = "Ingrese gerente";
            valid = false;
        }
        if (!nombreRegex.test(gerente)) {
            errors["gerenteErr"] = "Ingrese solo letras";
            valid = false;
        }
        if (operador == "") {
            errors["operadorErr"] = "Ingrese operador";
            valid = false;
        }

        if (!nombreRegex.test(operador)) {
            errors["operadorErr"] = "Ingrese solo letras";
            valid = false;
        }
        if (receptor == "") {
            errors["receptorErr"] = "Ingrese receptor";
            valid = false;
        }
        if (!nombreRegex.test(receptor)) {
            errors["receptorErr"] = "Ingrese solo letras";
            valid = false;
        }
        if (numAccionesOferta == "") {
            errors["numAccionesOfertaErr"] = "Ingrese número de acciones";
            valid = false;
        }

        setErrors(errors)
        setIsError(true)
        if (!valid) {
            return;
        }
        if (fechaConstitucion == null) {
            NotificationManager.error(
                "Ingrese fecha de constitución",
                "Sistema",
                5000,
                () => { },
                4
              );
            return
        }
        if (fechaInscripcion == null) {
            NotificationManager.error(
                "Ingrese fecha de inscripción",
                "Sistema",
                5000,
                () => { },
                4
              );
            return
        }

        if (precioInicialPorAccion <= 0) {
            NotificationManager.error(
                "Ingrese precio inicial por acción válido",
                "Sistema",
                5000,
                () => { },
                4
              );
            return
        }
        if (precioInicialPorAccion=="") {
            NotificationManager.error(
                "Ingrese precio inicial por acción",
                "Sistema",
                5000,
                () => { },
                4
              );
            return
        }
        
        if (valorUnitarioAccion == "" || valorUnitarioAccion == 0.00) {
            NotificationManager.error(
                "Ingrese valor de cada acción",
                "Sistema",
                5000,
                () => { },
                4
              );
              return
        }

        
        if (montoMinMejoraPrecio == "" || montoMinMejoraPrecio == 0.00) {
            NotificationManager.error(
                "Ingrese monto min. para mejorar precio",
                "Sistema",
                5000,
                () => { },
                4
              );
              return
        }
        
        /*if ( comisionOperador =="" ) {
            errors["comisionOperadorErr"] = "Ingrese comisión operador";
            valid = false;
        }*/
       /* if (comisionOperador == "") {
            NotificationManager.error(
                "Ingrese comisión operador",
                "Sistema",
                5000,
                () => { },
                4
              );
              return

        }*/
        /*if (comisionOperador < 0) {
            NotificationManager.error(
                "Ingrese comisión operador",
                "Sistema",
                5000,
                () => { },
                4
              );
              return

        }*/
        /*if (comisionBVQ ==""  ) {
            errors["comisionBvqErr"] = "Ingrese comisión BVQ";
            valid = false;
        }*/
        /*if (comisionBVQ == "") {
            NotificationManager.error(
                "Ingrese comisión BVQ",
                "Sistema",
                5000,
                () => { },
                4
              );
              return

        }*/
        if (porcentajePrecioTechoMax =="" || porcentajePrecioTechoMax == 0) {
            NotificationManager.error(
                "Ingrese porcentaje techo",
                "Sistema",
                5000,
                () => { },
                4
              );
              return
        }
        

        if (capitalPagado == "") {
            NotificationManager.error(
                "Ingrese capital pagado",
                "Sistema",
                5000,
                () => { },
                4
              );
              return
        }
        if (divididoEn == "") {
            NotificationManager.error(
                "Ingrese la cantidad dividida de las acciones",
                "Sistema",
                5000,
                () => { },
                4
              );
              return
        }
        if (valor == "") {
            NotificationManager.error(
                "Ingrese el valor del capital",
                "Sistema",
                5000,
                () => { },
                4
              );
              return
        }

        

        dispatch(
            updateEmpresaAct(
                empresaId,
                nombre,
                logo,
                direccion,
                estadoSelect["value"],
                currentUser["userId"],
                institucion,
                email,
                gerente,
                operador,
                receptor,
                numAccionesOferta,
                precioInicialPorAccion,
                valorUnitarioAccion,
                montoMinMejoraPrecio,
                comisionOperador,
                comisionBVQ,
                porcentajePrecioTechoMax,
                fechaConstitucion,
                fechaInscripcion,
                capitalPagado,
                divididoEn,
                valor,
                "0",
                "",
                "",
                "ASC",
                ""
            )
        )
            .then(() => {
                //window.location.reload();
                //agg

            })
            .catch(() => {

            });
        props.sendMessage(generateMessageAdm("ACTUALIZAR_ORDEN"))

        closeEditEmpresa();
        console.log("empresaId:" + empresaId)
        console.log("nombre:" + nombre)
        console.log("logo:" + logo)
        console.log("direccion:" + direccion)
        console.log("estado:" + estadoSelect["value"])
        console.log("usuario:" + currentUser["userId"])
        console.log("institucion:" + institucion)
        console.log("emai" + email)
        console.log("gerente " + gerente)
        console.log("operador " + operador)
        console.log("receptor " + receptor)
        console.log("num acc oferta " + numAccionesOferta)
        console.log("precio inicial accion" + precioInicialPorAccion)
        console.log("valor unt accion" + valorUnitarioAccion)
        console.log("monto min mejora precio " + montoMinMejoraPrecio)
        console.log("comision op " + comisionOperador)
        console.log("comision BVQ " + comisionBVQ)
        console.log("pocentaje techo precio mx " + porcentajePrecioTechoMax)

    }

    {/*Para agregar logo*/ }
    let [uploadId, setUploadId] = useState(
        currentUser !== null ? currentUser["UploadId"] : "-1"
    );

    let [fileNombre, setFileNombre] = useState(
        currentUser !== null ? currentUser["fileNombre"] : ""
    );

    let [fileNombreUrl, setFileNombreUrl] = useState(
        currentUser !== null ? currentUser["fileNombreUrl"] : ""
    );

    function reloadDocuments(file) {
        const result = JSON.parse(file);
        setUploadId(result["UploadId"]);
        setFileNombre(result["fileNombre"]);
        setFileNombreUrl(result["fileNombreUrl"]);
    }

    const convertirBase64 = (files) => {

        // agg
        // const imageFile = files[0];
        // const imageFileSize = imageFile.size;
        // const imageFileType = imageFile.type;
        // if (imageFileSize > 5000) {

        //   NotificationManager.error(
        //     "Error. El archivo es demasiado grande. Selecciona una imagen PNG menor a 5KB.",
        //   "Sistema",
        // 5000,
        // () => { },
        // 4
        //);
        // return
        //}
        //

        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let arrayImage = []
                let base = reader.result;
                arrayImage = base.split(',');
                setLogo(arrayImage[1])
                //setImage(arrayAux[1]);

            }
        })
    }

    return (
        <div className="overlay3 fade-in">
            <div className="borderContent ventanaPortafolio">
                <div className="innerShadow3">
                    <div id="formulario-estilo">
                        <div className="editEmpresa">
                            <h2>Empresa</h2>
                            <div className="vertical-scroll customScroll">
                                <div id="columna-1-formulario">
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i><span> Nombre:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                value={nombre}
                                                maxLength={1000}
                                                placeholder="Ingresar Nombre"
                                                className="input-form"
                                                onChange={(e) => {
                                                    setNombre(e.target.value);
                                                    errors["nombreErr"] = ""
                                                }}
                                            ></input>
                                            {errors["nombreErr"] !== "" ? <div className='camporequerido'>{errors["nombreErr"]}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Institución:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                value={institucion}
                                                maxLength={1000}
                                                placeholder="Ingresar Institución"
                                                className="input-form"
                                                onChange={(e) => {
                                                    setInstitucion(e.target.value);
                                                    errors["institucionErr"] = ""
                                                }}
                                            ></input>
                                            {errors["institucionErr"] !== "" ? <div className='camporequerido'>{errors["institucionErr"]}</div> : ""}
                                        </div>

                                    </div>

                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Logo:</span>
                                            </label>
                                        </div>

                                        <div className="col-70">

                                            <input
                                                type="file"
                                                className="boxStyleSelect"
                                                accept=".png"
                                                onChange={(e) => convertirBase64(e.target.files)}></input>
                                            <div className="boxStyleSelect">Selecciona el archivo para subirlo</div>
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Estado:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <Select
                                                autocomplete="off"
                                                className="input-form"
                                                id="estadoSelectCombo"
                                                value={estadoSelect}
                                                onChange={(e) => {
                                                    setEstadoSelect(e)
                                                }} placeholder="Seleccione" options={optionsEstado} />
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Dirección:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <textarea
                                                autocomplete="off"
                                                className="direccionEmpresa"
                                                type="text"
                                                value={direccion}
                                                maxLength={5000}
                                                style={{ height: '30px' }}
                                                placeholder="Dirección"

                                                onChange={(e) => {
                                                    setDireccion(e.target.value);
                                                    errors["direccionErr"] = ""
                                                }}

                                            ></textarea>
                                            {errors["direccionErr"] !== "" ? <div className='camporequerido'>{errors["direccionErr"]}</div> : ""}

                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Email:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <input
                                                autocomplete="off"
                                                type="email"
                                                value={email}
                                                maxLength={1000}
                                                placeholder="Ingresar Email"
                                                className="input-form"
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    errors["emailErr"] = ""
                                                }}
                                            ></input>
                                            {errors["emailErr"] !== "" ? <div className='camporequerido'>{errors["emailErr"]}</div> : ""}
                                        </div>
                                    </div>


                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Gerente:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                value={gerente}
                                                maxLength={1000}
                                                placeholder="Ingresar gerente"
                                                className="input-form"
                                                onChange={(e) => {
                                                    setGerente(e.target.value);
                                                    errors["gerenteErr"] = ""
                                                }}
                                            ></input>
                                            {errors["gerenteErr"] !== "" ? <div className='camporequerido'>{errors["gerenteErr"]}</div> : ""}
                                        </div>
                                    </div>

                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Operador:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                value={operador}
                                                maxLength={1000}
                                                placeholder="Ingresar operador"
                                                className="input-form"
                                                onChange={(e) => {
                                                    setOperador(e.target.value);
                                                    errors["operadorErr"] = ""
                                                }}
                                            ></input>
                                            {errors["operadorErr"] !== "" ? <div className='camporequerido'>{errors["operadorErr"]}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Receptor:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <input
                                                autocomplete="off"
                                                type="text"
                                                value={receptor}
                                                maxLength={1000}
                                                placeholder="Ingresar receptor"
                                                className="input-form"
                                                onChange={(e) => {
                                                    setReceptor(e.target.value);
                                                    errors["receptorErr"] = ""
                                                }}
                                            ></input>
                                            {errors["receptorErr"] !== "" ? <div className='camporequerido'>{errors["receptorErr"]}</div> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div id="columna-2-formulario">
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Fecha Constitución:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <DatePicker
                                                selected={Date.parse(fechaConstitucion)}
                                                onChange={date => setFechaConstitucion(date)}
                                                showMonthDropdown
                                                showYearDropdown
                                                dateFormat="dd/MM/yyyy"
                                                className="input-form"
                                            />
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-wrench"></i> <span>Fecha Inscripción:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <DatePicker
                                                selected={Date.parse(fechaInscripcion)}
                                                onChange={date => setFechaInscripcion(date)}
                                                showMonthDropdown
                                                showYearDropdown
                                                dateFormat="dd/MM/yyyy"
                                                className="input-form"
                                            />
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Num. acciones oferta:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="new-password"
                                                className="input-form"
                                                value={numAccionesOferta}
                                                maxLength={5}
                                                placeholder="Ingresar número de acciones"
                                                allowNegative={false}
                                                thousandSeparator={false}
                                                decimalScale={0}
                                                fixedDecimalScale={false}
                                                decimalSeparator={'.'}
                                                prefix={""}
                                                onValueChange={(values) => {

                                                    const { formattedValue, value } = values;
                                                    setNumAccionesOferta(value);
                                                    errors["numAccionesOfertaErr"] = ""
                                                }} />
                                            {errors["numAccionesOfertaErr"] !== "" ? <div className='camporequerido'>{errors["numAccionesOfertaErr"]}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Precio inicial por acción:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="off"
                                                className="input-form"
                                                value={precioInicialPorAccion}
                                                maxLength={5}
                                                allowNegative={false}
                                                placeholder="Ingresar precio inicial por acción"
                                                thousandSeparator={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                decimalSeparator={'.'}
                                                prefix={""}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setPrecioInicialPorAccion(value);
                                                    errors["precioInicialPorAccionErr"] = ""
                                                }} />
                                            {errors["precioInicialPorAccionErr"] !== "" ? <div className='camporequerido'>{errors["precioInicialPorAccionErr"]}</div> : ""}
                                        </div>
                                    </div>

                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Valor unitario por acción:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="off"
                                                className="input-form"
                                                value={valorUnitarioAccion}
                                                maxLength={5}
                                                allowNegative={false}
                                                placeholder="Ingresar valor unitario por acción"
                                                thousandSeparator={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                decimalSeparator={'.'}
                                                prefix={""}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setValorUnitarioAccion(value);
                                                    errors["valorUnitarioAccionErr"] = ""
                                                }} />
                                            {errors["valorUnitarioAccionErr"] !== "" ? <div className='camporequerido'>{errors["valorUnitarioAccionErr"]}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Monto min. para mejorar precio:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="off"
                                                className="input-form"
                                                value={montoMinMejoraPrecio}
                                                maxLength={5}
                                                placeholder="Ingresar monto mínimo"
                                                allowNegative={false}
                                                thousandSeparator={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                decimalSeparator={'.'}
                                                prefix={""}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setMontoMinMejoraPrecio(value);
                                                    errors["montoMinMejoraPrecioErr"] = ""
                                                }} />
                                            {errors["montoMinMejoraPrecioErr"] !== "" ? <div className='camporequerido'>{errors["montoMinMejoraPrecioErr"]}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Comisión operador:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="off"
                                                className="input-form"
                                                value={comisionOperador}
                                                maxLength={5}
                                                allowNegative={false}
                                                placeholder="Ingresar comisión operador"
                                                thousandSeparator={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                decimalSeparator={'.'}
                                                prefix={""}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setComisionOperador(value);
                                                    errors["comisionOperadorErr"] = ""
                                                }} />
                                            {errors["comisionOperadorErr"] !== "" ? <div className='camporequerido'>{errors["comisionOperadorErr"]}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Comisión BVQ:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="off"
                                                className="input-form"
                                                value={comisionBVQ}
                                                maxLength={5}
                                                allowNegative={false}
                                                placeholder="Ingresar comisión BVQ"
                                                thousandSeparator={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                decimalSeparator={'.'}
                                                prefix={""}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setComisionBVQ(value);
                                                    errors["comisionBvqErr"] = ""
                                                }} />
                                            {errors["comisionBvqErr"] !== "" ? <div className='camporequerido'>{errors["comisionBvqErr"]}</div> : ""}
                                        </div>
                                    </div>
                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Porcentaje de techo:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="off"
                                                className="input-form"
                                                value={porcentajePrecioTechoMax}
                                                maxLength={5}
                                                allowNegative={false}
                                                placeholder="Ingresar porcentaje de techo"
                                                thousandSeparator={false}


                                                prefix={""}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setPorcentajePrecioTechoMax(value);
                                                    errors["porcentajePrecioTechoMaxErr"] = ""
                                                }} />
                                            {errors["porcentajePrecioTechoMaxErr"] !== "" ? <div className='camporequerido'>{errors["porcentajePrecioTechoMaxErr"]}</div> : ""}
                                        </div>

                                    </div>


                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Capital pagado:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="off"
                                                className="input-form"
                                                value={capitalPagado}
                                                maxLength={10}
                                                allowNegative={false}
                                                placeholder="Ingresar capital pagado"
                                                thousandSeparator={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                decimalSeparator={'.'}
                                                prefix={""}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setCapitalPagado(value);
                                                    errors["capitalPagadoErr"] = ""
                                                }} />
                                            {errors["capitalPagadoErr"] !== "" ? <div className='camporequerido'>{errors["capitalPagadoErr"]}</div> : ""}
                                        </div>

                                    </div>

                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Divido en:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="off"
                                                className="input-form"
                                                value={divididoEn}
                                                maxLength={10}
                                                allowNegative={false}
                                                placeholder="Ingresar cantidad dividida"
                                                thousandSeparator={false}


                                                prefix={""}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setDivididoEn(value);
                                                    errors["divididoErr"] = ""
                                                }} />
                                            {errors["divididoErr"] !== "" ? <div className='camporequerido'>{errors["divididoErr"]}</div> : ""}
                                        </div>

                                    </div>

                                    <div className="row-form">
                                        <div className="col-30">
                                            <label>
                                                <i className="fas fa-edit"></i> <span>Valor:</span>
                                            </label>
                                        </div>
                                        <div className="col-70">
                                            <NumberFormat
                                                autocomplete="off"
                                                className="input-form"
                                                value={valor}
                                                maxLength={10}
                                                allowNegative={false}
                                                placeholder="Ingresar valor"
                                                thousandSeparator={false}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                decimalSeparator={'.'}
                                                prefix={""}
                                                onValueChange={(values) => {
                                                    const { formattedValue, value } = values;
                                                    setValor(value);
                                                    errors["valorErr"] = ""
                                                }} />
                                            {errors["valorErr"] !== "" ? <div className='camporequerido'>{errors["valorErr"]}</div> : ""}
                                        </div>

                                    </div>
                                </div>
                                <div className="textCenter clear-both">
                                    <div className="btnGuardar" onClick={actualizar} >
                                        GUARDAR
                                    </div>{" "}
                                    <div className="btnCancelar" onClick={closeEditEmpresa}>
                                        SALIR
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarEmpresa);
