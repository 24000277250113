import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from "react-notifications";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import logojunior from '../image/investTraderLogo.png';

import { login } from "../actions/auth";
import { getTodoEventoAct } from "../actions/administracion";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Campo requerido!
      </div>
    );
  }
};


const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = React.useState(false);
  const switchShown = () => setShown(!shown);
  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { listaTodosLosEventos } = useSelector((state) => state.administracion);
  let [loadTodosLosEventos, setLoadTodosLosEventos] = useState(false)
  const [isChecked, setIsChecked] = useState(false);



  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    console.log(e);
    setLoading(true);
    form.current.validateAll();
    dispatch(login(username, password))
      .then((data) => {
        console.log("data", data["role"])


        //agg
        if (data["role"] != "ROLE_ADMIN") {
          props.history.push("/seleccionarEvento");
        } else {
          props.history.push("/sala");
        }



        // window.location.reload();
      })
      .catch(() => {
        setLoading(false);

        setError('Credenciales inválidos');
      });


  };

  if (isLoggedIn) {
    //return <Redirect to="/seleccionarEvento" />;

  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div>
      <header>
        <div className="container-full-width">
          <div className="crypt-header">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                <div className="row">
                  <div className="col-xs-2">
                    <div className="crypt-logo"><img src={logojunior} alt="Logo" /></div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block d-lg-block">
                <ul className="crypt-heading-menu fright">

                  {/*<li className="crypt-box-menu menu-red"><a href="register">Regístrate</a></li>*/}

                </ul>
              </div>
              <i className="menu-toggle pe-7s-menu d-xs-block d-sm-block d-md-none d-sm-none"></i>
            </div>
          </div>
        </div>
        <div className="crypt-mobile-menu">
          <ul className="crypt-heading-menu">
            <li className="crypt-box-menu menu-green"><a href="">Regístrate</a></li>
            <li className="crypt-box-menu menu-red"><a href="">Iniciar sesión</a></li>

          </ul>
          <div className="crypt-gross-market-cap">
            <h5>$34.795.90 <span className="crypt-up pl-2">+3.435 %</span></h5>
            <h6>0.7925.90 BTC <span className="crypt-down pl-2">+7.435 %</span></h6></div>
        </div>
      </header>

      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="cryptorio-forms cryptorio-forms-dark text-center pt-5 pb-5">
              <div className="logo">
                <img src={logojunior} alt="Logo" />
              </div>
              <h3 className="p-4">Iniciar Sesión</h3>


              <div className="cryptorio-main-form">
                <Form onSubmit={handleLogin} ref={form} className="text-left">

                  <label className="label-credenciales" for="email">Correo electrónico</label>

                  <Input
                    autocomplete="off"
                    className="input-credenciales"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Escribe aquí tu correo electrónico"
                    required value={username}
                    validations={[required]}
                    onChange={onChangeUsername} />

                  <label className="label-credenciales" for="password">Contraseña</label>

                  {/*<div className="input-container">*/}
                  <div className="">
                    <Input
                      className="input-credenciales"
                      autocomplete="new-password"
                      type={shown ? 'text' : 'password'}
                      id="password" name="password"
                      placeholder="Escribe aquí tu contraseña"
                      required value={password}
                      onChange={onChangePassword}
                      validations={[required]} />

                    {/*<div className="inputPasswordSee" onClick={switchShown} title="Ver Contraseña">
                      {shown ? <i className="fas fa-eye fa-2x "></i> : <i className="fas fa-eye-slash fa-2x "></i>}
                    </div>*/}


                  </div>


                  <button className="inputIniciarSesion crypt-button-red-full" disabled={loading} >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Iniciar Sesión</span>
                  </button>

                  {/*<p className="float-right"><a href="/recuperar">¿Olvidaste tu contraseña?</a></p>*/}

                  <div className="text-center">


                    <div className="inputCondiciones">
                      <div className="custom-control custom-checkbox mr-sm-2">
                        <input type="checkbox" className="custom-control-input" id="terms-agree" required/>
                        <label className="custom-control-label" for="terms-agree">
                          Acepto la <a href="/condiciones" target='_blank'>
                            Política de privacidad de datos, términos y condiciones del servicio</a>, contenidos en este aplicativo.
                        </label>
                      </div>
                    </div>

                  </div>


                </Form>

              </div>

            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
        <NotificationContainer />
      </div>

    </div>
  );
};

export default Login;