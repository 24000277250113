import React, { useState,useEffect} from "react";
import { Redirect,useHistory  } from 'react-router-dom';
import { connect,useDispatch,useSelector } from "react-redux";


import audCampana from '../audio/campana.mp3'

const AperturaRueda = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);  
  
  let refLoading = React.createRef();          
  let history = useHistory();
  const dispatch = useDispatch();

  if (!currentUser) {
    return <Redirect to="/login" />;
  }else{
      setTimeout(()=>{
        //history.push('/juego')
      },5000)
  }


  return (
    <div>
        <audio src={audCampana} autoPlay={true} playsInline></audio>
      <div className="title-apertura">
      <div className="customBoxAviso">
      <div className="innerShadow">
      Apertura de rueda bursatil
      </div>
      </div>
      </div>
      <div className='apertura-cont-img'>
          <div className="ringing-bell"><i class="fas fa-bell campanaIcon animated faa-ring animated fa-5x"></i></div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({  
});
export default connect(mapStateToProps, {    
})(AperturaRueda);