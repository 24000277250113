export const ROL_ADMIN = 'A';
export const ROL_USUARIO = 'U';
export const ROL_DIRECTOR = 'R';



export function enableFuncionalityForRole(rol, fun) {
  const arrListAdminFun = ["LIST_USR", "CONF_EVENTO", "UPORN", "ADM", "FUNC_ADM"]
  const arrListRuedaFun = ["LIST_USR", "CONF_EVENTO", "UPORN", "ADM", "FUNC_RUED"]
  const arrListCajaFun = ["LIST_USR", "CONF_EVENTO", "UPORN", "ADM", "FUNC_CAJA"]
  const arrListLiquiFun = ["LIST_USR", "CONF_EVENTO", "UPORN", "ADM", "FUNC_LIQUI"]
  const arrListUsuarioFun = ["USER_ORDEN"]
  const arrListReceptorFun = ["USER_ORDEN", "FUNC_RECEP"]
  const arrListOperadorFun = ["USER_ORDEN", "FUNC_OPER"]
  const arrListRegistradorFun = ["USER_ORDEN", "FUNC_REGIS"]

  switch (rol) {
    case "ROLE_ADMIN":
      return arrListAdminFun.filter(value => (value === fun)).length > 0
      break
    case "ROLE_RUEDA":
      return arrListRuedaFun.filter(value => (value === fun)).length > 0
      break
    case "ROLE_USER":
      return arrListUsuarioFun.filter(value => (value === fun)).length > 0
      break

    //
    case "ROLE_RECEPTOR":
      return arrListReceptorFun.filter(value => (value === fun)).length > 0
      break
    case "ROLE_OPERADOR":
      return arrListOperadorFun.filter(value => (value === fun)).length > 0
      break
    case "ROLE_CAJA":
      return arrListCajaFun.filter(value => (value === fun)).length > 0
      break
    case "ROLE_LIQUIDACION":
      return arrListLiquiFun.filter(value => (value === fun)).length > 0
      break
    case "ROLE_REGISTRADOR":
      return arrListRegistradorFun.filter(value => (value === fun)).length > 0
      break
  }
}
export function getRole(roles, type) {
  switch (type) {
    case "A":
      return roles.filter(value => (value["Codigo"] === "ADMIN")).length > 0
    case "U":
      return roles.filter(value => value["Codigo"] === "USUARIO").length > 0
    case "R":
      return roles.filter(value => (value["Codigo"] === "ADMIN" || value["Codigo"] === "RUEDA")).length > 0
    default:
      return false
  }
}
export function formatDateTime(dateT) {

  if (dateT != null) {
    let date = new Date(dateT)

    let month = (date.getMonth() + 1).toString()
    if (month.length === 1)
      month = "0" + month

    let day = date.getDate().toString()
    if (day.length === 1)
      day = "0" + day

    let hours = date.getHours().toString()
    if (hours.length === 1)
      hours = "0" + hours

    let minutes = date.getMinutes().toString()
    if (minutes.length === 1)
      minutes = "0" + minutes

    let seconds = date.getSeconds().toString()
    if (seconds.length === 1)
      seconds = "0" + seconds

    return day + "/" + month + "/" + date.getFullYear()
  }
  else {
    return ""
  }
}


export function formatTime(dateT) {
  let date = new Date(dateT);

  let hours = date.getHours();

  let minutes = date.getMinutes().toString().padStart(2, "0");

  //let amPm = hours < 12 ? "AM" : "PM";
  if (hours === 0) {
    hours = 12;
  }

  return hours.toString().padStart(2, "0") + ":" + minutes;
}

export function time(dateT) {
  let date = new Date(dateT)

  let month = (date.getMonth() + 1).toString()
  if (month.length == 1)
    month = "0" + month

  let day = date.getDate().toString()
  if (day.length == 1)
    day = "0" + day

  let hours = date.getHours().toString()
  if (hours.length == 1)
    hours = "0" + hours

  let minutes = date.getMinutes().toString()
  if (minutes.length == 1)
    minutes = "0" + minutes

  let seconds = date.getSeconds().toString()
  if (seconds.length == 1)
    seconds = "0" + seconds

  return day + "/" + month + "/" + date.getFullYear() + " " + hours + ":" + minutes + ":" + seconds
}

export function generateMessageAdm(strType) {
  return JSON.stringify({ "ADM": strType })
}

export function secondFormatHour(seconds) {
  if (seconds < 0)
    return "00:00"
  return new Date(seconds * 1000).toISOString().slice(14, 19);
}
export function secondFormatComplete(seconds) {
  if (seconds < 0)
    return "00:00:00"
  return new Date(seconds * 1000).toISOString().slice(11, 19);
}

export function formatNumber(value) {
  if (value != null) {
    return Number.parseFloat(value).toFixed(2)
  }
  else {
    return ""
  }

}