import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid, { Column } from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { enableFuncionalityForRole, formatNumber, generateMessageAdm } from "../../components/general/Util";
import { formatDateTime } from "./Util";
import { getInversionistaAct, deleteInversionistaAct, getEmpresaSelectInversionista } from "../../actions/administracion";
import EditarInversionista from "./EditarInversionista";
import "../../config";
import { NotificationContainer } from "react-notifications";

const Inversionista = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px', height: '100%' }
    const { user: currentUser } = useSelector((state) => state.auth);

    let [eventoSeleccionadoId] = useState(props.eventoSeleccionado);
    let [empresaId] = useState(props.empresaId);
    let [usuarioId] = useState(props.usuarioId);

    const { listaInversionista, loadingInversionista } = useSelector((state) => state.administracion);
    const { listaEmpresasSelecInversionista, loadingEmpresa } = useSelector((state) => state.administracion);

    let [inversionistaSeleccionado, setInversionistaSeleccionado] = useState(null);
    let [editInversionistaOpen, setEditInversionistaOpen] = useState("")
    let [loadInverisonista, setLoadInversionista] = useState(false)


    console.log(listaInversionista)

    //

    if (loadInverisonista === false && listaEmpresasSelecInversionista === null) {
        setLoadInversionista(true)
        dispatch(getInversionistaAct(usuarioId,empresaId,"0", "", "", "ASC", ""))
            .then(() => {
            })
            .catch(() => {
            });

        // agg
        dispatch(getEmpresaSelectInversionista(usuarioId, empresaId))
            .then(() => {
            })
            .catch(() => {
            });

    }
    let [empresaFilter, setEmpresaFilter] = useState("");
    let [gridInfor, setGridInfor] = useState([listaInversionista !== null ? listaInversionista : []]);

    if (listaInversionista !== null && listaInversionista != gridInfor && empresaFilter === "")
        setGridInfor(listaInversionista)

    function filterCatalogo(nego, empresaFilter) {
        //value => 
        setGridInfor(nego.filter(function (item) {
            if (item["Nombre"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()) ||
                item["Direccion"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()) ||
                item["Estado"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()))
                return true;
            return false;
        }))
    }
    function closeInversionista() {
        props.closeInversionistaClick()
    }
    function openNewInversionista() {
        setInversionistaSeleccionado(null)
        setEditInversionistaOpen("EDIT")
    }
    function openNewInversionistaSE() {
        setInversionistaSeleccionado(null)
        setEditInversionistaOpen("EDIT")

    }

    function closeEditInversionista() {
        setEditInversionistaOpen("")
    }
    function formatEstado(estado) {
        switch (estado) {
            case "A":
                return "ACTIVO"
        }
        return "INACTIVO"
    }
    function formatEstado(estado) {
        switch (estado) {
            case "A":
                return "ACTIVO"
        }
        return "INACTIVO"
    }

    let [userRegistradorFuncionality] = useState(
        currentUser !== null
            ? enableFuncionalityForRole(currentUser["role"], "FUNC_REGIS")
            : false
    );
    let columns = [

        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Nombre', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center",formatter: (cell) => {
            return cell.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
          } },
        { name: 'Apellido', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>APELLIDO</div>), defaultFlex: 1, xdefaultLocked: 'start', defaultWidth: 400, headerAlign: "center" },
        { name: 'Cedula', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>CÉDULA</div>), defaultFlex: 1, xdefaultLocked: 'end', headerAlign: "center" },
        { name: 'Estado', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ESTADO</div>), defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => formatEstado(data["Estado"]), headerAlign: "center" },
    ]
    const renderRowContextMenu = useCallback((menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [

            {
                label: 'Editar',
                onClick: () => {
                    setInversionistaSeleccionado(rowProps.data)
                    setEditInversionistaOpen("EDIT")
                    console.log("seleccion inver", rowProps.data)
                }
            },
            {
                label: 'Eliminar',
                onClick: () => {
                    confirmAlert({
                        title: 'Eliminar inversionista',
                        message: '¿Seguro desea eliminar?.',
                        buttons: [
                            {
                                label: 'Si',
                                onClick: () => {
                                    dispatch(deleteInversionistaAct(rowProps.data["InversionistaId"],usuarioId,empresaId,))
                                        .then(() => {

                                        })
                                        .catch(() => { });
                                }
                            },
                            {
                                label: 'No',
                                onClick: () => {

                                }
                            }
                        ]
                    });
                }
            }
        ]
    }, [])
    return (
        <div
            className={[
                "overlay3",
                "fade-in"
            ].join(" ")}
        >
            <div className={['loading_cont', loadingInversionista !== true ? "displaynone" : ""].join(' ')}><div className='single6'></div></div>
            {editInversionistaOpen === "EDIT" ?
                <EditarInversionista
                    closeEditInversionista={closeEditInversionista}
                    inversionistaSeleccionado={inversionistaSeleccionado}
                    
                //listaEmpresasSelecInversionista = {listaEmpresasSelecInversionista}
                />
                : ""}
            <div className="borderContent ventanaPortafolio">
                <div className="innerShadow-height-100">
                    <h2>
                        Inversionista
                        <div className="subItemCatalogo">
                            <Menu menuButton={<MenuButton><i className="fas fa-cog"></i></MenuButton>}>
                                <MenuItem onClick={openNewInversionistaSE}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem>
                            </Menu>
                        </div>
                    </h2>
                    <div className="titleTable-search">INVERSIONISTA<div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
                        setEmpresaFilter(e.target.value);
                        filterCatalogo(listaInversionista !== null ? listaInversionista : [], e.target.value)
                    }} /><i className="fas fa-search"></i></div></div></div>


                    <DataGrid
                        idProperty="InversionistaId"
                        style={gridStyle}
                        columns={columns}
                        enableSelection={true}
                        selection={{ mode: 'single' }}
                        dataSource={gridInfor}
                        renderRowContextMenu={renderRowContextMenu}
                        theme="amber-dark"
                    />
                    <div className="dividerLine"></div>

                    {userRegistradorFuncionality == false ?
                        <div className="textCenter">
                            <div className="btnCancelar" onClick={closeInversionista}>
                                SALIR
                            </div>
                        </div>
                        : <div className="textCenter">

                        </div>}
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Inversionista);
