import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Websocket from 'react-websocket';
import { connect, useDispatch, useSelector } from "react-redux";
import ImageVideoGallery from "../components/general/ImageVideoGallery";
import { NotificationContainer } from "react-notifications";
import { showBanner } from "../actions/banner";
import { getEventosPorUsuario, setEventoObject } from "../actions/juego";
import Catalogos from "./general/Catalogos";
import Empresa from "./general/Empresa";
import Inversionista from "./general/Inversionista";
import Usuario from "./general/Usuario";
import Evento from "./general/Evento";
import Parametro from "./general/Parametro";
import { confirmAlert } from "react-confirm-alert"; // Import
import DataGrid, { Column } from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import "react-datepicker/dist/react-datepicker.css";
import { enableFuncionalityForRole } from "./general/Util";
import "@szhsin/react-menu/dist/index.css";
import { formatDateTime, formatNumber } from "./general/Util";
import Loading from "./general/LoadingContainer";
import GestionOrden from "./general/GestionOrden";
import EditarOrden from "./general/EditarOrden";
import VerEmpresas from "./general/VerEmpresas";
import Adjudicacion from "./general/Adjudicacion";
import { getAdjudicacionAct, getDatosAdjudicaciones, getEmpresaConEventoAct, getOrdenPorUsuarioAct, updateLiquidacion } from "../actions/administracion";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import { UseLocalStorage } from "./general/UseLocalStorage";
import "@szhsin/react-menu/dist/index.css";
import Ticker, { FinancialTicker, NewsTicker } from "nice-react-ticker";
import Liquidaciones from "./general/Liquidaciones";
import ReporteUserRueda from "./general/ReporteUserRueda";
import ControlJuego from "./general/ControlJuego";
import UsuarioActivo from "./general/UsuarioActivo";
import xml2js from 'xml2js'
import { getTitulosEventoById, setStatusByKey, getNegociacionesPorUsuario, getVariacionPrecio } from "../actions/juego";
import { NotificationManager } from "react-notifications";
import { toggleBotonAdjudicacion, toggleBotonApertura, toggleBotonSubasta } from "../actions/controlJuego";
import '../config'
let refWebSocket = null
let refUsuario = null
let refControlJuego = null

const Sala = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [gridStyle,setGridStyle] = useState({ minHeight: "calc(100vh/2 - 140px)", height: "100%" });
  const dispatch = useDispatch();
  let history = useHistory();
  const [loadEmpresaConEvento, setLoadEmpresaEvento] = useState(false);
  const { listEventos: currentEventos, eventoSeleccionado: eventoSeleccionado } = useSelector((state) => state.juego);
  const { listaEmpresaConEvento, loadingSala, loadingAdjudicacion, loadingOrdenUserId, loadingEmpresaConEvento, loadingInversionistaSelect } = useSelector((state) => state.administracion);


  const [openVentanaControl, setOpenVentanaControl] = useState(true);
  const [openVentanaUsuario, setOpenVentanaUsuario] = useState(true);

  let [openCatalogo, setOpenCatalogo] = useState("");
  let [openEmpresa, setOpenEmpresa] = useState("");
  let [openInversionista, setOpenInversionista] = useState("");
  let [openUsuario, setOpenUsuario] = useState("");
  let [openEvento, setOpenEvento] = useState("");
  let [openParametro, setOpenParametro] = useState("");
  let [openLiquidaciones, setOpenLiquidaciones] = useState("");
  let [openReporte, setOpenReporte] = useState("");
  let [openControlJuego, setOpenControlJuego] = useState("");
  let [openUsuarioActivo, setOpenUsuarioActivo] = useState("");

  let [openProfile, setOpenProfile] = useState("");
  let [even, setEven] = UseLocalStorage('text', "")
  let [loadEvent, setLoadEvent] = useState(false)

  //funcionalidades
  let [showEmpresa] = useState(currentUser !== null ? enableFuncionalityForRole(currentUser["role"], "USR_DASH_EMP") : false);
  const { datosjuego } = useSelector(state => state.controlJuego);
  //console.log("datosjuego", datosjuego)
  const [isDisabled, setIsDisabled] = useState(true)
  //console.log("isDisabled", isDisabled)
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);


  useEffect(() => {
    setIsDisabled(datosjuego && datosjuego["EstadoEjecucion"] !== "S")
  }, [datosjuego])
  // WS
  let [userList, setUserList] = useState([]);
  let [textoNotificacionEvento, setTextoNotificacionEvento] = useState("");
  let [hideMessageEvent, setHideMessageEvent] = useState(true);
  let [timeOutOrn, setTimeOutOrn] = useState(0);
  let [listMessage, setListMessage] = useState([]);
  let [pasoJugada, setPasoJugada] = useState("");
  let [jugada, setJugada] = useState(1);
  let [iniciaJuego, setIniciaJuego] = useState(true);
  let [startJuego, setStartJuego] = useState(false);
  const { valueVisibleSelectEditarOrden: estadoVisibleEditarOrden } = useSelector(state => state.controlJuego);
  const [number, setNumber] = useState(0);
  const [maximizeEmpresa, setMaximizeEmpresa] = useState(false);
  const [maximizeAdj, setMaximizeAdj] = useState(false);
  const [maximizeOrd, setMaximizeOrd] = useState(false);
  const [maximizeRegis, setMaximizeRegis] = useState(false);
  const [empresaFilterGen, setEmpresaFilterGen] = useState("");

  useEffect(() => {
    setDatosTicket(listaEmpresaConEvento !== null ? listaEmpresaConEvento['emp3']!==undefined ? listaEmpresaConEvento['emp3'] : listaEmpresaConEvento['emp1'] : [])
  }, [listaEmpresaConEvento])

  useEffect(() => {
    if(maximizeEmpresa)
      setGridStyle({ minHeight: "100%", height: "calc(100vh - 220px)" })
    else
      setGridStyle({ minHeight: "calc(100vh/2 - 140px)", height: "100%" })
  }, [maximizeEmpresa])  
  
  if (eventoSeleccionado !== null && loadEvent === false && currentUser !== null) {
    setLoadEvent(true)
    setEven(eventoSeleccionado);
    //console.log(even)
  }
  let [admFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_ADM")
      : false
  );
  let [userFuntion] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "USER_ORDEN")
      : false
  );

  // Nuevos usuarios
  let [userRuedaFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_RUED")
      : false
  );

  let [userCajaFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_CAJA")
      : false
  );

  let [userLiquidacionFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_LIQUI")
      : false
  );

  let [userRegistradorFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_REGIS")
      : false
  );


  const openCatalogosClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("OPEN");
    setOpenEmpresa("");
    setOpenEvento("");
    setOpenInversionista("");
    setOpenParametro("");
    setOpenUsuario("");
    setOpenLiquidaciones("");
    setOpenReporte("");
  };
  const closeCatalogosClick = () => setOpenCatalogo("");
  const openEmpresaClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("");
    setOpenEmpresa("OPEN");
    setOpenEvento("");
    setOpenInversionista("");
    setOpenParametro("");
    setOpenUsuario("");
    setOpenLiquidaciones("");
    setOpenReporte("");
  };
  const closeEmpresaClick = () => setOpenEmpresa("");
  const openInversionistaClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("");
    setOpenEmpresa("");
    setOpenEvento("");
    setOpenInversionista("OPEN");
    setOpenParametro("");
    setOpenUsuario("");
    setOpenLiquidaciones("");
    setOpenReporte("");
  };
  const closeInversionistaClick = () => setOpenInversionista("");
  const openUsuarioClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("");
    setOpenEmpresa("");
    setOpenEvento("");
    setOpenInversionista("");
    setOpenParametro("");
    setOpenUsuario("OPEN");
    setOpenLiquidaciones("");
    setOpenReporte("");
  };
  const closeUsuarioClick = () => setOpenUsuario("");
  const openEventoClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("");
    setOpenEmpresa("");
    setOpenEvento("OPEN");
    setOpenInversionista("");
    setOpenParametro("");
    setOpenUsuario("");
    setOpenLiquidaciones("");
    setOpenReporte("");
  };
  const closeEventoClick = () => setOpenEvento("");
  const openParametroClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("");
    setOpenEmpresa("");
    setOpenEvento("");
    setOpenInversionista("");
    setOpenParametro("OPEN");
    setOpenUsuario("");
    setOpenLiquidaciones("");
    setOpenReporte("");
  };
  const closeParametroClick = () => setOpenParametro("");

  //
  const openLiquidacionesClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("");
    setOpenEmpresa("");
    setOpenEvento("");
    setOpenInversionista("");
    setOpenParametro("");
    setOpenUsuario("");
    setOpenLiquidaciones("OPEN");
    setOpenReporte("");
  };
  const closeLiquidacionesClick = () => setOpenLiquidaciones("");

  const openReporteClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("");
    setOpenEmpresa("");
    setOpenEvento("");
    setOpenInversionista("");
    setOpenParametro("");
    setOpenUsuario("");
    setOpenLiquidaciones("");
    setOpenReporte("OPEN");
  };
  const closeReporteClick = () => setOpenReporte("");
  const openControlJuegoClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("");
    setOpenEmpresa("");
    setOpenEvento("");
    setOpenInversionista("");
    setOpenParametro("");
    setOpenUsuario("");
    setOpenLiquidaciones("");
    setOpenReporte("");
    setOpenControlJuego("OPEN");
    setOpenUsuarioActivo("");
  };
  const closeControlJuegoClick = () => setOpenControlJuego("");

  const openUsuarioActivoClick = (e) => {
    e.preventDefault();
    setOpenCatalogo("");
    setOpenEmpresa("");
    setOpenEvento("");
    setOpenInversionista("");
    setOpenParametro("");
    setOpenUsuario("");
    setOpenLiquidaciones("");
    setOpenReporte("");
    setOpenUsuarioActivo("OPEN");
  };
  const closeUsuarioActivoClick = () => setOpenUsuarioActivo("");


  //
  const openSelectEventoClick = (e) => {
    e.preventDefault();
    history.push("/seleccionarEvento");
  };




  let [empresaFilter, setEmpresaFilter] = useState("");
  let [gridInfor, setGridInfor] = useState([listaEmpresaConEvento !== null ? listaEmpresaConEvento : []]);

  let [datosTicket, setDatosTicket] = useState(listaEmpresaConEvento !== null ? listaEmpresaConEvento['emp3']!==undefined ? listaEmpresaConEvento['emp3'] : listaEmpresaConEvento['emp1'] : []);
  

  if (listaEmpresaConEvento !== null && listaEmpresaConEvento['emp1'] != gridInfor && empresaFilter === "") {
    setGridInfor(listaEmpresaConEvento['emp1']);

  }
  
  
  //console.log("even",even)
  if (loadEmpresaConEvento === false && even !== null && currentUser !== null) {
    setLoadEmpresaEvento(true);
    dispatch(getEmpresaConEventoAct(even, currentUser["userId"], currentUser["EmpresaId"]))
      .then(() => { })
      .catch(() => { });
  }

  const [selected, setSelected] = useState(0);
  const onSelectionChange = (data) => {
    setSelected(data.selected)
    if (gridInfor != null) {
      let empFil = gridInfor.filter(x => x.EmpresaId == data.selected)

      if (empFil != null && empFil.length > 0)
        setEmpresaFilterGen(empFil[0].NombreEmpresa)
    }
  }


  if (selected > 0 && listaEmpresaConEvento) {
   // console.log("entro")

    //let empresa = listaEmpresaConEvento.find(x => x.EmpresaId === selected[0])
    //console.log(empresa)

  }
  else {
   // console.log("no hay datos")
  }

  let [boolListEmpresaEvento, setboolListEmpresaEvento] = useState(false)
  if (listaEmpresaConEvento !== null && boolListEmpresaEvento == false) {
    //listaEmpresaConEvento.forEach((empresa) => {
    //console.log(empresa)

    //})
  }

  //console.log(listaEmpresaConEvento)



  function filterCatalogo(nego, empresaFilter) {
    setGridInfor(nego['emp1'].filter(function (item) {
      if (item["NombreEmpresa"].toLowerCase().includes(empresaFilter.toLocaleLowerCase())
      )
        return true;
      return false;
    }))
  }



  let columns = [
    /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
    { name: "NombreEmpresa", header: "EMPRESA", defaultFlex: 1, xdefaultLocked: "end", headerAlign: "center" },
    { name: "NumAccionesOferta", header: "ACCIONES", defaultFlex: 1, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center" },
    { name: "PrecioInicialPorAccion", header: "PRECIO", defaultFlex: 1, xdefaultLocked: "end", className: "numDataGrid", render: ({ value }) => formatNumber(value), headerAlign: "center" },
  ];

  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  // agregados para timer global
  function showMessageEvent(str, time) {
    setTextoNotificacionEvento(str)
    setHideMessageEvent(false)
    setTimeout(() => {
      setHideMessageEvent(true)
    }, parseInt(time) * 1000);
  }

  function setCodigoModalidad(mod) {
    dispatch(setStatusByKey("modalidadCodigo", mod))
  }

  function sendMessageLocal(msg) {
    refWebSocket.sendMessage(msg)
  }
  function sendMessageChat(msg, clientId) {
    addMessageHistory(msg, clientId, 'S')
    refWebSocket.sendMessage(msg)
  }

  function addMessageHistory(msg, clientId, type) {
    let lsTmp = listMessage[clientId]
    if (listMessage[clientId] === undefined || listMessage[clientId] === null)
      lsTmp = []

    let itemMsg = {};
    itemMsg["msg"] = msg;
    itemMsg["tipo"] = type;
    lsTmp.push(itemMsg)
    listMessage[clientId] = lsTmp
    setListMessage(listMessage)
  }

  // recuperamos de WS


  const handleData = (data) => {
    if (data === undefined || data.trim() === "") return;

    let result = JSON.parse(data);
    console.log("RESUL DE WS", result)

    switch (result["TYPE"]) {

      case "MSG_ERR":
        NotificationManager.error(
          "No se puede iniciar aún el evento, está fuera de fecha de inicio",
          "Sistema",
          5000,
          () => { },
          4
        );
        break
      case "EVENT":
        let initObject = JSON.parse(result["MSG"]);
        console.log("initObject[event]",initObject["event"])

        dispatch(setEventoObject(initObject["event"]))

        switch (initObject["EstadoEjecucion"]) {
          case "I":

            break
          case "S":

            break
          case "J":

            break
          case "F":

            break
          default:
            dispatch(toggleBotonApertura())
        }
        break
      case "JUGADA":
        let objectJugada = JSON.parse(result["MSG"]);
        console.log(objectJugada)
        dispatch(setEventoObject(objectJugada["event"]))
        break
      case "ACTUALIZAR":
        window.location.reload(false);
        break
      case "START":
        dispatch(setEventoObject(datosjuego))

        break
      case "ACTUALIZAR_ORDEN":
        
        let usuarioObject = JSON.parse(result["MSG"]);
        console.log(usuarioObject)

        
        dispatch(getOrdenPorUsuarioAct(currentUser["EmpresaId"], eventoSeleccionado, currentUser["userId"], "0", "", "", "ASC", ""))
          .then(() => {
          })
          .catch(() => {
          });
        dispatch(getAdjudicacionAct(eventoSeleccionado, currentUser["EmpresaId"], currentUser["userId"], "0", "", "", "ASC", ""))
          .then(() => {
          })
          .catch(() => {
        });

        // agg
       dispatch(getDatosAdjudicaciones(eventoSeleccionado, currentUser["userId"], currentUser["EmpresaId"], "0", "", "", "ASC", ""))

       // agg para editar datos empresa
       dispatch(getEmpresaConEventoAct(eventoSeleccionado, currentUser["userId"], currentUser["EmpresaId"]))
        break


      case "LIST_USR":
        //if(!enableUserList) return
        let parser = new xml2js.Parser();
        parser.parseString(result["MSG"],
          function (err, result) {
            try {
              var usuarios = result["CustomType"]["userList"][0]["d2p1:KeyValueOfstringClienteListwMlRxXJ_S"]
              usuarios.sort(function (a, b) {
                return a["d2p1:Value"][0]["strNick"][0].localeCompare(b["d2p1:Value"][0]["strNick"][0]);
              })
              console.log("Lista de usuarios", usuarios)
              setUserList(usuarios)
            } catch (ex) {

            }
          }
        );
        break

    }
  }

  return (
    <div className="container-fluid menuSidebar">
      <Websocket
        ref={Websocket => { refWebSocket = Websocket; }}
        url={global.config.URL_WEB_SOCKET + eventoSeleccionado + "?USR=" + currentUser["userId"] + "&CS=1&ROL=" + currentUser["role"] + "&UNAME=" + encodeURIComponent(currentUser["displayName"]) + "&IMG="}
        onMessage={handleData} />

      {openEmpresa === "OPEN" ? (
        <Empresa closeEmpresaClick={closeEmpresaClick} eventoSeleccionado={eventoSeleccionado} sendMessage={sendMessageLocal} />
      ) : (
        ""
      )}
      {openCatalogo === "OPEN" ? (
        <Catalogos closeCatalogosClick={closeCatalogosClick} />
      ) : (
        ""
      )}
      {userRegistradorFuncionality === true || openInversionista === "OPEN" ? (
        <Inversionista closeInversionistaClick={closeInversionistaClick} eventoSeleccionado={eventoSeleccionado} usuarioId={currentUser["userId"]} empresaId={currentUser["EmpresaId"]} />
      ) : (
        ""
      )}
      {openUsuario === "OPEN" ? (
        <Usuario closeUsuarioClick={closeUsuarioClick} />
      ) : (
        ""
      )}
      {openEvento === "OPEN" ? (
        <Evento closeEventoClick={closeEventoClick} />
      ) : (
        ""
      )}
      {openParametro === "OPEN" ? (
        <Parametro closeParametroClick={closeParametroClick} />
      ) : (
        ""
      )}


      {openReporte === "OPEN" && (userRuedaFuncionality === true || admFuncionality === true) ? (
        <ReporteUserRueda closeReporteClick={closeReporteClick} eventoSeleccionado={eventoSeleccionado} />
      ) : (
        ""
      )}
 

      {/*{userCajaFuncionality === true || userLiquidacionFuncionality === true || openLiquidaciones === "OPEN" ? (*/ }
      {openLiquidaciones === "OPEN" ? (
        <Liquidaciones closeLiquidacionesClick={closeLiquidacionesClick} eventoSeleccionado={eventoSeleccionado} usuarioId={currentUser["userId"]} empresaId={currentUser["EmpresaId"]} sendMessage={sendMessageLocal}/>
      ) : (
        ""
      )}

      {(userRuedaFuncionality === true || admFuncionality === true) ? (
        <ControlJuego ref={ControlJuego => { refControlJuego = ControlJuego; }}
          closeControlJuegoClick={closeControlJuegoClick}
          sendMessage={sendMessageLocal}
          sendMessageChat={sendMessageChat}
          listMessage={listMessage}
          startJuego={startJuego} />
      ) : (
        ""
      )}


      {(userRuedaFuncionality === true || admFuncionality === true) ? (
        <UsuarioActivo ref={UsuarioActivo => { refUsuario = UsuarioActivo; }}
          closeUsuarioActivoClick={closeUsuarioActivoClick} userList={userList} />
      ) : (
        ""
      )}


      <div >

        <div className="btnMenu" onClick={() => setShowMenu(!showMenu)}>
          <i className="fa fa-bars"></i>
        </div>

        {showMenu == true ? (
          <div className="crypt-side-menu crypt-left-sided crypt-floatable-menu bg-white">

            <ul>
              <li>
                <a href="">
                  <i className="pe-7s-graph1"></i> INICIO
                </a>
              </li>
            </ul>
            <hr />
            {/*<p>Configuración</p>*/}
            <ul>


              <li>
                <a href="#" onClick={openSelectEventoClick}>
                  <i className="pe-7s-gym"></i>Seleccionar Evento
                </a>
              </li>

              {admFuncionality === true ? (
                <li>
                  <a href="#" onClick={openCatalogosClick}>
                    <i className="pe-7s-gym"></i>Catálogo
                  </a>
                </li>
              ) : (
                ""
              )}
              {admFuncionality === true ? (
                <li>
                  <a href="#" onClick={openEmpresaClick}>
                    <i className="pe-7s-gym"></i>Empresa
                  </a>
                </li>
              ) : (
                ""
              )}
              {admFuncionality === true || userRuedaFuncionality === true ? (
                <li>
                  <a href="#" onClick={openEventoClick}>
                    <i className="pe-7s-gym"></i>Eventos
                  </a>
                </li>
              ) : (
                ""
              )}
              {admFuncionality === true ? (
                <li>
                  <a href="#" onClick={openInversionistaClick}>
                    <i className="pe-7s-gym"></i>Inversionista
                  </a>
                </li>
              ) : (
                ""
              )}
              {admFuncionality === true ? (
                <li>
                  <a href="#" onClick={openParametroClick}>
                    <i className="pe-7s-gym"></i>Parámetros
                  </a>
                </li>
              ) : (
                ""
              )}
              {admFuncionality === true || userRuedaFuncionality === true ? (
                <li>
                  <a href="#" onClick={openUsuarioClick}>
                    <i className="pe-7s-gym"></i>Usuarios
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
            <hr />

            <ul>
              {admFuncionality === true || userRuedaFuncionality === true ? (
                <li>
                  <a href="#" onClick={openReporteClick}>
                    <i className="pe-7s-gym"></i>Reportes
                  </a>
                </li>
              ) : (
                ""
              )}

              {admFuncionality === true || userRuedaFuncionality === true || userCajaFuncionality === true || userLiquidacionFuncionality === true ? (
                <li>
                  <a href="#" onClick={openLiquidacionesClick}>
                    <i className="pe-7s-wallet"></i>Liquidaciones
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        ) : <div className="crypt-side-menu2 crypt-left-sided crypt-floatable-menu bg-white">
        </div>}

      </div>




      {/*<button onClick={handleToggle} className="btn_finalizar" >Finalizar juego</button>*/}
      <div className="tiker-acciones-cont">
        <Ticker slideSpeed={datosTicket && datosTicket.length>5? datosTicket.length * 2 : 10} isNewsTicker={false} show={true} >
          {datosTicket != null ?
            datosTicket.map(element => {
              return <FinancialTicker
                id={element.EmpresaId}
                change={true}
                symbol={element.NombreEmpresa}
                lastPrice={formatNumber(element.PrecioInicialPorAccion)}
                percentage={formatNumber(element.PrecioInicialPorAccion)}
                currentPrice={element.NumAccionesOferta}
                className='ticker-text'
              />
            }) :""
          }
        </Ticker>
      </div>
      {admFuncionality === true && eventoSeleccionado || userFuntion === true || userRuedaFuncionality === true || userLiquidacionFuncionality ===true || userCajaFuncionality ===true ? (

        <div className="">

          <div className={['', loadingAdjudicacion !== true && loadingOrdenUserId !== true && loadingEmpresaConEvento !== true ? "displaynone" : ""].join(' ')}>
            <div className='single6'>
            </div>
          </div>
          <div className={['', loadingInversionistaSelect !== true ? "displaynone" : ""].join(' ')}>
            <div className='single6'>
            </div>
          </div>

          <div className={["row", showMenu === true ? "sm-gutters" : "sm-gutters2"].join(' ')}>
            <div className={["col-xl-5", maximizeEmpresa ? "maxi-empresas" : "", maximizeAdj || maximizeOrd || maximizeRegis ? "displaynone" : ""].join(' ')}>
              {/*<div className={["col-xl-", showMenu===false?"5":"8"].join(' ')}>*/}

              <div>

                <div className="crypt-market-status">

                  <div>
                    <h6 className="crypt-bg-head">
                      EMPRESAS
                      <div className="floatright">
                        <div className="filterNeg">
                          <input
                            type="text"
                            placeholder="Filtrar"
                            value={empresaFilter}
                            onChange={(e) => {
                              setEmpresaFilter(e.target.value);
                              filterCatalogo(listaEmpresaConEvento !== null ? listaEmpresaConEvento : [], e.target.value)

                            }} />
                          <i className="fas fa-search"></i>
                          <i className={["fas", !maximizeEmpresa ? "fa-window-maximize" : "fa-window-restore"].join(' ')} onClick={() => setMaximizeEmpresa(!maximizeEmpresa)}></i>
                        </div>
                      </div>
                    </h6>
                    <div className="tab-content">
                      <DataGrid
                        idProperty="EmpresaId"
                        style={gridStyle}
                        columns={columns}
                        enableSelection={true}
                        selection={{ mode: "single" }}
                        dataSource={gridInfor}
                        theme="amber-dark"
                        onSelectionChange={onSelectionChange}
                      />
                      <div className="no-orders text-center"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div className={["col-xl-7", maximizeAdj ? "maxi-empresas" : "", maximizeEmpresa || maximizeOrd || maximizeRegis ? "displaynone" : ""].join(' ')}>
              <div>
                <div className="crypt-market-status">
                  <div>
                    <Adjudicacion usuarioId={currentUser["userId"]} eventoId={eventoSeleccionado} empresaId={currentUser["EmpresaId"]} selected={selected} maximizeAdj={maximizeAdj} setMaximizeAdj={setMaximizeAdj} empresaFilterGen={empresaFilterGen} setEmpresaFilterGen={setEmpresaFilterGen} />
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className={["row", showMenu === true ? "sm-gutters" : "sm-gutters2"].join(' ')}>
            <div className={["col-xl-5", maximizeOrd ? "maxi-empresas" : "", maximizeAdj || maximizeEmpresa || maximizeRegis ? "displaynone" : ""].join(' ')}>
              <div>
                <div className="crypt-market-status">
                  <div>
                    <EditarOrden sendMessage={sendMessageLocal}
                      sendMessageChat={sendMessageChat}
                      eventoSeleccionado={eventoSeleccionado}
                      showEmpresa={showEmpresa} isDisabled={isDisabled} maximizeOrd={maximizeOrd} setMaximizeOrd={setMaximizeOrd} />
                  </div>
                </div>
              </div>
            </div>
            <div className={["col-xl-7", maximizeRegis ? "maxi-empresas" : "", maximizeAdj || maximizeOrd || maximizeEmpresa ? "displaynone" : ""].join(' ')}>
              <div>
                <div className="crypt-market-status">
                  <GestionOrden usuarioId={currentUser["userId"]} isDisabled={isDisabled} sendMessage={sendMessageLocal} maximizeRegis={maximizeRegis} setMaximizeRegis={setMaximizeRegis} empresaFilterGen={empresaFilterGen} setEmpresaFilterGen={setEmpresaFilterGen} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <NotificationContainer />
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Sala);
