export const toggleValueApertura = () => {
    return {
      type: 'SEND_VALUE_APERTURA'
    };
  };

  export const toggleValueSubasta = () => {
    return {
      type: 'SEND_VALUE_SUBASTA'
    };
  };
  export const toggleValueAdjudicacion = () => {
    return {
      type: 'SEND_VALUE_ADJUDICACION'
    };
  };

  export const toggleBotonApertura = () => {
    return {
      type: 'SEND_VALUE_BOTON_APERTURA'
    };
  };

  export const toggleBotonSubasta = () => {
    return {
      type: 'SEND_VALUE_BOTON_SUBASTA'
    };
  };

  export const toggleBotonAdjudicacion = () => {
    return {
      type: 'SEND_VALUE_BOTON_ADJUDICACION'
    };
  };
  
  export const toggleBotonVisibleEditarOrden = () => {
    return {
      type: 'SEND_VALUE_BOTON_EDITAR_ORDEN'
    };
  };