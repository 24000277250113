import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import logojunior from "../image/investTraderLogo.png";
import { sendEmailPassword } from "../actions/user";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Campo requerido!
      </div>
    );

  }
};

const Login = (props) => {
  const form = useRef();
  const dispatch = useDispatch();
  let [email, setEmail] = useState(null);
  let [errors] = useState({ "emailErr": "" });

  const [loading, setLoading] = useState(false);

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };



  const recuperarClave = (e) => {
    e.preventDefault();
    let valid = true

    dispatch(sendEmailPassword(email))
      .then(() => {

      })
      .catch(() => {
        setLoading(false);

      });
    setEmail('');
  }
  // if (!valid) {
  // return
  // }
  // }

  return (
    <div>
      <header>
        <div className="container-full-width">
          <div className="crypt-header">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                <div className="row">
                  <div className="col-xs-2">
                    <div className="crypt-logo">
                      <img src={logojunior} alt="Logo" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block d-lg-block">
                <ul className="crypt-heading-menu fright">
                  <li className="crypt-box-menu menu-green"><a href="/">Iniciar sesiÃ³n</a></li>
                  {/*<li className="crypt-box-menu menu-red"><a href="register">RegÃ­strate</a></li>*/}

                </ul>
              </div>
              <i className="menu-toggle pe-7s-menu d-xs-block d-sm-block d-md-none d-sm-none"></i>
            </div>
          </div>
        </div>

      </header>

      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="cryptorio-forms cryptorio-forms-dark text-center pt-5 pb-5">
              <div className="logo">
                <img src={logojunior} alt="Logo" />
              </div>
              <h3 className="p-4">Recuperar contraseña</h3>
              <div className="cryptorio-main-form">

                <Form onSubmit={recuperarClave} ref={form} className="text-left">
                  <label className="label-credenciales" for="email">Ingrese su correo electrónico</label>
                  <Input
                  
                    type="email"
                    value={email}
                    maxLength={300}
                    required
                    placeholder="Correo electrónico"
                    className="input-credenciales"
                    //validations={[required]}
                    onChange={onChangeEmail}
                  ></Input>
                  <button className="inputIniciarSesion crypt-button-red-full" disabled={loading} >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Enviar</span>
                  </button>
                </Form>
                <p className="float-left-recuperar">
                  <a href="/login">Regresar al Login</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Login;