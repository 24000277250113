import React, { useEffect, useState } from 'react'
import { Provider, connect, useSelector, useDispatch } from 'react-redux'
import { sendValue, updateLiquidacion } from '../../actions/administracion';
import { generateMessageAdm } from "../../components/general/Util"
import { toggleBotonVisibleEditarOrden, toggleBotonApertura, toggleBotonSubasta, toggleBotonAdjudicacion, toggleValueAdjudicacion, toggleValueApertura, toggleValueSubasta } from '../../actions/controlJuego';
import { setStatusByKey } from "../../actions/juego"
import store from '../../store';
const MyContext = React.createContext();

const ControlJuego = (props) => {

    const dispatch = useDispatch();
    const { listEventos: currentEventos, eventoSeleccionado: eventoSeleccionado } = useSelector((state) => state.juego);
    const [showButtonSubasta, setShowButtonSubasta] = useState(false);
    const [showButtonAdjudicacion, setshowButtonAdjudicacion] = useState(false);
    const [showButtonFinalizar, setshowButtonFinalizar] = useState(false);
    const [globalValue, setGlobalValue] = React.useState("Some initial value");

    const { valueApertura: estadoObtenidoApertura } = useSelector(state => state.controlJuego);
    const { valueSubasta: estadoObtenidoSubasta } = useSelector(state => state.controlJuego);
    const { valueAdjudicacion: estadoObtenidoAdjudicacion } = useSelector(state => state.controlJuego);

    const { valueBotonApertura: estadoObtenidoBotonApertura } = useSelector(state => state.controlJuego);
    const { valueBotonSubasta: estadoObtenidoBotonSubasta } = useSelector(state => state.controlJuego);
    const { valueBotonAdjudicacion: estadoObtenidoBotonAdjudicacion } = useSelector(state => state.controlJuego);


    const { datosjuego } = useSelector(state => state.controlJuego);
    let [mostratBotonesRigth, setMostratBotonesRigth] = useState(false);



    function closeControlJuego() {
        props.closeControlJuegoClick()
    }
    const handleToggle = () => {
        //props.sendMessage(generateMessageAdm("FEV"))
        dispatch(toggleBotonVisibleEditarOrden())
        dispatch(updateLiquidacion(eventoSeleccionado))
        //  .then(() => {
        //})
        //.catch(() => { });
        props.sendMessage(generateMessageAdm("FIN"))

    };

    // Iniciar juego Apertura
    function btnInicarJuegoApertura() {
        //dispatch(setStatusByKey("loadingAccionEvt", true))
        props.sendMessage(generateMessageAdm("START"))
        //  dispatch(toggleValueApertura())
    }
    // Iniciar juego subasta
    function btnInicarJuegoSubasta() {
        //dispatch(setStatusByKey("loadingAccionEvt", true))
        props.sendMessage(generateMessageAdm("SUBASTA"))
        dispatch(toggleValueSubasta())
    }
    // Iniciar juego adjudicacion
    function btnInicarJuegoAdjudicacion() {
        //dispatch(setStatusByKey("loadingAccionEvt", true))
        props.sendMessage(generateMessageAdm("ADJUDICACION"))
        dispatch(toggleValueAdjudicacion())
    }


    useEffect(() => {
        if (estadoObtenidoBotonApertura === false) {
            setShowButtonSubasta(true);
        }
    }, [estadoObtenidoBotonApertura]);

    useEffect(() => {
        if (estadoObtenidoBotonSubasta === false) {
            setshowButtonAdjudicacion(true);
        }
    }, [estadoObtenidoBotonSubasta]);

    /*useEffect(() => {
        if (estadoObtenidoBotonAdjudicacion === false) {
            setshowButtonFinalizar(true);
        }
    }, [estadoObtenidoBotonAdjudicacion]);*/

    function mostrarOcultarBotones() {
        setMostratBotonesRigth(!mostratBotonesRigth)
    }
    //console.log(datosjuego)
    return (
        <div className={""}>

            <div className=" ventanaPortafolio2">
                <div className={["userBtn","displaynone", mostratBotonesRigth === true ? "" : "minimizeUserList"].join(' ')}>
                    <div className="moreRowBtn" onClick={mostrarOcultarBotones}><i className={["fa", mostratBotonesRigth === true ? "fa-minus" : "fa-plus"].join(' ')}></i></div>
                    <div className="innerShadow3">
                        <ul>

                            <li className="btnNuevaJugada">
                                <div className="textCenter">

                                    {datosjuego && datosjuego["EstadoEjecucion"] === "" && (
                                        <button
                                            className='btnJuego'
                                            onClick={btnInicarJuegoApertura}>Apertura
                                        </button>)}


                                    {datosjuego && datosjuego["EstadoEjecucion"] === "I" ? (
                                        <button
                                            className='btnJuego'
                                            onClick={btnInicarJuegoSubasta}>Subasta
                                        </button>) : ""}


                                    {datosjuego && datosjuego["EstadoEjecucion"] === "S" ? (
                                        <button
                                            className='btnJuego'
                                            onClick={btnInicarJuegoAdjudicacion}>Adjudicación
                                        </button>) : ""}

                                    {datosjuego && datosjuego["EstadoEjecucion"] === "J" ? (
                                        <button
                                            className='btnJuego'
                                            onClick={handleToggle}
                                        >Finalizar evento
                                        </button>) : ""}

                                    {datosjuego && datosjuego["EstadoEjecucion"] === "F" ? (
                                        <span
                                        >Evento Finalizado
                                        </span>) : ""}

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export function estadoBoton() { };

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(ControlJuego,);
