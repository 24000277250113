import React, { useState, useEffect, useCallback } from "react";
import ElegirEvento from "./ElegirEvento";
import Sala from "./Sala";
import { connect, useDispatch, useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import "@szhsin/react-menu/dist/index.css";
import '@szhsin/react-menu/dist/index.css';
import { getTodoEventoAct } from "../actions/administracion";
import { Redirect } from "react-router-dom";

const SeleccionarEvento = (props) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { listaTodosLosEventos } = useSelector((state) => state.administracion);

  
  let [loadTodosLosEventos, setLoadTodosLosEventos] = useState(false)

  if (loadTodosLosEventos === false) {
    setLoadTodosLosEventos(true)
    dispatch(getTodoEventoAct(currentUser["userId"], currentUser["role"]))
      .then(() => {
      })
      .catch(() => {
      });


  }

  
  

 

//  if (listaTodosLosEventos===null) {
  //  return <Redirect to="/sala" />;
 // }else{
   // return <Redirect to="/elegirEvento" />;
 // }


  return (


    <div className="container-fluid">
      <div className="row sm-gutters">
        <div className="col-xl-12">
          <div className="crypt-market-status">
            {/*{listaTodosLosEventos !== null ? <ElegirEvento/> : <Sala/>}*/}


          <ElegirEvento />
          </div>
        </div>

      </div>

    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(SeleccionarEvento);
