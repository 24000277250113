import React, { useState, useEffect } from "react";
import '../config'
import logojunior from '../image/investTraderLogo.png';

const CondicionesLegales = (props) => {

    let [loadCssClass, setLoadCssClass] = useState(true);

    if (loadCssClass === true) {
        setLoadCssClass(false)
        document.getElementById('root').classList.add("root-fn");
    }

    return (
        <div>
            <header>
                <div className="container-full-width">
                    <div className="crypt-header">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5">
                                <div className="row">
                                    <div className="col-xs-2">
                                        <div className="crypt-logo"><img src={logojunior} alt="Logo" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 d-none d-md-block d-lg-block">
                                <ul className="crypt-heading-menu fright">

                                    {/*<li className="crypt-box-menu menu-red"><a href="register">Regístrate</a></li>*/}

                                </ul>
                            </div>
                            <i className="menu-toggle pe-7s-menu d-xs-block d-sm-block d-md-none d-sm-none"></i>
                        </div>
                    </div>
                </div>
                <div className="crypt-mobile-menu">
                    <ul className="crypt-heading-menu">
                        <li className="crypt-box-menu menu-green"><a href="">Regístrate</a></li>
                        <li className="crypt-box-menu menu-red"><a href="">Iniciar sesión</a></li>

                    </ul>
                    <div className="crypt-gross-market-cap">
                        <h5>$34.795.90 <span className="crypt-up pl-2">+3.435 %</span></h5>
                        <h6>0.7925.90 BTC <span className="crypt-down pl-2">+7.435 %</span></h6></div>
                </div>
            </header>
            <div className='fade-in'>
                <div className="overlay2">
                    <div className="winContainer-nopos">
                        <div className="borderContent">
                            <div className="innerShadow2 condiciones">
                                <p className="condiciones-titulo"><b>POLÍTICA EN MATERIA DE PROTECCIÓN DE DATOS PARA SERVICIOS DE CAPACITACIÓN Y EVENTOS</b></p>
                                <p>
                                    La Bolsa de Valores de Quito (En adelante BVQ), con el objetivo de aplicar las
                                    disposiciones, políticas y principios contenidos en la Ley de Protección de Datos
                                    Personales (En adelante LODP), en el tratamiento de la información proporcionada por
                                    usuarios de capacitación o eventos, informa:
                                </p>
                                <ul className="custom-list">
                                    <li>La BVQ reconoce sus obligaciones en calidad de responsable del tratamiento de
                                        datos personales contenidas en la LOPD y normativa secundaria en la materia, al
                                        recopilar datos personales directamente o por medio de la Institución solicitante,
                                        previo consentimiento expreso de sus titulares.</li>
                                    <li>El tratamiento de datos personales se sustenta en el consentimiento expreso de
                                        los usuarios, de una misión realizada en interés público y en el ejercicio de
                                        facultades conferidas a la BVQ por la Ley.</li>
                                    <li>Que la BVQ, para el adecuado desarrollo de sus actividades podrá requerir datos
                                        de sus clientes y cualquier otro Usuario que, de manera directa o indirecta, tenga
                                        relación con la BVQ.</li>
                                    <li>Que la BVQ podrá recoger, recopilar, registrar, organizar, estructurar, conservar,
                                        custodiar, modificar, consultar, utilizar, aprovechar, comunicar o transferir los datos
                                        personales del Titular, con la finalidad de dar cumplimiento a las obligaciones
                                        legales adquiridas.</li>
                                    <li>Tratará los Datos Personales de los Titulares para los propósitos establecidos en
                                        la regulación societaria y tributaria, en estricto apego a la Ley Orgánica de
                                        Protección de Datos Personales.</li>
                                    <li>La BVQ adoptará mecanismos de seguridad para la protección de datos
                                        personales a los que tenga acceso y por ende sujetarse al principio de seguridad
                                        de datos personales y por lo tanto implementará las medidas necesarias y a su
                                        alcance para asegurar la confidencialidad, integridad, seguridad y disponibilidad de
                                        los datos de su Titular.</li>
                                    <li>La BVQ ha implementado mecanismos de verificación, evaluación y valoración
                                        continua y permanente de la eficiencia, eficacia y efectividad de las medidas de
                                        carácter técnico, organizativo, con el objeto de garantizar y mejorar la seguridad
                                        del tratamiento de datos personales.</li>
                                    <li>Que en el ejercicio de sus derechos puede solicitar a la BVQ el acceso,
                                        rectificación, actualización, eliminación, oposición o suspensión del tratamiento de
                                        sus datos personales de conformidad con la ley vigente.</li>
                                    <li>La información y los medios para recopilar la información de los usuarios de
                                        capacitación, será por medio de: Formularios o fichas de inscripción en Excel;
                                        Base de datos puntopagobvq.com; Correo Electrónico; WhatsApp; Formulario de
                                        registro de asistencias a capacitaciones o eventos; Formularios electrónicos en
                                        aplicativos webs o transferencia de sus datos personales por medio de la
                                        Institución en la que labora o estudia y que haya solicitado los servicios y
                                        productos que ofrece la BVQ.</li>
                                </ul>
                                <p className="condiciones-titulo"><b>DECLARACIONES DEL USUARIO DE CAPACITACIONES O EVENTOS</b></p>
                                <p>
                                    En calidad de Titular de los Datos Personales o de responsable de los datos
                                    personales de las personas naturales para las que se solicitan los servicios ofrecidos
                                    por la BVQ, declara lo siguiente:
                                </p>

                                <ul className="custom-list">
                                    <li>De forma expresa ha consentido en la comunicación de los datos personales a la
                                        BVQ, para sus fines determinados o en su defecto otorga su consentimiento para
                                        el tratamiento de sus datos personales, en caso de ser provistos, para los fines
                                        que la BVQ determine, de conformidad con la Política en la materia; y, reconoce
                                        que dicha manifestación de voluntad es libre, específica, informada e inequívoca.</li>
                                    <li>Que ha consentido para que la BVQ trate bases de datos públicos o privados en
                                        las que se incluya sus Datos Personales, en caso de ser provistos.</li>
                                    <li>Reconoce que la BVQ le ha informado sobre los fines del tratamiento de los datos
                                        personales aquí proporcionados, en aplicación del principio de transparencia; y
                                        sobre los cuales brinda su expresa autorización y que serán utilizados para:
                                        
                                        <ul className="sub-list-interno">
                                            <li>Recopilación de Comunicación de productos y servicios de la BVQ.</li>
                                            <li>Utilización de información para fines estadísticos (elaboración de perfiles) y/o
                                                de investigación.</li>
                                            <li>Recopilación de información en bases o repositorios de datos.</li>
                                            <li>Información promocional respecto a capacitaciones, invitación a eventos
                                                organizados o patrocinados directa o indirectamente por la BVQ; y, demás
                                                fines comerciales acordes con la normativa vigente.</li>
                                            <li>Transferencia de datos a cualquier tercero, incluyendo, pero sin limitarse a
                                                autoridades judiciales, entidades de control, y empresas asociadas o filiales de
                                                BVQ, para el cumplimiento de fines directamente relacionados con las
                                                funciones legítimas de BVQ y del tercero destinatario.</li>
                                            <li>Facturación en el caso de capacitaciones que tienen un costo para el Usuario.</li>
                                            <li>En el caso de información de estudiantes recopilada para el uso de la
                                                herramienta “Juego de Bolsa”, la información únicamente tendrá como fin el
                                                asignar un usuario y contraseña para la utilización del aplicativo tecnológico “el
                                                Juego de Bolsa”.</li>
                                        </ul>
                                    </li>
                                    <li>Reconoce que, en el Titular de sus Datos Personales, podrá hacer efectivos sus
                                        derechos de acceso, eliminación, rectificación y actualización, oposición, anulación
                                        y limitación del tratamiento.</li>
                                    <li>Reconoce que el titular de los datos personales podrá, en cualquier momento y de
                                        forma gratuita, presentar requerimientos, peticiones, quejas o reclamaciones
                                        directamente al Responsable del Tratamiento, a través de la información de
                                        contacto que se encuentra a disposición en el portal web de la BVQ.</li>

                                </ul>
                                <p className="sub-list"><b>BASE LEGAL</b></p>
                                <p>
                                    El usuario de capacitaciones o eventos reconoce que la base legal que sustenta los
                                    procesos de recopilación de datos personales es la siguiente:
                                </p>
                                <p>
                                    En materia de mercado de valores:
                                </p>
                                <ul className="sub-list">
                                    <li>Libro II del Código Orgánico Monetario y Financiero</li>
                                    <li>Reglamento a la Ley de Mercado de Valores</li>
                                    <li>Codificación de Regulaciones de la Junta de Política y Regulación
                                        Financiera</li>
                                    <li>Reglamento General Conjunto de las Bolsas de Valores de Quito y
                                        Guayaquil</li>
                                    <li>Reglamento General de la Bolsa de Valores de Quito</li>
                                </ul>
                                <p>
                                    En materia de prevención de lavado de activos:
                                </p>
                                <ul className="sub-list">
                                    <li>Artículo 1 de la Ley Orgánica Reformatoria de Prevención detección y
                                        erradicación del Lavado de Activos</li>
                                    <li>Artículo 2 del Reglamento Ley de Lavado de Activos Y del Financiamiento
                                        de Delitos</li>
                                    <li>Artículo 2, y 3 de la Resolución No. JPRF-V-2022-024</li>
                                    <li>Artículo 1 de la Resolución SCVS-DNPLA-006-2022</li>

                                </ul>
                                <p>
                                    En materia tributaria para procesos de facturación:
                                </p>
                                <ul className="sub-list">
                                    <li>Ley Orgánica de Régimen Tributario Interno</li>
                                    <li>Reglamento de Comprobantes de Venta, Retención y Complementarios</li>
                                </ul>
                                <p className="sub-list"><b>CONSERVACIÓN</b></p>
                                <p>
                                    La información es conservada para el tratamiento durante el tiempo que requiera el
                                    servicio solicitado y podrá ser almacenada de manera ampliada por hasta 10 años,
                                    previo consentimiento de los titulares, para la promoción de productos, servicios y
                                    archivo estadístico de la BVQ.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CondicionesLegales;