import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Router, Switch, Route} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import 'react-notifications/lib/notifications.css';
import Login from "./components/Login";
import Register from "./components/Register";
import Sala from "./components/Sala";
import Juego from "./components/Juego";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import Header from "./components/general/Header";
import AperturaRueda from "./components/AperturaRueda";
import NoJuego from "./components/errors/NoJuego"
import Recuperar from "./components/Recuperar";
import SeleccionarEvento from "./components/SeleccionarEvento";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";
import ElegirEvento from "./components/ElegirEvento";
import CondicionesLegales from "./components/CondicionesLegales";

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  return (
    <Router history={history}>
      <div className="mainContainer style1">
        <Header></Header>
        <div>
          <Switch>
            <Route exact path={["/", "/home"]} component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/recuperar" component={Recuperar} />
            <Route exact path="/sala" component={Sala} />
            <Route exact path="/juego" component={Juego} />
            <Route exact path="/apertura" component={AperturaRueda} />
            <Route exact path="/nojuegos" component={NoJuego} />
            <Route exact path="/elegirEvento" component={ElegirEvento} />
            <Route exact path="/seleccionarEvento" component={SeleccionarEvento} />
            <Route path="/user" component={BoardUser} />
            <Route path="/mod" component={BoardModerator} />
            <Route path="/admin" component={BoardAdmin} />
            <Route path="/condiciones" component={CondicionesLegales} />
            
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default App;
