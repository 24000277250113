import axios from "axios";
import '../config'
 
//obtener dato empresa
const getDataEmpresaSer = ( empresaId)=>{
  const params = { "strEmpresaId":empresaId}
  return axios 
      .post(global.config.URL_SERVICE + "ObtenerDatoEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}

//obtener Abjudicaciones ObtenerAdjudicacionPag
const getAdjudicacionSer = (eventoId, empresaId, usuarioId,page,filterField,filer,typeOrd,sort)=>{
  const params = {"strEventoId":eventoId, "strEmpresaId":empresaId,"strUsuarioId":usuarioId,"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
      .post(global.config.URL_SERVICE + "ObtenerAdjudicacionEventoEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}


//obtener datos evento ObtenerDatosEvento
const getDataEventSer = (eventoId)=>{
  const params = {"strEventoId":eventoId}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerDatosEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
//fin obtener datos del evento

//perfil usuario 
const updatePerfilUsuario = (usuarioId,nombre,nick,email,clave,image,uploadId) => {
  const params = {"strUsuarioId":usuarioId,"strNombre":nombre,"strNick":nick,"strEmail":email,"strClave":clave,"strImage":image,"strRol":"","strUploadId":uploadId}
    return axios
        .post(global.config.URL_SERVICE + "ActualizarUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
            if(result.length>0){
              return result;  
            }else{
              return null
            }
        });
}
//fin perfil usuario 

//inicia evento empresa
const getEventoEmpresaSer = (eventoId)=>{
  const params = {"strEventoId":eventoId}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerEventoEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const getEventoEmpresaSinAsigSer = (eventoId)=>{
  const params = {"strEventoId":eventoId}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerEventoEmpresaSinAsig", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
const selectEventoEmpresaSer = (eventoId,empresaId,usuarioIdRegistro) => {
  const params = {"strEventoId":eventoId,"strEmpresaId":empresaId,"strUsuarioIdRegistro":usuarioIdRegistro}
  return axios
  .post(global.config.URL_SERVICE + "SeleccionarEventoEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });
}


const deleteEventoEmpresaSer=(eventoEmpresaId)=>{
  const params = {"strEventoEmpresaId":eventoEmpresaId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarEventoEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

//fin evento empresa


// obtener evnto y seleccionar evento para obtener empresa
const getEmpresaConEventoSer = (eventoId,usuarioId,empresaId)=>{
  const params = {"strEventoId":eventoId,"strUsuarioId":usuarioId,"strEmpresaId":empresaId}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerEmpresasConEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const getTodoEventoSer = (usuarioId)=>{
  const params = {"strUsuarioId":usuarioId}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerTodosLosEventos", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

// fin obtener evnto y seleccionar evento para obtener empresa

//parametros 
const deleteParametroSer=(parametroId)=>{
  const params = {"strParametroId":parametroId}
    return axios 
      .post(global.config.URL_SERVICE + "EliminarParametros", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        return result;  
      });
}

const updateParametroSer =(parametroId,codigo,nombre,descripcion,valor,usuarioIdRegistro,estado) =>{
  const params = {"strParametroId":parametroId,"strCodigo":codigo,"strNombre":nombre,"strDescripcion":descripcion,"strValor":valor,"strUsuarioIdRegistro":usuarioIdRegistro,"strEstado":estado}
    return axios 
      .post(global.config.URL_SERVICE + "GestionParametros", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        return result;  
    });
}
const getParametroSer = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
      .post(global.config.URL_SERVICE + "ObtenerParametrosPag", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}

//fin de parametros 

// inicia servicios para  usuario
const deleteUsuario=(usuarioId)=>{
  const params = {"strUsuarioId":usuarioId}
    return axios 
      .post(global.config.URL_SERVICE + "EliminarUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        return result;  
      });
}

const updateUsuario =(usuarioId,nombre,nick,email,rol,clave,estado,uploadId,imagenUrl) =>{
  const params = {"strUsuarioId":usuarioId,"strNombre":nombre,"strNick":nick,"strEmail":email,"strRol":rol,"strClave":clave,"strEstado":estado,"strUploadId":uploadId, "strImagenUrl": imagenUrl}
    return axios 
      .post(global.config.URL_SERVICE + "GestionUsuario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        return result;  
    });
}
const getUsuarios = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
      .post(global.config.URL_SERVICE + "ObtenerUsuariosPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
      return result;  
  });
}
//terminan los servicos par usuario		

//usuarioEmpresa
const getUsuarioEmpresaSer = (empresaId)=>{
  const params = {"strEmpresaId":empresaId}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerUsuarioEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const getUsuarioEmpresaSinAsigSer = (empresaId)=>{
  const params = {"strEmpresaId":empresaId}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerUsuarioEmpresaSinAsig", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
const selectUsuarioEmpresaSer = (empresaId,usuarioId) => {
  const params = {"strEmpresaId":empresaId,"strUsuarioId":usuarioId}
  return axios
  .post(global.config.URL_SERVICE + "SeleccionarUsuarioEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });
}


const deleteUsuarioEmpresaSer=(usuarioEmpresaId)=>{
  const params = {"strUsuarioEmpresaId":usuarioEmpresaId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarUsuarioEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

//fin usuario empresa

//
//evento


const getEventoSer = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerEventoPag", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const updateEventoSer = (eventoId,logoUrl,modalidadId,nombreEvento,logo,estado,usuarioRegistroId,fechaSubasta,inscripcion,subasta,colocacion,adjudicacion,nombreEmpresaCorto,nombreExponente) => {
  const params = {"strEventoId":eventoId, "strlLogoUrl": logoUrl, "strModalidadId":modalidadId,"strNombreEvento":nombreEvento,"strLogo":logo,"strEstado":estado,
                  "strUsuarioRegistroId":usuarioRegistroId,"strFechaSubasta":fechaSubasta,
                  "strPeriodoInscripcion":inscripcion,"strPeriodoSubasta":subasta,
                  "strPeriodoAdjudicacion":adjudicacion,"strPeriodoColocacion":colocacion, 
                  "strNombreEmpresaCorto": nombreEmpresaCorto, "strNombreExponente": nombreExponente}
  return axios
  .post(global.config.URL_SERVICE + "GestionEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });
}

const deleteEventoSer=(eventoId)=>{
  const params = {"strEventoId":eventoId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarEvento", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
//fin evento 

//obtener orden 
const getEventoSelectSer = ()=>{
  const params = {}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerEventoSelect", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const getEmpresaSelectSer = (eventoId)=>{
  const params = {"strEventoId":eventoId}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerEmpresaSelect", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    console.log(response["data"])
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
const getInversionistaSelectSer = (empresaId)=>{
  const params = {"strEmpresaId":empresaId}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerInversionistaSelect", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
const getOrdenSer = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerOrdenPag", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
const getOrdenPorUsuarioSer = (empresaId,eventoId,usuarioId,page,filterField,filer,typeOrd,sort)=>{
  const params = {"strEmpresaId":empresaId,"strEventoId":eventoId,"strUsuarioId":usuarioId,"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerOrdenPorUsuarioPag", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
const updateOrdenSer = (ordenId,empresaId,inversionistaId,accionesComprar,precioPagar,estado,usuarioRegistroId,eventoId) => {
  const params = {"strOrdenId":ordenId,"strEmpresaId":empresaId,"strInversionistaId":inversionistaId,"strAccionesComprar":accionesComprar,"strPrecioPagar":precioPagar,"strEstado":estado,"strUsuarioRegistroId":usuarioRegistroId,"strEventoId":eventoId}
  return axios
  .post(global.config.URL_SERVICE + "GestionOrden", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return result;
    }
  });
}

const deleteOrdenSer=(ordenId)=>{
  const params = {"strOrdenId":ordenId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarOrden", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

/// fin orden


//obtener usuarios
const getUsuariosSer = ()=>{
  const params = {}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerUsuarios", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
//fin obtener usuarios

// inicio obtener institucines
const getInstitucionesEmpresaSer = ()=>{
  const params = {}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerInstituciones", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
//fin obt instituciones


//inicio inversionista
const getInversionistaSer = (usuarioId, empresaId, page,filterField,filer,typeOrd,sort)=>{
  const params = {"intUsuarioId":usuarioId,"intEmpresaId":empresaId,"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerInversionistaPag", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const updateInversionistaSer = (inversionistaId,nombre,apellido,cedula,telefono,direccion,estado,usuarioRegistroId,empresaId, email) => {
  const params = {"strInversionistaId":inversionistaId,"strNombre":nombre,"strApellido":apellido,"strCedula":cedula,"strTelefono":telefono,"strDireccion":direccion,"strEstado":estado,"strUsuarioRegistroId":usuarioRegistroId,"strEmpresaId":empresaId, "strEmail":email}
  return axios
  .post(global.config.URL_SERVICE + "GestionInversionista", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);

    // aqui se cambio
    if(result!==""){
      return result;  
    }else{
      return null
    }
  });
}

const deleteInversionistaSer=(inversionistaId)=>{
  const params = {"strInversionistaId":inversionistaId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarInversionista", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}
//fin inversionista
//inicio empresa 
const getEmpresaSer = (page,filterField,filer,typeOrd,sort)=>{
  const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerEmpresaPag", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const updateEmpresaSer = (empresaId,nombre,logo,direccion,estado,usuarioRegistroId,institucion,email,gerente,operador,receptor,numAccionesOferta,precioInicialPorAccion,valorUnitarioAccion,montoMinMejoraPrecio,comisionOperador,comisionBVQ,PorcentajePrecioTechoMax,fechaConstitucion,fechaInscripcion,capitalPagado,divididoEn,valor) => {
  const params = {"strEmpresaId":empresaId,"strNombre":nombre,"strLogo":logo,"strDireccion":direccion,
                  "strEstado":estado,"strUsuarioRegistroId":usuarioRegistroId,"strInstitucion":institucion,"strEmail":email,
                "strGerente":gerente,"strOperador":operador,"strReceptor":receptor,"strNumAccionesOferta":numAccionesOferta,
                "strPrecioInicialPorAccion":precioInicialPorAccion,"strValorUnitarioAccion":valorUnitarioAccion,
                "strMontoMinMejoraPrecio":montoMinMejoraPrecio,"strComisionOperador":comisionOperador,
                "strComisionBVQ":comisionBVQ,"strPorcentajePrecioTechoMax":PorcentajePrecioTechoMax,"strFechaConstitucion":fechaConstitucion,"strFechaInscripcion":fechaInscripcion,
                "strCapitalPagado":capitalPagado,"strDivididoEn":divididoEn,"strValor":valor}
  return axios
  .post(global.config.URL_SERVICE + "GestionEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });
}

const deleteEmpresaSer=(empresaId)=>{
  const params = {"strEmpresaId":empresaId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

//fin empresa

//inicio item catalogos
// item catalogo

const getItemsCatalogosPag = (catalogoId,page,filterField,filer,typeOrd,sort)=>{
  const params = {"strCatalogoId":catalogoId,"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
  return axios 
  .post(global.config.URL_SERVICE + "ObtenerItemCatalogosPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}

const updateItemCatalogo = (itemId,codigo,nombre,activo,editar,itemPadreId,catalogoId,nombreCorto,estado) => {
  const params = {"strItemId":itemId,"strCodigo":codigo,"strNombre":nombre,"strActivo":activo,"strEditar":editar,"strItemPadreId":itemPadreId,"strCatalogoId":catalogoId,"strNombreCorto":nombreCorto,"strEstado":estado}
  return axios
  .post(global.config.URL_SERVICE + "GestionItemsCatalogo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    if(result===0){
      return result;  
    }else{
      return null
    }
  });
}

const deleteItemCatalogo=(itemId)=>{
  const params = {"strItemId":itemId}
  return axios 
  .post(global.config.URL_SERVICE + "EliminarItemCatalogo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
  .then((response) => {
    let itemR = response["data"].split("\\\"").join("\"");          
    const result = JSON.parse(itemR);
    return result;  
  });
}



//fin item catalogos
//catalogos 

const getCatalogosPadre = ()=>{
    const params = {}
    return axios 
    .post(global.config.URL_SERVICE + "ObtenerCatalogosPadre", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      return result;  
    });
  }
  
  const getGestionCatalogosPag = (page,filterField,filer,typeOrd,sort)=>{
    const params = {"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
    return axios 
    .post(global.config.URL_SERVICE + "ObtenerCatalogosPaginacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      return result;  
    });
  }
  
  const updateGestionCatalogo = (catalogoId,codigo,nombre,activo,editar, catalogoPadreId,estado) => {
    const params = {"strCatalogoId":catalogoId,"strCodigo":codigo,"strNombre":nombre,"strActivo":activo,"strEditar":editar,"strCatalogoPadreId":catalogoPadreId,"strEstado":estado}
    return axios
    .post(global.config.URL_SERVICE + "GestionCatalogos", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      if(result===0){
        return result;  
      }else{
        return null
      }
    });
  }
  
  const deleteGestionCatalogo=(catalogoId)=>{
    const params = {"strCatalogoId":catalogoId}
    return axios 
    .post(global.config.URL_SERVICE + "EliminarCatalogo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      return result;  
    });
  }
  //fin de catalogos ObtenerCatalogosPadre


  // agg JP
  const getEmpresaSelectInversionista = (usuarioId,empresaId)=>{
    const params = {"intUsuarioId":usuarioId, "intEmpresaId":empresaId}
    return axios 
    .post(global.config.URL_SERVICE + "ObtenerEmpresaSelectInversionista", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      console.log(result);
      return result;  
    });
  }

  const getDatosAdjudicaciones = (eventoId, usuarioId, empresaId, page,filterField,filer,typeOrd,sort)=>{
    const params = {"intEventoId":eventoId, "intUsuarioId": usuarioId, "intEmpresaId":empresaId ,"strPage":page,"strFilterField":filterField,"strFiler":filer,"strTypeOrd":typeOrd,"strSort":sort}
    return axios 
        .post(global.config.URL_SERVICE + "ObtenerLiquidacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
            let itemR = response["data"].split("\\\"").join("\"");          
            const result = JSON.parse(itemR);
        return result;  
    });
  }

  const updateImpresionLiquidaciones =(liquidacionId)=>{ //ruta,accionesLetras,capitalPagado,capitalPagado1,divididoAcciones,inversionistaNombre,lugarFecha,lugarFecha1, lugarFecha2, nombreEmpresa, nombreEmpresa1, nombreEmpresa2, numeroAcciones, numeroDesde, numeroHasta, numeroTitulo, precioAcciones, precioLetras) =>{
    const params = { "intLiquidacionId": liquidacionId
                    
    }
      return axios 
        .post(global.config.URL_SERVICE + "ImprimirCertificado", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let result = response["data"].split("\\\"").join("\"");   
          //console.log("result:", result);  
          return result;  
      });
  }

  const updateImpresionTitulos =(liquidacionId)=>{ //ruta,accionesLetras,capitalPagado,capitalPagado1,divididoAcciones,inversionistaNombre,lugarFecha,lugarFecha1, lugarFecha2, nombreEmpresa, nombreEmpresa1, nombreEmpresa2, numeroAcciones, numeroDesde, numeroHasta, numeroTitulo, precioAcciones, precioLetras) =>{
    const params = { "intLiquidacionId": liquidacionId
                   
    }
      return axios 
        .post(global.config.URL_SERVICE + "ImprimirTitulo", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let result = response["data"].split("\\\"").join("\"");          
          return result;  
      });
  }

  const updateImpresionReportRueda =(eventoId)=>{ //ruta,accionesLetras,capitalPagado,capitalPagado1,divididoAcciones,inversionistaNombre,lugarFecha,lugarFecha1, lugarFecha2, nombreEmpresa, nombreEmpresa1, nombreEmpresa2, numeroAcciones, numeroDesde, numeroHasta, numeroTitulo, precioAcciones, precioLetras) =>{
    const params = { "intEventoId": eventoId
                   
    }
      return axios 
        .post(global.config.URL_SERVICE + "ImprimirReportUserRueda", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let result = response["data"].split("\\\"").join("\"");          
          return result;  
      });
  }

  const sendEmailInvitacionUser =(eventoId, usuarioId, empresaId)=>{ //ruta,accionesLetras,capitalPagado,capitalPagado1,divididoAcciones,inversionistaNombre,lugarFecha,lugarFecha1, lugarFecha2, nombreEmpresa, nombreEmpresa1, nombreEmpresa2, numeroAcciones, numeroDesde, numeroHasta, numeroTitulo, precioAcciones, precioLetras) =>{
    const params = { "intEventoId": eventoId, "intUsuarioId": usuarioId, "intEmpresaId":empresaId
                   
    }
      return axios 
        .post(global.config.URL_SERVICE + "SendInvitacionEmail", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let result = response["data"].split("\\\"").join("\"");          
          return result;  
      });
  }
// Enviar liquidacion usuario
  const sendLiquidacionUser =(eventoId, liquidacionId)=>{ 
    const params = { "intEventoId": eventoId, "intLiquidacionId": liquidacionId
                   
    }
      return axios 
        .post(global.config.URL_SERVICE + "SendLiquidacionEmail", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let result = response["data"].split("\\\"").join("\"");          
          return result;  
      });
  }

  const updateLiquidacion =(eventoId) =>{
    const params = { "intEventoId":eventoId

    }
      return axios 
        .post(global.config.URL_SERVICE + "GestionLiquidacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
          return result;  
      });
  }

  // catalogos modalidad
  const getCatalogosModalidad = ()=>{
    const params = {}
    return axios 
    .post(global.config.URL_SERVICE + "ObtenerCatalogoModadlidad", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      console.log(response);
      return result;  
    });
  }


  // Enviar liquidacion usuario
  const sendLiquidacionInversionista =(eventoId, liquidacionId)=>{ 
    const params = { "intEventoId": eventoId, "intLiquidacionId": liquidacionId
                   
    }
      return axios 
        .post(global.config.URL_SERVICE + "SendLiquidacionEmailInversionista", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let result = response["data"].split("\\\"").join("\"");          
          return result;  
      });
  }

  // Cobrar liquidacion
  const updateCobrarLiquidacion=(liquidacionId)=>{
    const params = {"strLiquidacionId":liquidacionId}
    return axios 
    .post(global.config.URL_SERVICE + "EstadoCobrarLiquidacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      return result;  
    });
  }

  // Comentario liquidación
  const updateComentarioLiquidacion =(liquidacionId,comentario) =>{
    const params = {"strLiquidacionId":liquidacionId,"strComentario":comentario}
      return axios 
        .post(global.config.URL_SERVICE + "GestionLiquidacionComentario", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let itemR = response["data"].split("\\\"").join("\"");          
          const result = JSON.parse(itemR);
          return result;  
      });
  }

  // Estado iconosos a cobrar liquidacion
  const updateImprimirCertificado =(liquidacionId)=>{
    const params = {"strLiquidacionId":liquidacionId}
    return axios 
    .post(global.config.URL_SERVICE + "EstadoCertiImpreso", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      return result;  
    });
  }

  const updateImprimirLiquidacion =(liquidacionId)=>{
    const params = {"strLiquidacionId":liquidacionId}
    return axios 
    .post(global.config.URL_SERVICE + "EstadoLiquiImpreso", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      return result;  
    });
  }

  const updateEstadoEmailEmpresa =(liquidacionId)=>{
    const params = {"strLiquidacionId":liquidacionId}
    return axios 
    .post(global.config.URL_SERVICE + "EstadoEmailLiquiEmpresa", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      return result;  
    });
  }

  const updateEstadoEmailInversionista =(liquidacionId)=>{
    const params = {"strLiquidacionId":liquidacionId}
    return axios 
    .post(global.config.URL_SERVICE + "EstadoEmailLiquiInver", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
    .then((response) => {
      let itemR = response["data"].split("\\\"").join("\"");          
      const result = JSON.parse(itemR);
      return result;  
    });
  }

  const updateImpresionReportLiquidacion =(eventoId)=>{ //ruta,accionesLetras,capitalPagado,capitalPagado1,divididoAcciones,inversionistaNombre,lugarFecha,lugarFecha1, lugarFecha2, nombreEmpresa, nombreEmpresa1, nombreEmpresa2, numeroAcciones, numeroDesde, numeroHasta, numeroTitulo, precioAcciones, precioLetras) =>{
    const params = { "intEventoId": eventoId
                   
    }
      return axios 
        .post(global.config.URL_SERVICE + "ImprimirReportLiquidacion", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let result = response["data"].split("\\\"").join("\"");          
          return result;  
      });
  }

  const updateImpresionReportAdjudicacion =(eventoId)=>{ //ruta,accionesLetras,capitalPagado,capitalPagado1,divididoAcciones,inversionistaNombre,lugarFecha,lugarFecha1, lugarFecha2, nombreEmpresa, nombreEmpresa1, nombreEmpresa2, numeroAcciones, numeroDesde, numeroHasta, numeroTitulo, precioAcciones, precioLetras) =>{
    const params = { "intEventoId": eventoId
                   
    }
      return axios 
        .post(global.config.URL_SERVICE + "ImprimirReportAdjudicacionT", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
        .then((response) => {
          let result = response["data"].split("\\\"").join("\"");          
          return result;  
      });
  }
  


export default {
    getCatalogosPadre,
    getGestionCatalogosPag,
    updateGestionCatalogo,
    deleteGestionCatalogo,
    getItemsCatalogosPag,
    updateItemCatalogo,
    deleteItemCatalogo,
    getEmpresaSer,
    updateEmpresaSer,
    deleteEmpresaSer,
    getInversionistaSer,
    updateInversionistaSer,
    deleteInversionistaSer,
    getInstitucionesEmpresaSer,
    getUsuariosSer,
    getOrdenSer,
    getOrdenPorUsuarioSer,
    updateOrdenSer,
    deleteOrdenSer,
    getInversionistaSelectSer,
    getEmpresaSelectSer,
    getEventoSer,
    updateEventoSer,
    deleteEventoSer,
    getUsuarioEmpresaSer,
    getUsuarioEmpresaSinAsigSer,
    selectUsuarioEmpresaSer,
    deleteUsuarioEmpresaSer,
    getUsuarios,
    updateUsuario,
    deleteUsuario,
    getEventoSelectSer,
    deleteParametroSer,
    deleteParametroSer,
		updateParametroSer,
		getParametroSer,
    getTodoEventoSer,
    getEmpresaConEventoSer,
    deleteEventoEmpresaSer,
    selectEventoEmpresaSer,
    getEventoEmpresaSinAsigSer,
    getEventoEmpresaSer,
    updatePerfilUsuario,
    getDataEventSer,
    getAdjudicacionSer,
    getDataEmpresaSer,

    // agg
    getEmpresaSelectInversionista,
    getDatosAdjudicaciones,
    updateImpresionLiquidaciones,
    updateImpresionTitulos,
    updateImpresionReportRueda,
    sendEmailInvitacionUser,
    sendLiquidacionUser,
    updateLiquidacion,
    getCatalogosModalidad,
    sendLiquidacionInversionista,
    updateCobrarLiquidacion,
    updateComentarioLiquidacion,
    updateImprimirCertificado,
    updateImprimirLiquidacion,
    updateEstadoEmailEmpresa,
    updateEstadoEmailInversionista,
    updateImpresionReportLiquidacion,
    updateImpresionReportAdjudicacion
};