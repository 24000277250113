import {
  GET_EVENT_BY_USER,
  GET_EVENT_TITLE_BY_USER,
  GET_EVENT_BY_USER_FAIL,
  GET_EVENT_BY_USER_INIT,
  UPDATE_STATUS_BY_KEY,
    SET_MESSAGE,
    UPDATE_EVENTO_OBJECT
} from "./types";

import BannerService from "../services/juego.service";

export const getTitulosEventoById = (eventId) => (dispatch) => {
  dispatch({
    type: GET_EVENT_BY_USER_INIT,
  });
  return BannerService.getTitulosEventoById(eventId).then(
  (data) => {
    if(data!=null){
      dispatch({ 
        type: GET_EVENT_TITLE_BY_USER,
        payload: { data: data },
      });  
      return Promise.resolve();
    }else{
      dispatch({
        type: GET_EVENT_BY_USER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: "Error al obtener titulos",
      });
      return Promise.reject();
    }          
  },
  (error) => {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    dispatch({
      type: GET_EVENT_BY_USER_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  }
  );  
};
export const getEventosPorUsuario = (userId) => (dispatch) => {
    dispatch({
        type: GET_EVENT_BY_USER_INIT,
      });
    return BannerService.getEventosPorUsuario(userId).then(
      (data) => {
        if(data!=null){
          dispatch({ 
            type: GET_EVENT_BY_USER,
            payload: { data: data },
          });  
          return Promise.resolve();
        }else{
          dispatch({
            type: GET_EVENT_BY_USER_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: "Error al obtener banners",
          });
          return Promise.reject();
        }          
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: GET_EVENT_BY_USER_FAIL,
        });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  };

  // WS
  export const setStatusByKey = (key,value) => (dispatch) => {
    dispatch({ 
      type: UPDATE_STATUS_BY_KEY,
      payload: { key:key,value:value },
    });  
    return Promise.resolve();
  };
  export const setEventoObject = (value) => (dispatch) => {
    dispatch({ 
      type: UPDATE_EVENTO_OBJECT,
      payload: { value:value },
    });  
    return Promise.resolve();
  };