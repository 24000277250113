import axios from "axios";
import authHeader from "./auth-header";

require('dotenv').config()
 

const API_URL = process.env.URL_SERVICE;// "http://localhost:8080/api/test/";

// recuperar clave getPassword
const getPassword =(email) =>{
  const params = {"strEmail":email}
    return axios 
      .post(global.config.URL_SERVICE + "RecuperarClave", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        return result;  
    });
}

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getPassword
};