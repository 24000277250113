import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import banner from "./banner";
import juego from "./juego";
import user from "./user";
import controlJuego from "./controlJuego"; // agregado
import administracion from "./administracion"
export default combineReducers({
  auth,
  message,
  banner,
  juego,
  user,
  administracion,
  controlJuego
});
