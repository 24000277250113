import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import DataGrid, { Column } from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import "react-datepicker/dist/react-datepicker.css";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { formatDateTime, formatNumber, generateMessageAdm } from "./Util";
import {getOrdenAct,getOrdenPorUsuarioAct,deleteOrdenAct} from "../../actions/administracion";
import "../../config";
import { NotificationContainer } from "react-notifications";

const GestionOrden = (props) => {
  
  const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const {listaOrdenes,listaOrdenesPorUsuario,loadingOrden} = useSelector((state) => state.administracion); 
    const {eventoSeleccionado: eventoSeleccionado} = useSelector((state) => state.juego); 
    const usuario = useState(props.usuarioId)
    let [loadOrden, setLoadOrden] = useState(false) 
    if(loadOrden===false && listaOrdenesPorUsuario===null && eventoSeleccionado!==null){
        setLoadOrden(true)
        dispatch(getOrdenPorUsuarioAct(currentUser["EmpresaId"],eventoSeleccionado,currentUser["userId"],"0","","","ASC",""))
        .then(() => {
        })
        .catch(() => {
        });
        
    }

    let [empresaFilter, setEmpresaFilter] = useState("");   
    let [gridInfor, setGridInfor] = useState([listaOrdenesPorUsuario!==null?listaOrdenesPorUsuario:[]]);     

    if(listaOrdenesPorUsuario!==null && listaOrdenesPorUsuario!=gridInfor && empresaFilter==="")
        setGridInfor(listaOrdenesPorUsuario)
    
    function filterCatalogo(nego,empresaFilter){
        //value => 
        setGridInfor(nego.filter(function(item) {
            if (item["Empresa"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()) || 
                item["Inversionista"].toLowerCase().includes(empresaFilter.toLocaleLowerCase())
                )
                return true;
            return false;
        }))
    }

    const renderButtonObservaciones= (value) => {
      //console.log(value.data["Estado"])
      return (
        <>
        {value.data["Estado"]==="A"?
          <button 
            className="fas fa-check"//fas fa-clock
            onClick={
              (e) => {
               // handleClickCertificado(e)
                //setIsLoading(true)
              }
  
            }></button>
            :
            <button 
            className="fas fa-times"//fas fa-clock
            onClick={
              (e) => {
               // handleClickCertificado(e)
                //setIsLoading(true)
              }
  
            }></button>}
        </>
      )
    }
    
    let columns = [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: "Index", header: "ORDEN", defaultFlex: 1, xdefaultLocked: "end",className:"col", className:"numDataGrid", headerAlign: "center" },
        { name: "Empresa", header: "EMPRESA",defaultFlex: 1, xdefaultLocked: "end",className:"col", headerAlign: "center" },       
        { name: "AccionesComprar", header: "#ACC", defaultFlex: 1, xdefaultLocked: "start",className:"col", className:"numDataGrid" , headerAlign: "center"},
        { name: "PrecioPagar", header: "PRECIO", defaultFlex: 1, xdefaultLocked: "end",className:"col", className:"numDataGrid",  render: ({ value }) => formatNumber(value), headerAlign: "center" },
        /*{ name: "Inversionista", header: "INVERSIONISTA",defaultFlex: 1, xdefaultLocked: "end",className:"col", defaultWidth: 100, headerAlign: "center" },   */ 
        { name: "Estado", header: "ESTADO", defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonObservaciones(rowData) , headerAlign: "center"},
    ];


    const [gridStyle,setGridStyle] = useState({ minHeight: "calc(100vh/2 - 140px)", height: "100%" });
    const renderRowContextMenu = useCallback((menuProps, { rowProps } ) => {
      menuProps.autoDismiss = true
      menuProps.items = [
       
      ]
  }, [])  

  const updateFiltro = (text) =>{
    setEmpresaFilter(text);
    filterCatalogo(listaOrdenesPorUsuario!==null?listaOrdenesPorUsuario:[],text)
  }

  useEffect(() => {
    if(props.empresaFilterGen!=""){
      setEmpresaFilter(props.empresaFilterGen)
      updateFiltro(props.empresaFilterGen)
    }
      
  },[props.empresaFilterGen]);

  useEffect(() => {
    if(props.maximizeRegis)
      setGridStyle({ minHeight: "100%", height: "calc(100vh - 225px)" })
    else
      setGridStyle({ minHeight: "calc(100vh/2 - 140px)", height: "100%" })
  }, [props.maximizeRegis])  

  useEffect(() => {
    if (listaOrdenesPorUsuario !== null){
      if(empresaFilter!=""){
        updateFiltro(props.empresaFilterGen)
      }
      
    }
  }, [listaOrdenesPorUsuario]) 




  return (
    <div>
      <h6 className="crypt-bg-head">ÓRDENES REGISTRADAS<div className='floatright'><div className="filterNeg"><input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
            updateFiltro(e.target.value)            
        }}/> <i className="fas fa-search"></i><i className={["fas",!props.maximizeOrn?"fa-window-maximize":"fa-window-restore"].join(' ')} onClick={()=> props.setMaximizeRegis(!props.maximizeRegis)}></i></div></div></h6>
      <div className="tab-content">
        <DataGrid
          idProperty="OrdenId"
          style={gridStyle}
          columns={columns}
          enableSelection={true}
          selection={{ mode: "single" }}
          dataSource={gridInfor}
         // renderRowContextMenu={props.isDisabled==false? renderRowContextMenu:""}
          theme="amber-dark"
        />
        <div className="no-orders text-center"></div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionOrden);
