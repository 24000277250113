import {
  GET_EVENT_BY_USER,
  GET_EVENT_TITLE_BY_USER,
  GET_EVENT_BY_USER_FAIL,
  GET_EVENT_BY_USER_INIT,
  UPDATE_STATUS_BY_KEY,
  EVENT_SELECT,
  EMPRESA_SELECT,
  INSCRIPCION,
  COLOCACION,
  SUBASTA,
  ADJUDICACION,

} from "../actions/types";

const eventos = JSON.parse(localStorage.getItem("eventos"));
const titulosRVCount = localStorage.getItem("titulosRVCount");
const eventoSeleccionado = localStorage.getItem("eventoSeleccionado");
const empresaSeleccionada = localStorage.getItem("empresaSeleccionada")
const pInscripcion = localStorage.getItem("pInscripcion")
const pColocacion = localStorage.getItem("pColocacion")
const pSubasta = localStorage.getItem("pSubasta")
const pAdjudicaion = localStorage.getItem("pAdjudicaion")
const initialState = {
  listEventos: eventos !== null ? eventos : null,
  listTitleEvent: null,
  eventoSeleccionado: eventoSeleccionado,
  empresaSeleccionada: null,
  pInscripcion: null,
  pColocacion: null,
  pSubasta: null,
  pAdjudicaion: null,
  loadingEventosPorUsr: true,
  titulosRVCount: titulosRVCount !== null ? parseInt(titulosRVCount) : 0,

  //WS
  showLablesTime: false,
  allTime: -1,
  timeLastPlay: -1,
  loadingAccionEvt: false,
  modalidadCodigo: "",
  loadingAccionEvt: false,
  segundosApertura: -1,
  segundosSubasta: -1,
  segundosAdjudicacion: -1,
  
}

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_STATUS_BY_KEY:
      switch (payload.key) {

        case 'eventoSeleccionado':
          localStorage.setItem("eventoSeleccionado", payload.value);
          return {
            ...state,
            eventoSeleccionado: payload.value
          };
        case 'empresaSeleccionada':
          localStorage.setItem("empresaSeleccionada", payload.value);
          return {
            ...state,
            empresaSeleccionada: payload.value
          };
        case 'pInscripcion':
          localStorage.setItem("pInscripcion", payload.value);
          return {
            ...state,
            pInscripcion: payload.value
          };
        case 'pColocacion':
          localStorage.setItem("pColocacion", payload.value);
          return {
            ...state,
            pColocacion: payload.value
          };
        case 'pSubasta':
          localStorage.setItem("pSubasta", payload.value);
          return {
            ...state,
            pSubasta: payload.value
          };
        case 'pAdjudicaion':
          localStorage.setItem("pAdjudicaion", payload.value);
          return {
            ...state,
            pAdjudicaion: payload.value
          };

        // WS
        case 'showLablesTime':
          return {
            ...state,
            showLablesTime: payload.value,
          };
        case 'allTime':
          return {
            ...state,
            allTime: payload.value
          };
        case 'timeLastPlay':
          return {
            ...state,
            timeLastPlay: payload.value
          };
        case 'modalidadCodigo':
          return {
            ...state,
            modalidadCodigo: payload.value
          };
        case 'loadingAccionEvt':
          return {
            ...state,
            loadingAccionEvt: payload.value
          };
          case 'segundosApertura':
          return {
            ...state,
            segundosApertura: payload.value
          };
          case 'segundosSubasta':
          return {
            ...state,
            segundosSubasta: payload.value
          };
          case 'segundosAdjudicacion':
          return {
            ...state,
            segundosAdjudicacion: payload.value
          };
      }
      break
    case GET_EVENT_BY_USER:
      return {
        ...state,
        loadingEventosPorUsr: false,
        listEventos: payload.data
      };
    case GET_EVENT_TITLE_BY_USER:
      return {
        ...state,
        loadingEventosPorUsr: false,
        listTitleEvent: payload.data,
        titulosRVCount: payload.data.filter(value => value["TipoRentaCod"] === 'RV').length,
      };
    case GET_EVENT_BY_USER_INIT:
      return {
        ...state,
        loadingEventosPorUsr: true
      };
    case GET_EVENT_BY_USER_FAIL:
      return {
        ...state,
        loadingEventosPorUsr: false,
        listEventos: [],
      };
    case EVENT_SELECT:
      return {
        ...state,
        loadingEventosPorUsr: false,
        eventoSeleccionado: payload.data,
      };
    case EMPRESA_SELECT:
      return {
        ...state,
        empresaSeleccionada: payload.data,
      }
    case INSCRIPCION:
      return {
        ...state,
        pInscripcion: payload.data,
      }
    case COLOCACION:
      return {
        ...state,
        pColocacion: payload.data,
      }
    case SUBASTA:
      return {
        ...state,
        pSubasta: payload.data,
      }
    case ADJUDICACION:
      return {
        ...state,
        pAdjudicaion: payload.data,
      }

    default:
      return state;
  }
}
