import React, { useState } from 'react';
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu
} from '@szhsin/react-menu';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


const UsuarioActivo = (props) => {

    //console.log("usuario recibidos", props.userList)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    function closeBuscarUsuario() {
        props.closeUsuarioActivoClick()
    }


    const [showButton, setShowButton] = useState(false);
    let [userFilter, setUserFilter] = useState("");
    //let [showUserList, setShowUserList] = useState(windowDimensions.width > 650 ? true : false);
    let [showUserList, setShowUserList] = useState(false);
    const [globalValue, setGlobalValue] = React.useState("Some initial value");

    function hideShowUsers() {
        setShowUserList(!showUserList)
    }
    // setShowButton(true)
    return (
        <div className={""}>

        

            <div className="panel">
                

            <div className={["pasoUserRow", "userRow", props.enableUserList === false ? "displaynone" : showUserList === false ? "minimizeUserList" : ""].join(' ')}>
                <div className="userRowBtn" onClick={hideShowUsers}><i className="far fa-user"></i></div>
                <div className="innerShadow3">
                    <h3>USUARIOS CONECTADOS</h3>
                    <div className="userConectedTitle">Usuarios conectados: {props.userList !== null && props.userList !== undefined ? props.userList.length : "0"}</div>
                    <div className="searchUser"><input type="text" placeholder="Buscar" value={userFilter} onChange={(e) => {
                        setUserFilter(e.target.value);
                    }} />
                        <div className={["userListSidebar", showUserList === false ? "overflow-hidden" : ""].join(' ')}>
                        <ul>
                                {
                                    props.userList != null ? props.userList.filter(
                                        value => value["d2p1:Value"][0]["strNick"][0].toLowerCase().includes(
                                            userFilter.toLocaleLowerCase())).map((item, i) => {
                                                return <li key={item["d2p1:Key"][0]}>
                                                    <div className="userContTxt">
                                                        <div className="userNameList" title={item["d2p1:Value"][0]["strPrefix"][0] + " - " + item["d2p1:Value"][0]["strNick"][0]}>
                                                            <span>{item["d2p1:Value"][0]["strPrefix"][0]}-</span>{item["d2p1:Value"][0]["strNick"][0]}</div>
                                                        <div className="userLastPlay">Ingreso</div>
                                                    </div>
                                                    <div className="pasoOpcionesUsr userArrowRight"></div>
                                                </li>
                                            }) : ""
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </div>



                {/*<div className="innerShadow-height-100">
                    <div className="textCenter">
                        <h2>
                            Usuarios conectados
                            <div className="subItemCatalogo"></div>
                        </h2>
                        <div className="userConectedTitle">Usuarios conectados: {props.userList !== null && props.userList !== undefined ? props.userList.length : "0"}</div>
                        <div className="searchUser"><input type="text" placeholder="Buscar" value={userFilter} onChange={(e) => {
                            setUserFilter(e.target.value);
                        }} />
                            <i className="fas fa-search"></i>
                        </div>
                        <div className={"userListSidebar"}>
                            <ul>
                                {
                                    props.userList != null ? props.userList.filter(
                                        value => value["d2p1:Value"][0]["strNick"][0].toLowerCase().includes(
                                            userFilter.toLocaleLowerCase())).map((item, i) => {
                                                return <li key={item["d2p1:Key"][0]}>
                                                    <div className="userContTxt">
                                                        <div className="userNameList" title={item["d2p1:Value"][0]["strPrefix"][0] + " - " + item["d2p1:Value"][0]["strNick"][0]}>
                                                            <span>{item["d2p1:Value"][0]["strPrefix"][0]}-</span>{item["d2p1:Value"][0]["strNick"][0]}</div>
                                                        <div className="userLastPlay">Ingreso</div>
                                                    </div>
                                                    <div className="pasoOpcionesUsr userArrowRight"></div>
                                                </li>
                                            }) : ""
                                }
                            </ul>
                            </div>
                    </div>
                    <div>

                    </div>
                </div>*/}
            </div>

            


            </div>

    )
}

export default UsuarioActivo