import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import DataGrid, { Column } from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import "react-datepicker/dist/react-datepicker.css";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { enableFuncionalityForRole, formatDateTime, formatNumber } from "./Util";
import { getAdjudicacionAct } from "../../actions/administracion";
import "../../config";
import { NotificationContainer } from "react-notifications";

const Adjudicacion = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { listaAdjudicacion } = useSelector((state) => state.administracion);
  let [empresaSeleccionada, setEmpresaSeleccionada] = useState(props.selected)
  let [loadAdjudicacion, setLoadAdjudicacion] = useState(false)
  let [listaAdjudicacionEmpresa,setlistaAdjudicacionEmpresa] = useState(null)
  //console.log(props.selected)
  

  if (loadAdjudicacion === false && props.eventoId !== null) {

    setLoadAdjudicacion(true)
    if(props.selected>0){
      
      dispatch(getAdjudicacionAct(props.eventoId, props.selected, props.usuarioId, "0", "", "", "ASC", ""))

    }else{
      //console.log("e")
      dispatch(getAdjudicacionAct(props.eventoId, props.empresaId, props.usuarioId, "0", "", "", "ASC", ""))

    }

    
    
  
  }
  if(listaAdjudicacionEmpresa== null){
    setlistaAdjudicacionEmpresa(listaAdjudicacion!=null?listaAdjudicacion.filter(x=> x.EmpresaId===empresaSeleccionada[0]):[[],{"Count": 0}])
  }
  //console.log(listaAdjudicacion)

  let [userFuntion] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "USER_ORDEN")
      : false
  );

 const filtro = listaAdjudicacion!==undefined && listaAdjudicacion !== null ? listaAdjudicacion.filter(empresa => empresa.EmpresaId == empresaSeleccionada):[[],{"Count": 0}]
  //console.log(filtro)

  let [empresaFilter, setEmpresaFilter] = useState(props.empresaFilterGen);
  //console.log("listaAdjudicacion",listaAdjudicacion)
  let [gridInfor, setGridInfor] = useState([listaAdjudicacion!==undefined && listaAdjudicacion !== null ? listaAdjudicacion : [[],{"Count": 0}]]);

  if (listaAdjudicacion !== null && listaAdjudicacion != gridInfor && empresaFilter === "") {
    setGridInfor(listaAdjudicacion)

  }


  function filterCatalogo(nego, empresaFilter) {

    setGridInfor(nego.filter(function (item) {
      if (item["NombreInversionista"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()) || item["NombreEmpresa"].toLowerCase().includes(empresaFilter.toLocaleLowerCase())
      )
        return true;
      return false;
    }))
  }


 /* let columns = [
    /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },
    { name: "NombreEmpresa", header: "EMPRESA", defaultFlex: 1, xdefaultLocked: "end", className: "col", headerAlign: "center"},
    { name: "NumAcciones", header: "# ACC", defaultFlex: 1, xdefaultLocked: "end", className: "col", className:"numDataGrid", headerAlign: "center" },
    { name: "Precio", header: "PRECIO", defaultFlex: 1, xdefaultLocked: "end", className: "col", className:"numDataGrid",  render: ({ value }) => formatNumber(value), headerAlign: "center"},
    { name: "Colegio", header: "INSTITUCIÓN", defaultFlex: 1, xdefaultLocked: "end", className: "col", headerAlign: "center" },
    //{ name: "NombreInversionista", header: "INVERSIONISTA", defaultFlex: 1, xdefaultLocked: "end", className: "col", headerAlign: "center" },

  ];*/
  
  let columns = userFuntion === true ? [

    { name: "NombreEmpresa", header: "EMPRESA", defaultFlex: 1, xdefaultLocked: "end", className: "col", headerAlign: "center"},
    { name: "NumAcciones", header: "# ACC", defaultFlex: 1, xdefaultLocked: "end", className: "col", className:"numDataGrid", headerAlign: "center" },
    { name: "Precio", header: "PRECIO", defaultFlex: 1, xdefaultLocked: "end", className: "col", className:"numDataGrid",  render: ({ value }) => formatNumber(value), headerAlign: "center"},
    { name: "NombreInversionista", header: "INVERSIONISTA", defaultFlex: 1, xdefaultLocked: "end", className: "col", headerAlign: "center" },

  ] : 
    [
    { name: "NombreEmpresa", header: "EMPRESA", defaultFlex: 1, xdefaultLocked: "end", className: "col", headerAlign: "center"},
    { name: "NumAcciones", header: "# ACC", defaultFlex: 1, xdefaultLocked: "end", className: "col", className:"numDataGrid", headerAlign: "center" },
    { name: "Precio", header: "PRECIO", defaultFlex: 1, xdefaultLocked: "end", className: "col", className:"numDataGrid",  render: ({ value }) => formatNumber(value), headerAlign: "center"},
    { name: "Colegio", header: "INSTITUCIÓN", defaultFlex: 1, xdefaultLocked: "end", className: "col", headerAlign: "center" },
    ]

  const [gridStyle,setGridStyle] = useState({ minHeight: "calc(100vh/2 - 100px)", height: "100%" });
  const renderRowContextMenu = useCallback((menuProps, { rowProps }) => {
    menuProps.autoDismiss = true;
  }, []);

  const filterEmpresa = (text) =>{
    setEmpresaFilter(text);
    filterCatalogo(listaAdjudicacion !== null ? listaAdjudicacion : [], text)
  }

  useEffect(() => {
    if(props.empresaFilterGen!=""){
      setEmpresaFilter(props.empresaFilterGen)
      filterEmpresa(props.empresaFilterGen)
    }
      
  },[props.empresaFilterGen]);

  useEffect(() => {
    if(props.maximizeAdj)
      setGridStyle({ minHeight: "100%", height: "calc(100vh - 215px)" })
    else
      setGridStyle({ minHeight: "calc(100vh/2 - 140px)", height: "100%" })
  }, [props.maximizeAdj])  

  useEffect(() => {
    if (listaAdjudicacion !== null){
      if(empresaFilter!=""){
        filterEmpresa(props.empresaFilterGen)
      }
      
    }
  }, [listaAdjudicacion]) 




  return (
    <div>
      <h6 className="crypt-bg-head">ADJUDICACIÓN
        <div className='floatright'>
          <div className="filterNeg">
            <input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
              filterEmpresa(e.target.value)              
            }} /> <i className="fas fa-search"></i><i className={["fas",!props.maximizeAdj?"fa-window-maximize":"fa-window-restore"].join(' ')} onClick={()=> props.setMaximizeAdj(!props.maximizeAdj)}></i>
          </div>
        </div>
      </h6>
      <div className="tab-content">
        <DataGrid
          idProperty="AdjudicacionId"
          style={gridStyle}

          columns={columns}
          
          selection={{ mode: "single" }}
          dataSource={gridInfor}
          renderRowContextMenu={renderRowContextMenu}
          theme="amber-dark"
        />
        <div className="no-orders text-center"></div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Adjudicacion);
