import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import "@szhsin/react-menu/dist/index.css";
import { enableFuncionalityForRole, formatNumber } from "./Util";
import NumberFormat from 'react-number-format';
import { updateOrdenAct, getEmpresaSelectAct, getInversionistaSelectAct, getEventoSelectAct, getDataEmpresaAct, getEmpresaConEventoAct } from "../../actions/administracion";
import "../../config";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { generateMessageAdm } from "../../components/general/Util"
import AgregarInversionista from "./AgregarInversionista";

const EditarOrden = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { eventoSeleccionado: eventoSeleccionado } = useSelector((state) => state.juego);
  //console.log(eventoSeleccionado)

  const { datosEmpresa, listaAdjudicacion } = useSelector((state) => state.administracion);
  const dispatch = useDispatch();
  let [errors, setErrors] = useState({ "empresaErr": "" }, { "numAccionesErr": "" }, { "inversionistaErr": "" }, { "precioErr": "" });
  let [accionesComprar, setAccionesComprar] = useState(null);
  let [loadDatosEmpresa, setLoadDatosEmpresa] = useState(false);
  let [precio, setPrecio] = useState(null);
  let [isError, setIsError] = useState(false);
  let [openEditInversionista, setOpenEditInversionista] = useState("");
  let [fechaInscripcion, setFechaInscripcion] = useState(null);
  let [nuevoInversionistaOpen, setNuevoInversionistaOpen] = useState("")
  let [loadEmpresa, setLoadEmpresa] = useState(false)
  let [loadInversionista, setLoadInversionista] = useState(false)
  const { listaEmpresaConEvento, listaEmpresaSelect, listaInversionistaSelect } = useSelector((state) => state.administracion);
  const [isActiveButton, setIsActiveButton] = useState(false)
  const [clicked, setClicked] = useState(false);

  //const [sumaAcciones, setSumaAcciones] = useState(0);

  if (listaEmpresaConEvento === null && loadEmpresa === false && eventoSeleccionado !== null) {
    setLoadEmpresa(true)
    dispatch(getEmpresaSelectAct(eventoSeleccionado))
      .then(() => {
      })
      .catch(() => {
      });

    //agg
    //dispatch(getEmpresaConEventoAct(eventoSeleccionado, currentUser["userId"], currentUser["EmpresaId"]))
    //.then(() => { })
    //.catch(() => { });
    //hasta aqui

  }

  //console.log(listaEmpresaConEvento)


  let [precioUltimo, setPrecioUltimo] = useState(null);
  let [list, setList] = useState([]);

  let [inversionista, setInversionista] = useState(null)
  let [inversionistaSeleccionado, setInversionistaSeleccionado] = useState(listaInversionistaSelect !== null ? listaInversionistaSelect.filter(x => x.value === inversionista)[0] : {})

  //let [isDisabled] = useState(props.isDisabled);
  //console.log("RECIBIENDO", props.isDisabled)
  let [admFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_ADM")
      : false
  );
  let [userFuntion] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "USER_ORDEN")
      : false
  );

  let [userCajaFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_CAJA")
      : false
  );

  let [userLiquidacionFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_LIQUI")
      : false
  );
  if (fechaInscripcion === null) {
    setFechaInscripcion(new Date())
  }
  let cambioEstado = useState(null)
  cambioEstado = { value: 'A', label: "Activo" }

  let [estadoSelect, setEstadoSelect] = useState(cambioEstado);
  const optionsEstado = [
    { value: 'I', label: 'Inactivo' },
    { value: 'A', label: 'Activo' },
  ]





  let [empresa, setEmpresa] = useState(null)
  let [boolListEmpresaEvento, setboolListEmpresaEvento] = useState(false)


  if (listaEmpresaConEvento !== null && listaEmpresaConEvento !== undefined && boolListEmpresaEvento == false && userFuntion == true) {
    setboolListEmpresaEvento(true)
    listaEmpresaConEvento['emp2'].forEach((empresa) => {
      setEmpresa({ value: empresa.value, label: empresa.label })
      dispatch(getInversionistaSelectAct(empresa.value))
        .then(() => {
        })
        .catch(() => {
        });
    })
  }
  if (empresa === null) {
    setEmpresa({ value: '0', label: "Ninguno" })
  }

  if (inversionista === null) {
    setInversionista({ value: '0', label: "Ninguno" })
  }

  const openNewInversionista = () => { setInversionistaSeleccionado(null); setNuevoInversionistaOpen("OPEN") };
  const closeEditInversionista = () => { setNuevoInversionistaOpen("") };



  function actualizar() {
    if (!clicked) {
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
      }, 2000);
      let valid = true

      if (empresa["value"] === '0') {
        //errors["empresaErr"] = "Ingrese empresa"
        NotificationManager.warning(
          "Ingrese empresa ",
          "Sistema",
          3000,
          () => { },
          4
        );
        return
      }

      if (inversionista["value"] === '0') {
        //errors["inversionistaErr"] = "Ingrese inversionista"
        NotificationManager.warning(
          "Ingrese inversionista ",
          "Sistema",
          3000,
          () => { },
          4
        );
        return
      }

      if (accionesComprar === null) {
        //errors["numAccionesErr"] = "Ingrese acciones"
        NotificationManager.warning(
          "Ingrese acciones ",
          "Sistema",
          3000,
          () => { },
          4
        );
        return
      }
      if (precio === null) {
        //errors["precioErr"] = "Ingrese precio"
        NotificationManager.warning(
          "Ingrese precio ",
          "Sistema",
          3000,
          () => { },
          4
        );
        return
      }

      // validacion acciones 
      if (datosEmpresa !== null && parseFloat(accionesComprar) > parseFloat(datosEmpresa[0]["NumAccionesOferta"])) {
        NotificationManager.warning(
          "No puede exceder el número de acciones ofertadas",
          "Sistema",
          4000,
          () => { },
          4
        );
        return
      }

      if (parseFloat(accionesComprar) == "") {
        NotificationManager.warning(
          "Ingrese número de acciones",
          "Sistema",
          4000,
          () => { },
          4
        );
        return
      }

      if (parseFloat(accionesComprar) == 0) {
        NotificationManager.warning(
          "El número de acciones no puede ser 0",
          "Sistema",
          4000,
          () => { },
          4
        );
        return
      }

      if (datosEmpresa != null && (parseFloat(precioUltimo) === parseFloat(datosEmpresa[0]["PrecioInicialPorAccion"]))) {

        if (parseFloat(precio) !== parseFloat(datosEmpresa[0]["PrecioInicialPorAccion"]) && parseFloat(precio) < (parseFloat(precioUltimo) + parseFloat(datosEmpresa[0]["MontoMinMejoraPrecio"]))) {
          // + datosEmpresa[0]["MontoMinMejoraPrecio"]

          console.log(parseFloat(precio))
          console.log(parseFloat(precioUltimo))
          console.log(parseFloat(datosEmpresa[0]["MontoMinMejoraPrecio"]))
          console.log(parseFloat(precioUltimo) + parseFloat(datosEmpresa[0]["MontoMinMejoraPrecio"]))
          NotificationManager.warning(
            //"Precio debe ser igual al precio inicial o mayor e igual que " + formatNumber(precioUltimo) + " dólares",
            "Precio debe ser igual al precio inicial " + formatNumber(precioUltimo) + " dólares",
            "Sistema",
            4000,
            () => { },
            4
          );
          return
        }


      } else {


      }

      // Validacion monto minimo a mejorar
      if (datosEmpresa != null && (parseFloat(precio) < ((parseFloat(precioUltimo).toFixed(2)) + parseFloat(datosEmpresa[0]["MontoMinMejoraPrecio"].toFixed(2)))) && parseFloat(precio) !== parseFloat(precioUltimo)) {
        // + datosEmpresa[0]["MontoMinMejoraPrecio"]
        console.log(precio)
        console.log(precioUltimo)
        console.log("montomin", datosEmpresa[0]["MontoMinMejoraPrecio"])
        console.log((parseFloat(precioUltimo) + parseFloat(datosEmpresa[0]["MontoMinMejoraPrecio"])))
        NotificationManager.warning(
          // "Advertencia. Precio debe ser igual al precio ultimo ofertado o menor que " + formatNumber(precioUltimo) + " dólares",
          "Precio debe ser igual al precio ultimo ofertado o menor que " + formatNumber(precioUltimo),
          "Sistema",
          4000,
          () => { },
          4
        );
        return
      }
      // Incrementos 
      if ((parseFloat(precio) * 100).toFixed(2) % parseFloat((datosEmpresa[0]["MontoMinMejoraPrecio"] * 100)) != 0) {
        console.log(parseFloat(precio))
        console.log((parseFloat(precio) * 100))
        console.log(parseFloat((datosEmpresa[0]["MontoMinMejoraPrecio"] * 100)))
        NotificationManager.warning(
          "El precio debe tener incrementos de " + datosEmpresa[0]["MontoMinMejoraPrecio"],
          "Sistema",
          4000,
          () => { },
          4
        );
        return
      }






      // Validacion techo maximo
      if (datosEmpresa != null && parseFloat(precio) > parseFloat(datosEmpresa[0]["TechoPrecioMax"])) {
        NotificationManager.warning(
          "Precio debe ser menor que el techo máximo" + " USD " + formatNumber(datosEmpresa[0]["TechoPrecioMax"]) + "",
          "Sistema",
          4000,
          () => { },
          4
        );
        return
      }

      // Validacion ultimo precio

      if (datosEmpresa != null && (parseFloat(precio) < parseFloat(precioUltimo))) {
        NotificationManager.warning(
          "Precio debe ser mayor o igual a " + formatNumber(precioUltimo) + " dólares",
          "Sistema",
          4000,
          () => { },
          4
        );
        return
      }





      setErrors(errors)
      setIsError(true)
      if (!valid) {
        return
      }

      setIsActiveButton(true)
      dispatch(updateOrdenAct(
        "-1",
        empresa["value"],
        inversionista["value"],
        accionesComprar,
        precio,
        "A",
        currentUser["userId"],
        eventoSeleccionado,
        "0", "", "", "ASC", ""
      )).then(() => {
        //setEmpresa({ value: '0', label: "Ninguno" })
        setInversionista({ value: '0', label: "Ninguno" })
        setInversionistaSeleccionado(null)
        setAccionesComprar("")
        //setPrecio("")
        props.sendMessage(generateMessageAdm("ACTUALIZAR_ORDEN"))
        setIsActiveButton(false)
      })
        .catch(() => {
          setIsActiveButton(false)
        });


    }
  }
  console.log(isActiveButton);

  useEffect(() => {
    inversionistaSeleccionado = listaInversionistaSelect !== null ? listaInversionistaSelect.filter(x => x.value === inversionista)[0] : {}
    setInversionistaSeleccionado(inversionistaSeleccionado)
  }, [listaInversionistaSelect])


  useEffect(() => {

    //setPrecioUltimo(0)
    //console.log(precio)
    console.log("empresa", empresa, empresa["value"])
    if (datosEmpresa === null && empresa["value"] !== "0") {
      //setPrecio(0)
      //setPrecioUltimo(0)
      dispatch(getDataEmpresaAct(empresa["value"]))
        .then(() => {

        })
        .catch(() => {
        });
    }
  }, [empresa])

  useEffect(() => {

    console.log("listaAdjudicacion", listaAdjudicacion, "empresa", empresa, "listaEmpresaConEvento", listaEmpresaConEvento, "datosEmpresa", datosEmpresa)
    if (datosEmpresa !== null) {
      //setPrecio(0)
      //console.log((datosEmpresa[0]["MontoMinMejoraPrecio"] * 100))
    }

    if (listaEmpresaConEvento !== null) {

      setList(listaEmpresaConEvento['emp1']);

      let arrPrice = listaAdjudicacion !== null ? listaAdjudicacion.filter(x => x.EmpresaId == empresa["value"]) : []
      let arrPrice2 = list.filter(x => x.EmpresaId == empresa["value"])



      if (arrPrice2.length > 0) {
        //console.log("preci inicial")
        //console.log(arrPrice2[0]["PrecioInicialPorAccion"])

        if (precio == 0 || precio < parseFloat(arrPrice2[0]["PrecioInicialPorAccion"].toFixed(2))) {
          setPrecio(parseFloat(arrPrice2[0]["PrecioInicialPorAccion"].toFixed(2)))
          setPrecioUltimo(parseFloat(arrPrice2[0]["PrecioInicialPorAccion"].toFixed(2)))
        }
        //console.log(precio)
        //console.log(precioUltimo)
      }

      // si adjudicaciones tiene valores
      if (arrPrice.length > 0 && arrPrice2.length > 0 && arrPrice2 !== undefined && arrPrice2 !== null) {

        console.log("ajudicacionse")
        let sumaAcciones = sumColumn(arrPrice.filter(x => x.Precio == arrPrice[0]["Precio"]), ["NumAcciones"])

        if (arrPrice2 !== undefined && ((parseFloat(sumaAcciones)) >= parseFloat(arrPrice2[0]["NumAccionesOferta"]))) {
          console.log(arrPrice)
          let precioMejo = parseFloat(arrPrice[0]["Precio"].toFixed(2)) + parseFloat(arrPrice2[0]["MontoMinMejoraPrecio"].toFixed(2))
          if (precio == 0 || precio < precioMejo) {
            setPrecio(precioMejo)
            setPrecioUltimo(precioMejo)
          }
          //console.log(precio)
        } else {
          let precioMejo = parseFloat(arrPrice[0]["Precio"].toFixed(2))
          if (precio == 0 || precio < precioMejo) {
            setPrecio(parseFloat(arrPrice[0]["Precio"].toFixed(2)))
            setPrecioUltimo(parseFloat(arrPrice[0]["Precio"].toFixed(2)))
          }
          //console.log(precio)
          //console.log("-------")
          //console.log(precio)
          //console.log(precioUltimo)
        }


      }

    }
  }, [empresa, listaEmpresaConEvento, listaAdjudicacion]) //empresa, listaEmpresaConEvento, listaAdjudicacion


  //console.log("---------------------------")
  //console.log("precio", precio)
  //console.log("precio ultimo", precioUltimo)



  function sumColumn(arr, column) {
    let sum = 0;
    arr.forEach(item => {
      sum += item[column];
    });
    return sum;
  }

  return (
    <div>
      {nuevoInversionistaOpen === "OPEN" ?
        <AgregarInversionista
          closeEditInversionista={closeEditInversionista}
          inversionistaSeleccionado={inversionistaSeleccionado}
          empresaSeleccionada={empresa["value"]} /> : ""}

      <div className="crypt-boxed-area">

        <h6 className="crypt-bg-head">REGISTRO DE ORDEN
          <div className='floatright'>
            <div className="filterNeg">
              <i className={["fas", !props.maximizeOrn ? "fa-window-maximize" : "fa-window-restore"].join(' ')} onClick={() => props.setMaximizeOrd(!props.maximizeOrd)}></i>
            </div>
          </div>
        </h6>
        <div className="row no-gutters">
          <div className="col-md-6 customScroll">
            <div className="crypt-buy-sell-form">
              <div className="crypt-buy">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">Empresa</span>{" "}
                  </div>

                  <div className="col-70-orden">
                    <Select
                      isDisabled={userCajaFuncionality == true || userLiquidacionFuncionality == true || userFuntion == true ? true : props.isDisabled}
                      className="input-form1"
                      id="estadoSelectCombo"
                      value={empresa}
                      onChange={(e) => {

                        setEmpresa(e)

                        setLoadInversionista(true)

                        //agg
                        setPrecio(0)

                        dispatch(getInversionistaSelectAct(e.value))
                          .then(() => {
                            setInversionista({ value: '0', label: "Ninguno" })
                            setInversionistaSeleccionado(null)

                          })
                          .catch(() => {
                          });
                        dispatch(getDataEmpresaAct(e.value))
                          .then(() => {
                            setInversionista({ value: '0', label: "Ninguno" })
                            setInversionistaSeleccionado(null)

                          })
                          .catch(() => {
                          });



                        errors["empresaErr"] = ""
                      }} placeholder="Ninguno"
                      options={listaEmpresaConEvento !== null ? listaEmpresaConEvento['emp2'] : []}
                      tabIndex="1" />
                    {errors["empresaErr"] !== "" ? <div className='camporequerido'>{errors["empresaErr"]}</div> : ""}
                  </div>
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">Número de acciones</span>
                  </div>
                  <NumberFormat
                    disabled={userCajaFuncionality == true || userLiquidacionFuncionality == true ? true : props.isDisabled}
                    className="input-form2"
                    value={accionesComprar}
                    maxLength={5}
                    allowNegative={false}
                    thousandSeparator={false}
                    decimalScale={0}
                    fixedDecimalScale={false}
                    decimalSeparator={'.'}
                    prefix={""}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;


                      setAccionesComprar(value);
                      //precioPorAcciones(value)

                      errors["numAccionesErr"] = ""

                    }}
                    tabIndex="3"
                  />
                  {errors["numAccionesErr"] !== "" ? <div className='camporequerido'>{errors["numAccionesErr"]}</div> : ""}
                </div>

              </div>
            </div>
          </div>
          <div className="col-md-6 rightCol customScroll">
            <div className="crypt-buy-sell-form">
              <div className="crypt-buy">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">Inversionista</span>{" "}
                    {empresa !== null && empresa["value"] !== '0'?
                      <div className="input-group-text" title="Agregar" onClick={openNewInversionista}>
                        <i class="fas fa-user-plus"></i>
                      </div>
                      : ""}
                  </div>
                  <div className="col-70-orden">
                    <Select

                      isDisabled={userCajaFuncionality == true || userLiquidacionFuncionality == true ? true : props.isDisabled}
                      className="input-form1"
                      id="estadoSelectCombo"
                      value={inversionistaSeleccionado}
                      onChange={(e) => {
                        setInversionista(e)
                        setInversionistaSeleccionado(e)

                        errors["inversionistaErr"] = ""
                      }} placeholder="Ninguno"
                      options={listaInversionistaSelect !== null ? listaInversionistaSelect : []}
                      tabIndex="2" />
                    {errors["inversionistaErr"] !== "" ? <div className='camporequerido'>{errors["inversionistaErr"]}</div> : ""}
                  </div>
                </div>








                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    {" "}
                    <span className="input-group-text">Precio</span>
                  </div>
                  <NumberFormat
                    disabled={userCajaFuncionality == true || userLiquidacionFuncionality == true ? true : props.isDisabled}
                    className="input-form2"
                    value={precio}
                    thousandSeparator={false}
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalSeparator={'.'}
                    prefix={"$"}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setPrecio(parseFloat(value).toFixed(2));
                      //console.log(precio)


                      errors["precioErr"] = ""
                    }}
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return floatValue >= 0 && floatValue <= 99999.99;
                    }}
                    tabIndex="4"
                  />
                  {errors["precioErr"] !== "" ? <div className='camporequerido'>{errors["precioErr"]}</div> : ""}
                </div>

                <div>
                  <button
                    disabled={
                      userCajaFuncionality == true || userLiquidacionFuncionality == true || isActiveButton == true || clicked == true ? true : props.isDisabled
                    }
                    className={props.isDisabled == false && userCajaFuncionality == false && userLiquidacionFuncionality == false ? 'btnGuardar1' : 'btnGuardar1_False'}

                    onClick={(e) => {
                      actualizar()

                    }}
                  //onClick={actualizar}+
                  //onDoubleClick={() => console.log("on double click")}
                  >REGISTRAR

                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarOrden);
