import axios from "axios";
import '../config'

const getBannerByGame = () => {
    const params = {}
    return axios
      .post(global.config.URL_SERVICE + "ObtenerBannerSalaEspera", params,{headers: {'Content-Type': 'application/json; charset=utf-8',}})
      .then((response) => {
        let itemR = response["data"].split("\\\"").join("\"");          
        const result = JSON.parse(itemR);
        if(result.length>0){
          return result;  
        }else{
          return null
        }
      });
  };

export default {
    getBannerByGame
};
  