import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid, { Column } from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { formatDateTime, formatTime } from "./general/Util";
import { getTodoEventoAct } from "../actions/administracion";
import { setStatusByKey } from "../actions/juego";

import "../config";
import { NotificationContainer, NotificationManager } from "react-notifications";


const ElegirEvento = (props) => {


    let history = useHistory();
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);
    const { eventoSeleccionado: eventoSeleccionado } = useSelector((state) => state.juego);
    const [eventoSeleccionadoId, setEventoSeleccionadoId] = useState(-1)

    // nuevo minHeight: '300px', height: "100%"
    const [empresaSeleccionada, setEmpresaSeleccionada] = useState();
    //
    const gridStyle = { minHeight: '100vh', height: "100%" }
    const { listaTodosLosEventos } = useSelector((state) => state.administracion);
    //console.log("listaTodosLosEventos", listaTodosLosEventos)
    let [loadTodosLosEventos, setLoadTodosLosEventos] = useState(false)
    let [list, setList] = useState([]);

    if (loadTodosLosEventos === false) {
        setLoadTodosLosEventos(true)
        dispatch(getTodoEventoAct(currentUser["userId"], currentUser["role"]))
            .then(() => {
            })
            .catch(() => {
            });

    }




    let [empresaFilter, setEmpresaFilter] = useState("");
    let [gridInfor, setGridInfor] = useState([listaTodosLosEventos !== null ? listaTodosLosEventos : []]);

    if (listaTodosLosEventos !== null && listaTodosLosEventos != gridInfor && empresaFilter === "") {
        setGridInfor(listaTodosLosEventos)


        //agg
        if (listaTodosLosEventos.length == 1) {
            //setList(listaTodosLosEventos[0]);
            console.log(listaTodosLosEventos[0])
            setEventoSeleccionadoId(parseInt(listaTodosLosEventos[0]["EventoId"]))
            console.log(eventoSeleccionadoId)

            dispatch(setStatusByKey("eventoSeleccionado",parseInt(listaTodosLosEventos[0]["EventoId"])))

            history.push('/sala')
            window.location.reload();
        }
        // hasta aqui

    }
    

    function filterCatalogo(nego, empresaFilter) {
        //value => 
        setGridInfor(nego.filter(function (item) {
            if (item["NombreEvento"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()))
                return true;
            return false;
        }))
    }
    function closeEvento() {
        props.closeEventoClick()
    }

    function formatEstado(estado) {
        switch (estado) {
            case "A":
                return "ACTIVO"
            case "":
                return ""
        }

    }

    function formatModalidad(modalidad) {
        switch (modalidad) {
            case 1:
                return "VIRTUAL"
            case 2:
                return "SEMIPRESENCIAL"
            case 3:
                return "MANUAL"

        }
    }
    let columns = [

        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'NombreEvento', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>EVENTO</div>), defaultFlex: 1, xdefaultLocked: 'end', minWidth: 500 },
        { name: 'FechaSubasta', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>FECHA SUBASTA</div>), defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => formatDateTime(data["FechaSubasta"]) },
        { name: 'ModalidadId', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>MODALIDAD</div>), defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => formatModalidad(data["ModalidadId"]) },
        { name: 'Estado', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ESTADO</div>), defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => data != null ? formatEstado(data["Estado"]) : "" },
    ]
    const renderRowContextMenu = useCallback((menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
            {
                label: 'Ir a evento',
                onClick: () => {
                    setEventoSeleccionadoId(rowProps.data["EventoId"])
                    dispatch(setStatusByKey("eventoSeleccionado", parseInt(rowProps.data["EventoId"])))
                    history.push('/sala')
                    window.location.reload();
                }
            },
        ]
    }, [])


    if (!currentUser) {
        return <Redirect to="/login" />;
    }
    return (
        <div>
            <div>
                <h6 className="crypt-bg-head">BUSCAR EVENTO
                    <div className='floatright'>
                        <div className="filterNeg"><input type="text" placeholder="Filtrar" value={empresaFilter} onChange={(e) => {
                            setEmpresaFilter(e.target.value);
                            filterCatalogo(listaTodosLosEventos !== null ? listaTodosLosEventos : [], e.target.value)
                        }} /> <i className="fas fa-search"></i>
                        </div>
                    </div>
                </h6>
            </div>

            <div className="tab-content">
                <DataGrid
                    idProperty="EventoId"
                    style={gridStyle}
                    columns={columns}
                    enableSelection={true}
                    selection={{ mode: "single" }}
                    dataSource={gridInfor}
                    renderRowContextMenu={renderRowContextMenu}
                    theme="amber-dark"
                    stickyHeader={true}

                />
                <div className="no-orders text-center"></div>
            </div>
            <NotificationContainer />
        </div>
    );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(ElegirEvento);
