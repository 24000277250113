import React, { useState, useEffect, useCallback } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import DataGrid, { Column } from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/amber-dark.css';
import "react-datepicker/dist/react-datepicker.css";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { getCatalogos, deleteCatalogo } from "../../actions/administracion";
import EditarCatalogo from "./EditarCatalogo";
import ItemCatalogo from "./ItemCatalogo";
import "../../config";


const GestionCatalogo = (props) => {
    const dispatch = useDispatch();
    const gridStyle = { minHeight: '300px', height: '100%' }
    const { listaCatalogosPag, loadingCatalogos } = useSelector((state) => state.administracion);
    let [editCatalogoOpen, setEditCatalogoOpen] = useState("")
    let [itemCatalogoOpen, setItemCatalogoOpen] = useState("")
    let [catalogoSeleccionado, setCatalogoSeleccionado] = useState("null")
    let [loadCatalogs, setLoadCatalogs] = useState(false)
    if (loadCatalogs === false) {
        setLoadCatalogs(true)
        dispatch(getCatalogos("0", "", "", "ASC", ""))
            .then(() => {
            })
            .catch(() => {
            });

    }
    let [catalogoFilter, setCatalogoFilter] = useState("");
    let [gridInfor, setGridInfor] = useState([listaCatalogosPag !== null ? listaCatalogosPag : []]);

    if (listaCatalogosPag !== null && listaCatalogosPag != gridInfor && catalogoFilter === "")
        setGridInfor(listaCatalogosPag)

    function filterCatalogo(nego, catalogoFilter) {
        //value => 
        setGridInfor(nego.filter(function (item) {
            if (item["Codigo"].toLowerCase().includes(catalogoFilter.toLocaleLowerCase()) ||
                item["Nombre"].toLowerCase().includes(catalogoFilter.toLocaleLowerCase()))
                return true;
            return false;
        }))
    }
    function openNewCatalogo() {
        setCatalogoSeleccionado(null)
        setEditCatalogoOpen("EDIT")
    }
    function closeCatalogo() {
        setEditCatalogoOpen("")
    }
    function closeWindowCatalogo() {
        setLoadCatalogs(false)
        props.closeGestionCatalogoClick()
    }
    function closeItemCatalogo() {
        setItemCatalogoOpen("")
    }
    const renderRowContextMenu = useCallback((menuProps, { rowProps }) => {
        menuProps.autoDismiss = true
        menuProps.items = [
            {
                label: 'Items',
                onClick: () => {
                    setCatalogoSeleccionado(rowProps.data)
                    setItemCatalogoOpen("ITEM")
                    console.log(rowProps.data)
                }
            },
            {
                label: 'Editar',
                onClick: () => {
                    setCatalogoSeleccionado(rowProps.data)
                    setEditCatalogoOpen("EDIT")
                    console.log(rowProps.data)
                }
            },
            {
                label: 'Eliminar',
                onClick: () => {
                    confirmAlert({
                        title: 'Eliminar catálogo',
                        message: '¿Seguro desea eliminar?.',
                        buttons: [
                            {
                                label: 'Si',
                                onClick: () => {
                                    dispatch(deleteCatalogo(rowProps.data["CatalogoId"]))
                                        .then(() => {

                                        })
                                        .catch(() => { });
                                }
                            },
                            {
                                label: 'No',
                                onClick: () => {

                                }
                            }
                        ]
                    });
                }
            }
        ]
    }, [])
    function formatEstado(estado) {
        switch (estado) {
            case "A":
                return "ACTIVO"
        }
        return "INACTIVO"
    }
    //console.log(listaCatalogosPag)
    let columns = [
        /*{ name: 'EventoId', type: 'number', defaultWidth: 80 },*/
        { name: 'Codigo', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>CÓDIGO</div>), defaultFlex: 1, xdefaultLocked: 'end' , headerAlign: "center"},
        { name: 'Nombre', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>NOMBRE</div>), defaultFlex: 1, xdefaultLocked: 'start', defaultWidth: 400,headerAlign: "center" },
        { name: 'Estado', header: () => (<div style={{ textAlign: "center", fontSize: "13px" }}>ESTADO</div>), defaultFlex: 1, xdefaultLocked: 'end', render: ({ data }) => formatEstado(data["Estado"]), headerAlign: "center" },
    ]
    return (
        <div
            className={[
                "overlay3",
                "fade-in"
            ].join(" ")}
        >

            <div className={['loading_cont', loadingCatalogos !== true ? "displaynone" : ""].join(' ')}><div className='single6'></div></div>
            {editCatalogoOpen === "EDIT" ? <EditarCatalogo closeCatalogo={closeCatalogo} catalogoSeleccionado={catalogoSeleccionado} /> : ""}
            <div className="borderContent ventanaPortafolio">
                <div className="innerShadow-height-100">
                    <h2>
                        Catálogos
                        <div className="subItemCatalogo">
                            <Menu menuButton=
                                {<MenuButton><i className="fas fa-cog fa-1x"></i></MenuButton>}>
                                <MenuItem onClick={openNewCatalogo}><i className="fas fa-sign-out-alt"></i>Nuevo</MenuItem>
                            </Menu>
                        </div>
                    </h2>
                    <div className="titleTable-search">CATÁLOGOS
                        <div className='floatright'>
                            <div className="filterNeg">
                                <input 
                                autocomplete="off"
                                type="text" 
                                placeholder="Filtrar"
                                value={catalogoFilter} 
                                onChange={(e) => {
                                    setCatalogoFilter(e.target.value);
                                    filterCatalogo(listaCatalogosPag !== null ? listaCatalogosPag : [], e.target.value)
                                }} />
                                <i className="fas fa-search"></i>
                            </div>
                        </div>
                    </div>
                    <DataGrid
                        idProperty="CatalogoId"
                        style={gridStyle}
                        columns={columns}
                        enableSelection={true}
                        selection={{ mode: 'single' }}
                        dataSource={gridInfor}
                        renderRowContextMenu={renderRowContextMenu}
                        theme="amber-dark"
                    />
                    <div className="dividerLine"></div>
                    <div className="textCenter">
                        <div className="btnCancelar" onClick={props.closeCatalogosClick}>
                            SALIR
                        </div>
                    </div>
                </div>
            </div>
            {itemCatalogoOpen === "ITEM" ? <ItemCatalogo closeItemCatalogo={closeItemCatalogo} catalogoSeleccionado={catalogoSeleccionado} /> : ""}

        </div>
    );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(GestionCatalogo);
