import React, { useState,useEffect} from "react";
import { Redirect } from 'react-router-dom';
import { connect,useDispatch,useSelector } from "react-redux";

import { getEventosPorUsuario,getTitulosEventoById } from "../actions/juego";
import RentaFija from './juego/RentaFija'
import ItemRentaVariable from './juego/ItemRentaVariable'

import img01 from '../image/img01.jpg';
import img02 from '../image/img02.jpg';
import marca1 from '../image/marca1.png';
import marca2 from '../image/marca2.png';
import imagenNoticiaPositivaBig from '../image/imgNoticiaBig.jpg';
import imagenNoticiaNegativaBig from '../image/imgNoticiaBig2.jpg';
import imagenEmisor from '../image/logoRF1.png';

import leftArrow from '../image/arrowRight.svg';
import rightArrow from '../image/arrowLeft.svg';

const Juego = () => {
    const { user: currentUser } = useSelector((state) => state.auth);  
    const { listEventos: currentEventos,listTitleEvent: currentTitle } = useSelector((state) => state.juego);
    const { loadingEventosPorUsr } = useSelector((state) => state.juego);    
    const { titulosRVCount } = useSelector((state) => state.juego );    
    const dispatch = useDispatch();

    useEffect(() => {    
        
        dispatch(getEventosPorUsuario(currentUser["userId"]))
            .then(() => {          
            })
            .catch(() => {
            });
      }, [dispatch]);
    
    useEffect(() => {        
        dispatch(getTitulosEventoById(currentEventos!==null&&currentEventos.length>0?currentEventos[0]["EventoId"]:1))
        .then(() => {          
            console.log("getEventosPorUsuario ---- > " + currentEventos.length)
        })
        .catch(() => {
        });
    }, [dispatch]);

    
    
  if (!currentUser) {
    return <Redirect to="/login" />;
  }  

  return (
      <div>
      
      <div className="alertContainerJuego">
        <div>Selecciona las opciones que se muestran a continuación...</div>
      </div>
      
      <div class="overlay">
      <div className="borderContent ventanaPerfil">
      <div className="innerShadow3">
      <h2>Configuración <i class="fas fa-cog"></i></h2>
      <p><i class="far fa-address-card"></i> <span>Nombre:</span> Pablo</p>
      <p><i class="far fa-address-card"></i> <span>Apellido:</span> Admin</p>
      <p><i class="fas fa-mobile-alt"></i> <span>Teléfono:</span> 01234 456 789</p>
      <p><i class="far fa-envelope"></i> <span>Email:</span></p>
      <p><i class="fas fa-lock"></i> <span>Contraseña:</span> Cambiar</p>
      <div className="dividerLine"></div>
      <p><i class="fas fa-volume-up"></i> <span>Sonido:</span> Encendido</p>
      <div className="dividerLine"></div>
      <p><i class="fas fa-palette"></i> <span>Tema:</span> Oscuro</p>
      <div className="dividerLine"></div>
      <p className="textCenter"><div className="btnGuardar">GUARDAR</div> <div className="btnCancelar">CANCELAR</div></p>
      
      
      </div>
      </div>
      </div>
      
    <div className="mainContentInner">
      <div className="content">
      
      <div className="borderContent tableContent">
        <div className="innerShadow3">
      <div className="titleCont">      
        <h3>RENTA VARIABLE</h3>
        <div className="titleRight">
            <h3>COMPRAR</h3>
            <h3>VENDER</h3>
        </div>
     </div> 
      <div className="tableContainer">      
      <div className="rowCustom">
            <div className="oneCol"><img src={marca1} alt="Logo1" /></div>
            <div className="twoCol">OBL</div>
            <div className="threeCol">#ACC</div>
            <div className="fourCol">99</div>
            <div className="fiveCol"><div className="percentBarHorzontal bgYellow">%1.95</div></div>
            <div className="sixCol"><div className="cantidadCustom"><input type="text"/></div></div>
            <div className="sevenCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      <div className="rowCustom">
            <div className="oneCol"><img src={marca2} alt="Logo1" /></div>
            <div className="twoCol">NKE</div>
            <div className="threeCol">#ACC</div>
            <div className="fourCol">89</div>
            <div className="fiveCol"><div className="percentBarHorzontal bgPurple">%2.00</div></div>
            <div className="sixCol"><div className="cantidadCustom"><input type="text"/></div></div>
            <div className="sevenCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      <div className="rowCustom">
            <div className="oneCol"><img src={marca1} alt="Logo1" /></div>
            <div className="twoCol">BE</div>
            <div className="threeCol">#ACC</div>
            <div className="fourCol">999</div>
            <div className="fiveCol"><div className="percentBarHorzontal bgRed">%2.50</div></div>
            <div className="sixCol"><div className="cantidadCustom"><input type="text"/></div></div>
            <div className="sevenCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      <div className="rowCustom">
            <div className="oneCol"><img src={marca2} alt="Logo1" /></div>
            <div className="twoCol">PCO</div>
            <div className="threeCol">#ACC</div>
            <div className="fourCol">89</div>
            <div className="fiveCol"><div className="percentBarHorzontal bgGreen">%1.00</div></div>
            <div className="sixCol"><div className="cantidadCustom"><input type="text"/></div></div>
            <div className="sevenCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      <div className="rowCustom">
            <div className="oneCol"><img src={marca1} alt="Logo1" /></div>
            <div className="twoCol">OBL</div>
            <div className="threeCol">#ACC</div>
            <div className="fourCol">99</div>
            <div className="fiveCol"><div className="percentBarHorzontal bgYellow">%1.95</div></div>
            <div className="sixCol"><div className="cantidadCustom"><input type="text"/></div></div>
            <div className="sevenCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      <div className="rowCustom">
            <div className="oneCol"><img src={marca2} alt="Logo1" /></div>
            <div className="twoCol">NKE</div>
            <div className="threeCol">#ACC</div>
            <div className="fourCol">89</div>
            <div className="fiveCol"><div className="percentBarHorzontal bgPurple">%2.00</div></div>
            <div className="sixCol"><div className="cantidadCustom"><input type="text"/></div></div>
            <div className="sevenCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      <div className="rowCustom">
            <div className="oneCol"><img src={marca1} alt="Logo1" /></div>
            <div className="twoCol">BE</div>
            <div className="threeCol">#ACC</div>
            <div className="fourCol">999</div>
            <div className="fiveCol"><div className="percentBarHorzontal bgRed">%2.50</div></div>
            <div className="sixCol"><div className="cantidadCustom"><input type="text"/></div></div>
            <div className="sevenCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      <div className="rowCustom">
            <div className="oneCol"><img src={marca2} alt="Logo1" /></div>
            <div className="twoCol">PCO</div>
            <div className="threeCol">#ACC</div>
            <div className="fourCol">89</div>
            <div className="fiveCol"><div className="percentBarHorzontal bgGreen">%1.00</div></div>
            <div className="sixCol"><div className="cantidadCustom"><input type="text"/></div></div>
            <div className="sevenCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      
      </div>
      
      <div className="dividerLine"></div>
      
      <div className="titleCont">      
        <h3>RENTA FIJA</h3>
        <div className="titleRight">
            <h3>COMPRAR</h3>            
        </div>
      </div> 
      
      <div className="rowCustom2">
            <div className="oneCol"><div className="imgLogo gris"><img src={imagenEmisor} alt="El Juego de la Bolsa" /></div></div>
            <div className="twoCol">Ministerio de Finanzas Bonos del Estado </div>
            <div className="threeCol">1.500.000 - 5.23% - 180 días - 1/4</div>            
            <div className="fourCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      
      <div className="rowCustom2">
            <div className="oneCol"><div className="imgLogo yellowStyle"><img src={imagenEmisor} alt="El Juego de la Bolsa" /></div></div>
            <div className="twoCol">Ministerio de Finanzas Bonos del Estado </div>
            <div className="threeCol">1.500.000 - 5.23% - 180 días - 1/4</div>            
            <div className="fourCol"><div className="cantidadCustom"><input type="text"/></div></div>
      </div>
      
      
           
        </div>
      </div>
      
      </div>
      
      <div className="btnContainer">
        <div className="btnEnviar">Enviar</div>
        <div className="btnCancelar">Cancelar</div>
      </div>
      
                <div className="content hidetemp">
      
      <div className="borderContent noticiaPositivaBig">
        <div className="innerShadow3">
          <h3>NOTICIA POSITIVA</h3>                    
          <div className="imgNoticiaPositiva"><img src={imagenNoticiaPositivaBig} alt="El Juego de la Bolsa" /></div>
          <div className="txtNoticiaPositiva">Ecuador intensifica su promoción turística en el mundo. Depósitos en los bancos aumentaron...</div>
        </div>
      </div>
      
      <div className="borderContent noticiaNegativaBig">
        <div className="innerShadow3">
          <h3>NOTICIA NEGATIVA</h3>                    
          <div className="imgNoticiaNegativa"><img src={imagenNoticiaNegativaBig} alt="El Juego de la Bolsa" /></div>
          <div className="txtNoticiaNegativa">Ecuador intensifica su promoción turística en el mundo. Depósitos en los bancos aumentaron...</div>
        </div>
      </div>
      
      
                <div className="borderContent colRentaFija">
                    <div className="innerShadow3">
                    <h3>VALORES DE RENTA FIJA</h3>
                        <RentaFija titulos={currentTitle===null?[]:currentTitle}/>                        
                    </div>
                </div>
                
            
                <div className="borderContent colBarras">
                    <div className="innerShadow3">
                    <h3>REPRESENTACION EN BARRAS</h3>                    
                    <div className={["chart-container",titulosRVCount<=2?"sizeTwoBars":titulosRVCount===3?"sizeThreeBars":titulosRVCount===4?"sizeFourBars":titulosRVCount===5?"sizeFiveBars":titulosRVCount===6?"sizeSixBars":titulosRVCount===7?"sizeSevenBars":""].join(' ')}>
                        <div className="bottomACC">#ACC</div>
                        {
                            currentTitle!=null?currentTitle.filter(value => value["TipoRentaCod"] === 'RV').map((item, i) => { 
                                console.log(item)
                                if(item["TipoRentaCod"]==="RV"){
                                    return <ItemRentaVariable key={i} index={i} item={item}/>
                                }else{
                                    return ""
                                }
                            }):""
                        }             
                        {/*<div className="bar one"><div className="percent">43%</div><div className="logo1"><img src={marca2} alt="Logo Marca 1" /></div><div className="bottomPercent1">99</div></div>
                        <div className="bar two"><div className="percent">60%</div><div className="logo2"><img src={marca2} alt="Logo Marca 1" /></div><div className="bottomPercent2">99</div></div> 
                        <div className="bar three"><div className="percent">78%</div><div className="logo3"><img src={marca2} alt="Logo Marca 1" /></div><div className="bottomPercent3">99</div></div>
                        <div className="bar four"><div className="percent">79%</div><div className="logo4"><img src={marca2} alt="Logo Marca 1" /></div><div className="bottomPercent4">99</div></div>
                        <div className="bar five"><div className="percent">50%</div><div className="logo5"><img src={marca2} alt="Logo Marca 1" /></div><div className="bottomPercent5">99</div></div>
                        <div className="bar six"><div className="percent">69%</div><div className="logo6"><img src={marca2} alt="Logo Marca 1" /></div><div className="bottomPercent6">99</div></div>  
                        <div className="bar seven"><div className="percent">79%</div><div className="logo7"><img src={marca2} alt="Logo Marca 1" /></div><div className="bottomPercent7">99</div></div>  
                        */}
                        </div>
                    </div>
                </div>
            
                <div className="borderContent colJugadas">
                    <div className="innerShadow3">
                    <h3>JUGADAS</h3>
                    <div className="containerJugadas">
                        <div className="jugada up">
                            <ul>
                                <li><div className="imgLogoJugadas greenStyle"><img src={marca1} alt="Logo Marca 1" /></div></li>
                                <li><div className="upArrow"><i className="fas fa-arrow-up"></i></div></li>
                                <li><div className="cantidadJugada">43% <span>Lorem ipsum</span></div></li>                                
                            </ul>
                        </div>
                        <div className="jugada down">
                            <ul>
                                <li><div className="imgLogoJugadas redStyle"><img src={marca2} alt="Logo Marca 1" /></div></li>
                                <li><div className="upArrow"><i className="fas fa-arrow-down"></i></div></li>
                                <li><div className="cantidadJugada">43% <span>Lorem ipsum</span></div></li>                                
                            </ul>
                        </div>
                    </div>
            
                    </div>
                </div>
            </div>
                                                                                                                

<div className="arrowControlsContainer">            
  <div className="leftArrow arrowDisabled"><img src={leftArrow} alt="left arrow" /></div>
  <div className="rightArrow"><img src={rightArrow} alt="right arrow" /></div>
</div>
           <div className="footerColContainer">
           <div className="tableTransaction borderContent">
                    <div className="innerShadow2">
                        <div className="btnMaximizeGrid"><i class="far fa-window-maximize"></i></div>
                        <div className="titleTable">HISTORIAL DE TRANSACCIONES</div>
                        <div className="table-content">                                                                                        
                        <ul>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                            <li>Compra de adiddas 5000 a 3.24</li>
                        </ul>
                        </div>                                                                                                                
                    </div>
                </div> 
           </div>                                                                                                            
                                                                                                                
                                                                                                                
            <div className="footerColContainer">                                               
                <div className="footerCol borderContent">
                <div className="innerShadow2">
                    <h2>NOTICIA POSITIVA</h2>
                    <div className="noticeContainer">
                        <div className="imageNotice noticeStyle1">
                            <img src={img01} alt="Noticia 1" />
                        </div>
                        <h3>Adidas completa la colocación de 1.000 millones de euros en bonos</h3>
                        <p>Madrid – Adidas confirmaba en el día de ayer el haber terminado con éxito la colocación de una emisión de bonos por valor de 1.000 millones de euros.</p>
                    </div>
                </div>
                </div>
            
                <div className="footerCol borderContent">
                <div className="innerShadow2">
                    <h2>NOTICIA NEGATIVA</h2>
                    <div className="noticeContainer">
                        <div className="imageNotice noticeStyle2">
                            <img src={img02} alt="Noticia 2" />
                        </div>
                        <h3>El Ibex cae un 1,16% tras el aumento de restricciones y el oro se refuerza</h3>
                        <p>Madrid – Adidas confirmaba en el día de ayer el haber terminado con éxito la colocación de una emisión de bonos por valor de 1.000 millones de euros.</p>
                    </div>
                </div>
                </div>
            
                <div className="footerCol borderContent">
                    <div className="innerShadow2">
                    <h2>PROGRESIÓN</h2>
                    
                    </div>
                </div>            
            </div>
                                                                                                                
                                                                                                                
                                                                                                                
      
    </div> 
      <div className="userRow">
 <div className="innerShadow3">
  <h3>DIRECTOR DE RUEDA</h3>
  <div className="userConectedTitle">Usuarios conectados</div> 
      <div className="userListSidebar">
        <ul>
            <li>
                <div className="profileImageList"></div>
                <div className="userNameList"><span>#1</span> Nelly Wesley</div>
                <div className="userLastPlay">Última jugada 06/09/2020</div>
                <div className="userArrowRight"><i className="fas fa-chevron-right"></i></div>
            </li>
        </ul>
      </div>
 </div>
</div> 
<div className="userBtn">
  <div className="innerShadow3">
   <ul>
      <li><div className="btnCompra">Compra </div></li>
      <li><div className="btnVenta">Venta </div></li>
      <li><div className="btnNuevaJugada">Nueva Jugada</div></li>
   </ul>
  </div>
</div>                                                           
</div>
                                                                                                                
                                                     
                                                                                                                
  );
};
const mapStateToProps = state => ({    
    listEventos: state.listEventos,
    loadingEventosPorUsr: state.loadingEventosPorUsr,
    currentTitle: state.currentTitle,
    titulosRVCount: state.titulosRVCount
  });
export default connect(mapStateToProps, {    
})(Juego);
