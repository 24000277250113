import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import React, { useCallback, useState } from 'react'
import DataGrid, { Column } from '@inovua/reactdatagrid-community';
import { connect, useDispatch, useSelector } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import { getAdjudicacionAct, getDatosAdjudicaciones, sendLiquidacionInversionista, sendLiquidacionUser, updateCobrarLiquidacion, updateEstadoEmailEmpresa, updateEstadoEmailInversionista, updateImpresionLiquidaciones, updateImpresionTitulos, updateImprimirCertificado, updateImprimirLiquidacion } from '../../actions/administracion';
import { enableFuncionalityForRole, formatNumber, generateMessageAdm } from "../../components/general/Util";
import EditarComentarioLiqui from './EditarComentarioLiqui';
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/amber-dark.css";

const Liquidaciones = (props) => {

  //probar 
  //console.log(props.sendMessage)

  const dispatch = useDispatch();
  const gridStyle = { minHeight: '300px', height: '100%' }

  const { user: currentUser } = useSelector((state) => state.auth);
  let [eventoSeleccionadoId] = useState(props.eventoSeleccionado);
  let [empresaId] = useState(props.empresaId);
  let [usuarioId] = useState(props.usuarioId);
  console.log("ey", eventoSeleccionadoId)
  const { listaAdjudicacion } = useSelector((state) => state.administracion);

  // agg
  const { listaDatosAdjudicaciones, liquidacionesLoading, loadingAdjudicacionesD } = useSelector((state) => state.administracion);
  console.log("liquidacionesLoading", liquidacionesLoading)
  let [mostrarDatosAdjudicaciones, setMostrarDatosAdjudicaciones] = useState(false)

  let [loadAdjudicacion, setLoadAdjudicacion] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  // Comentario
  let [liquidacionSeleccionada, setLiquidacionSeleccionada] = useState(null);
  let [editComentarioLiquiOpen, setEditComentarioLiquiOpen] = useState("")

  let [admFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_ADM")
      : false
  );

  let [userCajaFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_CAJA")
      : false
  );

  let [userLiquidacionFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_LIQUI")
      : false
  );
  let [userRuedaFuncionality] = useState(
    currentUser !== null
      ? enableFuncionalityForRole(currentUser["role"], "FUNC_RUED")
      : false
  );

  if (listaDatosAdjudicaciones === null && mostrarDatosAdjudicaciones === false) {
    setMostrarDatosAdjudicaciones(true)
    dispatch(getDatosAdjudicaciones(eventoSeleccionadoId, usuarioId, empresaId, "0", "", "", "ASC", ""))
      .then(() => {
        //console.log("exito");
        console.log(listaDatosAdjudicaciones);
      })
      .catch(() => {
      });

  }
  //

  let [plantillaFilter, setPlantillaFilter] = useState("");
  let [gridInfor, setGridInfor] = useState([listaDatosAdjudicaciones !== null ? listaDatosAdjudicaciones : []]);

  if (listaDatosAdjudicaciones !== null && listaDatosAdjudicaciones != gridInfor && plantillaFilter === "")
    setGridInfor(listaDatosAdjudicaciones)


  function closeLiquidacion() {
    props.closeLiquidacionesClick()
  }



  const renderButtonELiquidacion = (value, rowData) => {
    return (
      <>
        <button style={{ border: 'none', outline: 'none' }}
          id={value.data !== null ?
            value.data["Liquidacion"] : "0"}
          className={value.data["EstadoEmailLiquiEmpresaEnviado"] === "E" ? "fas fa-envelope color" : "fas fa-envelope"}
          onClick={(e) => {
            handleClickLiquidacionIndividual(e)
            //setIsLoading(true)
          }}></button>
      </>
    )
  }

  const renderButtonEmailLiquidacionInversionista = (value, rowData) => {
    return (
      <>
        <button style={{ border: 'none', outline: 'none' }}
          id={value.data !== null ?
            value.data["Liquidacion"] : "0"}
          className={value.data["EstadoEmailLiquiInverEnviado"] === "E" ? "fas fa-envelope color" : "fas fa-envelope"}
          onClick={(e) => {
            handleClickLiquidacionIndividualInversionista(e)
            //setIsLoading(true)
          }}></button>
      </>
    )
  }




  // Imprimir button
  const renderButtonICertificado = (value) => {
    return (
      <>
        <button style={{ border: 'none', outline: 'none' }}
          id={value.data !== null ?
            value.data["Liquidacion"] : "0"}
          className={value.data["EstadoCertiImpreso"] === "I" ? "fas fa-print color" : "fas fa-print"}
          onClick={
            (e) => {
              handleClickCertificado(e)
              setIsLoading(true)
            }

          }></button>
      </>
    )
  }



  // Imprimir button
  const renderButtonITitulo = (value) => {
    return (
      <>
        <button style={{ border: 'none', outline: 'none' }}
          id={value.data !== null ?
            value.data["Liquidacion"] : "0"}
          className={value.data["EstadoLiquiImpreso"] === "I" ? "fas fa-print color" : "fas fa-print"}
          onClick={(e) => {
            handleClickTitulo(e)
            setIsLoading(true)
          }}></button>
      </>
    )
  }



  const handleClickCertificado = (rowData) => {
    let liquidacionId = parseInt(rowData.target.id)
    let data = listaDatosAdjudicaciones.filter(x => x.Liquidacion === liquidacionId)[0]//rowData.cellProps["data"]

    if (data !== null) {
      dispatch(updateImpresionLiquidaciones(liquidacionId))
        .then((url) => {

          if (url !== "-2") {
            dispatch(updateImprimirCertificado(liquidacionId, eventoSeleccionadoId, usuarioId, empresaId))
            openPDF(url);
            props.sendMessage(generateMessageAdm("ACTUALIZAR_ORDEN"))
          }
          setIsLoading(false);

        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }



  const handleClickTitulo = (rowData) => {
    let adjudicacionId = parseInt(rowData.target.id)
    let data = listaDatosAdjudicaciones.filter(x => x.AdjudicacionId === adjudicacionId)[0]
    if (data !== null) {
      dispatch(updateImpresionTitulos(adjudicacionId))
        .then((url) => {
          if (url !== "-2") {
            dispatch(updateImprimirLiquidacion(adjudicacionId, eventoSeleccionadoId, usuarioId, empresaId))
            openPDF(url);
            props.sendMessage(generateMessageAdm("ACTUALIZAR_ORDEN"))
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

  }

  function openPDF(pdf) {
    window.open(pdf);
    return false;
  }

  const handleClickLiquidacionIndividual = (rowData) => {
    let liquidacionId = parseInt(rowData.target.id)
    console.log("lista", listaDatosAdjudicaciones)
    console.log("id", liquidacionId)
    let data = listaDatosAdjudicaciones.filter(x => x.AdjudicacionId === liquidacionId)[0]
    if (data !== null) {
      dispatch(sendLiquidacionUser(eventoSeleccionadoId, liquidacionId))
        .then((url) => {
          dispatch(updateEstadoEmailEmpresa(liquidacionId, eventoSeleccionadoId, usuarioId, empresaId))
          props.sendMessage(generateMessageAdm("ACTUALIZAR_ORDEN"))
        })
        .catch(() => {
        });
    }

  }

  const handleClickLiquidacionIndividualInversionista = (rowData) => {
    let liquidacionId = parseInt(rowData.target.id)
    console.log("lista", listaDatosAdjudicaciones)
    console.log("id", liquidacionId)
    let data = listaDatosAdjudicaciones.filter(x => x.AdjudicacionId === liquidacionId)[0]
    if (data !== null) {
      dispatch(sendLiquidacionInversionista(eventoSeleccionadoId, liquidacionId))
        .then((url) => {
          dispatch(updateEstadoEmailInversionista(liquidacionId, eventoSeleccionadoId, usuarioId, empresaId))
          props.sendMessage(generateMessageAdm("ACTUALIZAR_ORDEN"))
        })
        .catch(() => {
        });
    }

  }

  function filterCatalogo(nego, empresaFilter) {
    //value => 
    setGridInfor(nego.filter(function (item) {
      if (item["NombreEmpresa"].toLowerCase().includes(empresaFilter.toLocaleLowerCase()) ||
        item["NombreInversionista"].toLowerCase().includes(empresaFilter.toLocaleLowerCase())
      )
        return true;
      return false;
    }))
  }

  const renderRowContextMenu = useCallback((menuProps, { rowProps }) => {
    //console.log(menuProps)
    menuProps.autoDismiss = true
    menuProps.items = [
      {
        label: 'Cobrar',
        onClick: () => {
          setLiquidacionSeleccionada(rowProps.data)
          setEditComentarioLiquiOpen("EDIT")
          console.log(rowProps.data)
        }
      },
      {
        label: 'Ver comentario',
        onClick: () => {
          setLiquidacionSeleccionada(rowProps.data)
          setEditComentarioLiquiOpen("EDIT")
          console.log(rowProps.data)
        }
      }

    ]
  }, [])

  function closeEditComentarioLiqui() {
    setEditComentarioLiquiOpen("")
  }
  const renderButtonObservaciones = (value) => {
    //console.log(value.data["Estado"])
    return (
      <>
        {value.data["EstadoCobrar"] === "C" ?
          <button
            className="fas fa-check"//fas fa-clock
            onClick={
              (e) => {
              }

            }></button>
          :
          <button
            className="fas fa-times"//fas fa-clock
            onClick={
              (e) => {
              }

            }></button>}
      </>
    )
  }




  let columns = admFuncionality === true ? [

    { name: "NombreEmpresa", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>EMPRESA</div>), defaultFlex: 1, xdefaultLocked: "end", headerAlign: "center", minWidth: 140 },
    { name: "Orden", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>LIQUIDACIÓN</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 130 },
    { name: "NombreInversionista", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>INVERSIONISTA</div>), defaultFlex: 1, xdefaultLocked: "end", headerAlign: "center", minWidth: 140 },
    { name: "NumAcciones", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}># ACCIONES</div>), defaultFlex: 1, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 115 },
    { name: "Precio", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>PRECIO</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", render: ({ value }) => formatNumber(value), headerAlign: "center", minWidth: 85 },
    { name: "ImprimirCertificado", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>CERTIFICADO</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonICertificado(rowData), headerAlign: "center", minWidth: 120 },
    { name: "ImprimirTitulo", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>LIQUIDACIÓN</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonITitulo(rowData), headerAlign: "center", minWidth: 125 },
    { name: "EnviarCorreo", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>CORREO<br />EMPRESA</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonELiquidacion(rowData), headerAlign: "center", minWidth: 100 },
    { name: "EnviarCorreoInver", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>CORREO<br />INVERSIONISTA</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonEmailLiquidacionInversionista(rowData), headerAlign: "center", minWidth: 135 },
    { name: "Cobrado", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>COBRADO</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonObservaciones(rowData), headerAlign: "center", minWidth: 105 },


  ] : "" ||
    userRuedaFuncionality === true ?
    [
      { name: "NombreEmpresa", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>EMPRESA</div>), defaultFlex: 1, xdefaultLocked: "end", headerAlign: "center", minWidth: 140 },
      //{ name: "OrdenId", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>ORDEN</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 90 },
      { name: "Orden", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>LIQUIDACIÓN</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 130 },
      { name: "NombreInversionista", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>INVERSIONISTA</div>), defaultFlex: 1, xdefaultLocked: "end", headerAlign: "center", minWidth: 140 },
      { name: "NumAcciones", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}># ACCIONES</div>), defaultFlex: 1, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 115 },
      { name: "Precio", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>PRECIO</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", render: ({ value }) => formatNumber(value), headerAlign: "center", minWidth: 85 },
      //{ name: "ImprimirCertificado", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>CERTIFICADO</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonICertificado(rowData), headerAlign: "center", minWidth: 120 },
      { name: "ImprimirTitulo", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>LIQUIDACIÓN</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonITitulo(rowData), headerAlign: "center", minWidth: 125 },
      { name: "EnviarCorreo", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>CORREO<br />EMPRESA</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonELiquidacion(rowData), headerAlign: "center", minWidth: 100 },
      { name: "EnviarCorreoInver", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>CORREO<br />INVERSIONISTA</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonEmailLiquidacionInversionista(rowData), headerAlign: "center", minWidth: 135 },
      //{ name: "Cobrado", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>COBRADO</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonObservaciones(rowData), headerAlign: "center", minWidth: 105 },

    ] : "" ||
      userCajaFuncionality === true ?
      [
        { name: "NombreEmpresa", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>EMPRESA</div>), defaultFlex: 1, xdefaultLocked: "end", headerAlign: "center", minWidth: 140 },
       // { name: "OrdenId", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>ORDEN</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 90 },
        { name: "Orden", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>LIQUIDACIÓN</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 130 }, 
        { name: "NombreInversionista", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>INVERSIONISTA</div>), defaultFlex: 1, xdefaultLocked: "end", headerAlign: "center", minWidth: 140 },
        { name: "NumAcciones", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}># ACCIONES</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 115 },
        { name: "Precio", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>PRECIO</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", render: ({ value }) => formatNumber(value), headerAlign: "center", minWidth: 85 },
        { name: "ImprimirCertificado", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>CERTIFICADO</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonICertificado(rowData), headerAlign: "center", minWidth: 120 },
        { name: "Cobrado", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>COBRADO</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonObservaciones(rowData), headerAlign: "center", minWidth: 105 },

      ] : "" ||
        userLiquidacionFuncionality === true ?
        [
          { name: "NombreEmpresa", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>EMPRESA</div>), defaultFlex: 1, xdefaultLocked: "end", headerAlign: "center", minWidth: 140 },
        // { name: "OrdenId", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>ORDEN</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 90 },
          
          { name: "Orden", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>LIQUIDACIÓN</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 130 },
          { name: "NombreInversionista", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>INVERSIONISTA</div>), defaultFlex: 1, xdefaultLocked: "end", headerAlign: "center", minWidth: 140 },
          { name: "NumAcciones", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}># ACCIONES</div>), defaultFlex: 1, xdefaultLocked: "end", className: "numDataGrid", headerAlign: "center", minWidth: 115 },
          { name: "Precio", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>PRECIO</div>), defaultFlex: 0.5, xdefaultLocked: "end", className: "numDataGrid", render: ({ value }) => formatNumber(value), headerAlign: "center", minWidth: 85 },
          { name: "ImprimirTitulo", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>LIQUIDACIÓN</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonITitulo(rowData), headerAlign: "center", minWidth: 125 },
          { name: "EnviarCorreo", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>CORREO<br />EMPRESA</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonELiquidacion(rowData), headerAlign: "center", minWidth: 100 },
          { name: "EnviarCorreoInver", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>CORREO<br />INVERSIONISTA</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonEmailLiquidacionInversionista(rowData), headerAlign: "center", minWidth: 135 },
          { name: "Cobrado", header: () => (<div style={{ textAlign: "center", fontSize: "12px" }}>COBRADO</div>), defaultFlex: 1, xdefaultLocked: "end", className: "colButton", render: (rowData) => renderButtonObservaciones(rowData), headerAlign: "center", minWidth: 105 },
        ] : "" ||
        [];



  return (
    <div className={["overlay3", "fade-in"].join(" ")}>

      <div className={['loading_cont', isLoading
        !== true ? "displaynone" : ""].join(' ')}><div className='single6'></div></div>
      {editComentarioLiquiOpen === "EDIT" ?
        <EditarComentarioLiqui
          sendMessage={props.sendMessage}
          closeEditComentarioLiqui={closeEditComentarioLiqui}
          liquidacionSeleccionada={liquidacionSeleccionada}
          eventoSeleccionadoId={eventoSeleccionadoId}
          usuarioId={usuarioId}
          empresaId={empresaId}
        /> : ""}


      <div className="borderContent ventanaPortafolio">
        <div className="innerShadow-height-100">
          <h2>
            Liquidaciones
            <div className="subItemCatalogo">
            </div>
          </h2>
          <div className="titleTable-search">LIQUIDACIONES
            <div className='floatright'>
              <div className="filterNeg">
                <input type="text" placeholder="Filtrar" value={plantillaFilter} onChange={(e) => {
                  setPlantillaFilter(e.target.value);
                  filterCatalogo(listaDatosAdjudicaciones !== null ? listaDatosAdjudicaciones : [], e.target.value)
                }} /><i className="fas fa-search"></i>
              </div>
            </div>
          </div>

          <DataGrid
            idProperty="AdjudicacionId"
            style={gridStyle}
            columns={columns}
            enableSelection={true}
            selection={{ mode: "single" }}
            dataSource={gridInfor}
            renderRowContextMenu={userLiquidacionFuncionality == false && userRuedaFuncionality == false ? renderRowContextMenu : ""}
            theme="amber-dark"

          />


          <div className="dividerLine">

          </div>
          {userLiquidacionFuncionality == false && userCajaFuncionality == false || 1 == 1 ?
            <div className="textCenter">
              <div className="btnCancelar" onClick={closeLiquidacion}>
                SALIR
              </div>
            </div>
            : <div className="textCenter">

            </div>}
          <div>

          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(Liquidaciones);

