import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import { updateCobrarLiquidacion, updateComentarioLiquidacion, updateParametroAct } from "../../actions/administracion";
import "../../config";
import NumberFormat from "react-number-format";
import { generateMessageAdm } from "./Util";
const EditarComentarioLiqui = (props) => {
    //console.log(props.liquidacionSeleccionada)
    //console.log(props.sendMessage)
    const dispatch = useDispatch();
    let [errors, setErrors] = useState({ "comentarioErr": "" }, { "descripcionErr": "" })

    let [liquidacionId, setLiquidacionId] = useState(props.liquidacionSeleccionada !== null ? props.liquidacionSeleccionada["Liquidacion"] : "")
    let [comentario, setComentario] = useState(props.liquidacionSeleccionada !== null ? props.liquidacionSeleccionada["Comentario"] : "")


    let [eventoSeleccionadoId] = useState(props.eventoSeleccionadoId);
    let [empresaId] = useState(props.empresaId);
    let [usuarioId] = useState(props.usuarioId);



    //let [descripcion,setDescripcion] = useState(props.parametroSeleccionado!==null?props.parametroSeleccionado["Descripcion"]:"")


    let [isError, setIsError] = useState(false)
    const { user: currentUser } = useSelector((state) => state.auth);
    const closeEditComentario = () => props.closeEditComentarioLiqui();




    const nombreRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;
    const textoRegex = /^[a-zA-Z0-9À-ÖØ-öø-ÿ{Ñ}{ñ}\s]*$/;

    function actualizar() {

        let valid = true
        //if(comentario == ""){
        //  errors["descripcionErr"] = "Ingrese descripción"
        //valid = false
        //}

        setErrors(errors)
        setIsError(true)
        if (!valid) {
            return
        }
        dispatch(updateComentarioLiquidacion(
            liquidacionId,
            comentario,
            eventoSeleccionadoId,
            usuarioId,
            empresaId

        )).then(() => {
            dispatch(updateCobrarLiquidacion(liquidacionId, eventoSeleccionadoId, usuarioId, empresaId, "0", "", "", "ASC", ""))
            props.sendMessage(generateMessageAdm("ACTUALIZAR_ORDEN"))
        }).catch(() => { }
        );

        
        closeEditComentario()
    }

    return (
        <div className="overlay3 fade-in">
            <div className="borderContent ventanaPerfil">
                <div className="innerShadow-height-100">
                    <div id="formulario-estilo">
                        <h2>
                            Liquidación
                        </h2>
                        <div className='vertical-scroll customScroll'>
                            <div className="columna-1-formulario-ventana-perfil-edit-evento-user">

                                <div className="row-form">
                                    <div className="col-30">
                                        <label>
                                            <i className="fas fa-edit"></i> <span>Comentario:</span>
                                        </label>
                                    </div>
                                    <div className="col-70">
                                        <textarea
                                            type="text"
                                            value={comentario}
                                            style={{ height: '100px' }}
                                            maxLength={1500}

                                            placeholder="Comentario"
                                            className="text-area-form"
                                            onChange={(e) => {
                                                setComentario(e.target.value);
                                                errors["comentarioErr"] = ""
                                            }}
                                        ></textarea>
                                        {errors["comentarioErr"] !== "" ? <div className='camporequerido'>{errors["comentarioErr"]}</div> : ""}
                                    </div>
                                </div>
                            </div>

                            <div className="textCenter clear-both">
                                <div className="btnGuardar" onClick={actualizar} >
                                    GUARDAR
                                </div>{" "}
                                <div className="btnCancelar" onClick={closeEditComentario}>
                                    SALIR
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(EditarComentarioLiqui);
